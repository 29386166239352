import { PageHeader } from '@app/components/layout/page/components/page-header';
import ProductTable from '@app/components/tables/product-table/product-table';
import { Button } from '@shared/components/ui/button';
import useAuth from '../../auth/use-auth';
import AlertMessage from '../../components/AlertMessage';
import Page from '../../components/layout/page/page';

import useHandleCreateNewProductAction from '@app/hooks/use-create-new-product-action';

const ProductsPage: React.FC = () => {
  const { handleCreateNewProductAction, UpsellDialogComponent } =
    useHandleCreateNewProductAction();

  const { user } = useAuth();

  const { organizationProductLimit, numberOfProducts } = user;

  return (
    <Page name="Products">
      <PageHeader
        name="Products"
        toolbar={<Button onClick={handleCreateNewProductAction}>Create</Button>}
      />

      {UpsellDialogComponent}

      {organizationProductLimit &&
        organizationProductLimit < numberOfProducts! && (
          <AlertMessage
            type="error"
            message="You have exceeded your product limit"
          />
        )}

      {
        // if product count is more than 80% of the limit, show warning
        organizationProductLimit &&
          organizationProductLimit * 0.8 <= numberOfProducts! &&
          organizationProductLimit > numberOfProducts! && (
            <AlertMessage
              type="warning"
              message={`You are nearing your limit of ${organizationProductLimit} products`}
            />
          )
      }
      {
        // if product count is more than 100% of the limit, show error
        organizationProductLimit &&
          organizationProductLimit === numberOfProducts && (
            <AlertMessage
              type="warning"
              message="You have reached your product limit"
            />
          )
      }
      <div className="mt-5 flex w-full">
        <ProductTable type="card" />
      </div>
    </Page>
  );
};

export default ProductsPage;
