import { roundNumberToNDecimalPlaces } from '@app/utils/helpers';
import { ResponsiveBar } from '@nivo/bar';

interface ComparisonFPPTBarChartProps {
  data: {
    name: string;
    farmingEmissions: number;
    processingEmissions: number;
    packagingEmissions: number;
    transportEmissions: number;
  }[];
  chartLayout: 'horizontal' | 'vertical' | undefined;
}

const colors: string[] = ['#c2ddcc', '#8cbae0', '#ffd78e', '#fdb588'];

export function ComparisonFPPTBarChart({
  data,
  chartLayout,
}: ComparisonFPPTBarChartProps) {
  const preProcessData = (
    data: ComparisonFPPTBarChartProps['data']
  ): {
    name: string;
    Farming: number;
    Processing: number;
    Packaging: number;
    Transport: number;
  }[] => {
    const result = [];
    for (let i = 0; i < data.length; i++) {
      result.push({
        name: data[i]['name'],
        Farming: roundNumberToNDecimalPlaces(
          data[i]['farmingEmissions'] * 1000,
          1
        ),
        Processing: roundNumberToNDecimalPlaces(
          data[i]['processingEmissions'] * 1000,
          1
        ),
        Packaging: roundNumberToNDecimalPlaces(
          data[i]['packagingEmissions'] * 1000,
          1
        ),
        Transport: roundNumberToNDecimalPlaces(
          data[i]['transportEmissions'] * 1000,
          1
        ),
      });
    }
    return result;
  };
  return (
    <ResponsiveBar
      data={preProcessData(data)}
      keys={['Farming', 'Processing', 'Packaging', 'Transport']}
      indexBy={'name'}
      margin={{ top: 30, right: 30, bottom: 70, left: 100 }}
      padding={0.3}
      layout={chartLayout}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      enableLabel={false}
      colors={colors}
      fill={[]}
      tooltip={({ id, color, value, data }) => (
        <div
          style={{
            padding: 12,
            color: '#000',
            background: '#fff',
            boxShadow: '5px 3px 5px #00000030',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              width: '15px',
              height: '15px',
              backgroundColor: color,
              marginRight: '7px',
            }}
          ></div>
          <span>
            {data.name} - {id} <b>{roundNumberToNDecimalPlaces(value, 2)}</b>{' '}
            gCO<sub>2</sub>e
          </span>
        </div>
      )}
      axisTop={null}
      axisRight={null}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Emissions (gCO2e)',
        legendPosition: 'middle',
        legendOffset: -60,
      }}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickValues: 8,
        tickRotation: 0,
        legendPosition: 'middle',
        legendOffset: 40,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      role="application"
      ariaLabel="comparisons fppt bar chart"
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'top-left',
          direction: 'row',
          justify: false,
          translateX: -30,
          translateY: -25,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 0,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 8,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
}
