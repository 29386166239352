/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * My Emissions API
 * We calculate food carbon footprints
 * OpenAPI spec version: 1.0.0 (v1)
 */

/**
 * * `A` - A
* `B` - B
* `C` - C
* `D` - D
* `E` - E
 */
export type EmissionsCategoryEnum = typeof EmissionsCategoryEnum[keyof typeof EmissionsCategoryEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EmissionsCategoryEnum = {
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E',
} as const;
