import React from "react";

import { Box, List, ListItem, Typography } from "@mui/material";

interface NoteBoxProps {
  notes: string[];
}

const NoteBox: React.FC<NoteBoxProps> = ({ notes }) => {
  return (
    <Box mt={3}>
      <Typography
        variant="body1"
        sx={{ color: "text.primary", fontWeight: "bold" }}
      >
        Notes/Assumptions
      </Typography>
      <Box>
        <List
          sx={{
            listStyleType: "disc",
            paddingLeft: 5,
          }}
        >
          {notes.length === 0 ? (
            <ListItem
              sx={{
                display: "list-item",
                color: "#00000038",
              }}
            >
              No Notes/Assumptions
            </ListItem>
          ) : (
            notes?.map((note, i) => (
              <ListItem
                key={i}
                dense
                sx={{
                  display: "list-item",
                  fontSize: "14px",
                }}
              >
                {note}
              </ListItem>
            ))
          )}
        </List>
        {/* {notes ? (
          <List>
            <ListItem>Some Assumption</ListItem>
            {notes?.map((note, i) => (
              <ListItem key={i}>{note}</ListItem>
            ))}
          </List>
        ) : (
          <Typography>No Notes</Typography>
        )} */}
      </Box>
    </Box>
  );
};

export default NoteBox;
