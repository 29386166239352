import { useAnalytics } from '@app/services/analytics/AnalyticsContext';
import { X } from 'lucide-react';
import { ReactNode, useState } from 'react';

interface BannerProps {
  name: string;
  visibleFrom: Date;
  visibleUntil: Date;
  dismissable?: boolean;
  children: ReactNode;
}

const withinVisibleTime = (visibleFrom: Date, visibleUntil: Date) => {
  const now = new Date();
  return now >= visibleFrom && now <= visibleUntil;
};

export function Banner({
  name,
  visibleFrom,
  visibleUntil,
  dismissable,
  children,
}: BannerProps) {
  const { track } = useAnalytics();

  const [hasDismissed, setHasDismissed] = useState<boolean>(
    localStorage.getItem(`banner-${name}-dismissed`) === 'true'
  );

  const dismiss = () => {
    setHasDismissed(true);
    localStorage.setItem(`banner-${name}-dismissed`, 'true');
    track('Dismissed Banner', { name });
  };

  if (
    (dismissable && hasDismissed) ||
    !withinVisibleTime(visibleFrom, visibleUntil)
  ) {
    return null;
  }

  return (
    <div className="relative flex min-h-10 flex-row items-center justify-center bg-secondary px-8 py-4">
      {children}
      {dismissable && (
        <div className="absolute right-0 top-0 flex h-full items-center justify-center px-2">
          <button
            onClick={dismiss}
            aria-label="Dismiss announcement"
            className="text-secondary hover:opacity-80"
          >
            <X size={24} />
          </button>
        </div>
      )}
    </div>
  );
}
