import SelectFormField from '@shared/components/form/select-form-field';
import { PackagingOption } from '@shared/components/product-form/components/recipe-packaging-row';
import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';

interface PackagingPickerProps {
  name: string;
  className?: ClassValue;
  options: PackagingOption[];
  showLabel?: boolean;
}

export function PackagingPicker({
  name,
  className,
  options,
  showLabel = true,
}: PackagingPickerProps) {
  const formatData = (packagingOptions: PackagingOption[]) => {
    return packagingOptions.map((item) => ({
      value: item.name,
      label: item.name,
    }));
  };
  return (
    <SelectFormField
      name={name}
      className={cn('', className)}
      options={formatData(options)}
      label={showLabel ? 'Type' : ''}
      includeErrorMessage
    />
  );
}
