import { PageHeader } from '@app/components/layout/page/components/page-header';
import EditDishForm, {
  editDishFormSchema,
} from '@app/components/product-form/edit-dish-form';
import {
  PatchedProductWithRelations,
  ProductWithRelations,
} from '@shared/api/types';

import { z } from 'zod';

export default function EditProductPage({
  product,
}: {
  product: ProductWithRelations;
}) {
  const getDefaultValues = (product: PatchedProductWithRelations) => {
    return {
      recipeName: product.name,
      servings: product.servings,
      netWeight: product.netWeight,
      ingredients: product.ingredients,
      packaging: product.productPackagingTypes?.map((packaging) => {
        return {
          uuid: packaging?.uuid,
          size: packaging?.size,
          name: packaging?.name,
          packagingType: packaging?.packagingType,
        };
      }),
    } as z.infer<typeof editDishFormSchema>;
  };

  const breadcrumbs = [
    {
      label: 'Products',
      url: '/products',
    },
    {
      label: `${product.name}`,
      url: `/products/${product.uuid}`,
    },
    {
      label: 'Edit',
      url: ``,
    },
  ];

  return (
    <>
      <PageHeader name={product.name} breadcrumbs={breadcrumbs} />
      <div className="max-w-form">
        <EditDishForm
          defaultValues={getDefaultValues(product)}
          productUuid={product.uuid}
        />
      </div>
    </>
  );
}
