import { ComparisonFPPTBarChart } from '@app/components/charts/comparisons-fppt-bar-chart';
import { Card, CardContent, CardHeader } from '@shared/components/ui/card';
import { Lightbulb } from 'lucide-react';
import { ComparisonResults } from './comparison-page';
import { ComparisonRow } from './components/comparison-row';

const getDifferenceText = (diff: number) => {
  if (diff > 0) {
    return `${Math.abs(diff)}% higher `;
  } else if (diff < 0) {
    return `${Math.abs(diff)}% lower `;
  }
  return 'the same ';
};

interface ComparisonPageSummaryProps {
  productAName: string;
  productBName: string;
  weights: ComparisonResults['weights'];
  emissionsPerServing: ComparisonResults['emissionsPerServing'];
  totalEmissions: ComparisonResults['totalEmissions'];
  carbonIntensity: ComparisonResults['carbonIntensity'];
  carbonRating: ComparisonResults['carbonRating'];
  emissionsServingsDifference: ComparisonResults['emissionsServingsDifference'];
  emissionsDifference: ComparisonResults['emissionsDifference'];
  carbonIntensityDifference: ComparisonResults['carbonIntensityDifference'];
  FPPTComparison: ComparisonResults['FPPTComparison'];
}

export function ComparisonPageSummary({
  productAName,
  productBName,
  weights,
  emissionsPerServing,
  totalEmissions,
  carbonIntensity,
  carbonRating,
  emissionsServingsDifference,
  emissionsDifference,
  carbonIntensityDifference,
  FPPTComparison,
}: ComparisonPageSummaryProps) {
  return (
    <div className="my-4 space-y-4">
      <Card>
        <CardHeader className="flex flex-row items-center">
          <Lightbulb className="mr-2 size-6 text-warning-400" />
          <p>
            {productAName} has a{' '}
            <b>{Math.abs(carbonIntensityDifference![0])}% </b>
            {carbonIntensityDifference![0] > 0 ? 'higher' : 'lower'} carbon
            footprint compared with {productBName}.
          </p>
        </CardHeader>
        <CardContent className="ml-8">
          <p className="text-sm">
            {productAName} has{' '}
            <b>{getDifferenceText(emissionsServingsDifference![0])}</b>
            emissions per servings,{' '}
            <b>{getDifferenceText(emissionsDifference![0])}</b> total emissions,
            and a <b>{getDifferenceText(carbonIntensityDifference![0])}</b>
            carbon intensity compared with {productBName}.
          </p>
        </CardContent>
      </Card>
      <Card>
        <CardContent className="p-4">
          <div className="grid grid-cols-3 pb-4">
            <p></p>
            <p>{productAName}</p>
            <p>{productBName}</p>
          </div>
          <header className="w-full rounded-lg bg-muted p-2 px-4 text-sm font-semibold">
            <h3>Total Results</h3>
          </header>
          <ComparisonRow
            name="Weight"
            fieldKey="totalIngredientWeight"
            values={weights!}
          />
          <ComparisonRow
            name="Emissions per serving"
            fieldKey="totalEmissionsPerServing"
            values={emissionsPerServing}
          />
          <ComparisonRow
            name="Total emissions"
            fieldKey="totalEmissions"
            values={totalEmissions}
          />
          <ComparisonRow
            name="Carbon intensity"
            fieldKey="totalEmissionsPerKg"
            values={carbonIntensity}
          />

          <ComparisonRow
            name="Carbon rating"
            fieldKey="emissionsCategory"
            values={carbonRating}
          />
          <header className="w-full rounded-lg bg-muted p-2 px-4 text-sm font-semibold">
            <h3>Breakdown of emissions by life-cycle stage</h3>
          </header>
          <div className="mt-10 h-96 w-full">
            <ComparisonFPPTBarChart
              data={FPPTComparison}
              chartLayout="vertical"
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
