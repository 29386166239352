import { EmissionsCategoryEnum, StatusEnum } from '@shared/api/types';
import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';

import labelA from './labels/label_a.svg';
import labelB from './labels/label_b.svg';
import labelC from './labels/label_c.svg';
import labelD from './labels/label_d.svg';
import labelE from './labels/label_e.svg';

export interface RatingLabel {
  rating: EmissionsCategoryEnum;
  status?: StatusEnum;
  className?: ClassValue;
}

export default function RatingLabel({
  rating,
  status,
  className,
}: RatingLabel) {
  let imageSrc;

  switch (rating) {
    case null:
      break;
    case 'A':
      imageSrc = labelA;
      break;
    case 'B':
      imageSrc = labelB;
      break;
    case 'C':
      imageSrc = labelC;
      break;
    case 'D':
      imageSrc = labelD;
      break;
    case 'E':
      imageSrc = labelE;
      break;
  }

  return (
    <img
      src={imageSrc}
      className={cn(
        'w-36',
        {
          'grayscale filter': status === 'expired',
        },
        className
      )}
      alt={`Rating: ${rating}`}
    />
  );
}
