import { ColumnDef } from '@tanstack/react-table';

import { DataTable } from '@shared/components/data-table/data-table';

import { HeaderCell } from '@shared/components/data-table/cells/header-cell';
import {
  capitalizeFirstLetter,
  getUserFriendlyDate,
  roundNumberToNDecimalPlaces,
} from '@shared/lib/utils';

import { Report, ReportCategoryEnum } from '@shared/api/types';
import { DataTableToolbar } from '@shared/components/data-table/data-table-toolbar';
import { useDataTable } from '@shared/components/data-table/hooks/use-data-table';
import { useGetStaticFilterOptions } from '@shared/components/data-table/hooks/use-get-static-filter-options';
import { DataTableFilterField } from '@shared/components/data-table/types';
import { Badge } from '@shared/components/ui/badge';
import { useNavigate } from 'react-router-dom';

function getColumns(): ColumnDef<Report>[] {
  return [
    {
      id: 'title',
      accessorKey: 'title',
      header: ({ column }) => (
        <HeaderCell column={column} name="Report" textWhite />
      ),
      cell: ({ row }) => {
        return <p className="max-w-xs truncate">{row.getValue('title')}</p>;
      },
      enableHiding: false,
      enableSorting: true,
    },
    {
      id: 'completedDate',
      accessorKey: 'completedDate',
      header: ({ column }) => (
        <HeaderCell column={column} name="Date of Completion" textWhite />
      ),
      cell: ({ row }) => {
        return (
          <p className="max-w-xs truncate">
            {getUserFriendlyDate(row.getValue('completedDate'))}
          </p>
        );
      },
      enableHiding: false,
      enableSorting: true,
    },
    {
      id: 'totalEmissions',
      accessorKey: 'totalEmissions',
      header: ({ column }) => (
        <HeaderCell
          column={column}
          name={
            <>
              Total Emissions{' '}
              <span style={{ fontSize: 12, marginLeft: '3px' }}>
                {' '}
                (kgCO<sub>2</sub>e)
              </span>
            </>
          }
          textWhite
        />
      ),
      cell: ({ row }) => {
        const totalEmissions: number = row.getValue('totalEmissions');
        return (
          <p className="max-w-xs truncate">
            {roundNumberToNDecimalPlaces(totalEmissions, 2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </p>
        );
      },
      enableHiding: false,
      enableSorting: true,
    },
    {
      id: 'averageEmissionsPerKg',
      accessorKey: 'averageEmissionsPerKg',
      header: ({ column }) => (
        <HeaderCell
          column={column}
          name={
            <>
              Carbon Intensity{' '}
              <span style={{ fontSize: 12, marginLeft: '3px' }}>
                {' '}
                (kgCO<sub>2</sub>e/kg)
              </span>
            </>
          }
          textWhite
        />
      ),
      cell: ({ row }) => {
        const averageEmissionsPerKg: number = row.getValue(
          'averageEmissionsPerKg'
        );
        return (
          <p className="max-w-xs truncate">
            {roundNumberToNDecimalPlaces(averageEmissionsPerKg, 2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </p>
        );
      },
      enableHiding: false,
      enableSorting: true,
    },
    {
      id: 'category',
      accessorKey: 'category',
      header: ({ column }) => (
        <HeaderCell column={column} name="Category" textWhite />
      ),
      cell: ({ row }) => {
        const category: ReportCategoryEnum = row.getValue('category');
        return (
          <p className="max-w-xs truncate">
            <Badge>{capitalizeFirstLetter(category)}</Badge>
          </p>
        );
      },
      filterFn: 'arrIncludesSome',
      enableHiding: false,
      enableSorting: false,
    },
  ];
}

interface ReportsTableProps {
  reports: Report[];
}

export default function ReportsTable({ reports }: ReportsTableProps) {
  const navigate = useNavigate();

  const filterFields: DataTableFilterField<Report>[] = [
    {
      label: 'Reports',
      value: 'title',
      placeholder: 'Search reports...',
    },
    {
      label: 'Category',
      value: 'category',
      options: useGetStaticFilterOptions(ReportCategoryEnum),
    },
  ];

  const { table } = useDataTable({
    data: reports,
    columns: getColumns(),
    filterFields,
  });

  return (
    <div>
      <DataTable
        table={table}
        enablePagination
        className="bg-gray-00"
        headerConfig={{
          className: 'bg-primary',
        }}
        onRowClick={(row) => {
          const reportUuid = row!.original.uuid;
          const reportCategory = row!.original.category;
          navigate(`/reports/${reportCategory}/${reportUuid}`);
        }}
      >
        <DataTableToolbar table={table} filterFields={filterFields} />
      </DataTable>
    </div>
  );
}
