import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@mui/material";
import { Country } from "./CreateIngredientPage";
import { useMutation } from "@tanstack/react-query";
import { CreateIngredient } from "../../api/api";
import InfoPopOver from "../../components/InfoPopOver";
interface Props {
  countries: Country[];
  foods: any[]; // add types
  onSuccessfulSubmit: (uuid: string) => void;
}
function getFlagEmoji(countryCode: string) {
  return countryCode
    .toUpperCase()
    .replace(/./g, (char) => String.fromCodePoint(127397 + char.charCodeAt(0)));
}

function CreateIngredientForm({ countries, foods, onSuccessfulSubmit }: Props) {
  // use useForm hook to create a form instance
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const [foodNotInList, setFoodNotInList] = useState(false);

  const countriesList = [
    // { name: "I don't know", iso_code: "", continent: "" },
    ...countries,
  ];

  const createIngredient = useMutation(
    (data: {
      // cannot pass multiple parameters to this method so need to pass them as an object instead ( see https://stackoverflow.com/questions/73117989/cant-i-put-multiple-data-arguments-in-the-react-query-usemutation-hook)
      baseFoodUuid: string;
      noBaseFood: boolean;
      ingredientName: string;
      selectedCountryIsoCode: any; // string or null
    }) => {
      return CreateIngredient(
        data.baseFoodUuid,
        data.noBaseFood,
        data.ingredientName,
        data.selectedCountryIsoCode // this is null if no country is selected
      ).then((res) => {
        onSuccessfulSubmit(res.uuid);
        return res;
      });
    }
  );

  // create a submit handler that calls the onCreateIngredient prop with the form data
  const onSubmit = (data: any) => {
    let { ingredientName, baseFoodUuid, countryIsoCode } = data;
    countryIsoCode =
      countryIsoCode == "" || countryIsoCode == undefined
        ? null
        : countryIsoCode;
    baseFoodUuid =
      baseFoodUuid == "" || baseFoodUuid == undefined || foodNotInList == true
        ? null
        : baseFoodUuid;

    createIngredient.mutate({
      baseFoodUuid: baseFoodUuid,
      noBaseFood: foodNotInList,
      ingredientName: ingredientName,
      selectedCountryIsoCode: countryIsoCode,
    });
  };

  // custom logic to filter foods by name and synonym instead of just name
  const filterOptions = (options: any, { inputValue }: any) => {
    return options.filter((option: any) => {
      let label = option.name.toLowerCase();
      let synonymsAsString = "";
      for (let i = 0; i < option.synonyms.length; i++) {
        synonymsAsString += option.synonyms[i].toLowerCase() + " ";
      }
      label += " " + synonymsAsString;
      const input = inputValue.toLowerCase();
      return label.includes(input);
    });
  };

  return (
    <>
      <Grid container spacing={4} sx={{ width: "100%" }}>
        <Grid item xs={12} md={8}>
          <FormLabel component="legend" sx={{ marginBottom: "8px" }}>
            What food is this?*
          </FormLabel>
          <Controller
            name="baseFoodUuid"
            control={control}
            rules={{ required: foodNotInList ? false : true }}
            render={({ field }) => (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={foods}
                filterOptions={filterOptions}
                getOptionLabel={(option: any) => {
                  // this determines what's shown as the autocomplete's text
                  /* if (getValues("baseFoodUuid") !== "") {
                    return option.name;
                  }
                  return ""; */
                  return option.name;
                }}
                renderOption={(
                  props,
                  option // this determines what gets shown as the autocomplete's options
                ) => (
                  <Tooltip title={""} placement="right">
                    <li {...props}>{option.name}</li>
                  </Tooltip>
                )}
                disabled={foodNotInList}
                onChange={(event, value) => {
                  field.onChange(value?.uuid);
                }}
                value={field.value}
                fullWidth={true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      backgroundColor: foodNotInList
                        ? "rgba(224, 224, 224, 0.8)"
                        : "white",
                    }}
                    placeholder="Start typing to search for a food.."
                  />
                )}
              />
            )}
          />
          {/* display error message if validation fails */}
          {errors.baseFoodUuid && (
            <p>
              A food is required, unless the "Food not in list" checkbox is
              ticked
            </p>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <FormGroup
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <FormControlLabel
                sx={{ marginBottom: "0px", marginRight: "6px" }} // conditionally render based on whether error message for basefoodUuid appears
                control={
                  <Checkbox
                    {...register("foodNotInList")}
                    onChange={(e) => {
                      setFoodNotInList(e.target.checked);
                    }}
                  />
                }
                label="Food not in list"
              />
              <InfoPopOver subjectMatter={"NoSuitableFoodMatch"} width="20px" />
            </div>
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={10}>
          <FormLabel component="legend" sx={{ marginBottom: "8px" }}>
            Custom Ingredient Name
          </FormLabel>
          <TextField
            id="ingredient_name"
            sx={{ backgroundColor: "white" }}
            //name="ingredient_name"
            placeholder="Whole Foods, gala apples, 5X2kg"
            type="text"
            fullWidth={true}
            // use register function to register the TextField component with react-hook-form
            {...register("ingredientName", {
              required: foodNotInList ? true : false,
            })}
          />
          {/* display error message if validation fails */}
          {errors.ingredientName && (
            <p>
              Ingredient name is required if the "Food not in list" checkbox is
              ticked
            </p>
          )}
        </Grid>{" "}
        <Grid item xs={12} md={10}>
          {" "}
          <FormLabel component="legend" sx={{ marginBottom: "8px" }}>
            Country of origin
          </FormLabel>
          <Controller
            name="countryIsoCode"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={
                  // countries list with I don't know option in the beginning
                  [{ name: "I don't know", iso_code: "" }].concat(countriesList)
                }
                defaultValue={{ name: "I don't know", iso_code: "" }}
                sx={{ backgroundColor: "white" }}
                getOptionLabel={(option: any) =>
                  getFlagEmoji(option.iso_code) + " " + option.name
                }
                onChange={(event, value) => field.onChange(value?.iso_code)}
                value={field.value}
                fullWidth={true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Start typing to search for a country.."
                  />
                )}
              />
            )}
          />
          {/* display error message if validation fails */}
          {errors.countryIsoCode && <p>Country of origin is required</p>}
        </Grid>
        <Grid item xs={10}>
          {/* use handleSubmit function to wrap the onSubmit handler */}
          <Button
            type="submit"
            variant="contained"
            sx={{ textTransform: "none", borderRadius: "10px", float: "right" }}
            onClick={handleSubmit(onSubmit)}
          >
            Create
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default CreateIngredientForm;
