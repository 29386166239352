import { useEffect } from 'react';
import { axiosPrivate } from '../api/axios';
import useAuth from '../auth/use-auth';
import useLogout from '../auth/use-logout';
import useRefreshToken from './useRefreshToken';

import { useNavigate } from 'react-router-dom';

const useAxiosPrivate = () => {
  const refresh = useRefreshToken();
  const navigate = useNavigate();
  const logout = useLogout();
  const { user, token } = useAuth();

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers?.Authorization) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error.config;
        if (error.response.status === 401 && !prevRequest._retry) {
          prevRequest._retry = true;
          const newAccesstoken = await refresh();
          prevRequest.headers['Authorization'] = `Bearer ${newAccesstoken}`;
          return axiosPrivate(prevRequest);
        }

        if (error.response.status === 401 && prevRequest._retry) {
          await logout();
          navigate('/login');
        }

        return Promise.reject(error);
      }
    );
    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [user, refresh]);

  return axiosPrivate;
};

export default useAxiosPrivate;
