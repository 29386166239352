import { useGetInfiniteProductsList } from '@shared/api';
import { ProductList } from '@shared/api/types';
import { useDebounce } from '@shared/components/data-table/hooks/use-debounce';
import RatingCloud, {
  RatingCloudProps,
} from '@shared/components/ratings/rating-cloud';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
} from '@shared/components/ui/command';
import { Loader2 } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';

interface ProductComparisonCommandProps {
  product_a: ProductList['uuid'];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ProductComparisonCommand({
  product_a,
  setOpen,
}: ProductComparisonCommandProps) {
  const navigate = useNavigate();

  const { ref, inView } = useInView();
  const [search, setSearch] = useState('' as string);
  const debouncedSearch = useDebounce(search, 500);

  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetInfiniteProductsList({
    status: ['complete'],
    page_size: 50,
    search: debouncedSearch || undefined,
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView, hasNextPage]);

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center p-4">
        <Loader2 className="animate-spin" />
      </div>
    );
  }
  if (data?.pages && data.pages.length < 1)
    return <div className="p-4 text-sm">No products found</div>;

  if (isError) <div className="p-4 text-sm">Error fetching products</div>;

  return (
    <Command>
      <CommandList>
        <CommandEmpty>No products found.</CommandEmpty>
        <CommandGroup>
          {data?.pages.map((page) => {
            return page.results
              ?.filter((product) => product.uuid !== product_a)
              .map((product) => {
                return (
                  <CommandItem
                    key={product.uuid}
                    value={product.uuid}
                    onSelect={async (value) => {
                      navigate({
                        pathname: '/product-comparison',
                        search: new URLSearchParams({
                          product_a: product_a,
                          product_b: value,
                        }).toString(),
                      });
                      setOpen(false);
                    }}
                    className="flex min-h-10 w-full justify-between"
                  >
                    <span>{product.name} </span>
                    {product.emissionsCategory && (
                      <RatingCloud
                        status={product.status}
                        rating={
                          product.emissionsCategory as RatingCloudProps['rating']
                        }
                      />
                    )}
                  </CommandItem>
                );
              });
          })}
          <div ref={ref}>
            {isFetchingNextPage && (
              <span className="p-4 py-2 text-center text-sm">Loading...</span>
            )}
          </div>
        </CommandGroup>
      </CommandList>
    </Command>
  );
}
