import { zodResolver } from '@hookform/resolvers/zod';
import { useUpdateProduct } from '@shared/api';
import Heading from '@shared/components/content/heading';
import { Icons } from '@shared/components/content/icons';
import InputFormField from '@shared/components/form/input-form-field';
import { recipeNameSchema } from '@shared/components/product-form/schema';
import { Button } from '@shared/components/ui/button';
import { Form } from '@shared/components/ui/form';
import { useToast } from '@shared/components/ui/use-toast';
import sentry from '@shared/services/sentry';
import { KeyboardEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

interface ProductDetailNameProps {
  name: string;
  productUuid: string;
}

const productNameSchema = z.object({
  recipeName: recipeNameSchema,
});

export default function ProductDetailName({
  name,
  productUuid,
}: ProductDetailNameProps) {
  const [editMode, setEditMode] = useState(false);
  const { toast } = useToast();

  const { mutateAsync: updateProduct, isLoading: updatingProduct } =
    useUpdateProduct();
  const form = useForm<z.infer<typeof productNameSchema>>({
    resolver: zodResolver(recipeNameSchema),
    defaultValues: {
      recipeName: name,
    },
  });

  const { getValues, reset, resetField } = form;

  const handleNameChange = async () => {
    try {
      const validField = await form.trigger('recipeName');
      if (!validField) return;
      const name = getValues('recipeName');

      const updatedProduct = await updateProduct({
        productUuid,
        data: {
          name,
        },
      });
      resetField('recipeName', { defaultValue: updatedProduct.name });
      setEditMode(false);
    } catch (error) {
      sentry.log(error);
      toast({
        variant: 'destructive',
        title: 'An error occurred while updating the product name',
        description: 'Please try again or contact us if the error persists',
      });
    }
  };

  const handleRecipeNameKeyDown = async (
    event: KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleNameChange();
    }
  };

  return (
    <div>
      {!editMode && (
        <div className="flex items-center">
          <Heading variant="subtitle" className="font-semibold">
            {name}
            <Button
              variant="ghost"
              size="icon"
              className="ml-3 size-3.5 text-gray-500"
              onClick={() => setEditMode(true)}
              icon={<Icons.pencil />}
            />
          </Heading>
        </div>
      )}

      {editMode && (
        <Form {...form}>
          <form className="flex w-full items-center space-x-1">
            <InputFormField
              name="recipeName"
              className="col-span-8 flex h-8  w-max items-center"
              inputClassName="h-8 border-none "
              includeErrorMessage
              onKeyDown={handleRecipeNameKeyDown}
              disabled={updatingProduct}
            />
            <Button
              type="button"
              onClick={handleNameChange}
              variant="ghost"
              size="icon"
              disabled={updatingProduct}
              loading={updatingProduct}
            >
              {!updatingProduct && <Icons.check className="size-4" />}
            </Button>

            <Button
              type="button"
              onClick={() => {
                setEditMode(false);
                reset();
              }}
              variant="ghost"
              size="icon"
              disabled={updatingProduct}
            >
              <Icons.x className="size-4" />
            </Button>
          </form>
        </Form>
      )}
    </div>
  );
}
