import Page from '../../components/layout/page/page';
import PasswordResetForm from '../forms/password-reset-form';

const PasswordResetPage = () => {
  return (
    <Page name="New Password">
      <div className="flex items-center justify-center">
        <PasswordResetForm />
      </div>
    </Page>
  );
};

export default PasswordResetPage;
