/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * My Emissions API
 * We calculate food carbon footprints
 * OpenAPI spec version: 1.0.0 (v1)
 */

/**
 * * `restaurant` - Restaurant
* `caterer` - Caterer
* `wholesaler-distributor` - Wholesaler / Distributor
* `food-beverage-producer` - Food & Beverage Producer
* `retailer` - Retailer
* `consultancy` - Consultancy
* `other` - Other
 */
export type OrganizationCategoryEnum = typeof OrganizationCategoryEnum[keyof typeof OrganizationCategoryEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationCategoryEnum = {
  restaurant: 'restaurant',
  caterer: 'caterer',
  'wholesaler-distributor': 'wholesaler-distributor',
  'food-beverage-producer': 'food-beverage-producer',
  retailer: 'retailer',
  consultancy: 'consultancy',
  other: 'other',
} as const;
