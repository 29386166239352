import { RatingCloudProps } from '@shared/components/ratings/rating-cloud';
import { Card, CardContent, CardHeader } from '@shared/components/ui/card';
import { Lightbulb } from 'lucide-react';
import { emissionsFactors } from './comparison-context-data';
import ComparisonVisualisations from './components/comparison-visualisation';

export interface ComparisonProduct {
  name: string;
  rating: RatingCloudProps['rating'];
}

interface ComparisonPageVisualisationsProps {
  productA: ComparisonProduct;
  productB: ComparisonProduct;
  emissionsSavings: number;
}

export function ComparisonPageVisualisations({
  productA,
  productB,
  emissionsSavings,
}: ComparisonPageVisualisationsProps) {
  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <h3 className="text-lg">Visualising the difference</h3>
        <p>
          We can place the emission savings in context by comparing transport
          distances covered by various modes of transport.
        </p>
      </div>

      <ComparisonVisualisations
        productA={productA}
        productB={productB}
        emissionsSavings={emissionsSavings}
      />

      <Card className="hidden">
        <CardHeader className="flex flex-row items-center">
          <Lightbulb className="mr-2 size-6 text-warning-400" />
          <p>Example statements:</p>
        </CardHeader>
        <CardContent className="ml-8">
          <p className="mb-2 text-sm">
            {productA.name} has X% lower emissions than {productB.name}, this is
            the same as;
          </p>
          <ul className="list-inside list-disc text-sm ">
            {emissionsFactors.map((factor, i) => {
              if (i < 3) {
                return (
                  <li key={factor.description}>
                    Travelling{' '}
                    {(
                      Math.abs(emissionsSavings / 1000) / factor.factor
                    ).toFixed(2)}
                    km {factor.contextText} ({factor.source})
                  </li>
                );
              }
            })}
          </ul>
        </CardContent>
      </Card>
    </div>
  );
}
