import { useCallback, useMemo } from 'react';

const HUBSPOT_ID = 25639734;
const scriptSrc = `//js-eu1.hs-scripts.com/${HUBSPOT_ID}.js`;
const HUBSPOT_ENABLED = import.meta.env.MODE !== 'development';

interface Window {
  _hsq?: Array<[string, ...unknown[]]>;
  HubSpotConversations?: {
    widget: {
      remove: () => void;
      hide: () => void;
    };
  };
}

function loadHubSpotScript() {
  const script = document.createElement('script');
  script.src = scriptSrc;
  script.async = true;
  script.defer = true;
  document.body.appendChild(script);
}

export function useHubspot() {
  const init = useCallback(() => {
    if (HUBSPOT_ENABLED && !(window as Window)._hsq) {
      loadHubSpotScript();
    }
  }, []);

  const remove = useCallback(() => {
    const script = document.querySelector(`script[src="${scriptSrc}"]`);

    if (script) {
      script.remove();
    }

    if ((window as Window)._hsq) {
      delete (window as Window)._hsq;
    }
  }, []);

  const identify = useCallback((email: string) => {
    const hubspot = (window as Window)._hsq;
    if (hubspot) {
      hubspot.push([
        'identify',
        {
          email,
        },
      ]);
    }
  }, []);

  const hubspot = useMemo(() => {
    return {
      init,
      remove,
      identify,
    };
  }, [init, remove, identify]);

  return hubspot;
}
