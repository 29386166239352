import { useCreateProcessing, useProcessingFileCreate } from '@shared/api';
import { ProcessingTypeCreate } from '@shared/api/types';
import { Icons } from '@shared/components/content/icons';
import Text from '@shared/components/content/text';
import { uploadFile } from '@shared/lib/utils';
import sentry from '@shared/services/sentry';
import { useState } from 'react';
import { z } from 'zod';
import CreatePptForm, { createPptSchema } from './create-ppt-form';

const processingDescriptionPlaceholder =
  'e.g. Assembly line (components put together manually), products packaged with packer, cold storage on-site for 8 hours.';
const namePlaceholder = 'Enter a name for your processing step';

interface CreateProcessingFormProps {
  onProcessingCreated?: (data: ProcessingTypeCreate) => void;
}

export default function CreateProcessingForm({
  onProcessingCreated,
}: CreateProcessingFormProps) {
  const { mutateAsync: createProcessing } = useCreateProcessing();
  const { mutateAsync: confirmProcessingFileUpload } =
    useProcessingFileCreate();
  const [isLoading, setIsLoading] = useState(false);

  const handleProcessingCreate = async (
    values: z.infer<typeof createPptSchema>
  ) => {
    try {
      setIsLoading(true);
      const createItemResponse = await createProcessing({
        data: {
          name: values.name,
          description: values.description,
          fileName: values.file.name,
        },
      });
      const { uuid: pptItemUuid, fileUuid } = createItemResponse;

      const uploadResponse = await uploadFile({
        data: createItemResponse,
        file: values.file,
      });

      const uploadSuccessful = uploadResponse!.status === 204;

      await confirmProcessingFileUpload({
        processingTypeUuid: pptItemUuid,
        processingTypeFileUuid: fileUuid,
        data: { uploadSuccessful: uploadSuccessful },
      });
      onProcessingCreated?.(createItemResponse);
    } catch (error) {
      sentry.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <CreatePptForm
      namePlaceholder={namePlaceholder}
      descriptionPlaceholder={processingDescriptionPlaceholder}
      onFormSubmit={(values) => handleProcessingCreate(values)}
      isLoading={isLoading}
      infoSection={<ProcessingInfoAlert />}
    />
  );
}

const ProcessingInfoAlert = () => {
  return (
    <Text>
      To add a processing step, you will need to fill out one of the following
      templates and upload it through this form.
      <br />
      <Icons.paperClip className=" inline size-3.5" /> Template 1:{' '}
      <a
        href="
              https://myemissions-platform-production.s3.eu-west-2.amazonaws.com/template-files/processing_production_run_template.xlsx"
        rel="noopener noreferrer"
        className="underline underline-offset-2"
      >
        report total energy use and production
      </a>{' '}
      (‘top down approach’)
      <br />
      <Icons.paperClip className=" inline size-3.5" /> Template 2:{' '}
      <a
        href="
              https://myemissions-platform-production.s3.eu-west-2.amazonaws.com/template-files/processing_machines_used_template.xlsx"
        rel="noopener noreferrer"
        className="underline underline-offset-2"
      >
        list machines used{' '}
      </a>
      (‘bottom up approach’)
    </Text>
  );
};
