import Page from '@app/components/layout/page/page';
import { useHubspot } from '@app/services/hubspot/useHubspot';
import { useEffect } from 'react';
import SignupForm from './signup-form';
import SignupInfo from './signup-info';

export default function SignupPage() {
  const hubspot = useHubspot();

  useEffect(() => {
    hubspot.init();

    return () => {
      hubspot.remove();
    };
  }, [hubspot]);

  return (
    <Page name="Sign Up">
      <div className="flex size-full items-center justify-center p-2 md:p-0">
        <div className="space-y-4 lg:flex lg:space-x-4 lg:space-y-0">
          <SignupForm />
          <SignupInfo />
        </div>
      </div>
    </Page>
  );
}
