import { Account } from '@shared/api/types';
import { AxiosResponse } from 'axios';
import { TProductResult } from '../types/products';
import axios, { axiosPrivate } from './axios';

// export const PREFIX
export const URL_VERSION = 'app/';

export const REFRESH_TOKEN_URL = URL_VERSION + 'token/refresh2/';

export const LOGIN_URL = URL_VERSION + 'login/';
export const LOGOUT_URL = URL_VERSION + 'logout/';
export const HOME_URL = URL_VERSION + 'home/';
export const ASSESSMENT_URL = URL_VERSION + 'assessments/';
export const PACKAGING_URL = URL_VERSION + 'packaging/';
export const PROCESSING_URL = URL_VERSION + 'processing/';
export const TRANSPORT_URL = URL_VERSION + 'transport/';
export const COLLECTIONS_URL = URL_VERSION + 'collections/';
export const REPORTS_URL = URL_VERSION + 'reports/';
export const INGREDIENTS_URL = URL_VERSION + 'organization_foods/';
export const ACCOUNT_URL = URL_VERSION + 'account/';
export const PASSWORD_CHANGE_URL = URL_VERSION + 'password/change/';
export const PASSWORD_RESET_URL = URL_VERSION + 'password/reset/';
export const PASSWORD_RESET_CONFIRM = URL_VERSION + 'password/reset/confirm';
export const COUNTRIES_URL = URL_VERSION + 'attributes/countries/';
export const UNITS_URL = URL_VERSION + 'units/';
export const INGREDIENT_CHOICES = URL_VERSION + 'ingredient_choices/';
export const TARGETS_URL = URL_VERSION + 'targets/';
export const TARGETS_METRICS_URL = URL_VERSION + 'targets/metrics/';
export const PRODUCTS_CSV_URL = URL_VERSION + 'products/csv/';

// NEW API
export const PRODUCTS_URL = URL_VERSION + 'products/';
export const ORGANIZATION_FOOD_URL = URL_VERSION + 'organization_foods/';

export const FOODS_URL = URL_VERSION + 'foods/';

export const getProductsCsvExport = (collectionUuid?: string) => {
  let url = PRODUCTS_CSV_URL;

  if (collectionUuid) {
    // Append collectionUuid as a query parameter if provided
    url += `?collection=${collectionUuid}`;
  }

  return axiosPrivate.get(url, {
    withCredentials: true,
  });
};

export const LoginClient = (email: string, password: string) => {
  return axios.post(
    LOGIN_URL,
    {
      email,
      password,
    },
    {
      withCredentials: true,
    }
  );
};

export const CreateCollection = (
  name: string,
  description: string,
  products: string[]
) => {
  return axiosPrivate.post(COLLECTIONS_URL, {
    name,
    description,
    products,
  });
};

export const CreatePackaging = async (
  name: string,
  fileName: string,
  description: string
) => {
  const res = await axiosPrivate.post(PACKAGING_URL, {
    name,
    fileName,
    description,
  });

  return res.data;
};

export const confirmPackagingFileUpload = async (
  packagingTypeUuid: string,
  fileUuid: string,
  uploadSuccessful: boolean
) => {
  const res = await axiosPrivate.post(
    `${PACKAGING_URL}${packagingTypeUuid}/file/${fileUuid}/`,
    {
      uploadSuccessful: uploadSuccessful,
    }
  );
  return res.data;
};

export const CreateProcessing = async (
  name: string,
  fileName: string,
  description: string
) => {
  const res = await axiosPrivate.post(PROCESSING_URL, {
    name,
    fileName,
    description,
  });

  return res.data;
};

export const confirmProcessingFileUpload = async (
  processingTypeUuid: string,
  fileUuid: string,
  uploadSuccessful: boolean
) => {
  const res = await axiosPrivate.post(
    `${PROCESSING_URL}${processingTypeUuid}/file/${fileUuid}/`,
    {
      uploadSuccessful: uploadSuccessful,
    }
  );
  return res.data;
};

export const CreateTransport = async (
  name: string,
  fileName: string,
  description: string
) => {
  const res = await axiosPrivate.post(TRANSPORT_URL, {
    name,
    fileName,
    description,
  });

  return res.data;
};

export const confirmTransportFileUpload = async (
  transportUuid: string,
  fileUuid: string,
  uploadSuccessful: boolean
) => {
  const res = await axiosPrivate.post(
    `${TRANSPORT_URL}${transportUuid}/file/${fileUuid}/`,
    {
      uploadSuccessful: uploadSuccessful,
    }
  );
  return res.data;
};

export const CreateAssessment = (productUuid: string) => {
  return axiosPrivate.post(ASSESSMENT_URL, {
    productUuid,
  });
};

export const CreateIngredient = async (
  baseFoodUuid: string,
  noBaseFood: boolean,
  description: string = '',
  countryOfOrigin: string
) => {
  const response = await axiosPrivate.post(INGREDIENTS_URL, {
    baseFoodUuid,
    noBaseFood,
    description,
    countryOfOrigin,
  });

  return response.data;
};

export const EditCollection = (
  collectionUuid: string,
  name: string,
  description: string,
  products: string[]
) => {
  return axiosPrivate.put(`${COLLECTIONS_URL}${collectionUuid}/`, {
    name,
    description,
    products,
  });
};

export const ResetPasswordClient = (email: string) => {
  return axios.post(PASSWORD_RESET_URL, {
    email,
  });
};

export const ResetPasswordChangeClient = (
  new_password1: string,
  new_password2: string,
  uid?: string,
  token?: string
) => {
  return axios.post(
    `${PASSWORD_RESET_CONFIRM}/${uid}/${token}/`,
    {
      new_password1,
      new_password2,
      uid,
      token,
    },
    {
      withCredentials: true,
    }
  );
};

// DEPRECATING
export async function getIngredientsData() {
  const response = await axiosPrivate.get(INGREDIENTS_URL, {
    withCredentials: true,
  });
  return response.data;
}

// NEW API
export async function getOrganizationFoodsData() {
  const response = await axiosPrivate.get(ORGANIZATION_FOOD_URL, {
    withCredentials: true,
  });
  return response.data;
}

export async function getProductIngredientsData(productUuid: string) {
  const response = await axiosPrivate.get(
    `${PRODUCTS_URL}${productUuid}/ingredients/`,
    {
      withCredentials: true,
    }
  );
  return response.data;
}

export function getAccountData(
  accessToken: string
): Promise<AxiosResponse<Account>> {
  return axiosPrivate.get(ACCOUNT_URL, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    withCredentials: false,
  });
}

export async function getDashboardData() {
  const response = await axiosPrivate.get(HOME_URL, {
    withCredentials: true,
  });
  return response.data;
}

// new api
export async function getProductsData() {
  const response = await axiosPrivate.get(PRODUCTS_URL, {
    withCredentials: true,
  });
  return response.data;
}

export async function getCountriesData() {
  const response = await axiosPrivate.get(COUNTRIES_URL, {
    withCredentials: true,
  });
  return response.data;
}

// new api
export async function getProductData(productUuid: string) {
  const response = await axiosPrivate.get(`${PRODUCTS_URL}${productUuid}`, {
    withCredentials: true,
  });
  return response.data;
}

export async function getProductResultData(
  productUuid: string
): Promise<TProductResult> {
  const response = await axiosPrivate.get(
    `${PRODUCTS_URL}${productUuid}/result/`,
    {
      withCredentials: true,
    }
  );
  return response.data;
}

export async function getAssessmentData(assessmentUuid: string) {
  const response = await axiosPrivate.get(
    `${ASSESSMENT_URL}${assessmentUuid}`,
    {
      withCredentials: true,
    }
  );
  return response.data;
}

export async function getCollectionsData() {
  const response = await axiosPrivate.get(COLLECTIONS_URL, {
    withCredentials: true,
  });
  return response.data;
}
export async function getCollectionData(collectionUuid: string) {
  const response = await axiosPrivate.get(
    `${COLLECTIONS_URL}${collectionUuid}`,
    {
      withCredentials: true,
    }
  );
  return response.data;
}

export async function deleteCollectionData(collectionUuid: string) {
  const response = await axiosPrivate.delete(
    `${COLLECTIONS_URL}${collectionUuid}`,
    {
      withCredentials: true,
    }
  );
  return response.data;
}

export async function getReports() {
  const response = await axiosPrivate.get(REPORTS_URL, {
    withCredentials: true,
  });
  return response.data;
}

export async function getReportData(reportUuid: string) {
  const response = await axiosPrivate.get(`${REPORTS_URL}${reportUuid}`, {
    withCredentials: true,
  });
  return response.data;
}

export async function getAssessmentListData() {
  const response = await axiosPrivate.get(ASSESSMENT_URL, {
    withCredentials: true,
  });
  return response.data;
}

export async function getPackagingListData() {
  const response = await axiosPrivate.get(PACKAGING_URL, {
    withCredentials: true,
  });
  return response.data;
}

export async function getFoodsData() {
  const response = await axiosPrivate.get(FOODS_URL, {
    withCredentials: true,
  });
  return response.data;
}

export async function getProcessingListData() {
  const response = await axiosPrivate.get(PROCESSING_URL, {
    withCredentials: true,
  });
  return response.data;
}

export async function getTransportListData() {
  const response = await axiosPrivate.get(TRANSPORT_URL, {
    withCredentials: true,
  });
  return response.data;
}

export async function getPackagingDetailData(packagingUuid: string) {
  const response = await axiosPrivate.get(`${PACKAGING_URL}${packagingUuid}`, {
    withCredentials: true,
  });
  return response.data;
}

export const logoutClient = () => {
  return axios.post(
    LOGOUT_URL,
    {},
    {
      withCredentials: true,
    }
  );
};

export const CreateProduct = async (
  name: string,
  servings: number | null,
  isProductInformationDataComplete?: boolean
) => {
  const res = await axiosPrivate.post(PRODUCTS_URL, {
    name,
    servings,
    isProductInformationDataComplete: isProductInformationDataComplete || false,
  });

  return res.data;
};

export const UpdateProduct = async (
  product_id: string,
  name: string,
  servings: number | null,
  weightPerServing: number | null,
  isProductInformationDataComplete?: boolean
) => {
  const res = await axiosPrivate.patch(`${PRODUCTS_URL}${product_id}/`, {
    name,
    servings,
    weightPerServing,
    isProductInformationDataComplete: isProductInformationDataComplete || false,
  });
  return res.data;
};

export const SubmitProductForAssessment = async (product_id: string) => {
  const res = await axiosPrivate.post(`${PRODUCTS_URL}${product_id}/submit/`, {
    withCredentials: true,
  });
  return res.data;
};

export const UpdateProductStatus = async (product_id: string) => {
  const res = await axiosPrivate.post(`${PRODUCTS_URL}${product_id}/edit/`, {
    withCredentials: true,
  });
  return res.data;
};

export const UpdateProductMarkAsComplete = async (
  product_id: string,
  section: string,
  isComplete: boolean
) => {
  const res = await axiosPrivate.patch(`${PRODUCTS_URL}${product_id}/`, {
    [section]: isComplete,
  });
  return res.data;
};

// PRODUCT INGREDIENTS ---------------------------------------------------------------
// GET INGREDIENTS
// POST INGREDIENTS
export const PostProductIngredient = async (
  product_uuid: string,
  ingredient: {
    productUuid: string;
    organizationFoodUuid: string;
    quantity: number | null;
    unitUuid: string | null;
  }
) => {
  const res = await axiosPrivate.post(
    `${PRODUCTS_URL}${product_uuid}/ingredients/`,
    {
      productUuid: ingredient.productUuid,
      organizationFoodUuid: ingredient.organizationFoodUuid,
      quantity: ingredient.quantity,
      unitUuid: ingredient.unitUuid,
    },

    {
      withCredentials: true,
    }
  );
  return res.data;
};

// PATCH INGREDIENTS
export const PatchProductIngredient = async (
  product_uuid: string,
  ingredient_uuid: string,
  ingredient: {
    productUuid: string;
    organizationFoodUuid: string;
    quantity: number | null;
    unitUuid: string | null;
  }
) => {
  const res = await axiosPrivate.patch(
    `${PRODUCTS_URL}${product_uuid}/ingredients/${ingredient_uuid}/`,
    {
      productUuid: ingredient.productUuid,
      organizationFoodUuid: ingredient.organizationFoodUuid,
      quantity: ingredient.quantity,
      unitUuid: ingredient.unitUuid,
    },

    {
      withCredentials: true,
    }
  );
  return res.data;
};

// DELETE INGREDIENTS
export const DeleteProductIngredient = async (
  product_uuid: string,
  ingredient_uuid: string
) => {
  const res = await axiosPrivate.delete(
    `${PRODUCTS_URL}${product_uuid}/ingredients/${ingredient_uuid}/`,

    {
      withCredentials: true,
    }
  );
  return res.data;
};

// PRODUCT PACKAGING ---------------------------------------------------------------

// GET PACKAGING
export const GetProductPackaging = async (product_uuid: string) => {
  const res = await axiosPrivate.get(
    `${PRODUCTS_URL}${product_uuid}/packaging/`,
    {
      withCredentials: true,
    }
  );
  return res.data;
};

// GET PACKAGING OPTIONS
export const GetProductPackagingOptions = async () => {
  const res = await axiosPrivate.get(`${PACKAGING_URL}`, {
    withCredentials: true,
  });
  return res.data.packagingTypes;
};

// POST PACKAGING
export const PostProductPackaging = async (
  product_uuid: string,
  packaging: {
    packagingTypeUuid: string;
    proportionOfProduct: number | null;
  }
) => {
  const res = await axiosPrivate.post(
    `${PRODUCTS_URL}${product_uuid}/packaging/`,
    {
      packagingTypeUuid: packaging.packagingTypeUuid,
      proportionOfProduct: packaging.proportionOfProduct,
    },
    {
      withCredentials: true,
    }
  );
  return res.data;
};

// PATCH PACKAGING
export const PatchProductPackaging = async (
  product_uuid: string,
  packaging_uuid: string,
  packaging: {
    packagingTypeUuid: string;
    proportionOfProduct: number | null;
  }
) => {
  const res = await axiosPrivate.patch(
    `${PRODUCTS_URL}${product_uuid}/packaging/${packaging_uuid}/`,
    {
      packagingTypeUuid: packaging.packagingTypeUuid,
      proportionOfProduct: packaging.proportionOfProduct,
    },
    {
      withCredentials: true,
    }
  );
  return res.data;
};

// DELETE PACKAGING
export const DeleteProductPackaging = async (
  product_uuid: string,
  packaging_uuid: string
) => {
  const res = await axiosPrivate.delete(
    `${PRODUCTS_URL}${product_uuid}/packaging/${packaging_uuid}/`,
    {
      withCredentials: true,
    }
  );
  return res.data;
};

// PRODUCT PROCESSING ---------------------------------------------------------------
// GET PROCESSING
export const GetProductProcessing = async (product_uuid: string) => {
  const res = await axiosPrivate.get(
    `${PRODUCTS_URL}${product_uuid}/processing/`,
    {
      withCredentials: true,
    }
  );
  return res.data;
};

// GET PROCESSING OPTIONS
export const GetProductProcessingOptions = async () => {
  const res = await axiosPrivate.get(`${PROCESSING_URL}`, {
    withCredentials: true,
  });
  return res.data;
};

// POST PROCESSING
export const PostProductProcessing = async (
  product_uuid: string,
  processing: {
    processingTypeUuid: string;
    proportionOfProduct: number | null;
  }
) => {
  const res = await axiosPrivate.post(
    `${PRODUCTS_URL}${product_uuid}/processing/`,
    {
      processingTypeUuid: processing.processingTypeUuid,
      proportionOfProduct: processing.proportionOfProduct,
    },
    {
      withCredentials: true,
    }
  );
  return res.data;
};

// PATCH PROCESSING
export const PatchProductProcessing = async (
  product_uuid: string,
  processing_uuid: string,
  processing: {
    processingTypeUuid: string;
    proportionOfProduct: number | null;
  }
) => {
  const res = await axiosPrivate.patch(
    `${PRODUCTS_URL}${product_uuid}/processing/${processing_uuid}/`,
    {
      processingTypeUuid: processing.processingTypeUuid,
      proportionOfProduct: processing.proportionOfProduct,
    },
    {
      withCredentials: true,
    }
  );
  return res.data;
};

// DELETE PROCESSING
export const DeleteProductProcessing = async (
  product_uuid: string,
  processing_uuid: string
) => {
  const res = await axiosPrivate.delete(
    `${PRODUCTS_URL}${product_uuid}/processing/${processing_uuid}/`,
    {
      withCredentials: true,
    }
  );
  return res.data;
};

// PRODUCT TRANSPORT ---------------------------------------------------------------
// GET TRANSPORT
export const GetProductTransport = async (product_uuid: string) => {
  const res = await axiosPrivate.get(
    `${PRODUCTS_URL}${product_uuid}/transport/`,
    {
      withCredentials: true,
    }
  );
  return res.data;
};

// GET TRANSPORT OPTIONS
export const GetProductTransportOptions = async () => {
  const res = await axiosPrivate.get(`${TRANSPORT_URL}`, {
    withCredentials: true,
  });
  return res.data;
};

// POST TRANSPORT
export const PostProductTransport = async (
  product_uuid: string,
  transport: {
    transportTypeUuid: string;
    proportionOfProduct: number | null;
  }
) => {
  const res = await axiosPrivate.post(
    `${PRODUCTS_URL}${product_uuid}/transport/`,
    {
      transportTypeUuid: transport.transportTypeUuid,
      proportionOfProduct: transport.proportionOfProduct,
    },
    {
      withCredentials: true,
    }
  );
  return res.data;
};

// PATCH TRANSPORT
export const PatchProductTransport = async (
  product_uuid: string,
  transport_uuid: string,
  transport: {
    transportTypeUuid: string;
    proportionOfProduct: number | null;
  }
) => {
  const res = await axiosPrivate.patch(
    `${PRODUCTS_URL}${product_uuid}/transport/${transport_uuid}/`,
    {
      transportTypeUuid: transport.transportTypeUuid,
      proportionOfProduct: transport.proportionOfProduct,
    },
    {
      withCredentials: true,
    }
  );
  return res.data;
};

// DELETE TRANSPORT
export const DeleteProductTransport = async (
  product_uuid: string,
  transport_uuid: string
) => {
  const res = await axiosPrivate.delete(
    `${PRODUCTS_URL}${product_uuid}/transport/${transport_uuid}/`,
    {
      withCredentials: true,
    }
  );
  return res.data;
};

// GET UNITS
export const getUnits = async () => {
  const res = await axiosPrivate.get(`${UNITS_URL}`, {
    withCredentials: true,
  });
  return res.data;
};

// AVAILABLE INGREDIENTS
export const getIngredientChoices = async () => {
  const res = await axiosPrivate.get(`${INGREDIENT_CHOICES}`, {
    withCredentials: true,
  });
  return res.data;
};

export const getTargets = async () => {
  const res = await axiosPrivate.get(`${TARGETS_URL}`, {
    withCredentials: true,
  });
  return res.data;
};

export const getTargetsMetrics = async () => {
  const res = await axiosPrivate.get(`${TARGETS_METRICS_URL}`, {
    withCredentials: true,
  });
  return res.data;
};

export const CreateTarget = async (
  metricName: string,
  targetValue: number,
  collectionUuid?: string | null
) => {
  const res = await axiosPrivate.post(TARGETS_URL, {
    metricName,
    targetValue,
    collectionUuid,
  });

  return res.data;
};

export async function deleteTarget(targetUuid: string) {
  const response = await axiosPrivate.delete(`${TARGETS_URL}${targetUuid}/`, {
    withCredentials: true,
  });
  return response.data;
}

export async function deleteProduct(productUuid: string) {
  const response = await axiosPrivate.delete(`${PRODUCTS_URL}${productUuid}/`, {
    withCredentials: true,
  });
  return response.data;
}
