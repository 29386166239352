import useAuth from '@app/auth/use-auth';
import Page from '@app/components/layout/page/page';

import { PageHeader } from '@app/components/layout/page/components/page-header';
import CreateDishForm from '@app/components/product-form/create-dish-form';
import CreateProductForm from '@app/components/product-form/create-product-form';

export default function CreateProductPage() {
  const { user } = useAuth();

  return (
    <>
      <Page name="Create Product" title="Create product">
        <PageHeader name="Create Product" />
        <div className="max-w-form">
          {user.productCategory === 'dish' && <CreateDishForm />}
          {user.productCategory === 'product' && <CreateProductForm />}
        </div>
      </Page>
    </>
  );
}
