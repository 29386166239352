import ProductTable from '@app/components/tables/product-table/product-table';
import { CollectionDetail } from '@shared/api/types';
import { useOutletContext } from 'react-router-dom';

export default function CollectionDetailProducts() {
  const collection: CollectionDetail = useOutletContext();
  return (
    <ProductTable
      collectionUuid={collection.uuid}
      enableRowClick={true}
      includeToolbarActions={false}
    />
  );
}
