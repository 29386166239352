import { FormProps } from './types';

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@shared/components/ui/form';
import { cn } from '@shared/lib/utils';
import {
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from 'react-hook-form';
import { Checkbox } from '../ui/checkbox';

interface CheckboxMultipleFormFieldProps extends FormProps {
  options: {
    label: string;
    value: string;
  }[];
}

export default function CheckboxMultipleFormField({
  name,
  options,
}: CheckboxMultipleFormFieldProps) {
  const { control } = useFormContext();
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <div className="space-y-2">
            {options.map((option) => (
              <FormItem key={option.value}>
                <FormControl>
                  <CustomCheckboxOption
                    field={field}
                    label={option.label}
                    value={option.value}
                  />
                </FormControl>
              </FormItem>
            ))}
          </div>
        );
      }}
    />
  );
}

interface CustomCheckboxOptionProps {
  label: string;
  value: string;
  field: ControllerRenderProps<FieldValues, string>;
}

function CustomCheckboxOption({
  field,
  label,
  value,
}: CustomCheckboxOptionProps) {
  return (
    <FormLabel>
      <Checkbox
        showCheckmark={false}
        checked={field.value?.includes(value)}
        onCheckedChange={(checked) => {
          return checked
            ? field.onChange([...field.value, value])
            : field.onChange(field.value?.filter((v) => v !== value));
        }}
        className={cn(
          'ml-auto h-16 w-full border-gray-100 rounded-md data-[state=checked]:border-teal-600 data-[state=checked]:bg-teal-100 data-[state=checked]:text-gray-900'
        )}
      >
        {label}
      </Checkbox>
    </FormLabel>
  );
}
