import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
} from '@shared/components/ui/navigation-menu';
import { cn } from '@shared/lib/utils';
import { NavLink } from 'react-router-dom';

export interface Tab {
  title: string;
  icon?: React.ReactNode;
  href: string;
  disabled?: boolean;
  hide?: boolean;
}

export interface PageTabs {
  tabs: Tab[];
}

export function PageTabs({ tabs }: PageTabs) {
  return (
    <NavigationMenu className="w-full overflow-y-auto">
      <NavigationMenuList className="space-x-0">
        {tabs
          .filter((tab) => !tab.hide)
          .map((tab) => (
            <NavigationMenuItem key={tab.title}>
              <NavLink
                to={tab.href}
                onClick={(e) => tab.disabled && e.preventDefault()}
                className={({ isActive }) => {
                  return cn(
                    'border-b-2 font-medium text-sm px-4 py-2.5 transition-all ease-in duration-150 flex flex-row items-center',
                    {
                      ' text-primary-700 border-b-primary-600': isActive,
                      ' hover:text-gray-700 hover:border-b-gray-300': !isActive,
                      'hover: cursor-default hover:text-gray-300 hover:border-b-none text-gray-300 ':
                        tab.disabled,
                    }
                  );
                }}
                end
              >
                <NavigationMenuLink>{tab.title}</NavigationMenuLink>
                {tab.icon && (
                  <div className="ml-1.5 flex h-[20px] items-center">
                    {tab.icon}
                  </div>
                )}
              </NavLink>
            </NavigationMenuItem>
          ))}
      </NavigationMenuList>
    </NavigationMenu>
  );
}
