import Box from '@mui/material/Box';
import { ResponsivePie } from '@nivo/pie';
import { TotalEmissionsByCategory } from '@shared/api/types';
import {
  getColorByEmissionsCategory,
  roundNumberToNDecimalPlaces,
} from '../../../utils/helpers';

interface UnitsEmissionsCategoryPieChartProps {
  totalEmissionsByCategory: TotalEmissionsByCategory[];
}

const margin = { top: 40, right: 80, bottom: 46, left: 80 };

const styles = {
  root: {
    fontFamily: 'Inter, Roboto',
    textAlign: 'center',
    // marginTop: "32px",
    height: '100%',
    minWidth: '100px',
    width: '100%',

    position: 'relative',
  },
  overlay: {
    position: 'absolute',
    top: margin.top,
    right: margin.right,
    bottom: margin.bottom,
    left: margin.left,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 96,
    color: '#000',

    // background: "#FFFFFF33",
    textAlign: 'center',
    // This is important to preserve the chart interactivity
    pointerEvents: 'none',
  },
  totalLabel: {
    fontSize: 24,
  },
};
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.

const UnitsEmissionsCategoryPieChart: React.FC<
  UnitsEmissionsCategoryPieChartProps
> = ({ totalEmissionsByCategory }: UnitsEmissionsCategoryPieChartProps) => {
  const preProcessData = (
    data: TotalEmissionsByCategory[]
  ): { emissionsCategory: string; value: number; color: string }[] => {
    const result = [];
    for (let i = 0; i < data.length; i++) {
      result.push({
        id: data[i]['emissionsCategory'], // id is needed as per Nivo documentation
        emissionsCategory: data[i]['emissionsCategory'],
        value: data[i]['totalProducts']
          ? roundNumberToNDecimalPlaces(data[i]['totalProducts']!, 1)
          : roundNumberToNDecimalPlaces(data[i]['totalKg']!, 1), // has to be called "value" as specified by Nivo documentation,,
        color: getColorByEmissionsCategory(data[i]['emissionsCategory']),
      });
    }
    return result;
  };

  return (
    <Box sx={styles.root}>
      <ResponsivePie
        data={preProcessData(totalEmissionsByCategory)}
        margin={margin}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#161a1e"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLinkLabelsDiagonalLength={12}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: 'color',
          modifiers: [['darker', 2]],
        }}
        innerRadius={0.8}
        padAngle={2}
        cornerRadius={70}
        colors={{ datum: 'data.color' }}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.2]],
        }}
        enableArcLabels={false} // Remove Labels
        enableArcLinkLabels={true} // Remove lines
        tooltip={({ datum: { id, value, color } }) => (
          <div
            style={{
              padding: 12,
              color: '#000',
              background: '#fff',
              boxShadow: '5px 3px 5px #00000030',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width: '15px',
                height: '15px',
                backgroundColor: color,
                marginRight: '10px',
              }}
            ></div>
            <span>
              {id}:{' '}
              <b>{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</b>{' '}
              {totalEmissionsByCategory[0].totalProducts
                ? 'Products sold'
                : 'Kg purchased'}
            </span>
          </div>
        )}
        layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends']}
      />
    </Box>
  );
};

export default UnitsEmissionsCategoryPieChart;
