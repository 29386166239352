import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@shared/components/ui/form';
import { cn } from '@shared/lib/utils';
import { useFormContext } from 'react-hook-form';
import { Checkbox } from '../ui/checkbox';
import { FormProps } from './types';

interface CheckboxFormFieldProps extends FormProps {}

export default function CheckboxFormField({
  name,
  className,
  label,
  includeErrorMessage,
}: CheckboxFormFieldProps) {
  const { control } = useFormContext();
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={cn('', className)}>
          <div className="flex items-center">
            <FormControl>
              <Checkbox
                checked={field.value}
                onCheckedChange={field.onChange}
              />
            </FormControl>
            <FormLabel className="ml-2 ">{label}</FormLabel>
          </div>

          {includeErrorMessage && <FormMessage />}
        </FormItem>
      )}
    />
  );
}
