import { CollectionList } from '@shared/api/types';

import { SimpleDialog } from '@app/hooks/use-simple-dialog';
import { useDeleteCollection } from '@shared/api';
import Text from '@shared/components/content/text';
import { Button } from '@shared/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@shared/components/ui/dropdown-menu';
import { useToast } from '@shared/components/ui/use-toast';
import sentry from '@shared/services/sentry';
import { Loader2, MoreHorizontal } from 'lucide-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface CollectionTableRowActionsProps {
  collection: CollectionList;
}

export default function CollectionTableRowActions({
  collection,
}: CollectionTableRowActionsProps): JSX.Element {
  const [open, setOpen] = React.useState(false);
  const { toast } = useToast();

  const navigate = useNavigate();

  const { mutateAsync: deleteCollection, isLoading: isDeleting } =
    useDeleteCollection();

  const onCollectionDelete = async () => {
    try {
      await deleteCollection({ collectionUuid: collection.uuid });
      toast({
        description: 'Collection deleted successfully.',
      });
    } catch (error) {
      sentry.log(error);
      toast({
        description: 'Failed to delete collection.',
        variant: 'destructive',
      });
    }
  };

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="sm">
          <span className="sr-only">Open menu</span>
          <MoreHorizontal className="size-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="z-50 w-[200px]">
        <DropdownMenuLabel>Actions</DropdownMenuLabel>
        <DropdownMenuGroup
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <DropdownMenuItem
            onClick={() => {
              navigate(`/collections/${collection.uuid}`);
            }}
          >
            Open
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() =>
              window.open(`/collections/${collection.uuid}`, '_blank')
            }
          >
            Open in new tab
          </DropdownMenuItem>

          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => navigate(`/collections/${collection.uuid}/edit`)}
          >
            Edit
          </DropdownMenuItem>
          <SimpleDialog
            trigger={
              <DropdownMenuLabel className="cursor-default rounded-sm font-normal text-destructive hover:bg-accent">
                Delete
              </DropdownMenuLabel>
            }
            header="Delete Collection"
            description={
              <>
                <Text>Are you sure you want to delete this collection?</Text>
                <Text className="font-bold">
                  This action cannot be undone. Deleting a collection will not
                  delete the products inside
                </Text>
              </>
            }
            footer={
              <Button
                type="submit"
                variant="destructive"
                disabled={isDeleting}
                onClick={async () => await onCollectionDelete()}
              >
                Delete
                {isDeleting && (
                  <Loader2 size={14} className="ml-2 animate-spin" />
                )}
              </Button>
            }
          />
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
