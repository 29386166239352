import Navbar from '@app/components/layout/nav/navbar';
import TextLink from '@shared/components/content/text-link';
import Loader from '@shared/components/loader';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import useAuth from './use-auth';

const WEBSITE_URL = import.meta.env.VITE_WEBSITE_URL;

export default function AuthLayout() {
  const { loadingAuth, isAuthenticated, user } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isVerifyEmailPage = pathname.includes('verify-email');

  useEffect(() => {
    if (isAuthenticated && !isVerifyEmailPage) {
      return navigate('/');
    }

    if (isAuthenticated && isVerifyEmailPage && user?.emailVerified) {
      return navigate('/guide/welcome');
    }
  }, [isAuthenticated, navigate, isVerifyEmailPage, user?.emailVerified]);

  if (loadingAuth) return <Loader className="h-screen" size="lg" withLogo />;

  return (
    <div className="flex h-full min-h-screen flex-col bg-primary">
      <Navbar />
      <div className="grid flex-1 grid-cols-1 overflow-y-auto py-2">
        <Outlet />
      </div>
      <p className="mb-auto p-4 text-center text-sm text-gray-00">
        {'Copyright © '}
        <TextLink to={WEBSITE_URL} internal={false} color="light">
          My Emissions
        </TextLink>{' '}
        {new Date().getFullYear()}
      </p>
    </div>
  );
}
