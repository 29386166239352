import { Grid, Typography } from '@mui/material';
import { CollectionDetail } from '@shared/api/types';
import { useOutletContext } from 'react-router-dom';
import AverageLifeCycleEmissions from '../../dashboard/sections/AverageLifeCycleEmissions';
import ProductRatings from '../../dashboard/sections/ProductRatings';
import TopStats from '../../dashboard/sections/TopStats';

export default function CollectionDetailSummary() {
  const collection: CollectionDetail = useOutletContext();
  return (
    <div>
      <Typography
        variant="h6"
        sx={{
          color: 'text.primary',
        }}
      >
        Overview
      </Typography>
      <Grid container spacing={2} mt={1}>
        <TopStats
          averageProductEmissionsPerKg={collection!.averageEmissionsPerKg}
          totalNumberOfProducts={collection!.numProducts}
          numberOfIngredients={collection!.numIngredients}
          numberProductsDraft={collection.numProductsDraft}
          numberProductsEditing={collection.numProductsEditing}
          numberProductsInProgress={collection.numProductsInProgress}
          numberProductsCompleted={collection.numProductsComplete}
        />
      </Grid>
      <Grid
        item
        container
        rowSpacing={3}
        columnSpacing={2}
        justifyContent="center"
        alignItems="center"
        mt={1}
      >
        <ProductRatings
          productEmissionsCategoryBreakdown={
            collection.productEmissionsCategoryBreakdown
          }
          averageEmissionsCategory={collection.averageEmissionsCategory}
        />
        <AverageLifeCycleEmissions
          averageLifeCycleStageEmissionsPerKg={
            collection.averageLifeCycleStageEmissionsPerKg
          }
        />
      </Grid>
    </div>
  );
}
