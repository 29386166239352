// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/bar
import { ResponsiveBar } from "@nivo/bar";
import { roundNumberToNDecimalPlaces } from "../../utils/helpers";

interface ProductIngredientsBarChartProps {
  chartLayout: "horizontal" | "vertical" | undefined;
  productIngredientsBarChartData: {
    longDesc: string;
    farmingEmissions: number;
    processingEmissions: number;
    packagingEmissions: number;
    transportEmissions: number;
    emissions: number;
  }[];
  maxValue?: number;
}

const colors = ["#c2ddcc", "#8cbae0", "#ffd78e", "#fdb588"];

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.

const ProductIngredientsBarChart: React.FC<ProductIngredientsBarChartProps> = ({
  productIngredientsBarChartData,
  maxValue,
  chartLayout,
}) => {
  const preProcessData = (
    data: {
      longDesc: string;
      farmingEmissions: number;
      processingEmissions: number;
      packagingEmissions: number;
      transportEmissions: number;
    }[]
  ): {
    longDesc: string;
    Farming: number;
    Processing: number;
    Packaging: number;
    Transport: number;
  }[] => {
    var result = [];
    for (var i = 0; i < data.length; i++) {
      result.push({
        longDesc: data[i]["longDesc"],
        Farming: roundNumberToNDecimalPlaces(
          data[i]["farmingEmissions"] * 1000,
          1
        ),
        Processing: roundNumberToNDecimalPlaces(
          data[i]["processingEmissions"] * 1000,
          1
        ),
        Packaging: roundNumberToNDecimalPlaces(
          data[i]["packagingEmissions"] * 1000,
          1
        ),
        Transport: roundNumberToNDecimalPlaces(
          data[i]["transportEmissions"] * 1000,
          1
        ),
      });
    }
    return result.reverse();
  };

  return (
    <ResponsiveBar
      data={preProcessData(productIngredientsBarChartData)}
      keys={["Farming", "Processing", "Packaging", "Transport"]}
      indexBy={"longDesc"}
      margin={{ top: 30, right: 30, bottom: 100, left: 120 }}
      padding={0.3}
      layout={chartLayout}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      enableLabel={false}
      maxValue={maxValue ? maxValue * 1000 + maxValue * 1000 * 0.1 : "auto"}
      colors={colors}
      fill={[
        {
          match: {
            id: "processing",
          },
          id: "dots",
        },
        {
          match: {
            id: "packaging",
          },
          id: "lines",
        },
      ]}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      tooltip={({ id, color, value, data }) => (
        <div
          style={{
            padding: 12,
            color: "#000",
            background: "#fff",
            boxShadow: "5px 3px 5px #00000030",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "15px",
              height: "15px",
              backgroundColor: color,
              marginRight: "7px",
            }}
          ></div>
          <span>
            {id} - {data.longDesc}{" "}
            <b>{roundNumberToNDecimalPlaces(value, 2)}</b> gCO<sub>2</sub>e
          </span>
        </div>
      )}
      axisTop={null}
      axisRight={null}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        // legend: "Ingredient",
        legendPosition: "middle",
        legendOffset: 0,
        format: (v) => {
          if (v.length > 14) {
            return v.substring(0, 14) + "...";
          }
          return v;
        },
      }}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickValues: 8,
        tickRotation: 0,
        legend: "Emissions (gCO2e)",
        legendPosition: "middle",
        legendOffset: 40,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      role="application"
      ariaLabel="Nivo bar chart demo"
    />
  );
};

export default ProductIngredientsBarChart;
