// React
import * as React from "react";

// Materual UI Components
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

// Helpers
import { roundNumberToNDecimalPlaces } from "../../../../utils/helpers";

interface ProductProcessingTableProps {
  steps: {
    description: string;
    location: string;
    calculationMethod: string;
    emissionsPerProduct: number;
  }[];
}

const ProductProcessingTable: React.FC<ProductProcessingTableProps> = ({
  steps,
}) => {
  return (
    // <h1>{steps ? "There are Steps" : <></>}</h1>
    <>
      {steps?.length === 0 || !steps ? (
        <></>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Processing step</TableCell>
                <TableCell align="left">Location</TableCell>
                <TableCell align="left">Calculation method</TableCell>
                <TableCell align="right">
                  Emissions per Product (gCO<sub>2</sub>e)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {steps.map((step) => (
                <TableRow
                  key={step.description}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {step.description}
                  </TableCell>
                  <TableCell align="left">{step.location}</TableCell>
                  <TableCell align="left">{step.calculationMethod}</TableCell>
                  <TableCell align="right">
                    {(step.emissionsPerProduct &&
                      roundNumberToNDecimalPlaces(
                        step.emissionsPerProduct * 1000,
                        1
                      )) ||
                      "N/A"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default ProductProcessingTable;
