/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * My Emissions API
 * We calculate food carbon footprints
 * OpenAPI spec version: 1.0.0 (v1)
 */

/**
 * * `purchasing` - Purchasing
* `production` - Production
* `sales` - Sales
 */
export type ReportCategoryEnum = typeof ReportCategoryEnum[keyof typeof ReportCategoryEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReportCategoryEnum = {
  purchasing: 'purchasing',
  production: 'production',
  sales: 'sales',
} as const;
