import { Box, Button, Typography } from '@mui/material';

const PageNotFound = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      marginTop="150px"
      sx={{
        height: '100%',
      }}
    >
      <Box my="auto">
        <Typography
          variant="h1"
          sx={{ fontWeight: 'bold', color: 'text.secondary' }}
        >
          404
        </Typography>
        <Typography variant="h4">Oh no! </Typography>
        <Typography variant="h4">Page Not Found</Typography>
        <Button
          variant="contained"
          href="/"
          sx={{
            marginTop: '1rem',
          }}
        >
          Return to Dashboard
        </Button>
      </Box>
    </Box>
  );
};

export default PageNotFound;
