//Packages
import React from 'react';
import { Link } from 'react-router-dom';
import FoodEmissionsBox from '../../../components/FoodEmissionsBox';

// Components
import ProductEmissionsBox from '../../../components/ProductEmissionsBox';

type Item = {
  // can be an ingredient or a product, this component does not care which one it is
  uuid: string;
  name: string;
  longDesc: string;
  totalEmissionsPerKg: number;
  emissionsCategory: string;
  status: string;
  countryOfOrigin: string | null;
};

interface ProductsInterface {
  items: Item[];
  itemsType: 'Foods' | 'Products';
  numShown: number;
}

const ProductsBox: React.FC<ProductsInterface> = ({
  items,
  itemsType,
  numShown,
}) => {
  return (
    <>
      {items.map(
        (item, i) =>
          i < numShown && (
            <div style={{ marginBottom: '10px' }} key={item.uuid}>
              {itemsType === 'Products' && (
                <>
                  <Link
                    to={'products/' + item.uuid}
                    style={{ textDecoration: 'none', color: '#161a1e' }}
                  >
                    <ProductEmissionsBox
                      name={item.name}
                      totalEmissionsPerKg={item.totalEmissionsPerKg}
                      emissionsCategory={item.emissionsCategory}
                      uuid={item.uuid}
                      status={item.status}
                    />
                  </Link>
                </>
              )}
              {itemsType === 'Foods' && (
                <>
                  <FoodEmissionsBox
                    name={item.longDesc}
                    totalEmissionsPerKg={item.totalEmissionsPerKg}
                    emissionsCategory={item.emissionsCategory}
                    uuid={item.uuid}
                    countryOfOrigin={item.countryOfOrigin}
                  />
                </>
              )}
            </div>
          )
      )}
    </>
  );
};

export default ProductsBox;
