import { PageHeader } from '@app/components/layout/page/components/page-header';
import CreateTransportForm from '@app/components/product-form/create-ppt-forms/create-transport-form';
import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import Page from '../../components/layout/page/page';

function CreateTransportPage() {
  const [displaySuccess, setDisplaySuccess] = React.useState<boolean>(false);

  return (
    <Page name="Create Transport">
      <PageHeader
        title="Create Transport"
        name="Create Transport"
        className="mb-10"
      />
      <Grid container spacing={4} sx={{ width: '100%' }}>
        <Grid item xs={12} style={{ paddingTop: '0.5rem' }}>
          {displaySuccess ? (
            <>
              <Typography
                variant="h5"
                style={{
                  marginTop: '2rem',
                  color: 'green',
                  fontWeight: 'bolder',
                }}
              >
                Transport created successfully!
              </Typography>
              <Button
                type="submit"
                variant="contained"
                sx={{ textTransform: 'none', marginTop: '2rem' }}
                onClick={() => setDisplaySuccess(false)}
              >
                Create another transport
              </Button>
            </>
          ) : (
            <CreateTransportForm
              onTransportCreated={() => setDisplaySuccess(true)}
            />
          )}
        </Grid>
      </Grid>
    </Page>
  );
}

export default CreateTransportPage;
