import { useCreatePackaging, usePackagingFileCreate } from '@shared/api';
import { z } from 'zod';

import { PackagingTypeCreate } from '@shared/api/types';
import { Icons } from '@shared/components/content/icons';
import Text from '@shared/components/content/text';
import { uploadFile } from '@shared/lib/utils';
import sentry from '@shared/services/sentry';
import { useState } from 'react';
import CreatePptForm, { createPptSchema } from './create-ppt-form';

const descriptionPlaceholder =
  'e.g. Cardboard skillet with a plastic film and product sticker. Five sandwiches are packaged in a 370 x 185 x 150mm cardboard box.';
const namePlaceholder = 'Enter a name for your packaging item';

interface CreatePackagingFormProps {
  onPackagingCreated?: (data: PackagingTypeCreate) => void;
}

export default function CreatePackagingForm({
  onPackagingCreated,
}: CreatePackagingFormProps) {
  const { mutateAsync: createPackaging } = useCreatePackaging();
  const { mutateAsync: confirmPackagingFileUpload } = usePackagingFileCreate();
  const [isLoading, setIsLoading] = useState(false);

  const handlePackagingCreate = async (
    values: z.infer<typeof createPptSchema>
  ) => {
    try {
      setIsLoading(true);
      const createItemResponse = await createPackaging({
        data: {
          name: values.name,
          description: values.description,
          fileName: values.file.name,
        },
      });
      const { uuid: pptItemUuid, fileUuid } = createItemResponse;

      const uploadResponse = await uploadFile({
        data: createItemResponse,
        file: values.file,
      });

      const uploadSuccessful = uploadResponse!.status === 204;

      await confirmPackagingFileUpload({
        packagingTypeUuid: pptItemUuid,
        packagingTypeFileUuid: fileUuid,
        data: { uploadSuccessful: uploadSuccessful },
      });
      onPackagingCreated?.(createItemResponse);
    } catch (error) {
      sentry.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="max-w-3xl">
      <CreatePptForm
        namePlaceholder={namePlaceholder}
        descriptionPlaceholder={descriptionPlaceholder}
        onFormSubmit={(values) => handlePackagingCreate(values)}
        isLoading={isLoading}
        infoSection={<PackagingInfoSection />}
      />
    </div>
  );
}

const PackagingInfoSection = () => {
  return (
    <Text>
      To add a packaging item, you will need to fill out the following template
      and upload it through this form:{' '}
      <Icons.paperClip className=" inline size-3.5" />{' '}
      <a
        href="
              https://myemissions-platform-production.s3.eu-west-2.amazonaws.com/template-files/packaging_template.xlsx"
        rel="noopener noreferrer"
        className="underline underline-offset-2"
      >
        Packaging template{' '}
      </a>
      <br />
    </Text>
  );
};
