import Page from '../../components/layout/page/page';

import ForgotPasswordForm from '../forms/forgot-password-form';

const ForgotPasswordPage: React.FC = () => {
  return (
    <Page name="Reset Password">
      <div className=" flex size-full items-center justify-center">
        <ForgotPasswordForm />
      </div>
    </Page>
  );
};

export default ForgotPasswordPage;
