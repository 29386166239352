import ProductPPTEmissionsDonutChart from '@app/components/charts/ProductPPTEmissionsDonutChart';
import ProductLifeCycleInfo from '@app/components/RecipleLifeCycleInfo';
import StatsCard from '@app/components/stats/StatsCard';
import { RECIPE_LIFE_CYCLE_INFO } from '@app/utils/constants';
import { roundNumberToNDecimalPlaces } from '@app/utils/helpers';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { ProductResult } from '@shared/api/types';
import React from 'react';
import { useOutletContext } from 'react-router-dom';
import ProductPackagingTypes from '../../tabs/components/ProductPackagingTypes';
import { getSeparateTotalEmissions } from './product-detail-processing';

export default function ProductDetailPackaging() {
  const product: ProductResult = useOutletContext();

  const { status, packaging } = product;

  const computePercUnpackaged = () => {
    let result = 100;
    for (let i = 0; i < packaging.packagingTypes.length; i++) {
      if (packaging.packagingTypes[i].proportionOfProduct < 0) {
        return 0;
      }
      result = result - packaging.packagingTypes[i].proportionOfProduct * 100;
    }
    return result;
  };

  const unpackagedPerc = computePercUnpackaged();

  const totalPackagingEmissions = getSeparateTotalEmissions(
    product,
    'Packaging'
  );

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          filter: status === 'expired' ? 'grayscale(100%)' : '',
        }}
      >
        <ProductLifeCycleInfo
          title="Packaging"
          text={RECIPE_LIFE_CYCLE_INFO.Packaging.text}
          image={RECIPE_LIFE_CYCLE_INFO.Packaging.image}
        />
      </Grid>
      <Typography
        variant="h5"
        sx={{
          color: 'text.primary',
          marginTop: '1.5rem',
          marginBottom: '1.5rem',
          fontWeight: 600,
        }}
      >
        Overview
      </Typography>
      <Grid
        container
        item
        spacing={2}
        //alignItems="flex-start"
        sx={{
          filter: status === 'expired' ? 'grayscale(100%)' : '',
        }}
      >
        <Grid item xs={12} sm={12} md={6}>
          <Box
            sx={{
              backgroundColor: 'white',
              borderRadius: '10px',
              width: '100%',
              paddingX: '1.4rem',
              paddingY: '1rem',
              minHeight: '109px',
              overflow: 'visible',
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={0.5}
            >
              <Typography
                variant="body2"
                sx={{
                  color: 'text.primary',
                  fontWeight: 'medium',
                  marginBottom: '0',
                  minHeight: '24px',
                }}
              >
                Total Emissions from Packaging (gCO2<sub>e</sub>)
              </Typography>
              {/* <InfoPopOver subjectMatter="" /> */}
            </Box>
            <div
              style={{
                width: '100%',
                height: '305px',
              }}
            >
              <ProductPPTEmissionsDonutChart
                type="Packaging"
                PPTTotalEmissions={totalPackagingEmissions}
                productEmissionsCategoryBreakdown={[
                  {
                    label: 'Ingredients',
                    emissions: product.ingredientPackagingEmissions,
                  },
                  {
                    label: 'Product',
                    emissions: packaging.packagingEmissions,
                  },
                ]}
              />
            </div>
          </Box>
        </Grid>
        <Grid item container spacing={2} xs={12} sm={12} md={6}>
          <Grid item xs={12} sm={12} md={12}>
            <Box>
              <StatsCard
                title="Emissions from Product Packaging"
                value={
                  packaging!.packagingEmissions !== null
                    ? roundNumberToNDecimalPlaces(
                        packaging!.packagingEmissions * 1000,
                        1
                      )
                    : 'N/A'
                }
                unit="gCO2e"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={12}>
            <Box>
              <StatsCard
                title="Percentage of Product's Total Packaging Emissions"
                value={
                  packaging.shareOfTotalEmissions !== null
                    ? roundNumberToNDecimalPlaces(
                        packaging!.shareOfTotalEmissions * 100,
                        1,
                        packaging!.shareOfTotalEmissions * 100 === 100
                          ? false
                          : true // don't add trailing zero if it's 100%
                      )
                    : 'N/A'
                }
                unit="%"
                tooltip
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={12}>
            <Box>
              <StatsCard
                title="Percentage of Product's Packaged"
                value={
                  packaging.proportionOfProductsPackaged !== null
                    ? roundNumberToNDecimalPlaces(
                        packaging.proportionOfProductsPackaged * 100,
                        1,
                        packaging.proportionOfProductsPackaged * 100 === 100
                          ? false
                          : true
                      ) // don't add trailing zero if it's 100%
                    : 'N/A'
                } // Change this when nathan confirms that value is not nullable
                unit="%"
                tooltip
              />
            </Box>
          </Grid>{' '}
        </Grid>

        <Grid item xs={12}>
          <Divider
            sx={{
              marginY: '1.5rem',
            }}
          />
          <Typography
            variant="h5"
            sx={{
              color: 'text.primary',

              fontWeight: 600,
              marginTop: '1.5rem',
              marginBottom: '0.75rem',
            }}
          >
            Product&apos;s Packaging Types
          </Typography>

          {packaging.packagingTypes.map((type) => (
            <React.Fragment key={type.name}>
              <ProductPackagingTypes
                description={type.name}
                proportionOfProduct={type.proportionOfProduct}
                totalEmissionsPerItem={type.totalEmissionsPerItem}
                items={type.items}
                notesAndAssumptions={type.notesAndAssumptions}
              />
            </React.Fragment>
          ))}
        </Grid>
        <Grid item xs={12}>
          {unpackagedPerc > 0 ? (
            <Typography
              variant="h6"
              sx={{
                color: 'text.primary',
                marginBottom: '0.5rem',
                fontSize: '1.125rem',
              }}
            >
              <strong>Unpackaged ({unpackagedPerc}%)</strong>
            </Typography>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
