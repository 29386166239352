import { Box, Typography } from "@mui/material";
import ProductTransportTable from "./ProductTransportTable";

import NotesBox from "../../../../components/NotesBox";
import { roundNumberToNDecimalPlaces } from "../../../../utils/helpers";
import { ProductTransportType } from "../../../../types/products";

const ProductTransportTypes: React.FC<ProductTransportType> = ({
  description,
  proportionOfProduct,
  totalEmissionsPerItem,
  journeys,
  notesAndAssumptions,
}) => {
  return (
    <Box mt={2} mb={7}>
      <Box
        display="flex"
        sx={{
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "start", md: "center" },
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "text.primary",
            marginBottom: "0.5rem",
            fontSize: "1.125rem",
          }}
        >
          {description} (
          <strong>
            {roundNumberToNDecimalPlaces(
              proportionOfProduct * 100,
              1,
              proportionOfProduct * 100 === 100 ? false : true
            )}
            % {/* don't add trailing 0 if it's 100% */}
          </strong>{" "}
          of products)
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: "text.primary", marginBottom: "0.5rem" }}
        >
          Total Emissions per Product:{" "}
          <strong>
            {roundNumberToNDecimalPlaces(totalEmissionsPerItem * 1000, 1)} gCO
            <sub>2</sub>e
          </strong>
        </Typography>
      </Box>
      {/* Rename to items */}
      {/* <ProductTransportTable journeys={journeyGroups} /> */}
      <ProductTransportTable journeys={journeys} />

      {notesAndAssumptions.length !== 0 ? (
        <NotesBox notes={notesAndAssumptions} />
      ) : null}
    </Box>
  );
};

export default ProductTransportTypes;
