/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * My Emissions API
 * We calculate food carbon footprints
 * OpenAPI spec version: 1.0.0 (v1)
 */

/**
 * * `product` - Product
* `dish` - Dish
 */
export type ProductCategoryEnum = typeof ProductCategoryEnum[keyof typeof ProductCategoryEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductCategoryEnum = {
  product: 'product',
  dish: 'dish',
} as const;
