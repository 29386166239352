import { REFRESH_TOKEN_URL, getAccountData } from '../api/api';
import axios from '../api/axios';
import useAuth from '../auth/use-auth';

const useRefreshToken = () => {
  const { setAuth } = useAuth();
  const refresh = async () => {
    const response = await axios.post(
      REFRESH_TOKEN_URL,
      {},
      {
        withCredentials: true,
      }
    );

    if (response.data.access) {
      const userResponse = await getAccountData(response.data.access);
      setAuth({
        access_token: response.data.access,
        user: userResponse.data,
      });
    }

    return response.data.access;
  };

  return refresh;
};

export default useRefreshToken;
