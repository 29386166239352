import ProductTransportPicker from '@app/components/product-form/product-transport-picker';
import { useGetAvailableTransport } from '@shared/api';
import { AvailableTransport } from '@shared/api/types';
import { Icons } from '@shared/components/content/icons';
import InputFormField from '@shared/components/form/input-form-field';
import { RepeatableRowComponentProps } from '@shared/components/form/types';
import { Button } from '@shared/components/ui/button';
import { FormLabel } from '@shared/components/ui/form';
import { Skeleton } from '@shared/components/ui/skeleton';
import { cn } from '@shared/lib/utils';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

interface ProductTransportRowProps extends RepeatableRowComponentProps {}

export interface TransportOption {
  uuid: string;
  name: string;
}

type ProductTransportRowData = {
  transportType: string;
  name: string;
  size: string;
};

export default function ProductTransportRow({
  index,
  remove,
  setDisableAdd,
}: ProductTransportRowProps) {
  const {
    data: availableTransport,
    isLoading: transportOptionsLoading,
    isError: isTransportOptionsError,
    error: transportOptionsError,
  } = useGetAvailableTransport({
    include: 'custom',
  });
  const { getValues, watch } = useFormContext();

  const transportRows: ProductTransportRowData[] = getValues('transport');
  const selectedTransportRows = transportRows.map(({ name }, i) => ({
    name,
    index: i,
  }));

  const availableTransportOptions: AvailableTransport = useMemo(() => {
    const availableCustoms: AvailableTransport['custom'] | undefined =
      availableTransport?.custom?.filter((p) => {
        const selectedRow = selectedTransportRows.find(
          ({ name }) => name === p.name
        );
        return !selectedRow || selectedRow.index === index;
      });

    return {
      custom: availableCustoms,
    } as AvailableTransport;
  }, [availableTransport?.custom, index, selectedTransportRows]);

  const watchTransportName = watch(`transport.${index}.name`);

  useEffect(() => {
    transportOptionsError ||
    transportOptionsLoading ||
    availableTransportOptions?.custom?.length === 1
      ? setDisableAdd(true)
      : setDisableAdd(false);
  }, [
    transportOptionsError,
    transportOptionsLoading,
    setDisableAdd,
    availableTransportOptions,
  ]);

  if (transportOptionsLoading) {
    return (
      <div className="grid grid-cols-12 items-end gap-2">
        <Skeleton className="col-span-9 h-10 rounded-lg" />
        <Skeleton className="col-span-3 h-10 rounded-lg" />
      </div>
    );
  }

  if (isTransportOptionsError || !availableTransport) {
    throw new Error(
      `There was an error loading transport options ${transportOptionsError || 'No Data'}`
    );
  }

  return (
    <div className="grid grid-cols-12 items-start gap-2">
      <ProductTransportPicker
        name={`transport.${index}.transportType`}
        availableTransport={availableTransportOptions}
        index={index}
        label={index === 0 ? 'Step' : ''}
        className="col-span-9"
      />

      <InputFormField
        name={`transport.${index}.proportionOfProduct`}
        label={index === 0 ? 'Percentage' : ''}
        disabled={!watchTransportName}
        type="number"
        min={1}
        className="col-span-2"
        inputClassName="text-right"
      />

      <div className="col-span-1 grid items-end">
        {index === 0 && (
          <FormLabel className="mb-0 opacity-0">Remove</FormLabel>
        )}
        <Button
          type="button"
          variant="outline"
          size="sm"
          className={cn('size-10', {
            'mt-2': index === 0,
          })}
          onClick={() => {
            setDisableAdd(false);
            remove(index);
          }}
        >
          <Icons.x className="size-4" />
        </Button>
      </div>
    </div>
  );
}
