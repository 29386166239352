import Page from '@app/components/layout/page/page';
import { useNavigate } from 'react-router-dom';
import OnboardingGuideTemplate from './components/onboarding-guide-template';

import Text from '@shared/components/content/text';
import NatalieHeadshot from '../../../shared/assets/natalie-headshot.jpg';

export default function GetInTouch() {
  const navigate = useNavigate();

  return (
    <Page name="Get in touch">
      <OnboardingGuideTemplate
        preTitle={false}
        title="Time to start assessing products!"
        description="Welcome to the My Emissions community! We're excited to have you on board. Enjoy 3 free product assessments to get started."
        primaryActionText="Get started"
        handlePrimaryAction={() => {
          navigate('/products/create');
        }}
        secondaryActionText="Back"
        handleSecondaryAction={() => {
          navigate('/guide/reports');
        }}
      >
        <div className="my-10 flex h-full min-h-20 max-w-lg items-center justify-between space-x-4 rounded-lg border border-teal-300 bg-gray-00 p-4 ">
          <img
            src={NatalieHeadshot}
            alt="Natalie Headshot"
            className="mx-auto size-32 rounded-full"
          />
          <Text className="text-left">
            If you have any questions whilst using the platform please reach out
            to your Account Manager Natalie
          </Text>
        </div>
      </OnboardingGuideTemplate>
    </Page>
  );
}
