// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/bar
import { ResponsiveBar } from '@nivo/bar';
import React from 'react';
import {
  computeMaxValue,
  roundNumberToNDecimalPlaces,
} from '../../../utils/helpers';

interface TotalEmissionsByStageBarChartProps {
  totalEmissionsByStage: {
    stage: string;
    emissions: number;
  }[];
  totalEmissions: number;
}

const TotalEmissionsByStageBarChart: React.FC<
  TotalEmissionsByStageBarChartProps
> = ({ totalEmissionsByStage, totalEmissions }) => {
  const maxChartValue = computeMaxValue(totalEmissionsByStage, 'emissions');
  const computeMargin = () => {
    return totalEmissions > 100000 && totalEmissions < 1000000
      ? { top: 25, right: 30, bottom: 45, left: 100 }
      : { top: 25, right: 30, bottom: 45, left: 85 };
  };

  const preProcessData = (
    totalEmissionsByStage: { stage: string; emissions: number }[]
  ): { stage: string; emissions: number; color?: string }[] => {
    const result = [];
    for (let i = 0; i < totalEmissionsByStage.length; i++) {
      result.push({
        stage: capitalizeFirstLetter(totalEmissionsByStage[i]['stage']),
        emissions: roundNumberToNDecimalPlaces(
          totalEmissionsByStage[i]['emissions'],
          2
        ), // has to be called "value" as specified by Nivo documentation,
        color: '#08314c',
      });
    }
    return result;
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <ResponsiveBar
      data={preProcessData(totalEmissionsByStage)}
      theme={{ fontFamily: 'Inter, Roboto' }}
      keys={['emissions']}
      indexBy="stage"
      margin={computeMargin()}
      padding={0.4}
      valueScale={{ type: 'linear' }}
      colors={{ datum: 'data.color' }}
      layout="vertical"
      animate={true}
      maxValue={maxChartValue + maxChartValue / 8}
      enableLabel={false}
      axisRight={null}
      enableGridY={true}
      layers={['grid', 'axes', 'bars', 'markers', 'legends', 'annotations']}
      tooltip={({ id, value, color, data }) => (
        <div
          style={{
            padding: 12,
            color: '#000',
            background: '#fff',
            boxShadow: '5px 3px 5px #00000030',
          }}
        >
          <span>
            {capitalizeFirstLetter(data.stage)}:{' '}
            <b>{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</b> kgCO
            <sub>2</sub>e
          </span>
        </div>
      )}
      axisBottom={{
        legendPosition: 'middle',
        legendOffset: 20,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickValues: 7,
        format: (value) => {
          return value > 10000000
            ? (value / 1000000)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'M'
            : value > 100000
              ? (value / 1000)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'K'
              : value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        legend: 'Emissions per kg (kgCO2e/kg)',

        legendPosition: 'middle',
        legendOffset:
          totalEmissions > 100000 && totalEmissions < 1000000 ? -80 : -65,
      }}
    />
  );
};

export default TotalEmissionsByStageBarChart;
