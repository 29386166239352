import HubspotMeetingEmbed from '@app/components/hubspot-meeting-embed';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@shared/components/ui/dialog';
import { useCallback, useState } from 'react';

const useUpsellDialog = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const showDialog = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const UpsellDialogComponent = (
    <UpsellDialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} />
  );

  return {
    showDialog,
    UpsellDialogComponent,
  };
};

interface UpsellDialogProps {
  open?: boolean;
  onClose?: () => void;
}

function UpsellDialog({ open, onClose }: UpsellDialogProps) {
  return (
    <Dialog
      open={open}
      onOpenChange={(isOpen) => {
        if (!isOpen && onClose) onClose();
      }}
    >
      <DialogContent className="max-w-4xl">
        <DialogHeader>
          <DialogTitle className="px-6 pt-6 text-3xl font-bold">
            Book a call with us to upgrade your plan!
          </DialogTitle>
          <DialogDescription className="px-6 py-2 text-base font-normal">
            The Free Plan gives you 3 product assessments. If you are interested
            in upgrading to our Standard Plan or learning about our carbon
            emissions reporting (including accurate Scope 3 data), you can book
            a call with us below.
            <div className="mt-2">
              <HubspotMeetingEmbed className=" mt-2 h-[664px] w-full" />
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}

export default useUpsellDialog;
