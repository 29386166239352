import { createContext, useContext } from "react";

export type AnalyticsContextType = {
  track: (event: string, properties?: any) => void;
  trackPage: (page?: string) => void;
  resetAnalytics: () => void;
};

export const AnalyticsContext = createContext<AnalyticsContextType>(
  {} as AnalyticsContextType
);

export function useAnalytics(): AnalyticsContextType {
  return useContext(AnalyticsContext);
}
