import { Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';

import Text from '@shared/components/content/text';
import { cn } from '@shared/lib/utils';
import { FC } from 'react';
import useAuth from '../../auth/use-auth';

interface NumProductsAndNumIngredientsStatProps {
  numProducts: number;
  numIngredients: number;
}

const NumProductsAndNumIngredientsStat: FC<
  NumProductsAndNumIngredientsStatProps
> = ({ numProducts, numIngredients }) => {
  const { user } = useAuth();
  const location = useLocation();
  return (
    <div
      className={cn(
        'flex flex-col w-full justify-start items-start space-x-0 rounded-r-lg bg-gray-00 ',
        {
          'md:flex-row md:justify-start md:items-center space-x-0 lg:pl-1 lg:space-x-6':
            user.productCategory === 'dish',
        }
      )}
    >
      <div className="flex p-4  sm:pl-4">
        <div className="ml-4 mr-8 flex size-11 items-center justify-center rounded-full bg-gray-25 p-4">
          <ListAltOutlinedIcon />
        </div>
        <div>
          <Typography variant="caption">No. of Products</Typography>
          <div className="flex flex-nowrap">
            <Text className="text-nowrap text-2xl font-bold">
              {numProducts}
              {user.organizationProductLimit &&
                !location.pathname.includes('collection') &&
                ` / ${user.organizationProductLimit}`}
            </Text>
          </div>
        </div>
      </div>
      <div className="flex p-4  sm:pl-4">
        <div className="ml-4 mr-8 flex size-11 items-center justify-center rounded-full bg-gray-25 p-4">
          <FormatListBulletedOutlinedIcon />
        </div>
        <div>
          <Typography variant="caption" sx={{ whiteSpace: 'nowrap' }}>
            No. of Ingredients
          </Typography>
          <Typography variant="h5">
            <b>{numIngredients}</b>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default NumProductsAndNumIngredientsStat;
