import { PageHeader } from '@app/components/layout/page/components/page-header';

import { useGetCollections } from '@shared/api';
import { Button } from '@shared/components/ui/button';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Page from '../../components/layout/page/page';

import CollectionTable from '@app/components/tables/collections-table/collections-table';
import Text from '@shared/components/content/text';

const CollectionListPage: React.FC = () => {
  const navigate = useNavigate();

  const { data, status } = useGetCollections();

  return (
    <Page name="Collections" status={status}>
      <PageHeader
        name="Collections"
        description=" Create a collection for a group of similar products. Products can
              be part of multiple collections"
        toolbar={
          <Button type="button" onClick={() => navigate('/collections/create')}>
            Create
          </Button>
        }
      />
      {data?.length === 0 ? (
        <div className="flex justify-between">
          <Text>You have no collections. Please create one.</Text>
        </div>
      ) : (
        <CollectionTable collections={data!} />
      )}
    </Page>
  );
};

export default CollectionListPage;
