export const ACTIVE_LINK_STYLE =
  'text-teal-800 shadow-sm shadow-teal-50 bg-teal-50 ring-1 ring-teal-50';

export const BASE_LINK_STYLE =
  'flex items-center gap-3 rounded-md text-sm px-3 py-2 text-gray-800 transition-all delay-0';

export const HOVER_LINK_STYLE =
  'hover:bg-gray-50 hover:ring hover:ring-1 hover:ring-gray-50 hover:text-gray-700 hover:no-underline';

export const SIDEBAR_HEADER_STYLE =
  'border-b border-teal-100 px-4 h-[90px] bg-teal-200/30';

export const SIDEBAR_ICON_STYLE = 'size-[18px]';

export const DESKTOP_EXPANDED_SIDEBAR_WIDTH = 'w-[256px]';
export const DESKTOP_COLLAPSED_SIDEBAR_WIDTH = 'w-[82px]';
export const MOBILE_SIDEBAR_WIDTH = 'w-[256px]';
