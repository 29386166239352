import SelectFormField from '@shared/components/form/select-form-field';
import { cn } from '@shared/lib/utils';

interface PackagingSizePickerProps {
  sizes: string[];
  name: string;
  disabled?: boolean;
  showLabel?: boolean;
  className?: string;
}

export default function PackagingSizePicker({
  className,
  sizes,
  name,
  disabled,
  showLabel = true,
}: PackagingSizePickerProps) {
  const formatData = (sizes: string[]) => {
    return sizes.map((size) => ({
      value: size,
      label: size,
    }));
  };
  return (
    <SelectFormField
      name={name}
      className={cn('', className)}
      options={formatData(sizes)}
      label={showLabel ? 'Size' : ''}
      disabled={disabled}
      includeErrorMessage
    />
  );
}
