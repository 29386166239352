import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { ResponsivePie } from '@nivo/pie';
import {
  getColorByLifeCycleStage,
  roundNumberToNDecimalPlaces,
} from '../../utils/helpers';

interface ProductPPTEmissionsDonutChartProps {
  type: string;
  PPTTotalEmissions: number;
  productEmissionsCategoryBreakdown: {
    label: string;
    emissions: number;
  }[];
}

const margin = { top: 36, right: 16, bottom: 46, left: 16 };

const styles = {
  root: {
    fontFamily: 'Inter, Roboto',
    textAlign: 'center',
    height: '100%',
    minWidth: '100px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  overlay: {
    position: 'absolute',
    top: margin.top,
    right: margin.right,
    bottom: margin.bottom,
    left: margin.left,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 96,
    color: '#000',
    textAlign: 'center',
    pointerEvents: 'none',
  },
  totalLabel: {
    fontSize: 24,
  },
};

const ProductPPTEmissionsDonutChart: React.FC<
  ProductPPTEmissionsDonutChartProps
> = ({
  PPTTotalEmissions,
  productEmissionsCategoryBreakdown,
  type,
}: ProductPPTEmissionsDonutChartProps) => {
  const getLighterColorByLifeCycleStage = (lifeCycleStage: string) => {
    switch (lifeCycleStage) {
      case 'Farming':
        return '#e3efe7';
      case 'Processing':
        return '#c9def0';
      case 'Packaging':
        return '#ffe9c2';
      case 'Transport':
        return '#fed4b9';
      default:
        return '#000';
    }
  };

  const preProcessData = (
    data: { label: string; emissions: number }[]
  ): { label: string; value: number; color: string }[] => {
    const result = [];
    for (let i = 0; i < data.length; i++) {
      result.push({
        id: data[i]['label'],
        label: data[i]['label'],
        value: roundNumberToNDecimalPlaces(data[i]['emissions'] * 1000, 1),
        color: data[i]['label'].includes('Ingredients')
          ? getColorByLifeCycleStage(type)
          : getLighterColorByLifeCycleStage(type),
      });
    }
    return result;
  };

  return (
    <Box sx={styles.root}>
      <ResponsivePie
        data={preProcessData(productEmissionsCategoryBreakdown)}
        margin={margin}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#161a1e"
        arcLinkLabelsThickness={2}
        arcLinkLabelsStraightLength={10}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLinkLabelsDiagonalLength={6}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: 'color',
          modifiers: [['darker', 2]],
        }}
        innerRadius={0.8}
        padAngle={2}
        cornerRadius={70}
        colors={{ datum: 'data.color' }}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.2]],
        }}
        enableArcLabels={false}
        enableArcLinkLabels={true}
        defs={[
          {
            id: 'dots',
            label: 'patternDots',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: 'lines',
            label: 'patternLines',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        tooltip={({ datum: { id, value, color, label } }) => (
          <div
            style={{
              padding: 12,
              color: '#000',
              background: '#fff',
              boxShadow: '5px 3px 5px #00000030',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width: '15px',
                height: '15px',
                backgroundColor: color,
                marginRight: '10px',
              }}
            ></div>
            <span>
              Emissions from {label.toString().toLowerCase()}:{' '}
              <b>{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</b>{' '}
              gCO<sub>2</sub>e
            </span>
          </div>
        )}
        layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends']}
      />
      <Box sx={styles.overlay}>
        <Box
          sx={{
            backgroundColor: '#F5F5F5',
            height: { xs: '150px', sm: '155px' },
            width: { xs: '150px', sm: '155px' },
            borderRadius: '100%',
          }}
        ></Box>
      </Box>
      <Box sx={styles.overlay}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 'bold',
          }}
        >
          {' '}
          {PPTTotalEmissions !== 0
            ? roundNumberToNDecimalPlaces(PPTTotalEmissions * 1000, 1)
            : 'N/A'}
        </Typography>

        <Typography variant="caption">
          gCO2<sub>e</sub>
        </Typography>
      </Box>
    </Box>
  );
};

export default ProductPPTEmissionsDonutChart;
