interface PageErrorProps {
  errorTitle: string;
  errorMessage: string | JSX.Element;
}

export default function PageError({
  errorTitle,
  errorMessage,
}: PageErrorProps) {
  return (
    <div className="h-full content-center">
      <div className="mx-auto size-min space-y-6 self-center">
        <div className="space-y-2 uppercase">
          <h1 className="text-6xl font-extrabold">Oops!</h1>
          <h3 className="text-nowrap text-2xl font-bold text-warning">
            {errorTitle}
          </h3>
        </div>
        <p>{errorMessage}</p>
      </div>
    </div>
  );
}
