import { z } from 'zod';

export const collectionSchema = z.object({
  name: z.string().min(1, {
    message: 'Please enter a collection name',
  }),
  description: z.string().optional(),
  products: z.array(z.string()).refine((val) => val.length > 0, {
    message: 'Please select at least one product',
  }),
});
