/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * My Emissions API
 * We calculate food carbon footprints
 * OpenAPI spec version: 1.0.0 (v1)
 */

/**
 * * `1-10` - 1 - 10
* `11-50` - 11 - 50
* `51-250` - 51 - 250
* `251-1000` - 251 - 1000
* `1000+` - 1000+
 */
export type OrganizationNumProductsRangeEnum = typeof OrganizationNumProductsRangeEnum[keyof typeof OrganizationNumProductsRangeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationNumProductsRangeEnum = {
  '1-10': '1-10',
  '11-50': '11-50',
  '51-250': '51-250',
  '251-1000': '251-1000',
  '1000+': '1000+',
} as const;
