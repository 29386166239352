import { PageHeader } from '@app/components/layout/page/components/page-header';
import { useGetTransport } from '@shared/api';
import Loader from '@shared/components/loader';
import { Button } from '@shared/components/ui/button';
import { useNavigate } from 'react-router-dom';
import Page from '../../components/layout/page/page';
import TransportTable from './components/transport-table';

export interface Transport {
  createdDate: string;
  modifiedDate: string;
  description: string;
  notesAndAssumptions: string[];
  uuid: string;
}

const TransportPage: React.FC = () => {
  const navigate = useNavigate();
  const { data: transportData, isLoading, isError } = useGetTransport();

  return (
    <Page name="Transport">
      <PageHeader
        name="Transport"
        toolbar={
          <Button onClick={() => navigate('/transport/create')}>Create</Button>
        }
      />
      <div style={{ marginBottom: '3rem' }}>
        {isLoading && <Loader />}
        {transportData && transportData.length < 1 && (
          <p>You have no transport types yet. Please create some.</p>
        )}

        {transportData && transportData.length > 0 && (
          <TransportTable transport={transportData} />
        )}
      </div>
    </Page>
  );
};

export default TransportPage;
