import PageError from './page-error';

export default function PageErrorForbidden() {
  return (
    <PageError
      errorTitle="Page not accessible"
      errorMessage={
        <span className="whitespace-nowrap">
          Sorry, you&apos;re not allowed to access this page.
          <br />
          Get in touch if you think this might be an error.
        </span>
      }
    />
  );
}
