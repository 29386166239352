import { useGetProductResult } from '@shared/api';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@shared/components/ui/tabs';
import { Loader2 } from 'lucide-react';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PageHeader } from '../layout/page/components/page-header';
import Page from '../layout/page/page';
import { ComparisonPageVisualisations } from './comparison-page-visualisations';

import { useAnalytics } from '@app/services/analytics/AnalyticsContext';
import { Button } from '@shared/components/ui/button';
import { useToast } from '@shared/components/ui/use-toast';
import { ComparisonPageSummary } from './comparison-page-summary';

export const getPercentageDifference = (value1: number, value2: number) => {
  if (value1 === value2) {
    return 0;
  }
  const diff = (value1 - value2) / value2;
  return Math.round(diff * 100);
};

export type ComparisonResults = {
  weights: number[];
  emissionsPerServing: number[];
  totalEmissions: number[];
  carbonIntensity: number[];
  carbonRating: string[];

  emissionsServingsDifference: number[];
  emissionsDifference: number[];
  carbonIntensityDifference: number[];

  FPPTComparison: {
    name: string;
    farmingEmissions: number;
    processingEmissions: number;
    packagingEmissions: number;
    transportEmissions: number;
  }[];

  emissionsSavings: number;
};

export default function ComparisonPage() {
  const { track } = useAnalytics();
  const { toast } = useToast();
  const [searchParams] = useSearchParams();
  const productA = searchParams.get('product_a');
  const productB = searchParams.get('product_b');

  const {
    data: productAResult,
    isLoading: productAResultLoading,
    isError: productAResultError,
  } = useGetProductResult(productA!);

  const {
    data: productBResult,
    isLoading: prodictBResultLoading,
    isError: productBResultError,
  } = useGetProductResult(productB!);

  const handleShare = async () => {
    try {
      navigator.clipboard.writeText(
        `${window.location.origin}/product-comparison?product_a=${productA}&product_b=${productB}`
      );
      toast({
        description: 'Copied to clipboard',
      });
    } catch {
      toast({
        description: 'Failed to copy to clipboard',
        variant: 'destructive',
      });
    } finally {
      track('Shared Product Comparison');
    }
  };

  // When both products are loaded, we can calculate the comparison results
  const comparisonResults = useMemo(() => {
    if (productAResult && productBResult) {
      const products = [productAResult, productBResult];
      const results = products.reduce(
        (acc, product, i) => {
          const newAcc = { ...acc };

          newAcc.weights.push(product.totalIngredientWeight / 1000);
          newAcc.emissionsPerServing.push(product.totalEmissionsPerServing);
          newAcc.totalEmissions.push(product.totalEmissions);
          newAcc.carbonIntensity.push(product.totalEmissionsPerKg);
          newAcc.carbonRating.push(product.emissionsCategory);

          newAcc.FPPTComparison.push({
            name: product.name,
            farmingEmissions: product.lifeCycleStageEmissions[0].emissions,
            processingEmissions: product.lifeCycleStageEmissions[1].emissions,
            packagingEmissions: product.lifeCycleStageEmissions[2].emissions,
            transportEmissions: product.lifeCycleStageEmissions[3].emissions,
          });

          newAcc.emissionsSavings =
            newAcc.totalEmissions[0] - newAcc.totalEmissions[i];

          if (i > 0) {
            newAcc.emissionsServingsDifference.push(
              getPercentageDifference(
                newAcc.emissionsPerServing[0],
                newAcc.emissionsPerServing[1]
              )
            );

            newAcc.emissionsDifference.push(
              getPercentageDifference(
                newAcc.totalEmissions[0],
                newAcc.totalEmissions[1]
              )
            );

            newAcc.carbonIntensityDifference.push(
              getPercentageDifference(
                newAcc.carbonIntensity[0],
                newAcc.carbonIntensity[1]
              )
            );
          }

          return newAcc;
        },
        {
          weights: [],
          emissionsPerServing: [],
          totalEmissions: [],
          carbonIntensity: [],
          carbonRating: [],
          emissionsServingsDifference: [],
          emissionsDifference: [],
          carbonIntensityDifference: [],
          FPPTComparison: [],
          emissionsSavings: 0,
        } as ComparisonResults
      );

      return results;
    }
  }, [productAResult, productBResult]);

  const {
    weights,
    emissionsPerServing,
    totalEmissions,
    carbonIntensity,
    carbonRating,
    emissionsServingsDifference,
    emissionsDifference,
    carbonIntensityDifference,
    FPPTComparison,
    emissionsSavings,
  } = comparisonResults ?? {};

  return (
    <Page name="Product Comparison">
      <PageHeader
        name="Comparison"
        toolbar={
          <Button variant="ghost" onClick={handleShare}>
            Share
          </Button>
        }
      />

      {(productAResultLoading || prodictBResultLoading) && (
        <div className="flex h-96 flex-col items-center justify-center">
          <Loader2 className="animate-spin" />
        </div>
      )}

      {(productAResultError || productBResultError) && <div>Error</div>}

      {productAResult && productBResult && comparisonResults && (
        <Tabs defaultValue="summary">
          <TabsList className="mb-4">
            <TabsTrigger value="summary">Summary</TabsTrigger>
            <TabsTrigger
              value="visualisation"
              onClick={() => track('Viewed Comparison Visualisation')}
            >
              Visualisation
            </TabsTrigger>
          </TabsList>
          <TabsContent value="summary">
            <ComparisonPageSummary
              productAName={productAResult.name}
              productBName={productBResult.name}
              weights={weights!}
              emissionsPerServing={emissionsPerServing!}
              totalEmissions={totalEmissions!}
              carbonIntensity={carbonIntensity!}
              carbonRating={carbonRating!}
              emissionsServingsDifference={emissionsServingsDifference!}
              emissionsDifference={emissionsDifference!}
              carbonIntensityDifference={carbonIntensityDifference!}
              FPPTComparison={FPPTComparison!}
            />
          </TabsContent>
          <TabsContent value="visualisation">
            <ComparisonPageVisualisations
              productA={{
                name: productAResult.name,
                rating: productAResult.emissionsCategory,
              }}
              productB={{
                name: productBResult.name,
                rating: productBResult.emissionsCategory,
              }}
              emissionsSavings={emissionsSavings!}
            />
          </TabsContent>
        </Tabs>
      )}
    </Page>
  );
}
