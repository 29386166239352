// Packages
import React from "react";

// Material UI Components
import { Box, Grid, Typography } from "@mui/material";

// Components
import InfoPopOver from "../../../components/InfoPopOver";
import DashboardFPPTBarChart from "../../../components/charts/DashboardFPPTBarChart";

interface AverageLifeCycleEmissionsInterface {
  averageLifeCycleStageEmissionsPerKg: {
    lifeCycleStage: string;
    emissions: number;
  }[];
}

const AverageLifeCycleEmissions: React.FC<
  AverageLifeCycleEmissionsInterface
> = ({ averageLifeCycleStageEmissionsPerKg }) => {
  return (
    <>
      <Grid item xs={12} sm={12} md={6} mt={2}>
        <Box display="flex" alignItems="center" mb="1.3rem">
          <Typography
            variant="h6"
            sx={{ color: "text.primary", marginRight: "0.5rem" }}
          >
            Average Life Cycle Emissions (per kg)
          </Typography>
          <InfoPopOver subjectMatter="Average Life Cycle Emissions" />
        </Box>
        <div
          style={{
            height: "350px",
            width: "98%",
            // marginLeft: "16px",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            display: "flex",
          }}
        >
          <Box
            sx={{
              height: "100%",
              width: "100%",
            }}
          >
            <DashboardFPPTBarChart
              chartLayout="vertical"
              averageLifeCycleStageEmissionsPerKg={
                averageLifeCycleStageEmissionsPerKg
              }
            />
          </Box>
        </div>
      </Grid>
    </>
  );
};

export default AverageLifeCycleEmissions;
