import { PageHeader } from '@app/components/layout/page/components/page-header';
import Page from '@app/components/layout/page/page';
import { PageTabs, Tab } from '@shared/components/page-tabs';
import { Badge } from '@shared/components/ui/badge';
import { Outlet } from 'react-router-dom';

const IngredientsLayout: React.FC = () => {
  const tabs: Tab[] = [
    {
      title: 'All',
      href: '/ingredients',
    },
    {
      title: 'Insights',
      href: '/ingredients/insights',
      icon: <Badge variant="new_feature">New</Badge>,
    },
  ];
  return (
    <Page name="Ingredients" page="layout">
      <PageHeader name="Ingredients" tabs={<PageTabs tabs={tabs} />} />
      <Outlet />
    </Page>
  );
};

export default IngredientsLayout;
