import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';

interface HubspotMeetingEmbedProps {
  className?: ClassValue;
}

export default function HubspotMeetingEmbed({
  className,
}: HubspotMeetingEmbedProps) {
  return (
    <iframe
      src="https://meetings-eu1.hubspot.com/natalie-smith/platform-upgrade?embed=true"
      className={cn('', className)}
    />
  );
}
