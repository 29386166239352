import { zodResolver } from '@hookform/resolvers/zod';
import InputFormField from '@shared/components/form/input-form-field';
import { Button } from '@shared/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@shared/components/ui/form';
import { Input } from '@shared/components/ui/input';
import { Textarea } from '@shared/components/ui/textarea';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

export const createPptSchema = z.object({
  name: z
    .string({
      required_error: 'Please enter a name',
    })
    .min(1, {
      message: 'The name must be longer than 1 character',
    }),
  description: z.string().optional(),
  file: z.instanceof(File, {
    message: 'Please upload a file',
  }),
});

interface CreatePptFormProps {
  namePlaceholder: string;
  descriptionPlaceholder: string;
  isLoading?: boolean;
  infoSection?: React.ReactNode;
  onFormSubmit: (data: z.infer<typeof createPptSchema>) => void;
}
export default function CreatePptForm({
  namePlaceholder,
  descriptionPlaceholder,
  infoSection,
  isLoading = false,
  onFormSubmit,
}: CreatePptFormProps) {
  const form = useForm<z.infer<typeof createPptSchema>>({
    resolver: zodResolver(createPptSchema),
  });

  async function onSubmit(values: z.infer<typeof createPptSchema>) {
    onFormSubmit(values);
  }

  return (
    <Form {...form}>
      <form
        onSubmit={(e) => {
          e.stopPropagation();
          form.handleSubmit(onSubmit)(e);
        }}
        className="max-w-form space-y-8"
      >
        <div className="space-y-4">
          {infoSection && <div className="pb-1">{infoSection}</div>}
          <InputFormField
            name="name"
            label="Name"
            placeholder={namePlaceholder}
            includeErrorMessage
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description (optional)</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder={descriptionPlaceholder}
                    className="resize-none"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div>
            <FormField
              control={form.control}
              name="file"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>File</FormLabel>
                  <FormControl>
                    <Input
                      type="file"
                      onChange={(e) => {
                        field.onChange(e.target.files?.[0] ?? null);
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>

        <Button type="submit" className=" float-end" loading={isLoading}>
          Submit
        </Button>
      </form>
    </Form>
  );
}
