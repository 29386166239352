import Text from '@shared/components/content/text';
import { Button } from '@shared/components/ui/button';
import { useNavigate } from 'react-router-dom';

export default function DashboardEmptyPage() {
  const navigate = useNavigate();
  return (
    <div className="grid flex-1">
      <div className="m-auto grid justify-items-center space-y-10">
        <Text className="text-2xl">Welcome to your dashboard</Text>
        <div className="grid max-w-2xl grid-cols-3 items-end justify-items-center">
          <div className="col-span-3 flex w-52 items-baseline space-x-2 opacity-55 md:col-span-1">
            <div className="h-16 w-8 rounded-md border border-secondary-700 bg-secondary-200"></div>
            <div className="h-10 w-8 rounded-md border border-secondary-700 bg-secondary-200"></div>
            <div className="h-32 w-8 rounded-md border border-secondary-700 bg-secondary-200"></div>
            <div className="h-44 w-8 rounded-md border border-secondary-700 bg-secondary-200"></div>
            <div className="h-6 w-8 rounded-md border border-secondary-700 bg-secondary-200"></div>
            <div className="h-40 w-8 rounded-md border border-secondary-700 bg-secondary-200"></div>
          </div>
          <div className="hidden opacity-55 md:flex">
            <div className="size-40 rounded-full border border-warning-700 bg-warning-200"></div>
          </div>
          <div className="hidden h-min grid-cols-2 gap-2 opacity-55 md:grid">
            <div className="h-20 rounded-md border border-teal-700 bg-teal-200"></div>
            <div className="h-20 w-48 max-w-full rounded-md border border-teal-700 bg-teal-200"></div>
            <div className="h-20 w-48 max-w-full rounded-md border border-teal-700 bg-teal-200"></div>
            <div className="h-20 w-48 max-w-full rounded-md border border-teal-700 bg-teal-200"></div>
          </div>
        </div>
        <Text className="max-w-xl text-center md:max-w-2xl">
          Once you’ve completed your first assessment you’ll start seeing an
          overview of your products and their emissions here. Get started by
          assessing your first product!
        </Text>
        <Button
          type="button"
          className="bg-primary"
          onClick={() => navigate('/products/create')}
        >
          Assess your first product
        </Button>
      </div>
    </div>
  );
}
