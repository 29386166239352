// React
import * as React from 'react';

// Material UI Components
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

// Helpers
import LinkIcon from '@mui/icons-material/Link';
import { Link } from 'react-router-dom';
import InfoPopOver from '../../../../components/InfoPopOver';
import { ProductIngredient } from '../../../../types/products';
import { LIFE_CYCLE_COLORS } from '../../../../utils/constants';
import { roundNumberToNDecimalPlaces } from '../../../../utils/helpers';

interface ProductIngredientsTableProps {
  ingredients: ProductIngredient[];
  ingredientFarmingEmissions: number;
  ingredientPackagingEmissions: number;
  ingredientProcessingEmissions: number;
  ingredientTransportEmissions: number;
  totalIngredientWeight: number;
  totalIngredientEmissions: number;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
    paddingX: '0rem',
  },
}));

const ProductIngredientsTable: React.FC<ProductIngredientsTableProps> = ({
  ingredients,
  ingredientFarmingEmissions,
  ingredientPackagingEmissions,
  ingredientProcessingEmissions,
  ingredientTransportEmissions,
  totalIngredientWeight,
  totalIngredientEmissions,
}) => {
  const computeTotalGrossIngredientWeight = (): number => {
    let totalGrossIngredientWeight = 0;
    ingredients.forEach((ingredient) => {
      totalGrossIngredientWeight += ingredient.weight;
    });
    return totalGrossIngredientWeight;
  };
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 650,
          // border: "1px solid #00000010",
          borderRadius: '10px',
        }}
        aria-label="simple table"
      >
        <TableHead sx={{ backgroundColor: '#ffffff' }}>
          <TableRow>
            <StyledTableCell colSpan={2}></StyledTableCell>
            <StyledTableCell
              align="center"
              colSpan={4}
              sx={{
                paddingBottom: '0rem',
                // backgroundColor: "#00000005",
              }}
            >
              Emissions (gCO<sub>2</sub>e)
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell align="left">Ingredient</StyledTableCell>
            <StyledTableCell align="center">Weight (g)</StyledTableCell>
            <StyledTableCell
              sx={{
                backgroundColor: LIFE_CYCLE_COLORS['Farming'],
                paddingX: '1rem',
              }}
              align="center"
            >
              Farming
            </StyledTableCell>
            <StyledTableCell
              sx={{
                backgroundColor: LIFE_CYCLE_COLORS['Processing'],
                paddingX: '1rem',
              }}
              align="center"
            >
              Processing
            </StyledTableCell>
            <StyledTableCell
              sx={{
                backgroundColor: LIFE_CYCLE_COLORS['Packaging'],
                paddingX: '1rem',
              }}
              align="center"
            >
              Packaging
            </StyledTableCell>
            <StyledTableCell
              sx={{
                backgroundColor: LIFE_CYCLE_COLORS['Transport'],
                paddingX: '1rem',
              }}
              align="center"
            >
              Transport
            </StyledTableCell>
            <StyledTableCell align="center">Total</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ingredients.map((ingredient) => (
            <React.Fragment key={ingredient.longDesc}>
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  '&:nth-of-type(even)': {
                    backgroundColor: '#00000007',
                  },
                  transition: 'all 1s ease',
                }}
              >
                <TableCell
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {ingredient.productUuid ? (
                    <Link
                      to={`/products/${ingredient.productUuid}`}
                      // target="_blank"
                      style={{
                        color: '#161a1e',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {ingredient.longDesc}
                      <LinkIcon
                        sx={{
                          fontSize: '1rem',
                          marginLeft: '0.5rem',
                        }}
                      />
                    </Link>
                  ) : (
                    ingredient.longDesc
                  )}
                  {ingredient.withoutMatchReason !== null && (
                    <InfoPopOver
                      style={{
                        marginLeft: '0.5rem',
                      }}
                      width="15px"
                      subjectMatter={`No Suitable Match - ${ingredient.withoutMatchReason}`}
                    />
                  )}

                  {/* <ExpandMore /> */}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    borderRight: '1px solid #00000020',
                  }}
                >
                  {/* {roundNumberToNDecimalPlaces(ingredient.weight, 1)} */}

                  {(ingredient.weight !== null &&
                    roundNumberToNDecimalPlaces(ingredient.weight, 1)) ||
                    '-'}
                  {ingredient.netWeight && (
                    <>
                      <br />{' '}
                      <small style={{ color: '#6e6e6e' }}>
                        Net:{' '}
                        {roundNumberToNDecimalPlaces(ingredient.netWeight, 1)}
                      </small>
                    </>
                  )}
                </TableCell>
                <TableCell align="right">
                  {(ingredient.farmingEmissions !== null &&
                    roundNumberToNDecimalPlaces(
                      ingredient.farmingEmissions * 1000,
                      1
                    )
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')) ||
                    '-'}
                </TableCell>
                <TableCell align="right">
                  {(ingredient.processingEmissions !== null &&
                    roundNumberToNDecimalPlaces(
                      ingredient.processingEmissions * 1000,
                      1
                    )
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')) ||
                    '-'}
                </TableCell>
                <TableCell align="right">
                  {(ingredient.packagingEmissions !== null &&
                    roundNumberToNDecimalPlaces(
                      ingredient.packagingEmissions * 1000,
                      1
                    )
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')) ||
                    '-'}
                </TableCell>
                <TableCell align="right">
                  {(ingredient.transportEmissions !== null &&
                    roundNumberToNDecimalPlaces(
                      ingredient.transportEmissions * 1000,
                      1
                    )
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')) ||
                    '-'}
                </TableCell>
                <TableCell align="right">
                  {(ingredient.emissions !== null &&
                    roundNumberToNDecimalPlaces(ingredient.emissions * 1000, 1)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')) ||
                    '-'}
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
          <TableRow
            sx={{
              borderTop: '1px solid black',
              fontWeight: 'bold',
            }}
          >
            <TableCell
              sx={{
                fontWeight: 'bold',
              }}
            >
              Total
            </TableCell>
            <TableCell
              align="right"
              sx={{
                fontWeight: 'bold',
              }}
            >
              {roundNumberToNDecimalPlaces(
                computeTotalGrossIngredientWeight(),
                1
              )
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',') || '-'}
              {totalIngredientWeight &&
                totalIngredientWeight !==
                  computeTotalGrossIngredientWeight() && (
                  <>
                    <br />{' '}
                    <small style={{ color: '#6e6e6e', fontWeight: '500' }}>
                      Net:{' '}
                      {(totalIngredientWeight !== null &&
                        roundNumberToNDecimalPlaces(totalIngredientWeight, 1)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')) ||
                        '-'}
                    </small>
                  </>
                )}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                fontWeight: 'bold',
              }}
            >
              {(ingredientFarmingEmissions !== null &&
                roundNumberToNDecimalPlaces(
                  ingredientFarmingEmissions * 1000,
                  1
                )
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')) ||
                '-'}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                fontWeight: 'bold',
              }}
            >
              {(ingredientProcessingEmissions !== null &&
                roundNumberToNDecimalPlaces(
                  ingredientProcessingEmissions * 1000,
                  1
                )
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')) ||
                '-'}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                fontWeight: 'bold',
              }}
            >
              {(ingredientPackagingEmissions !== null &&
                roundNumberToNDecimalPlaces(
                  ingredientPackagingEmissions * 1000,
                  1
                )
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')) ||
                '-'}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                fontWeight: 'bold',
              }}
            >
              {(ingredientTransportEmissions !== null &&
                roundNumberToNDecimalPlaces(
                  ingredientTransportEmissions * 1000,
                  1
                )
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')) ||
                '-'}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                fontWeight: 'bold',
              }}
            >
              {(totalIngredientEmissions !== null &&
                roundNumberToNDecimalPlaces(totalIngredientEmissions * 1000, 1)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')) ||
                '-'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductIngredientsTable;
