import { Button } from '@shared/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@shared/components/ui/dropdown-menu';
import { MoreHorizontal } from 'lucide-react';

export interface PageHeaderToolbarProps
  extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode[] | React.ReactNode;
}

export default function PageHeaderToolbar({
  children,
}: PageHeaderToolbarProps) {
  return (
    <div>
      <div className="md:hidden">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="size-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="size-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="flex flex-col">
            {children}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="hidden md:block">
        <div className="flex items-center">{children}</div>
      </div>
    </div>
  );
}
