import '@shared/services/sentry';
import '@shared/styles/globals.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { inject } from '@vercel/analytics';
import { createRoot } from 'react-dom/client';
import App from './App';

inject();

const queryClient = new QueryClient();
const domNode = document.getElementById('root');

if (domNode) {
  const root = createRoot(domNode);
  root.render(
    <QueryClientProvider client={queryClient}>
      <App />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
} else {
  throw 'Could not find "root" element in document';
}
