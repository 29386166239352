import Page from '@app/components/layout/page/page';
import { useNavigate } from 'react-router-dom';
import OnboardingGuideTemplate from './components/onboarding-guide-template';

import HomeScreenshot from './assets/home-screenshot.png';

export default function WelcomePage() {
  const navigate = useNavigate();
  return (
    <Page name="Welcome page">
      <OnboardingGuideTemplate
        title="Welcome to My Emissions"
        description="We’ve put together a short guide to get you started and help you make the most of the My Emissions platform."
        handlePrimaryAction={() => {
          navigate('/guide/products');
        }}
        primaryActionText="Let's go!"
        handleSecondaryAction={() => {
          navigate('/');
        }}
        secondaryActionText="Skip for now"
      >
        <div className="flex justify-center">
          <img
            src={HomeScreenshot}
            alt="Screenshot of My Emissions dashboard"
            className="max-h-[365px] w-full max-w-lg rounded-lg border border-teal-300 shadow-xl md:max-w-2xl"
          />
        </div>
      </OnboardingGuideTemplate>
    </Page>
  );
}
