import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';
import React from 'react';
import Text from './text';

type UnitType = 'gCO2e' | 'kgCO2e/kgfood';

interface UnitProps {
  variant: UnitType;
  className?: ClassValue;
}

const renderUnit = (variant: UnitType) => {
  switch (variant) {
    case 'gCO2e':
      return (
        <>
          gCO<sub>2</sub>e
        </>
      );
    case 'kgCO2e/kgfood':
      return (
        <>
          kgCO<sub>2</sub>e/kg<sub>food</sub>
        </>
      );
  }
};

const Unit: React.FC<UnitProps> = ({ variant, className }) => {
  return (
    <Text
      variant="unit"
      className={cn('text-[10px] leading-[18px]', className)}
    >
      {renderUnit(variant)}
    </Text>
  );
};

export default Unit;
