import PageError from './page-error';

export default function PageErrorFallback() {
  return (
    <PageError
      errorTitle="Something went wrong"
      errorMessage="Please refresh and try again or contact us if the error persists!"
    />
  );
}
