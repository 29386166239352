import { createContext, useContext } from 'react';

export type IntercomContextType = {
  showIntercom: () => void;
  hideIntercom: () => void;
  resetIntercom: () => void;
};

export const IntercomContext = createContext<IntercomContextType>(
  {} as IntercomContextType
);

export function useIntercom(): IntercomContextType {
  return useContext(IntercomContext);
}
