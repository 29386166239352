import Loader from '@shared/components/loader';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import useAuth from '../../auth/use-auth';

const AuthenticatedRoutes = () => {
  const { loadingAuth, isAuthenticated, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (loadingAuth) return;

    if (!loadingAuth && !isAuthenticated) {
      navigate('/login');
    }

    if (
      (!user?.emailVerified || !user?.hasCompletedSignup) &&
      isAuthenticated &&
      !loadingAuth
    ) {
      navigate('/sign-up/about-company');
    }
  }, [isAuthenticated, loadingAuth, navigate, user]);

  if (loadingAuth || !isAuthenticated)
    return <Loader className="h-screen" size="lg" withLogo />;

  return <Outlet />;
};

export default AuthenticatedRoutes;
