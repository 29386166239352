import { type Table } from '@tanstack/react-table';

import { computeProductsExportFileName } from '@app/utils/helpers';
import { useGetProductsCSVExport } from '@shared/api';
import { ProductList } from '@shared/api/types';
import { Icons } from '@shared/components/content/icons';
import { Button } from '@shared/components/ui/button';
import sentry from '@shared/services/sentry';
import { useState } from 'react';

interface ProductsTableToolbarActionsProps {
  table: Table<ProductList>;
}

export function ProductsTableToolbarActions({
  table,
}: ProductsTableToolbarActionsProps) {
  const [download, setDownload] = useState(false);
  const { refetch } = useGetProductsCSVExport(download);

  const onProductsCSVExport = async () => {
    try {
      setDownload(true);
      const { data } = await refetch();
      const filename = computeProductsExportFileName();
      const tempLink = document.createElement('a');
      tempLink.setAttribute('download', `${filename}.csv`);
      const universalBOM = '\uFEFF';
      tempLink.href = `data:text/csv;charset=utf-8,${encodeURIComponent(
        universalBOM + data
      )}`;
      tempLink.click();
    } catch (error) {
      sentry.log(error);
    } finally {
      setDownload(false);
    }
  };

  return (
    <div className="flex items-center gap-2">
      <Button
        variant="outline"
        size="sm"
        onClick={onProductsCSVExport}
        icon={<Icons.download className="mr-2 size-4" aria-hidden="true" />}
        loading={download}
        disabled={download}
      >
        Download
      </Button>
    </div>
  );
}
