import { Box, Divider, Typography } from "@mui/material";
import ProductProcessingTable from "./ProductProcessingTable";

// Temporary import of mock data to be able to see UI without making too many changes (TO BE DELETE)
import NotesBox from "../../../../components/NotesBox";
import { roundNumberToNDecimalPlaces } from "../../../../utils/helpers";

interface ProductProcessingTypesProps {
  description: string;
  proportionOfProduct: number;
  totalEmissionsPerItem: number;
  steps: {
    description: string;
    location: string;
    calculationMethod: string;
    emissionsPerProduct: number;
  }[];
  notesAndAssumptions: string[];
}
const ProductProcessingTypes: React.FC<ProductProcessingTypesProps> = ({
  description,
  proportionOfProduct,
  totalEmissionsPerItem,
  steps,
  notesAndAssumptions,
}) => {
  return (
    <Box mt={2} mb={7}>
      <Box
        display="flex"
        sx={{
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "start", md: "center" },
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "text.primary",
            marginBottom: "0.5rem",
            fontSize: "1.125rem",
          }}
        >
          {description} (
          <strong>
            {roundNumberToNDecimalPlaces(
              proportionOfProduct * 100,
              1,
              proportionOfProduct * 100 === 100 ? false : true
            )}
            % {/* don't add trailing 0 if it's 100% */}
          </strong>{" "}
          of products)
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: "text.primary", marginBottom: "0.5rem" }}
        >
          Total Emissions per Product:{" "}
          <strong>
            {(totalEmissionsPerItem &&
              roundNumberToNDecimalPlaces(totalEmissionsPerItem * 1000, 1)) ||
              "N/A"}{" "}
            gCO
            <sub>2</sub>e
          </strong>
        </Typography>
      </Box>
      {/* Rename to items */}
      <ProductProcessingTable steps={steps} />
      {notesAndAssumptions.length !== 0 ? (
        <NotesBox notes={notesAndAssumptions} />
      ) : null}
      {/* <NotesBox notes={notesAndAssumptions} /> */}
    </Box>
  );
};

export default ProductProcessingTypes;
