import ProductIngredientsBarChart from '@app/components/charts/ProductIngredientsBarChart';
import ProductLifeCycleInfo from '@app/components/RecipleLifeCycleInfo';
import StatsCard from '@app/components/stats/StatsCard';
import { RECIPE_LIFE_CYCLE_INFO } from '@app/utils/constants';
import { roundNumberToNDecimalPlaces } from '@app/utils/helpers';
import { Box, Grid, Paper } from '@mui/material';
import { ProductResult } from '@shared/api/types';
import { useOutletContext } from 'react-router-dom';
import ProductIngredientsTable from '../../tabs/components/ProductIngredientsTable';

export default function ProductDetailIngredients() {
  const product: ProductResult = useOutletContext();
  const {
    status,
    totalIngredientWeight,
    totalIngredientEmissions,
    ingredientShareOfTotalEmissions,
    ingredientFarmingEmissions,
    ingredientPackagingEmissions,
    ingredientProcessingEmissions,
    ingredientTransportEmissions,
    ingredients,
  } = product;

  const sortIngredientsByEmissions = (): void => {
    ingredients.sort(function (a, b) {
      return b.emissions - a.emissions;
    });
  };

  const items: object[] = [];

  const noOfIngPerSlide =
    window.screen.width < 480 ? 3 : window.screen.width < 768 ? 4 : 7;

  const computeBarChartHeight = () => {
    const pxHeightPerIngredient = 110;
    return (ingredients.length * pxHeightPerIngredient).toString() + 'px';
  };

  const sortIngredients = () => {
    const newList = ingredients.slice(0);
    for (let i = 0; i < newList.length; i++) {
      items.push(newList.splice(0, noOfIngPerSlide));
    }
    return '';
  };

  sortIngredientsByEmissions();
  sortIngredients();
  return (
    <Grid container>
      <div>
        <Grid
          item
          sx={{
            filter: status === 'expired' ? 'grayscale(100%)' : '',
          }}
        >
          <Grid item xs={12}>
            <ProductLifeCycleInfo
              title="Ingredients"
              text={RECIPE_LIFE_CYCLE_INFO.Ingredient.text}
              image={RECIPE_LIFE_CYCLE_INFO.Ingredient.image}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{
            marginBottom: '1rem',
            filter: status === 'expired' ? 'grayscale(100%)' : '',
          }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <Box>
              <StatsCard
                title="Total Emissions from Ingredients"
                value={
                  (totalIngredientEmissions &&
                    roundNumberToNDecimalPlaces(
                      totalIngredientEmissions * 1000,
                      1
                    )) ||
                  'N/A'
                }
                unit="gCO2e"
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box>
              <StatsCard
                title="Percentage of Product's Total Emissions - Ingredients"
                value={
                  (ingredientShareOfTotalEmissions &&
                    roundNumberToNDecimalPlaces(
                      ingredientShareOfTotalEmissions * 100,
                      1
                    )) ||
                  'N/A'
                }
                unit="%"
                tooltip
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <ProductIngredientsTable
              ingredients={ingredients}
              ingredientFarmingEmissions={ingredientFarmingEmissions}
              ingredientPackagingEmissions={ingredientPackagingEmissions}
              ingredientProcessingEmissions={ingredientProcessingEmissions}
              ingredientTransportEmissions={ingredientTransportEmissions}
              totalIngredientWeight={totalIngredientWeight}
              totalIngredientEmissions={totalIngredientEmissions}
            />
          </Grid>

          <Grid item xs={12}>
            <Box>
              {status === 'in progress' ? (
                <Box
                  sx={{
                    width: '100%',
                    marginX: 'auto',
                    height: computeBarChartHeight(),
                  }}
                ></Box>
              ) : (
                <>
                  <Paper>
                    <Box
                      sx={{
                        width: '100%',
                        minHeight: '180px',
                        marginX: 'auto',
                        height: computeBarChartHeight(),
                      }}
                    >
                      <ProductIngredientsBarChart
                        productIngredientsBarChartData={ingredients}
                        maxValue={ingredients[0].emissions}
                        chartLayout="horizontal"
                      />
                    </Box>
                  </Paper>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
}
