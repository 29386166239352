import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

import { INFO_SUBJECT_MATTERS_MESSAGES } from "../utils/constants";
import { Box } from "@mui/material";
import { HelpOutlineOutlined, InfoOutlined } from "@mui/icons-material";

interface InfoPopOverProps {
  style?: React.CSSProperties;
  subjectMatter: string;
  width?: string | "20px";
}
const InfoPopOver: React.FC<InfoPopOverProps> = ({
  subjectMatter,
  width,
  style,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Box
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{
          justifySelf: "center",
          display: "flex",
          ...style,
        }}
      >
        <HelpOutlineOutlined
          sx={{
            width: { width },
            alignSelf: "center",
          }}
        />
      </Box>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 2, maxWidth: "500px" }}>
          {INFO_SUBJECT_MATTERS_MESSAGES[subjectMatter]}
        </Typography>
      </Popover>
    </div>
  );
};

export default InfoPopOver;
