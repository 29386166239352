import { useGenerateRecipe } from '@admin/reggie/api/useGenerateRecipe';
import useAuth from '@app/auth/use-auth';
import CollectionPicker from '@app/components/collection-picker';
import Heading from '@shared/components/content/heading';
import { Icons } from '@shared/components/content/icons';
import InputFormField from '@shared/components/form/input-form-field';
import { RepeatableFormRows } from '@shared/components/form/repeatable-form-rows';
import GenerateRecipeCard from '@shared/components/product-form/components/generate-recipe-card';
import RecipeIngredientRow from '@shared/components/product-form/components/recipe-ingredient-row';
import { useGetUnitUuid } from '@shared/components/product-form/hooks/use-get-unit-uuid';
import { Button } from '@shared/components/ui/button';
import { Form } from '@shared/components/ui/form';
import { toast } from '@shared/components/ui/use-toast';
import { cn } from '@shared/lib/utils';
import sentry from '@shared/services/sentry';
import { ClassValue } from 'clsx';
import { KeyboardEvent, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import RecipePackagingRow from './components/recipe-packaging-row';
import ReggieLoader from './components/reggie-loader';

interface ProductFormProps<T> {
  form: UseFormReturn<any>;
  handleSubmit: (data: T) => Promise<void>;
  fields: string[];
  includeReggie?: boolean;
  submitText?: string;
  secondaryButtonText?: string;
  secondaryButtonAction?: (data: T) => void;
  className?: ClassValue;
}

export default function ProductForm<T>({
  form,
  handleSubmit,
  fields,
  includeReggie = false,
  submitText = 'Create product',
  secondaryButtonText,
  secondaryButtonAction,
  className,
}: ProductFormProps<T>) {
  const defaultUnit = useGetUnitUuid('g');
  const { mutateAsync: generateRecipe } = useGenerateRecipe();
  const [formLoading, setFormLoading] = useState(false);
  const [loadingRecipeGeneration, setLoadingRecipeGeneration] = useState(false);
  const [forceReggieLoaderComplete, setForceReggieLoaderComplete] =
    useState(false);

  const { user } = useAuth();

  const onGenerateRecipe = async () => {
    const recipeNameError = await form.trigger('recipeName');
    if (!recipeNameError) return;

    const recipeName = form.getValues('recipeName');
    setLoadingRecipeGeneration(true);
    setForceReggieLoaderComplete(false);

    try {
      const generatedRecipe = await generateRecipe({
        name: recipeName,
        user_uuid: user.uuid,
      });
      if (generatedRecipe.ingredients.length === 0) {
        toast({
          title: 'No recipe could be found',
          description: 'Please check your recipe name',
          variant: 'destructive',
        });
        return;
      }
      form.setValue(
        'ingredients',
        generatedRecipe.ingredients
          .map((ingredient) => ({
            entityUuid: ingredient.base_food_uuid,
            quantity: ingredient.weight,
            unitUuid: defaultUnit,
            entityType: 'base_food',
          }))
          .sort((a, b) => b.quantity - a.quantity)
      );
      form.setValue('reggieUuid', generatedRecipe.request_uuid);
    } catch (error) {
      toast({
        title: 'Failed to generate recipe',
        description: 'Please try again or contact us if the error persists',
        variant: 'destructive',
      });
      sentry.log(error);
    } finally {
      setForceReggieLoaderComplete(true);
    }
  };

  const handleRecipeNameKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      includeReggie && onGenerateRecipe();
    }
  };

  useEffect(() => {
    if (forceReggieLoaderComplete) {
      const timer = setTimeout(() => {
        setLoadingRecipeGeneration(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [forceReggieLoaderComplete]);

  const onSubmit = async (data: T) => {
    setFormLoading(true);
    try {
      await handleSubmit(data);
    } catch (error) {
      null;
    } finally {
      setFormLoading(false);
    }
  };

  const onSecondaryAction = () => {
    const data = form.getValues();
    if (secondaryButtonAction) {
      secondaryButtonAction(data);
    }
  };

  function fieldExists(field: string) {
    return fields.includes(field);
  }

  return (
    <div className={cn('w-full', className)}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="mb-4 space-y-6">
          {fields.includes('recipeName') && (
            <fieldset disabled={formLoading} className="relative space-y-2 ">
              <Heading variant="section">Enter your recipe name</Heading>
              <div className="grid grid-cols-12 items-start gap-2">
                <InputFormField
                  name="recipeName"
                  className="col-span-8"
                  placeholder="Tomato Soup"
                  includeErrorMessage
                  disabled={loadingRecipeGeneration}
                  onKeyDown={handleRecipeNameKeyDown}
                />
                {includeReggie && (
                  <Button
                    type="button"
                    size="lg"
                    variant="secondary"
                    className="col-span-4"
                    loading={loadingRecipeGeneration}
                    disabled={loadingRecipeGeneration}
                    icon={<Icons.wand2 className="size-4" />}
                    onClick={onGenerateRecipe}
                  >
                    Generate recipe
                  </Button>
                )}
              </div>
              {includeReggie && <GenerateRecipeCard />}
            </fieldset>
          )}
          {loadingRecipeGeneration ? (
            <div className="flex w-full items-center justify-center pt-6">
              <ReggieLoader forceComplete={forceReggieLoaderComplete} />
            </div>
          ) : (
            <div>
              <Heading variant="section" className="mb-3">
                Enter your recipe
              </Heading>
              <div className="space-y-6">
                {!fieldExists('servings') &&
                !fieldExists('netWeight') ? null : (
                  <fieldset className="" disabled={formLoading}>
                    <div className="grid grid-cols-12 items-end gap-2">
                      {fieldExists('servings') && (
                        <InputFormField
                          name="servings"
                          type="number"
                          label="Servings"
                          className="col-span-3"
                          disabled={loadingRecipeGeneration}
                        />
                      )}
                      {(fieldExists('netWeight') && (
                        <InputFormField
                          name="netWeight"
                          type="number"
                          className="col-span-4"
                          inputClassName="text-right"
                          label="Net food weight"
                          adornmentRight="kg"
                          step="0.001" // 1g
                          optional
                          disabled={loadingRecipeGeneration}
                        />
                      )) || <div className="w-full"></div>}
                    </div>
                  </fieldset>
                )}
                {fieldExists('ingredients') && (
                  <fieldset disabled={formLoading}>
                    <RepeatableFormRows
                      name="ingredients"
                      component={RecipeIngredientRow}
                      includeErrorMessage
                      newRowObject={{
                        entityUuid: '',
                        quantity: 1,
                        unitUuid: '',
                        entityType: 'base_food',
                      }}
                    />
                  </fieldset>
                )}
                {fieldExists('packaging') && (
                  <fieldset disabled={formLoading} className="space-y-3">
                    <div className="flex items-baseline gap-1">
                      <Heading variant="section" className="flex">
                        Add packaging
                      </Heading>
                      <p className="text-sm text-muted-foreground">
                        (optional)
                      </p>
                    </div>
                    <RepeatableFormRows
                      name="packaging"
                      component={RecipePackagingRow}
                      newRowObject={{
                        packagingType: '',
                        size: '',
                        name: '',
                      }}
                    />
                  </fieldset>
                )}
                {fieldExists('collections') && (
                  <fieldset disabled={formLoading} className="space-y-3">
                    <div className="flex items-baseline gap-1">
                      <Heading variant="section" className="flex">
                        Add to collections
                      </Heading>
                      <p className="text-sm text-muted-foreground">
                        (optional)
                      </p>
                    </div>
                    <div className="grid grid-cols-12 items-end gap-2">
                      <CollectionPicker
                        name="collections"
                        className="col-span-7"
                      />
                    </div>
                  </fieldset>
                )}
              </div>
              <div className="mt-12 grid grid-cols-12 justify-between">
                <div className="col-span-4">
                  {secondaryButtonAction && (
                    <Button
                      type="button"
                      size="lg"
                      variant="secondary"
                      onClick={onSecondaryAction}
                      disabled={formLoading}
                    >
                      {secondaryButtonText}
                    </Button>
                  )}
                </div>
                <div className="col-span-4 col-start-9">
                  <Button
                    type="submit"
                    size="lg"
                    className="w-full"
                    loading={formLoading}
                  >
                    {submitText}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </form>
      </Form>
    </div>
  );
}
