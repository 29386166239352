import { zodResolver } from '@hookform/resolvers/zod';
import TextLink from '@shared/components/content/text-link';
import InputFormField from '@shared/components/form/input-form-field';
import Logo from '@shared/components/logo/logo';
import { Button } from '@shared/components/ui/button';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@shared/components/ui/card';
import { Form } from '@shared/components/ui/form';
import { useToast } from '@shared/components/ui/use-toast';
import { cn } from '@shared/lib/utils';
import sentry from '@shared/services/sentry';
import { ClassValue } from 'clsx';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import useAuth from '../use-auth';
const WEBSITE_URL = import.meta.env.VITE_WEBSITE_URL;

const user = z.object({
  email: z.string().email().trim(),
  password: z.string().min(8, {
    message: 'Password must be at least 8 characters',
  }),
});

const loginFormSchema = user.required();

interface LoginFormProps {
  className?: ClassValue;
}

export default function LoginForm({ className }: LoginFormProps) {
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const { handleLogin } = useAuth();
  const navigate = useNavigate();
  const { toast } = useToast();

  const form = useForm<z.infer<typeof loginFormSchema>>({
    resolver: zodResolver(loginFormSchema),
  });

  const onSubmit = async (data: z.infer<typeof loginFormSchema>) => {
    setIsLoggingIn(true);
    try {
      await handleLogin(data.email, data.password);
      navigate('/');
    } catch (error) {
      sentry.log(error);
      toast({
        title: 'An error occurred whilst trying to log in',
        description:
          'Please check you have the correct email and password. Contact us if the error persists',
        variant: 'destructive',
      });
    } finally {
      setIsLoggingIn(false);
    }
  };

  return (
    <Form {...form}>
      <Card
        className={cn(
          'min-w-[300px] max-w-md sm:px-6 py-6 p-4 shadow-md w-full',
          className
        )}
      >
        <CardHeader className="flex items-center">
          <Logo className="size-14" />
          <CardTitle className="text-2xl">Sign in</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="grid gap-4">
              <InputFormField
                name="email"
                label="Email"
                placeholder="food@mycompany.com"
                disabled={isLoggingIn}
                includeErrorMessage
              />
              <InputFormField
                name="password"
                type="password"
                label="Password"
                disabled={isLoggingIn}
                includeErrorMessage
              />
              <Button
                type="submit"
                size="lg"
                className="w-full"
                loading={isLoggingIn}
              >
                Sign in
              </Button>
            </div>
          </form>
          <TextLink
            to="/password/forgot"
            className="ml-auto mt-2 inline-block text-sm"
          >
            Forgot your password?
          </TextLink>
          <p className="mt-8 text-center text-sm text-muted-foreground ">
            Don&apos;t have an account?{' '}
            <TextLink to={`/sign-up`}>Sign up</TextLink>
          </p>
        </CardContent>
      </Card>
    </Form>
  );
}
