import {
  PPTTypes,
  emptyPptRow,
  formattedTypes,
} from '@app/pages/product/product-summary-page';
import { useUpdateProduct } from '@shared/api';
import { ProductWithRelations } from '@shared/api/types';
import Heading from '@shared/components/content/heading';
import { Icons } from '@shared/components/content/icons';
import { HeaderCell } from '@shared/components/data-table/cells/header-cell';
import { NumberCell } from '@shared/components/data-table/cells/number-cell';
import { DataTable } from '@shared/components/data-table/data-table';
import { useDataTable } from '@shared/components/data-table/hooks/use-data-table';
import { RepeatableFormRows } from '@shared/components/form/repeatable-form-rows';
import RecipePackagingRow from '@shared/components/product-form/components/recipe-packaging-row';
import { SimpleTooltip } from '@shared/components/simple-tooltip';
import { Button } from '@shared/components/ui/button';
import { capitalizeFirstLetter } from '@shared/lib/utils';
import sentry from '@shared/services/sentry';
import { ColumnDef } from '@tanstack/react-table';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ProductProcessingRow from './product-processing-row';
import ProductTransportRow from './product-transport-row';

function useColumns<Data>(type: string): ColumnDef<Data>[] {
  return [
    {
      accessorKey: 'uuid',
    },
    {
      accessorKey: 'name',
      header: ({ column }) => (
        <HeaderCell
          column={column}
          name={(type === 'packaging' && 'Item') || 'Step'}
        />
      ),
      cell: ({ getValue, row }) => {
        const assessable = row.original.isAssessable;

        return (
          <span className="flex items-center space-x-2">
            <p>{getValue<string>()}</p>
            {!assessable && (
              <SimpleTooltip
                trigger={<Icons.clock className="size-4" />}
                content={`This ${type === 'packaging' ? 'item' : 'step'} cannot be instantly assessed`}
              />
            )}
          </span>
        );
      },
      enableSorting: false,
    },
    {
      accessorKey: 'size',
      header: ({ column }) => <HeaderCell column={column} name="Size" />,
      cell: ({ getValue }) => <p>{getValue<string>()}</p>,
      enableSorting: false,
    },
    {
      accessorKey: 'proportionOfProduct',
      header: ({ column }) => (
        <HeaderCell
          column={column}
          name="% of product"
          className="text-right"
        />
      ),
      cell: ({ getValue }) => <NumberCell number={getValue<number>() * 100} />,
      enableSorting: false,
    },
  ];
}

interface PPTFormSectionProps<T> {
  type: 'processing' | 'packaging' | 'transport';
  productUuid: ProductWithRelations['uuid'];
  data?: T[];
}

export default function PPTFormSection<T>({
  productUuid,
  type,
  data,
}: PPTFormSectionProps<T>) {
  const [isEditing, setIsEditing] = useState(false);

  const { mutateAsync: updateProduct, isLoading: updatingProduct } =
    useUpdateProduct();
  const { trigger, getValues, resetField } = useFormContext();

  const handleUpdate = async () => {
    try {
      const validFields = await trigger(type);
      if (!validFields) return;
      const values = getValues(type);

      const updatedProduct = await updateProduct({
        productUuid,
        data: {
          [`product${capitalizeFirstLetter(type)}Types`]: values.map(
            (value: any) => {
              return {
                [`${type}Type`]: value[`${type}Type`],
                proportionOfProduct: value.proportionOfProduct / 100,
              };
            }
          ),
        },
      });

      const productType: PPTTypes[] =
        (type === 'packaging'
          ? updatedProduct.productPackagingTypes
          : type === 'processing'
            ? updatedProduct.productProcessingTypes
            : updatedProduct.productTransportTypes) || [];

      resetField(type, {
        defaultValue:
          productType!.length > 0 ? formattedTypes(type, productType) : [],
      });

      setIsEditing(false);
    } catch (error) {
      sentry.log(error);
    }
  };

  const columns = useColumns(type);

  const { table } = useDataTable({
    columns,
    data: data!,
    columnVisibilityState: {
      uuid: false,
      size: type === 'packaging',
    },
  });

  return (
    <div className="w-full max-w-form">
      <div className="flex h-8 items-center justify-between">
        <Heading variant="section">{capitalizeFirstLetter(type)}</Heading>
        {!isEditing && (
          <Button
            variant="link"
            onClick={() => {
              setIsEditing(true);
            }}
          >
            {data?.length === 0 ? `Add ${type}` : `Edit ${type}`}
          </Button>
        )}
      </div>
      {data!.length === 0 && !isEditing && (
        <span className="text-sm">
          No {type} {type === 'packaging' ? 'items' : 'steps'} have been added
        </span>
      )}
      {data!.length > 0 && !isEditing && (
        <DataTable
          table={table}
          className="bg-none"
          cellClassName="py-2 pl-0"
          headerConfig={{
            cell: 'pl-0 ',
          }}
        />
      )}

      {isEditing && (
        <div className="mt-4">
          {type === 'packaging' && (
            <RepeatableFormRows
              name={type}
              component={RecipePackagingRow}
              componentVariant="product"
              newRowObject={emptyPptRow('packaging', true)}
            />
          )}
          {type === 'transport' && (
            <RepeatableFormRows
              name={type}
              component={ProductTransportRow}
              newRowObject={emptyPptRow('transport')}
            />
          )}
          {type === 'processing' && (
            <RepeatableFormRows
              name={type}
              component={ProductProcessingRow}
              newRowObject={emptyPptRow('processing')}
            />
          )}
          <div className="flex justify-end space-x-2">
            <Button
              variant="secondary"
              type="button"
              onClick={async () => {
                setIsEditing(false);
                resetField(type);
              }}
            >
              Cancel
            </Button>
            <Button
              type="button"
              onClick={handleUpdate}
              loading={updatingProduct}
            >
              Update {type}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
