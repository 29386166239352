import Text from '@shared/components/content/text';
import { Badge } from '@shared/components/ui/badge';
import { Card, CardContent, CardTitle } from '@shared/components/ui/card';
import { cn } from '@shared/lib/utils';
import { HTMLAttributes } from 'react';

interface OnboardingGuideCardProps extends HTMLAttributes<HTMLDivElement> {
  step?: string;
  image: string;
  description?: string;
  addOn?: boolean;
  comingSoon?: boolean;
}

export default function OnboardingGuideCards({
  step,
  image,
  description,
  addOn = false,
  className,
  comingSoon = false,
}: OnboardingGuideCardProps) {
  return (
    <Card
      className={cn(
        'space-y-3 rounded-lg border border-teal-300 pt-4',
        className
      )}
    >
      <CardTitle>
        <Text className="font-bold">{step}</Text>
      </CardTitle>
      <CardContent>
        <div className="relative mx-auto mt-6 flex h-52 w-full flex-none resize-none justify-center ">
          {comingSoon ? (
            <div className="flex size-full items-center justify-center rounded-lg bg-gray-50">
              Coming soon
            </div>
          ) : (
            <img src={image} alt="" className="object-cover" />
          )}
          {addOn && (
            <Badge
              variant="secondary"
              className="absolute -bottom-3 -right-0 z-10"
            >
              Add-on
            </Badge>
          )}
        </div>
      </CardContent>
      <CardContent className="mt-3">
        <Text className="mt-4">{description}</Text>
      </CardContent>
    </Card>
  );
}
