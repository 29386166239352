import useAuth from '@app/auth/use-auth';
import { ProductCategoryEnum } from '@shared/api/types';
import { BannerMethodologyUpdate } from '@shared/components/banner/banner-methodology-update';
import { Outlet } from 'react-router-dom';
import { Sidebar } from './nav/sidebar';
import { useSidebarWidth } from './nav/use-sidebar-width';

const Layout = () => {
  const { user } = useAuth();
  const userType: ProductCategoryEnum = user.productCategory;
  const { sidebarWidth, sidebarRef } = useSidebarWidth();

  return (
    <div className="flex h-full bg-gray-25">
      <div ref={sidebarRef} className="fixed h-full shrink-0 overflow-y-auto">
        <Sidebar variant={userType} />
      </div>
      <div
        className="min-h-screen grow overflow-y-auto"
        style={{
          marginLeft: `${sidebarWidth}px`,
        }}
      >
        <BannerMethodologyUpdate />
        <div className="container h-full min-h-screen px-4 py-1 md:px-8">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
