import { roundNumberToNDecimalPlaces } from '@app/utils/helpers';
import RatingCloud, {
  RatingCloudProps,
} from '@shared/components/ratings/rating-cloud';
import { cn } from '@shared/lib/utils';
import { ArrowDown, ArrowUp } from 'lucide-react';
import { getPercentageDifference } from '../comparison-page';

interface ComparisonRowProps {
  name: string;
  fieldKey:
    | 'totalIngredientWeight'
    | 'totalEmissionsPerKg'
    | 'totalEmissions'
    | 'totalEmissionsPerServing'
    | 'emissionsCategory';
  values: string[] | number[];
}

// Handle this function. Could be a utility function
const getUnit = (fieldKey: string) => {
  switch (fieldKey) {
    case 'totalIngredientWeight':
      return <span className="text-xs font-semibold">kg</span>;
    case 'totalEmissionsPerKg':
      return (
        <span className="text-xs font-semibold">
          kgCO<sub>2</sub>e/kg<sub>food</sub>
        </span>
      );
    case 'totalEmissions':
      return (
        <span className="text-xs font-semibold">
          kgCO<sub>2</sub>e
        </span>
      );
    case 'totalEmissionsPerServing':
      return (
        <span className="text-xs font-semibold">
          kgCO<sub>2</sub>e
        </span>
      );
    default:
      return null;
  }
};

const percentageDifferenceComponent = (value1: number, value2: number) => {
  const diff = getPercentageDifference(value1, value2);

  if (diff === 0) return null;

  return (
    <div
      className={cn('flex items-center', {
        'text-secondary': diff < 0,
        'text-destructive': diff > 0,
      })}
    >
      {diff > 0 ? <ArrowUp size={16} /> : <ArrowDown size={16} />}
      <small>{Math.abs(diff)}%</small>
    </div>
  );
};

export function ComparisonRow({ name, fieldKey, values }: ComparisonRowProps) {
  return (
    <div
      className={cn('m mt-2 grid p-4', {
        'grid-cols-3': values.length === 2, // default 'grid-cols-1
        'grid-cols-4': values.length === 3,
        'grid-cols-5': values.length === 4,
        'grid-cols-6': values.length === 5,
      })}
    >
      <div className="text-sm">{name}</div>
      {values?.map((value, i) => {
        return (
          <div key={i} className="flex">
            <span className="flex items-baseline space-x-1">
              <p className="font-bold ">
                {fieldKey === 'emissionsCategory' && (
                  <RatingCloud rating={value as RatingCloudProps['rating']} />
                )}
                {typeof value === 'number' &&
                  roundNumberToNDecimalPlaces(value, 2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </p>{' '}
              {getUnit(fieldKey)}
            </span>
            {i == 0 && (
              <div className="ml-4 flex">
                {typeof value === 'number' && (
                  <p>
                    {percentageDifferenceComponent(
                      values[0] as number,
                      values[1] as number
                    )}
                  </p>
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
