import { Icons } from '@shared/components/content/icons';
import { Badge } from '@shared/components/ui/badge';
import {
  Command,
  CommandGroup,
  CommandItem,
} from '@shared/components/ui/command';
import { useSearch } from '@shared/lib/hooks/useSearch';
import { SelectItem } from '@shared/lib/types';
import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';
import { Command as CommandPrimitive } from 'cmdk';
import { X } from 'lucide-react';
import React from 'react';

interface MultiSelectProps {
  className?: ClassValue;
  placeholder?: string;
  items: SelectItem[];
  onCreate: (value: string) => Promise<SelectItem>;
  onSelectionChange: (selected: SelectItem[]) => void;
}

export function MultiSelect({
  placeholder = 'Search...',
  items,
  onCreate,
  onSelectionChange,
}: MultiSelectProps) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState<SelectItem[]>(
    items.filter((item) => item.selected)
  );
  const [inputValue, setInputValue] = React.useState('');

  React.useEffect(() => {
    onSelectionChange(selected);
  }, [selected, onSelectionChange]);

  const filteredItems = useSearch({
    items: items,
    searchQuery: inputValue,
    searchKeys: ['label'],
  });

  const handleSelect = (item: SelectItem) => {
    setSelected((prev) => [...prev, item]);
    setInputValue('');
  };

  const handleUnselect = (item: SelectItem) => {
    setSelected((prev) => prev.filter((s) => s.value !== item.value));
  };

  const handleCreate = async (value: string) => {
    const newItem = await onCreate(value);
    setSelected((prev) => [...prev, newItem]);
    setInputValue('');
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const input = inputRef.current;
    if (input) {
      if (e.key === 'Delete' || e.key === 'Backspace') {
        if (input.value === '') {
          setSelected((prev) => {
            const newSelected = [...prev];
            newSelected.pop();
            return newSelected;
          });
        }
      }
      if (e.key === 'Escape') {
        input.blur();
      }
    }
  };

  const emptyState =
    selected.length === filteredItems.length && inputValue.length === 0;

  return (
    <Command
      onKeyDown={handleKeyDown}
      className="h-full overflow-visible"
      shouldFilter={false}
    >
      <button
        type="button"
        className={cn(
          'group flex min-h-10 items-center rounded-md border border-input px-2 py-1.5 text-sm'
        )}
        onClick={() => {
          inputRef.current?.focus();
          setOpen(true);
        }}
      >
        <div className="flex flex-1 flex-wrap gap-1">
          {selected.map((item) => (
            <MultiSelectBadge
              key={item.value}
              item={item}
              handleUnselect={handleUnselect}
            />
          ))}
          <CommandPrimitive.Input
            ref={inputRef}
            value={inputValue}
            onValueChange={setInputValue}
            onBlur={() => setOpen(false)}
            placeholder={selected.length === 0 ? placeholder : ''}
            className="ml-1 flex flex-1 cursor-pointer outline-none placeholder:text-gray-400 disabled:pointer-events-none disabled:bg-gray-00 disabled:opacity-50"
          />
        </div>
      </button>
      <div className="relative">
        {open && (
          <div className="absolute top-0 z-10 mt-1 w-full rounded-md border bg-popover text-popover-foreground shadow-md outline-none animate-in">
            <CommandGroup className="max-h-[200px] overflow-scroll">
              {inputValue.length > 0 &&
                !items.some((item) => item.label === inputValue) && (
                  <CommandItem
                    value={inputValue}
                    onSelect={() => handleCreate(inputValue)}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <Icons.plusCircle className="mr-2 size-4" />
                    Create &quot;{inputValue}&quot;
                  </CommandItem>
                )}
              {filteredItems.length > 0 &&
                filteredItems.map((item) => {
                  if (selected.some((s) => s.value === item.value)) {
                    return null;
                  }
                  return (
                    <CommandItem
                      key={item.value}
                      onMouseDown={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onSelect={() => handleSelect(item)}
                      className="cursor-pointer"
                    >
                      {item.label}
                    </CommandItem>
                  );
                })}
              {emptyState && (
                <p className="p-1 text-sm text-muted-foreground">
                  No items. Start typing to create a new one!
                </p>
              )}
            </CommandGroup>
          </div>
        )}
      </div>
    </Command>
  );
}

interface MultiSelectBadgeProps {
  item: SelectItem;
  handleUnselect: (item: SelectItem) => void;
}

function MultiSelectBadge({ item, handleUnselect }: MultiSelectBadgeProps) {
  return (
    <Badge className="rounded-lg">
      {item.label}
      <button
        className="ml-1 rounded-full outline-none ring-offset-background focus:ring-2 focus:ring-ring focus:ring-offset-2"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleUnselect(item);
          }
        }}
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onClick={() => handleUnselect(item)}
      >
        <X className="size-3 text-muted-foreground hover:text-foreground" />
      </button>
    </Badge>
  );
}
