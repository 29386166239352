import PageHeaderToolbar from '@app/components/layout/page/components/page-header-toolbar';
import { SimpleDialog } from '@app/hooks/use-simple-dialog';
import { useDeleteCollection } from '@shared/api';
import { CollectionDetail } from '@shared/api/types';
import Text from '@shared/components/content/text';
import { Button } from '@shared/components/ui/button';
import { useToast } from '@shared/components/ui/use-toast';
import sentry from '@shared/services/sentry';
import { useNavigate } from 'react-router-dom';

interface CollectionDetailPageToolbarProps {
  collection: CollectionDetail;
}

export function CollectionDetailPageToolbar({
  collection,
}: CollectionDetailPageToolbarProps) {
  const { toast } = useToast();
  const navigate = useNavigate();

  const { mutateAsync: deleteCollection, isLoading: isDeleting } =
    useDeleteCollection();

  const handleOnDelete = async () => {
    try {
      await deleteCollection({
        collectionUuid: collection.uuid,
      });
      toast({
        description: 'Collection deleted',
        variant: 'success',
      });
      navigate('/collections');
    } catch (error) {
      sentry.log(error);
      toast({
        description: 'Error deleting collection',
        variant: 'destructive',
      });
    }
  };

  return (
    <PageHeaderToolbar>
      <Button
        type="button"
        variant="ghost"
        className="justify-start"
        onClick={() => navigate(`/collections/${collection.uuid}/edit`)}
      >
        Edit
      </Button>
      <SimpleDialog
        contentClassName="w-min"
        trigger={
          <Button variant="ghost" className="justify-start text-destructive">
            Delete
          </Button>
        }
        header="Delete collection"
        description={
          <div>
            <Text>
              Are you sure you want to delete this collection? This action
              cannot be undone.
            </Text>
            <Text className="mt-4 font-bold">
              Deleting a collection will not delete the products inside.
            </Text>
          </div>
        }
        footer={
          <Button
            type="submit"
            variant="destructive"
            onClick={async () => handleOnDelete()}
            loading={isDeleting}
          >
            Delete
          </Button>
        }
      />
    </PageHeaderToolbar>
  );
}
