import Text from '@shared/components/content/text';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@shared/components/ui/card';
import { Separator } from '@shared/components/ui/separator';
import { cn } from '@shared/lib/utils';

import ABWorldFoodsLogo from '@shared/assets/client-assets/clientLogo-ab-world-foods.png';
import CollectivFood from '@shared/assets/client-assets/clientLogo-collectiv-food.png';
import FSCLogo from '@shared/assets/client-assets/clientLogo-fsc.png';
import JETLogo from '@shared/assets/client-assets/clientLogo-just-eat-takeaway.png';
import TheGoodEatingCoLogo from '@shared/assets/client-assets/clientLogo-the-good-eating-co.png';
import TucoLogo from '@shared/assets/client-assets/clientLogo-tuco.png';
import MeatlessFarmLogo from '@shared/assets/client-assets/logo-meatless-farm-colour.png';
import SelfridgesLogo from '@shared/assets/client-assets/logo-selfridges-colour.png';
import UMassDiningLogo from '@shared/assets/client-assets/UMass-Dining_vert-cmyk.png';
import { Icons } from '@shared/components/content/icons';

interface SignupInfoProps extends React.HTMLAttributes<HTMLDivElement> {}

export default function SignupInfo({ className }: SignupInfoProps) {
  const clientLogos = [
    {
      src: JETLogo,
      alt: 'JET Logo',
    },
    {
      src: SelfridgesLogo,
      alt: 'Selfridges Logo',
    },
    {
      src: FSCLogo,
      alt: 'FSC Logo',
      className: 'w-14',
    },
    {
      src: ABWorldFoodsLogo,
      alt: 'AB World Foods Logo',
    },
    {
      src: TheGoodEatingCoLogo,
      alt: 'The Good Eating Co Logo',
    },
    {
      src: TucoLogo,
      alt: 'Tuco Logo',
      className: 'w-16',
    },
    {
      src: CollectivFood,
      alt: 'Collectiv Food Logo',
    },
    {
      src: MeatlessFarmLogo,
      alt: 'Meatless Farm Logo',
    },
    {
      src: UMassDiningLogo,
      alt: 'UMass Dining Logo',
    },
  ];

  return (
    <Card className={cn('max-w-md p-4 py-6 md:max-w-lg', className)}>
      <CardHeader>
        <CardTitle>Instantly measure the emissions of your recipes</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <Text>
          Sign up for a free account and calculate emissions for up to 3
          recipes. Use our AI recipe generator for instant results. Food
          companies only. No credit card or commitment required.
        </Text>
        <Text>Our free account includes:</Text>
        <div className="space-y-2">
          <div className="flex items-center space-x-3">
            <Icons.check className="text-secondary-600" />
            <Text>Get carbon ratings for 3 recipes, absolutely free.</Text>
          </div>
          <div className="flex items-center space-x-3">
            <Icons.check className="text-secondary-600" />
            <Text>Use our AI recipe generator to get instant results.</Text>
          </div>
          <div className="flex items-center space-x-3">
            <Icons.check className="text-secondary-600" />
            <Text>Use the My Emissions carbon label (foodservice only).</Text>
          </div>
        </div>
      </CardContent>
      <Separator />
      <CardFooter className="mt-4">
        <div className="w-full space-y-4">
          <Text variant="subtle">
            Join forward-thinking companies already working with My Emissions:
          </Text>
          <div className="mt-6 grid grid-cols-2 items-center justify-items-center gap-10 lg:grid-cols-3">
            {clientLogos.map((clientLogo, index) => (
              <img
                key={index}
                src={clientLogo.src}
                alt={clientLogo.alt}
                className={clientLogo.className!}
              />
            ))}
          </div>
        </div>
      </CardFooter>
    </Card>
  );
}
