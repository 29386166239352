import {
  OrganizationAimsEnum,
  OrganizationCategoryEnum,
  OrganizationNumProductsRangeEnum,
} from '@shared/api/types';

export const organizationNumProductsRangeOptions = [
  {
    label: '1-10',
    value: OrganizationNumProductsRangeEnum['1-10'],
  },
  {
    label: '11-50',
    value: OrganizationNumProductsRangeEnum['11-50'],
  },
  {
    label: '51-250',
    value: OrganizationNumProductsRangeEnum['51-250'],
  },
  {
    label: '251-1000',
    value: OrganizationNumProductsRangeEnum['251-1000'],
  },
  {
    label: '1000+',
    value: OrganizationNumProductsRangeEnum['1000+'],
  },
];

export const organizationCategoryOptions = [
  {
    label: 'Restaurant',
    value: OrganizationCategoryEnum['restaurant'],
  },
  {
    label: 'Caterer',
    value: OrganizationCategoryEnum['caterer'],
  },
  {
    label: 'Wholesaler/Distributor',
    value: OrganizationCategoryEnum['wholesaler-distributor'],
  },
  {
    label: 'Food & Beverage Producer',
    value: OrganizationCategoryEnum['food-beverage-producer'],
  },
  {
    label: 'Retailer',
    value: OrganizationCategoryEnum['retailer'],
  },
  {
    label: 'Consultancy',
    value: OrganizationCategoryEnum['consultancy'],
  },
  {
    label: 'Other',
    value: OrganizationCategoryEnum['other'],
  },
];

export const organizationAimsOptions = [
  {
    label: "Reduce our company's emissions",
    value: OrganizationAimsEnum['reduce-emissions'],
  },
  {
    label: 'Display carbon labels',
    value: OrganizationAimsEnum['carbon-labelling'],
  },
  {
    label: 'Accurately report our scope 3 emissions',
    value: OrganizationAimsEnum['report-emissions'],
  },
  {
    label: 'Set and monitor our progress towards Net zero',
    value: OrganizationAimsEnum['monitor-net-zero'],
  },
  {
    label: 'Complete a full corporate carbon assessment',
    value: OrganizationAimsEnum['corporate-assessment'],
  },
];
