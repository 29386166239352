import useHandleCreateNewProductAction from '@app/hooks/use-create-new-product-action';
import { useGetProductResult } from '@shared/api';
import Heading from '@shared/components/content/heading';
import Loader from '@shared/components/loader';
import RatingCard from '@shared/components/ratings/rating-card';
import RatingExplainer from '@shared/components/ratings/rating-explainer';
import { Button } from '@shared/components/ui/button';
import sentry from '@shared/services/sentry';
import { FC, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Page from '../layout/page/page';

const ProductResult: FC = () => {
  const navigate = useNavigate();
  const { product_id: productUuid } = useParams();
  const { data: productResult, isLoading: productLoading } =
    useGetProductResult(productUuid!);
  const { handleCreateNewProductAction, UpsellDialogComponent } =
    useHandleCreateNewProductAction();

  const shouldRedirect =
    !productLoading && (!productResult || productResult.status !== 'complete');

  useEffect(() => {
    if (shouldRedirect) {
      sentry.log(
        new Error(`Failed loading product result for product ${productUuid}`)
      );
      navigate('/products/');
    }
  }, [shouldRedirect, navigate, productUuid]);

  if (productLoading || shouldRedirect) {
    return <Loader />;
  }

  return (
    <Page name="Product Result" title="Product Result">
      <div className="mx-auto mt-16 flex w-full max-w-content-center flex-col items-center">
        <Heading className="mb-10">{productResult.name}</Heading>
        <RatingCard
          rating={productResult.emissionsCategory}
          carbonIntensity={productResult.totalEmissionsPerKg}
          className="mb-10"
        />
        <RatingExplainer
          rating={productResult.emissionsCategory}
          carbonIntensity={productResult.totalEmissionsPerKg}
          className="mb-10"
        />
        <div className="flex w-full justify-between">
          <Button
            size="lg"
            variant="secondary"
            className="mb-6"
            onClick={handleCreateNewProductAction}
          >
            Assess another product
          </Button>
          <Link to={`/products/${productUuid}`}>
            <Button size="lg" className="mb-6">
              View full assessment
            </Button>
          </Link>
        </div>
        {UpsellDialogComponent}
      </div>
    </Page>
  );
};

export default ProductResult;
