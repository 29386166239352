import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { EmissionsCategoryEnum } from '@shared/api/types';
import RatingCloud from '@shared/components/ratings/rating-cloud';
import React from 'react';
import '../styles/Styles';
import { getFlagEmoji, roundNumberToNDecimalPlaces } from '../utils/helpers';

interface FoodEmissionsBoxProps {
  name: string;
  totalEmissionsPerKg: number | null;
  emissionsCategory: EmissionsCategoryEnum | null;
  uuid: string;
  countryOfOrigin: string | null;
}

const FoodEmissionsBox: React.FC<FoodEmissionsBoxProps> = ({
  name,
  totalEmissionsPerKg,
  emissionsCategory,
  countryOfOrigin,
}) => {
  return (
    <Box
      sx={{
        backgroundColor: '#FFFFFF',
        padding: '0.5rem',
        borderRadius: '8px',
        marginTop: '10px',
        '&:hover': {
          boxShadow: 3,
        },
      }}
    >
      <Grid container>
        <Grid
          item
          xs={1}
          sx={{
            marginRight: '10px',
          }}
        >
          <RatingCloud rating={emissionsCategory} />
        </Grid>
        <Grid
          item
          xs={6}
          sm={7}
          sx={{
            flexGrow: 1,
          }}
          style={{ paddingTop: '5px', paddingLeft: '16px' }}
        >
          <Typography noWrap variant="body2">
            {name}
            {countryOfOrigin ? (
              <> (origin: {getFlagEmoji(countryOfOrigin)})</>
            ) : (
              ''
            )}
          </Typography>
        </Grid>

        <Grid item xs={3} ml="auto">
          {totalEmissionsPerKg !== null && (
            <b>
              <span
                style={{
                  float: 'right',
                  marginRight: '20px',
                  marginTop: '5px',
                  // set to no wrap
                  whiteSpace: 'nowrap',
                }}
              >
                {roundNumberToNDecimalPlaces(
                  totalEmissionsPerKg,
                  2,
                  true
                ).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                <span style={{ fontSize: 12, marginLeft: '3px' }}>
                  {' '}
                  kgCO<sub>2</sub>e/kg
                </span>
              </span>
            </b>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default FoodEmissionsBox;
