import { Box, Button, Chip, Stack, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { NUMBER_TO_MONTH_MAPPING } from '../../utils/constants';

import React, { memo, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getProductsCsvExport } from '../../api/api';
import { roundNumberToNDecimalPlaces } from '../../utils/helpers';

import AddIcon from '@mui/icons-material/Add';
import { EmissionsCategoryEnum, ProductList } from '@shared/api/types';
import RatingCloud, {
  RatingCloudProps,
} from '../../../shared/components/ratings/rating-cloud';

interface ProductsFilterableTableProps {
  products: ProductList[];
  selectableRows?: boolean;
  selectedProducts?: string[]; // list of uuids
  title?: string;
  onProductsSelect?: (selectedProducts: any) => void;
  enableCsvExport?: boolean;
  disableCreateProductFunctionality?: boolean;
  collectionUuid?: string;
}

declare module '@mui/material/styles' {
  interface Components {
    [key: string]: any;
  }
}

export type GeneralFilterType =
  | 'dropdown'
  | 'checkbox'
  | 'multiselect'
  | 'textField'; // needed for specifying filterType at column level (see https://github.com/gregnb/mui-datatables/issues/652)
export type DisplayFilterType = 'excluded' | true | false | undefined;

function getUserFriendlyDate(dateAsStr: string): string {
  // dateAsStr comes in as YYYY-MM-DDTHH:mm:ss.sssZ (e.g. 2022-09-20T16:06:48.558053Z)
  const year = dateAsStr.slice(0, 4);
  const month = NUMBER_TO_MONTH_MAPPING[dateAsStr.slice(5, 7)];
  const day = dateAsStr.slice(8, 10);
  return day + ' ' + month + ' ' + year;
}

export function getUserFriendlyDate2(dateAsStr: string): string {
  // dateAsStr comes in as YYYY-MM-DDTHH:mm:ss.sssZ (e.g. 2022-09-20T16:06:48.558053Z)
  const year = dateAsStr.slice(0, 4);
  const month = NUMBER_TO_MONTH_MAPPING[dateAsStr.slice(5, 7)];
  const day = dateAsStr.slice(8, 10);
  return day + ' ' + month + ' ' + year;
}

const computeProductsExportFileName = (): string => {
  const today = new Date();
  const date =
    today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(); // "2022-10-03"
  return `products_${date}`; // "products_2022-10-03"
};

const columns = [
  // INVISIBLE COLUMNS USED FOR NAVIGATION
  {
    name: 'uuid',
    label: 'uuid',
    options: {
      filter: false,
      display: 'excluded' as DisplayFilterType,
    },
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      filter: false,
      display: 'excluded' as DisplayFilterType,
    },
  },
  {
    name: 'expiryDate',
    label: 'Expiry Date',
    options: {
      filter: false,
      display: 'excluded' as DisplayFilterType,
    },
  },
  {
    name: 'modifiedTime',
    label: 'Last Modified',
    options: {
      filter: false,
      display: 'excluded' as DisplayFilterType,
    },
  },

  // VISIBLE COLUMNS
  {
    name: 'name',
    label: 'Product',
    options: {
      filter: false,
      sort: true,
      customHeadLabelRender: () => {
        return (
          <Typography
            variant="body2"
            sx={{
              color: 'white',
              textTransform: 'none',
            }}
          >
            <b>Product</b>
          </Typography>
        );
      },
      customBodyRender: (product: any, tableMeta: any) => {
        const uuid = tableMeta.rowData[0];
        const modifiedTime = tableMeta.rowData[3];
        return (
          <Typography
            variant="body2"
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <>{product} </>

            <small
              style={{
                color: '#7B7B7B',
                opacity: 0.7,
                fontSize: '0.65rem',
                // marginLeft: "1rem",
              }}
            >
              Modified: {getUserFriendlyDate(modifiedTime)}
            </small>
          </Typography>
        );
      },
      sortThirdClickReset: true,
    },
  },

  {
    name: 'status',
    label: 'Status',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true,

      filterType: 'multiselect' as GeneralFilterType,
      customFilterListOptions: {
        render: (v: string) => `${v.charAt(0).toUpperCase() + v.slice(1)}`,
      },
      filterOptions: {
        names: ['complete', 'in progress', 'draft', 'expired'],
        renderValue: (v: string) => `${v.charAt(0).toUpperCase() + v.slice(1)}`,
        fullWidth: true,
      },

      customHeadLabelRender: () => {
        return (
          <Typography
            variant="body2"
            sx={{
              color: 'white',
              textTransform: 'none',
            }}
          >
            <b>Status</b>
          </Typography>
        );
      },
      customBodyRender: (status: any, tableMeta: any) => {
        const uuid = tableMeta.rowData[0];
        const dataCompletionPercentage =
          tableMeta.rowData[tableMeta.rowData.length - 1];

        return (
          <React.Fragment>
            {status === 'complete' && (
              <Chip
                label={`${status.charAt(0).toUpperCase() + status.slice(1)}`}
                variant="outlined"
                size="small"
                style={{
                  backgroundColor: 'white',
                  color: status === 'complete' ? '#62AE84' : '#0288d1',
                  borderColor: status === 'complete' ? '#62AE84' : '#0288d1',
                  fontWeight: 'normal',
                }}
              />
            )}

            {status === 'in progress' && (
              <Chip
                label={`In Progress`}
                variant="outlined"
                size="small"
                style={{
                  backgroundColor: 'white',
                  color: 'rgb(255, 167, 38)',
                  borderColor: 'rgb(255, 167, 38)',
                  fontWeight: 'normal',
                }}
              />
            )}

            {status === 'draft' && (
              <Chip
                label={`Draft - ${dataCompletionPercentage}%`}
                variant="outlined"
                size="small"
                style={{
                  backgroundColor: 'white',
                  color: '#7B7B7B',
                  borderColor: '#7B7B7B',
                  fontWeight: 'normal',
                }}
              />
            )}
            {status === 'expired' && (
              <Chip
                label={`Expired`}
                variant="outlined"
                size="small"
                style={{
                  backgroundColor: 'white',
                  color: '#7B7B7B',
                  borderColor: '#7B7B7B',
                  fontWeight: 'normal',
                }}
              />
            )}
          </React.Fragment>
        );
      },
    },
  },
  {
    name: 'assessmentDate',
    // label: "Last Modified",
    options: {
      filter: false,
      customHeadLabelRender: () => {
        return (
          <Typography
            variant="body2"
            sx={{
              color: 'white',
              textTransform: 'none',
            }}
          >
            <b>Assessment Date</b>
          </Typography>
        );
      },
      customBodyRender: (date: any, tableMeta: any) => {
        const uuid = tableMeta.rowData[0];
        return date ? <p>{getUserFriendlyDate2(date)}</p> : <p></p>;
      },
      sortThirdClickReset: true,
    },
  },

  {
    name: 'totalEmissions',
    label: 'Total Emissions (gCO2e)',
    options: {
      filter: false,
      sort: true,
      sortThirdClickReset: true,

      customHeadLabelRender: () => {
        return (
          <>
            <Stack direction="column" spacing={0}>
              <Typography
                variant="body2"
                sx={{
                  color: 'white',
                  textTransform: 'none',
                }}
              >
                <b>Total Emissions</b>
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color: 'white',
                  textTransform: 'none',
                }}
              >
                (gCO<sub>2</sub>e)
              </Typography>
            </Stack>
          </>
        );
      },
      customBodyRender: (amount: any, tableMeta: any) => {
        const uuid = tableMeta.rowData[0];
        const status = tableMeta.rowData[1];

        if (status === 'complete' || status === 'expired') {
          try {
            return (
              <span style={{ width: '100% !important' }}>
                {roundNumberToNDecimalPlaces(amount * 1000, 1)}
              </span>
            );
          } catch {
            return <span></span>;
          }
        }

        return '';
      },
      sortCompare: (order: string) => {
        return (obj1: any, obj2: any) => {
          let val1 = obj1.data;
          let val2 = obj2.data;
          const obj1status = obj1.rowData[1];
          const obj2status = obj2.rowData[1];
          if (obj1status !== 'complete' && obj1status !== 'expired') {
            val1 = order === 'asc' ? 99999999 : 0;
          }
          if (obj2status !== 'complete' && obj2status !== 'expired') {
            val2 = order === 'asc' ? 99999999 : 0;
          }
          return (val1 - val2) * (order === 'asc' ? 1 : -1); // standard sorting
        };
      },
    },
  },

  {
    name: 'totalEmissionsPerKg',
    label: 'Carbon Intensity (kgCO2e/kgfood)',
    options: {
      filter: false,
      sort: true,
      sortThirdClickReset: true,

      customHeadLabelRender: () => {
        return (
          <>
            <Stack direction="column" spacing={0}>
              <Typography
                variant="body2"
                sx={{
                  color: 'white',
                  textTransform: 'none',
                }}
              >
                <span>
                  <b>Carbon Intensity</b>
                </span>
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color: 'white',
                  textTransform: 'none',
                }}
              >
                (kgCO<sub>2</sub>e/kg<sub>food</sub>)
              </Typography>
            </Stack>
          </>
        );
      },
      customBodyRender: (amount: any, tableMeta: any) => {
        const uuid = tableMeta.rowData[0];
        const status = tableMeta.rowData[1];

        if (status === 'complete' || status === 'expired') {
          try {
            return <span>{roundNumberToNDecimalPlaces(amount, 2)}</span>;
          } catch {
            return '';
          }
        }

        return '';
      },
      sortCompare: (order: string) => {
        // override default sorting so that in progress products are always shown at the bottom
        return (obj1: any, obj2: any) => {
          let val1 = obj1.data;
          let val2 = obj2.data;

          const obj1status = obj1.rowData[1];
          const obj2status = obj2.rowData[1];
          if (obj1status !== 'complete' && obj1status !== 'expired') {
            val1 = order === 'asc' ? 99999999 : 0;
          }
          if (obj2status !== 'complete' && obj2status !== 'expired') {
            val2 = order === 'asc' ? 99999999 : 0;
          }
          return (val1 - val2) * (order === 'asc' ? 1 : -1); // standard sorting
        };
      },
    },
  },

  {
    name: 'emissionsCategory',
    label: 'Rating',
    options: {
      filter: true,
      sort: false,

      filterType: 'multiselect' as GeneralFilterType,
      filterOptions: {
        names: ['A', 'B', 'C', 'D', 'E'],
        fullWidth: true,
      },
      customBodyRender: (emissionsCategory: string, tableMeta: any) => {
        const uuid = tableMeta.rowData[0];
        const status = tableMeta.rowData[1];

        if (
          status === 'complete' ||
          status === 'expired' ||
          status === 'in progress'
        ) {
          try {
            return (
              <>
                <RatingCloud
                  rating={emissionsCategory as RatingCloudProps['rating']}
                  status={status}
                />
              </>
            );
          } catch {
            if (status === 'in progress') {
              return (
                <RatingCloud
                  rating={emissionsCategory as EmissionsCategoryEnum}
                />
              );
            } else {
              return (
                <Box
                  sx={{
                    paddingLeft: 3,
                  }}
                >
                  {' '}
                  <Typography>-</Typography>
                </Box>
              );
            }
          }
        }

        return <></>;
      },
      customHeadLabelRender: () => {
        return (
          <Typography
            variant="body2"
            sx={{
              color: 'white',
              textTransform: 'none',
            }}
          >
            <b>Rating</b>
          </Typography>
        );
      },
    },
  },

  {
    name: 'dataCompletionPercentage',
    label: 'dataCompletionPercentage',
    options: {
      filter: false,
      display: 'excluded' as DisplayFilterType,
    },
  },
];

const ProductsFilterableTable: React.FC<ProductsFilterableTableProps> = ({
  products,
  selectableRows,
  selectedProducts,
  onProductsSelect,
  title,
  enableCsvExport,
  disableCreateProductFunctionality,
  collectionUuid,
}) => {
  const navigate = useNavigate();
  const [numActiveFilters, setNumActiveFilters] = useState(0);
  const [selectedRowsIndexes, setSelectedRowsIndexes] = useState<number[]>([]);

  function computeSelectedProductsFromDataIndexes(dataIndexes: any) {
    const result = [];
    for (let i = 0; i < dataIndexes.length; i++) {
      result.push(products[dataIndexes[i]].uuid);
    }
    return result;
  }

  function computeProductsDataIndexesFromProductsUuids() {
    const dataIndexes = [];
    for (let i = 0; i < products.length; i++) {
      if (selectedProducts?.includes(products[i].uuid)) {
        // if product you're iterating is one of the selected ones
        dataIndexes.push(i);
      }
    }
    return dataIndexes;
  }

  useEffect(() => {
    setSelectedRowsIndexes(computeProductsDataIndexesFromProductsUuids());
  }, []);

  function getMuiTheme() {
    // needed for some reason to make column headers visible
    return createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              // textAlign: 'center',
              backgroundColor: '#08314c',
              color: '#FFFFFF',
              '&:first-child': {
                // add border radius to top left of table
                borderTopLeftRadius: numActiveFilters === 0 ? '10px' : '0px',
              },

              '&:last-child': {
                // add border radius to top right of table
                backgroundColor: '#08314c',
                borderTopRightRadius: !onProductsSelect
                  ? numActiveFilters === 0
                    ? '10px'
                    : '0px'
                  : '0px',
              },
            },
            sortAction: {
              '& path': {
                color: '#FFFFFF', // or whatever you need
              },
            },
            sortActive: {
              color: '#FFFFFF', // whatever you need
            },
          },
        },
        MUIDataTableToolbar: {
          styleOverrides: {
            icon: {
              color: '#FFFFFF',
            },
            root: {
              color: title ? '#333' : '#f8f8f8', // temporary hacky workaround: makes title (which we don't want to see) same color as background and thus not visible
              backgroundColor: '#f8f8f8',
              paddingLeft: '0',
            },
          },
        },
        MUIDataTableSearch: {
          styleOverrides: {
            main: {
              marginLeft: '-1.5rem',
            },
          },
        },
        MUIDataTableFilterList: {
          styleOverrides: {
            root: {
              color: '#FFFFFF',
              backgroundColor: '#08314c',
              margin: '0px',
              paddingLeft: '8px',
              borderTopRightRadius: '10px',
              borderTopLeftRadius: '10px',
            },
          },
        },
        MuiSelect: {
          styleOverrides: {
            select: {
              color: '#1a253a',
            },
          },
        },
        MuiChip: {
          styleOverrides: {
            root: {
              color: '#08314c',
              fontWeight: '600',
              backgroundColor: 'rgb(180,199,229,1)',
              margin: '0',
            },
          },
        },
        MuiTablePagination: {
          styleOverrides: {
            select: {
              color: '#1a253a', // styles number at the bottom of table, for pagination
            },
          },
        },
        MuiSvgIcon: {
          styleOverrides: {
            root: {
              color: '#1a253a !important',
            },
          },
        },
        MuiTableRow: {
          styleOverrides: {
            root: {
              cursor: 'pointer',
            },
          },
        },
        MUIDataTableSelectCell: {
          styleOverrides: {
            headerCell: {
              backgroundColor: '#08314c',
              borderTopLeftRadius: numActiveFilters === 0 ? '10px' : '0px',
            },
          },
        },
      },
    });
  }

  const onProductsCsvExport = () => {
    getProductsCsvExport(collectionUuid ? collectionUuid : '').then(
      (res: any) => {
        const filename = computeProductsExportFileName();
        const tempLink = document.createElement('a');
        tempLink.setAttribute('download', `${filename}.csv`);
        const universalBOM = '\uFEFF';
        tempLink.href = `data:text/csv;charset=utf-8,${encodeURIComponent(
          universalBOM + res.data
        )}`; // NB: The encodeURIComponent and UniversalBOM are needed in order to deal with special characters. See answer here: https://stackoverflow.com/questions/42462764/javascript-export-csv-encoding-utf-8-issue
        tempLink.click();
      }
    );
  };

  const options: MUIDataTableOptions = {
    filterType: 'dropdown',
    selectableRows: selectableRows ? 'multiple' : 'none',

    selectToolbarPlacement: 'none',
    onRowSelectionChange(currentRowsSelected, allRowsSelected, rowsSelected) {
      const selectedRowsDataIndexes = rowsSelected;
      setSelectedRowsIndexes(selectedRowsDataIndexes!);
      const selectedProductsUuids = computeSelectedProductsFromDataIndexes(
        selectedRowsDataIndexes
      );
      onProductsSelect!(selectedProductsUuids);
    },
    rowsSelected: selectedRowsIndexes,
    selectableRowsHeader: false,
    print: false,
    responsive: 'standard',

    rowsPerPageOptions: [5, 10, 25, 100],
    download: onProductsSelect ? false : enableCsvExport ? true : false, //if you're in a page where products can be selected, csv export is disabled by default
    onDownload: (buildHead, buildBody, columns, data) => {
      onProductsCsvExport(); // overwrite default export functionality with our custom export functionality
      return false;
    },
    setRowProps: (row) => {
      return {
        style: {},

        onMouseOver: () => {
          const rowX = document.getElementById(`Action-${row[0]}`);
          if (rowX) {
            rowX.style.display = 'block';
          }
        },

        onMouseLeave: () => {
          const rowX = document.getElementById(`Action-${row[0]}`);
          if (rowX) {
            rowX.style.display = 'none';
          }
        },
      };
    },
    onCellClick(colData, cellMeta: any) {
      const dataIndex = cellMeta.dataIndex;
      const productUuid = products[dataIndex].uuid;
      const productStatus = products[dataIndex].status;
      if (cellMeta.colIndex !== 11) {
        if (!selectableRows) {
          if (
            productStatus === 'complete' ||
            productStatus === 'expired' ||
            productStatus === 'in progress'
          ) {
            navigate(`/products/${productUuid}`);
          }

          if (productStatus === 'draft') {
            navigate(`/products/${productUuid}/edit`);
          }
        }
      }
    },

    textLabels: {
      body: {
        noMatch: 'No matching products',
        toolTip: '', // NO TOOLTIP
      },
      pagination: {
        next: 'Next Page',
        previous: 'Previous Page',
        rowsPerPage: 'Products per page:',
        displayRows: 'of',
      },
    },
    onFilterChange: (columnChanged, filterList) => {
      if (onProductsSelect) {
        const selectedProductsUuids =
          computeSelectedProductsFromDataIndexes(selectedRowsIndexes);
        onProductsSelect!(selectedProductsUuids);
      }
      let numActiveFilters = 0;
      for (let i = 0; i < filterList.length; i++) {
        numActiveFilters += filterList[i].length;
      }
      setNumActiveFilters(numActiveFilters);
    },
    customToolbar: () => {
      return disableCreateProductFunctionality ? null : (
        <Link
          to="/products/create"
          style={{ backgroundColor: '#F8F8F8', marginLeft: 'auto' }}
        >
          <Button
            variant="contained"
            style={{ backgroundColor: '#08314c' }}
            sx={{
              textTransform: 'none',
              color: 'white',
              marginRight: '-24px',
            }}
          >
            <AddIcon sx={{ mr: 1, color: 'white !important' }} />
            <Typography variant="body2" sx={{ mr: 1 }}>
              Create{' '}
            </Typography>
          </Button>
        </Link>
      );
    },
  };

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        title={title ? title : null}
        data={products}
        columns={columns}
        options={options}
      />
    </ThemeProvider>
  );
};

export default memo(ProductsFilterableTable);
