import { ResponsiveBar } from '@nivo/bar';
import React from 'react';
import {
  getColorByLifeCycleStage,
  roundNumberToNDecimalPlaces,
} from '../../utils/helpers';

import {
  Agriculture,
  Factory,
  Inventory2,
  LocalShipping,
} from '@mui/icons-material';

interface ProductFPPTBarChartProps {
  data: {
    lifeCycleStage: string;
    ingredientsEmissions: number;
    productEmissions: number;
    totalEmissions: number;
  }[];
  showXAxis?: boolean;
  marginRight?: number;
  marginBottom?: number;
  marginLeft?: number;
  includeCustomBarComponent?: boolean;
}

const ProductFPPTBarChart: React.FC<ProductFPPTBarChartProps> = ({
  data,
  showXAxis = true,
  marginRight = 50,
  marginBottom = 50,
  marginLeft = 117,
  includeCustomBarComponent = true,
}) => {
  const getLighterColorByLifeCycleStage = (lifeCycleStage: string) => {
    switch (lifeCycleStage) {
      case 'Farming':
        return '#e3efe7';
      case 'Processing':
        return '#c9def0';
      case 'Packaging':
        return '#ffe9c2';
      case 'Transport':
        return '#fed4b9';
      default:
        return '#000';
    }
  };

  const preProcessData = (
    data: {
      lifeCycleStage: string;
      ingredientsEmissions: number;
      productEmissions: number;
    }[]
  ): {
    lifeCycleStage: string;
    ingredientsEmissions: number;
    productEmissions: number;
    ingredientsEmissionsColor: string;
    productEmissionsColor: string;
    totalEmissions: number;
  }[] => {
    const result = [];
    for (let i = 0; i < data.length; i++) {
      const ingredientsEmissions = roundNumberToNDecimalPlaces(
        data[i]['ingredientsEmissions'] * 1000,
        1
      );
      const productEmissions = roundNumberToNDecimalPlaces(
        data[i]['productEmissions'] * 1000,
        1
      );
      result.push({
        lifeCycleStage: data[i]['lifeCycleStage'],
        ingredientsEmissions,
        productEmissions,
        ingredientsEmissionsColor: getColorByLifeCycleStage(
          data[i]['lifeCycleStage']
        ),
        productEmissionsColor: getLighterColorByLifeCycleStage(
          data[i]['lifeCycleStage']
        ),
        totalEmissions: roundNumberToNDecimalPlaces(
          data[i]['ingredientsEmissions'] * 1000 +
            data[i]['productEmissions'] * 1000,
          1
        ),
      });
    }
    return result.reverse();
  };

  const CustomBarComponent = ({ bars }: any) => {
    const size = 24;
    const images = bars.map((bar: any, index: number) => {
      return (
        <React.Fragment key={bar.key}>
          {bar.key.includes('Packaging') && index === 1 ? (
            <svg
              width="25"
              height="25"
              x={bar.x - 110}
              y={bar.y + 5}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Inventory2 style={{ color: '#08314c' }} fontSize="large" />
            </svg>
          ) : bar.key.includes('Transport') && index === 0 ? (
            <svg
              width="30"
              height="30"
              x={bar.x - 110}
              y={bar.y + 5}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <LocalShipping style={{ color: '#08314c' }} fontSize="large" />
            </svg>
          ) : bar.key.includes('Farming') && index === 3 ? (
            <svg
              width="30"
              height="26"
              x={bar.x - 110}
              y={bar.y + 5}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Agriculture style={{ color: '#08314c' }} fontSize="large" />
            </svg>
          ) : bar.key.includes('Processing') && index === 2 ? (
            <>
              <svg
                width="30"
                height="26"
                x={bar.x - 110}
                y={bar.y + 5}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <Factory style={{ color: '#08314c' }} fontSize="large" />
              </svg>
            </>
          ) : null}
        </React.Fragment>
      );
    });
    return <g>{images}</g>;
  };

  return (
    <ResponsiveBar
      data={preProcessData(data)}
      keys={['ingredientsEmissions', 'productEmissions']}
      indexBy="lifeCycleStage"
      margin={{
        top: -5,
        right: marginRight,
        bottom: marginBottom,
        left: marginLeft,
      }}
      padding={0.3}
      valueScale={{ type: 'linear' }}
      //@ts-expect-error - d
      colors={({ id, data }) => data[`${id}Color`]}
      layout={'horizontal'}
      animate={true}
      maxValue={'auto'}
      enableLabel={false}
      axisRight={null}
      enableGridY={false}
      layers={[
        'grid',
        'axes',
        'bars',
        'markers',
        'legends',
        'annotations',
        ...(includeCustomBarComponent ? [CustomBarComponent] : []),
      ]}
      tooltip={({ id, data }) => {
        const hoveredBar: string | number = id;
        return (
          <div
            style={{
              padding: 12,
              color: '#000',
              background: '#fff',
              boxShadow: '5px 3px 5px #00000030',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {hoveredBar === 'ingredientsEmissions' && (
              <>
                <span>
                  Total {data.lifeCycleStage} Emissions:{' '}
                  <b>{data.totalEmissions}</b> gCO
                  <sub>2</sub>e
                </span>
                <span
                  style={{
                    color: '#64758b',
                  }}
                >
                  <small>
                    From ingredients: <b>{data.ingredientsEmissions}</b> gCO
                    <sub>2</sub>e
                  </small>
                </span>
              </>
            )}
            {hoveredBar === 'productEmissions' && (
              <>
                <span>
                  Total {data.lifeCycleStage} Emissions:{' '}
                  <b>{data.totalEmissions}</b> gCO
                  <sub>2</sub>e
                </span>
                <span style={{ color: '#64758b' }}>
                  <small>
                    From product: <b>{data.productEmissions}</b> gCO
                    <sub>2</sub>e
                  </small>
                </span>
              </>
            )}
          </div>
        );
      }}
      axisBottom={
        showXAxis
          ? {
              tickSize: 3,
              tickPadding: 5,
              tickRotation: 0,
              tickValues: 6,

              legendPosition: 'middle',
              legend: `Emissions (gCO2e)`,
              legendOffset: 30,
            }
          : null
      }
    />
  );
};

export default ProductFPPTBarChart;
