import { SimpleTooltip } from '@shared/components/simple-tooltip';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@shared/components/ui/accordion';
import { cn } from '@shared/lib/utils';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  ACTIVE_LINK_STYLE,
  BASE_LINK_STYLE,
  HOVER_LINK_STYLE,
} from '../styles';
import { NavCategory } from '../types';
import SidebarLink from './sidebar-link';

interface BaseSidebarLinkGroupProps {
  category: NavCategory;
}

interface SidebarLinkGroupProps extends BaseSidebarLinkGroupProps {
  isCollapsed: boolean;
}

function SidebarLinkGroup({
  category,
  isCollapsed,
}: SidebarLinkGroupProps): React.ReactElement {
  if (isCollapsed) {
    return <CollapsedSidebarLinkGroup category={category} />;
  }
  return <ExpandedSidebarLinkGroup category={category} />;
}

export default SidebarLinkGroup;

function CollapsedSidebarLinkGroup({
  category,
}: BaseSidebarLinkGroupProps): React.ReactElement {
  const location = useLocation();
  const isActive = category.items.some((item) => item.to === location.pathname);

  return (
    <SimpleTooltip
      trigger={
        <div className="flex items-center justify-center">
          <div
            className={cn(
              BASE_LINK_STYLE,
              !isActive && HOVER_LINK_STYLE,
              isActive && ACTIVE_LINK_STYLE,
              'flex items-center justify-center'
            )}
          >
            {category.icon}
          </div>
        </div>
      }
      content={
        <div>
          <p className="pl-3 pt-1 font-semibold">{category.title}</p>
          <div className="mt-2 space-y-2">
            {category.items.map((subItem, subIndex) => (
              <SidebarLink key={subIndex} item={subItem} isCollapsed={false} />
            ))}
          </div>
        </div>
      }
      side="right"
      className="max-w-[200px] py-2"
      delayDuration={200}
    />
  );
}

function ExpandedSidebarLinkGroup({
  category,
}: BaseSidebarLinkGroupProps): React.ReactElement {
  const [isExpanded, setIsExpanded] = useState<string | null>(null);
  const location = useLocation();
  const isActive = category.items.some((item) => item.to === location.pathname);

  return (
    <Accordion
      type="single"
      onValueChange={setIsExpanded}
      collapsible
      className={cn(BASE_LINK_STYLE, 'w-full rounded-lg py-0 px-0')}
    >
      <AccordionItem value="item-1" className="w-full border-none">
        <AccordionTrigger
          className={cn(
            'flex w-full rounded-md px-3 py-2 hover:no-underline',
            !(isActive && !isExpanded) && HOVER_LINK_STYLE,
            isActive && (isExpanded ? 'text-primary' : ACTIVE_LINK_STYLE)
          )}
        >
          <div className="flex items-center gap-3">
            {category.icon} {category.title}
          </div>
        </AccordionTrigger>
        <AccordionContent className="space-y-2 py-2 pl-7 pr-1">
          {category.items.map((item, index: number) => (
            <SidebarLink key={index} item={item} isCollapsed={false} />
          ))}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
