import useAuth from '@app/auth/use-auth';
import Navbar from '@app/components/layout/nav/navbar';
import Page from '@app/components/layout/page/page';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCompleteSignup } from '@shared/api';
import Loader from '@shared/components/loader';
import { Form } from '@shared/components/ui/form';
import { useToast } from '@shared/components/ui/use-toast';
import sentry from '@shared/services/sentry';
import { useEffect } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { Outlet, useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { aboutCompanyFormSchema } from './schema';

export default function SignupFlowLayout() {
  const { toast } = useToast();
  const { loadingAuth, isAuthenticated, user, refreshUser } = useAuth();
  const navigate = useNavigate();

  const { mutateAsync: completeSignup, isLoading: completingSignup } =
    useCompleteSignup();

  const form = useForm<z.infer<typeof aboutCompanyFormSchema>>({
    resolver: zodResolver(aboutCompanyFormSchema),
    defaultValues: {
      countryUuid: '',
      organizationCategory: '',
      organizationNumProductsRange: '',
      organizationAims: [],
    },
  });

  const onSubmit = async (data: z.infer<typeof aboutCompanyFormSchema>) => {
    try {
      await completeSignup({
        data: data,
      });
      await refreshUser();

      if (user?.emailVerified) return navigate('/guide/welcome/');

      return navigate('/verify-email');
    } catch (error) {
      sentry.log(error);
      toast({
        title: 'Error',
        description:
          'An error occurred while completing signup. Please try again or contact us if error persists.',
      });
    }
  };

  useEffect(() => {
    if (loadingAuth) return;

    if (!isAuthenticated) {
      return navigate('/login');
    }

    if (user?.emailVerified && user?.hasCompletedSignup) {
      return navigate('/');
    }

    if (user?.hasCompletedSignup) {
      return navigate('/verify-email');
    }
  }, [isAuthenticated, loadingAuth, navigate, user]);

  if (loadingAuth || !isAuthenticated)
    return <Loader className="h-screen" size="lg" withLogo />;

  return (
    <Page name="signup-layout" page="layout">
      <div className="flex h-full min-h-screen flex-col bg-teal-200">
        <Navbar
          logo={{
            variant: 'name',
            color: 'dark',
          }}
        />
        <div className="grid flex-1 grid-cols-1 overflow-y-auto py-2">
          <Form {...form}>
            <Outlet
              context={[
                onSubmit as SubmitHandler<FieldValues>,
                completingSignup as boolean,
              ]}
            />
          </Form>
        </div>
      </div>
    </Page>
  );
}
