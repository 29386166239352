/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * My Emissions API
 * We calculate food carbon footprints
 * OpenAPI spec version: 1.0.0 (v1)
 */

/**
 * * `reduce-emissions` - Reduce our company's emissions
* `carbon-labelling` - Display carbon labels
* `report-emissions` - Accurately report our scope 3 emissions
* `monitor-net-zero` - Set and monitor our progress towards Net Zero
* `corporate-assessment` - Complete a full corporate carbon assessment
 */
export type OrganizationAimsEnum = typeof OrganizationAimsEnum[keyof typeof OrganizationAimsEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationAimsEnum = {
  'reduce-emissions': 'reduce-emissions',
  'carbon-labelling': 'carbon-labelling',
  'report-emissions': 'report-emissions',
  'monitor-net-zero': 'monitor-net-zero',
  'corporate-assessment': 'corporate-assessment',
} as const;
