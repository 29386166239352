import { Alert } from "@mui/material";

interface AlertProps {
  message?: string;
  type: any;
}

const AlertMessage: React.FC<AlertProps> = ({ message, type }) => {
  // const type = "info" || null;
  return (
    <>
      <Alert
        severity={type || "info"}
        sx={{ marginTop: "2rem", width: "100%" }}
      >
        {message
          ? message
          : "Oh no! An error has occurred. Please try again later, or contact us if the error persists."}
      </Alert>
    </>
  );
};

export default AlertMessage;
