import ProductPPTEmissionsDonutChart from '@app/components/charts/ProductPPTEmissionsDonutChart';
import Page from '@app/components/layout/page/page';
import ProductLifeCycleInfo from '@app/components/RecipleLifeCycleInfo';
import StatsCard from '@app/components/stats/StatsCard';
import { RECIPE_LIFE_CYCLE_INFO } from '@app/utils/constants';
import { roundNumberToNDecimalPlaces } from '@app/utils/helpers';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { ProductResult } from '@shared/api/types';
import React from 'react';
import { useOutletContext } from 'react-router-dom';
import ProductProcessingTypes from '../../tabs/components/ProductProcessingTypes';

export const getSeparateTotalEmissions = (
  product: ProductResult,
  stage: string
) => {
  return (
    product?.lifeCycleStageEmissions.find(
      (item) => item.lifeCycleStage === stage
    )?.emissions || 0
  );
};

export default function ProductDetailProcessing() {
  const product: ProductResult = useOutletContext();

  const { processing, status } = product;

  const totalProcessingEmissions = getSeparateTotalEmissions(
    product,
    'Processing'
  );

  return (
    <Page name="Product detail - Processing">
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            filter: status === 'expired' ? 'grayscale(100%)' : '',
          }}
        >
          <ProductLifeCycleInfo
            title="Processing"
            text={RECIPE_LIFE_CYCLE_INFO.Processing.text}
            image={RECIPE_LIFE_CYCLE_INFO.Processing.image}
          />
        </Grid>
        <Typography
          variant="h5"
          sx={{
            color: 'text.primary',
            marginTop: '1.5rem',
            marginBottom: '1.5rem',
            fontWeight: 600,
          }}
        >
          Overview
        </Typography>
        <Grid
          container
          item
          alignItems="flex-start"
          spacing={2}
          sx={{
            filter: status === 'expired' ? 'grayscale(100%)' : '',
          }}
        >
          <Grid item xs={12} sm={12} md={6}>
            <Box
              sx={{
                backgroundColor: 'white',
                borderRadius: '10px',
                width: '100%',
                paddingX: '1.4rem',
                paddingY: '1rem',
                minHeight: '109px',
                overflow: 'visible',
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb={0.5}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: 'text.primary',
                    fontWeight: 'medium',
                    marginBottom: '0',
                    minHeight: '24px',
                  }}
                >
                  Total Emissions from Processing (gCO2<sub>e</sub>)
                </Typography>
              </Box>
              <div
                style={{
                  width: '100%',
                  height: '305px',
                }}
              >
                <ProductPPTEmissionsDonutChart
                  type="Processing"
                  PPTTotalEmissions={totalProcessingEmissions}
                  productEmissionsCategoryBreakdown={[
                    {
                      label: 'Ingredients',
                      emissions: product.ingredientProcessingEmissions,
                    },
                    {
                      label: 'Product',
                      emissions: processing.processingEmissions,
                    },
                  ]}
                />
              </div>
            </Box>
          </Grid>
          <Grid item container spacing={2} xs={12} md={6}>
            <Grid item xs={12} lg={12}>
              <Box>
                <StatsCard
                  title="Emissions from Product Processing"
                  value={
                    (processing.processingEmissions &&
                      roundNumberToNDecimalPlaces(
                        processing.processingEmissions * 1000,
                        1
                      )) ||
                    'N/A'
                  }
                  unit="gCO2e"
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Box>
                <StatsCard
                  title="Percentage of Product's Total Processing Emissions"
                  value={
                    (processing.shareOfTotalEmissions &&
                      roundNumberToNDecimalPlaces(
                        processing.shareOfTotalEmissions * 100,
                        1,
                        processing.shareOfTotalEmissions * 100 === 100
                          ? false
                          : true
                      )) ||
                    'N/A'
                  } // don't add trailing 0 if it's 100%
                  unit="%"
                  tooltip
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              lg={12}
              sx={{
                display: {
                  xs: 'none',
                  md: 'block',
                },
                minHeight: '109px',
              }}
            ></Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider
              sx={{
                marginY: '1.5rem',
              }}
            />
            <Typography
              variant="h5"
              sx={{
                color: 'text.primary',
                marginTop: '1.5rem',
                marginBottom: '0.75rem',
                fontWeight: 600,
              }}
            >
              Product&apos;s Processing Types
            </Typography>

            {processing.processingTypes.map((type) => (
              <React.Fragment key={type.name}>
                <ProductProcessingTypes
                  description={type.name}
                  proportionOfProduct={type.proportionOfProduct}
                  totalEmissionsPerItem={type.totalEmissionsPerItem}
                  steps={type.steps}
                  notesAndAssumptions={type.notesAndAssumptions}
                />
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
}
