import useSimpleDialog from '@app/hooks/use-simple-dialog';
import {
  AvailablePackaging,
  CustomPackaging,
  DefaultPackaging,
} from '@shared/api/types';
import {
  CategorizedPicker,
  Category,
} from '@shared/components/form/categorized-picker';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@shared/components/ui/form';
import { cn } from '@shared/lib/utils';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import CreatePackagingForm from './create-ppt-forms/create-packaging-form';

interface ProductPackagingPickerProps {
  name: string;
  label?: string;
  availablePackaging: AvailablePackaging;
  index?: number;
  className?: string;
  includeErrorMessage?: boolean;
}

type AvailablePackagingType = DefaultPackaging | CustomPackaging;

export default function ProductPackagingPicker({
  name,
  availablePackaging,
  index,
  className,
  includeErrorMessage,
  label,
}: ProductPackagingPickerProps) {
  const { control, setValue, getValues } = useFormContext();
  const { showSimpleDialog, closeSimpleDialog, SimpleDialogComponent } =
    useSimpleDialog();

  const categories: Category<AvailablePackagingType>[] = useMemo(() => {
    const defaultPackagingCategory: Category<DefaultPackaging> = {
      name: 'Standard packaging',
      items: availablePackaging.default,
      searchableKeys: ['name'],
      valueKey: 'uuid',
      labelKey: 'name',
      onSelect: (selectedItem: DefaultPackaging) => {
        setValue(`packaging.${index}.name`, selectedItem.name);
        setValue(`packaging.${index}.packagingType`, selectedItem.uuid);
        setValue(`packaging.${index}.availablePackagingType`, 'default'); //TODO use enum
      },
    };

    const customPackagingCategory: Category<CustomPackaging> = {
      name: 'My packaging',
      items: availablePackaging.custom || [],
      searchableKeys: ['name'],
      valueKey: 'uuid',
      labelKey: 'name',
      onSelect: (selectedItem: CustomPackaging) => {
        setValue(`packaging.${index}.name`, selectedItem.name);
        setValue(`packaging.${index}.packagingType`, selectedItem.uuid);
        setValue(`packaging.${index}.availablePackagingType`, 'custom'); //TODO use enum
      },
    };

    return [
      defaultPackagingCategory,
      customPackagingCategory,
    ] as Category<AvailablePackagingType>[];
  }, [index, setValue, availablePackaging]);

  return (
    <FormField
      control={control}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <>
            <FormItem className={cn('grid flex-1 items-center', className)}>
              {label && <FormLabel>Type</FormLabel>}
              <FormControl>
                <CategorizedPicker
                  categories={categories}
                  value={field.value}
                  key={categories[1].items.length}
                  error={fieldState.error?.message}
                  onCreate={showSimpleDialog}
                />
              </FormControl>
              {includeErrorMessage &&
                index === getValues('packaging').length - 1 && <FormMessage />}
            </FormItem>
            {SimpleDialogComponent({
              header: 'Create Packaging Item',
              description: (
                <CreatePackagingForm
                  onPackagingCreated={(data) => {
                    setValue(`packaging.${index}.name`, data.name);
                    setValue(`packaging.${index}.packagingType`, data.uuid);
                    setValue(
                      `packaging.${index}.availablePackagingType`,
                      'custom'
                    );
                    closeSimpleDialog();
                  }}
                />
              ),
            })}
          </>
        );
      }}
    />
  );
}
