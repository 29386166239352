import { Box, Typography } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteTarget, getTargets } from '../../api/api';
import Page from '../../components/layout/page/page';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import TargetsTable from './components/TargetsTable';

interface Target {
  uuid: string;
  metricName: string;
  targetValue: number;
  currentValue: number;
  createdDate: Date;
  isAchieved: boolean;
}

export default function TargetsPage() {
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();

  const { data: targets } = useQuery({
    queryKey: ['targets'],
    queryFn: getTargets,
    //staleTime: 1000 * 60 * 60 * 24,
  });

  const onTargetDelete = async (targetUuid: string) => {
    await deleteTarget(targetUuid)
      .then((res) => {
        console.log(res);
        queryClient.invalidateQueries(['targets']);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Page name="Targets">
      <Box
        sx={{
          maxWidth: '100%',
        }}
      >
        <Typography
          variant="h5"
          mt={2}
          sx={{
            color: 'text.secondary',
          }}
        >
          Targets
        </Typography>

        {targets && (
          <TargetsTable targets={targets} onTargetDelete={onTargetDelete} />
        )}
      </Box>
    </Page>
  );
}
