// Packages
import React from 'react';

// Material UI Componennts

// Components
import useAuth from '@app/auth/use-auth';
import AvgMealEmissionsStat from '../../../components/stats/AvgEmissionsPerKgStat';
import NumProductsAndNumIngredientsStat from '../../../components/stats/NumProductsAndNumIngredientsStat';
import ProductsStatusBreakdown from '../../../components/stats/ProductsStatusBreakdown';

interface TopStatsInterface {
  averageProductEmissionsPerKg: number;
  totalNumberOfProducts: number;
  numberOfIngredients: number;
  numberProductsDraft: number;
  numberProductsEditing: number;
  numberProductsInProgress: number;
  numberProductsCompleted: number;
}

const TopStats: React.FC<TopStatsInterface> = ({
  averageProductEmissionsPerKg,
  totalNumberOfProducts,
  numberOfIngredients,
  numberProductsDraft,
  numberProductsInProgress,
  numberProductsCompleted,
  numberProductsEditing,
}: TopStatsInterface) => {
  const { user } = useAuth();
  return (
    <div className="mt-4 w-full items-center justify-between space-y-4 pl-4 sm:flex sm:flex-col md:flex md:flex-row md:items-end md:space-x-4">
      <div className="flex size-full flex-col rounded-lg sm:flex-row">
        <AvgMealEmissionsStat
          avgProductEmissionsPerKg={averageProductEmissionsPerKg}
        />

        <NumProductsAndNumIngredientsStat
          numProducts={totalNumberOfProducts}
          numIngredients={numberOfIngredients}
        />
      </div>
      {user.productCategory === 'product' && (
        <ProductsStatusBreakdown
          calculating={numberProductsInProgress}
          draft={numberProductsDraft}
          complete={numberProductsCompleted}
          editing={numberProductsEditing}
        />
      )}
    </div>
  );
};

export default TopStats;
