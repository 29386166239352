import Logo from '@shared/components/logo/logo';
import { Button } from '@shared/components/ui/button';
import { cn } from '@shared/lib/utils';
import { PanelLeft, PanelRight } from 'lucide-react';
import {
  DESKTOP_EXPANDED_SIDEBAR_WIDTH,
  SIDEBAR_HEADER_STYLE,
} from '../styles';
import { SidebarItem, SidebarItemPosition } from '../types';
import SidebarLink from './sidebar-link';
import SidebarLinkGroup from './sidebar-link-group';

const renderSidebarItems = (
  sidebarItems: SidebarItem[],
  isCollapsed: boolean
) =>
  sidebarItems.map((sidebarItem, index) => {
    if ('items' in sidebarItem.item) {
      return (
        <SidebarLinkGroup
          key={sidebarItem.item.title || index}
          category={sidebarItem.item}
          isCollapsed={isCollapsed}
        />
      );
    }
    return (
      <SidebarLink
        key={sidebarItem.item.title || index}
        item={sidebarItem.item}
        isCollapsed={isCollapsed}
      />
    );
  });

export const ExpandedSidebarContent = ({
  items,
  cta,
  onCollapse,
}: {
  items: SidebarItem[];
  cta?: React.ReactNode;
  onCollapse?: () => void;
}) => {
  return (
    <>
      <div
        className={cn(
          'flex items-center justify-between',
          SIDEBAR_HEADER_STYLE,
          DESKTOP_EXPANDED_SIDEBAR_WIDTH
        )}
      >
        <Logo variant="name" color="dark" className="w-10/12" />
        {onCollapse && (
          <Button
            variant="ghost"
            size="icon"
            onClick={onCollapse}
            className="ml-2 p-0 text-teal-800 hover:bg-teal-100"
            aria-label="Collapse sidebar"
          >
            <PanelLeft className="size-4" />
          </Button>
        )}
      </div>
      <div className="flex-1">
        <nav className="grid items-start space-y-3 px-4 py-3 font-medium">
          {renderSidebarItems(
            items.filter((item) => item.position === SidebarItemPosition.Top),
            false
          )}
        </nav>
      </div>
      <div className="mt-auto pb-0">
        <nav className="grid items-start space-y-3 p-2 font-medium lg:px-4">
          {renderSidebarItems(
            items.filter(
              (item) => item.position === SidebarItemPosition.Bottom
            ),
            false
          )}
        </nav>
        {cta && (
          <div className="flex w-full flex-col items-center justify-center space-y-2 border-t p-6">
            {cta}
          </div>
        )}
      </div>
    </>
  );
};

export const CollapsedSidebarContent = ({
  items,
  onExpand,
}: {
  items: SidebarItem[];
  onExpand: () => void;
}) => {
  return (
    <>
      <div
        className={cn(
          'flex items-center justify-center pb-6 pt-4 lg:h-[90px]',
          SIDEBAR_HEADER_STYLE
        )}
      >
        <Logo variant="icon" className="w-[46px]" />
        <Button
          variant="secondary"
          size="icon"
          onClick={onExpand}
          aria-label="Expand sidebar"
          className="absolute mt-[96px] size-6 border-teal-100 text-teal-800 hover:border-teal-200  hover:text-teal-500"
        >
          <PanelRight className="size-3.5" />
        </Button>
      </div>
      <div className="flex-1">
        <nav className="mt-2 grid items-start gap-2 p-2 font-medium">
          {renderSidebarItems(
            items.filter((item) => item.position === SidebarItemPosition.Top),
            true
          )}
        </nav>
      </div>
      <div className="mt-auto pb-4">
        <nav className="grid items-start gap-2 p-2 font-medium">
          {renderSidebarItems(
            items.filter(
              (item) => item.position === SidebarItemPosition.Bottom
            ),
            true
          )}
        </nav>
      </div>
    </>
  );
};
