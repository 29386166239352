import 'react-circular-progressbar/dist/styles.css';

import { Outlet, useParams } from 'react-router-dom';

import { PageHeader } from '@app/components/layout/page/components/page-header';
import { useGetCollection } from '@shared/api';
import { CollectionDetail } from '@shared/api/types';
import { PageTabs, Tab } from '@shared/components/page-tabs';
import { getUserFriendlyDate } from '@shared/lib/utils';
import Page from '../../../components/layout/page/page';
import { CollectionDetailPageToolbar } from './collection-detail-page-toolbar';

const CollectionDetailPage: React.FC = () => {
  const params = useParams();

  const collectionUuid = params.collection_id;

  const { data: collection, status: collectionStatus } = useGetCollection(
    collectionUuid!
  );

  const tabs: Tab[] = [
    {
      title: 'Summary',
      href: `/collections/${collectionUuid}`,
    },
    {
      title: 'Products',
      href: `/collections/${collectionUuid}/products`,
    },
    {
      title: 'Ingredients',
      href: `/collections/${collectionUuid}/ingredients`,
    },
  ];

  return (
    <Page name="Collection" status={collectionStatus} title={collection?.name}>
      {collection && (
        <div className="overflow-hidden">
          <PageHeader
            name={collection.name}
            description={getUserFriendlyDate(collection.createdDate)}
            breadcrumbs={[
              {
                label: 'Collections',
                url: '/collections',
              },
              {
                label: collection.name,
                url: '',
              },
            ]}
            toolbar={<CollectionDetailPageToolbar collection={collection} />}
            tabs={<PageTabs tabs={tabs} />}
          />
          <Outlet context={collection as CollectionDetail} />
        </div>
      )}
    </Page>
  );
};

export default CollectionDetailPage;
