// React
import React, { useState } from 'react';

// Material UI Components
import { Box, Button, TextField } from '@mui/material';

// Components
import AlertMessage from '../../components/AlertMessage';

// Hooks
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

import { PASSWORD_CHANGE_URL } from '../../api/api';

interface ChangePasswordFormProps {
  children?: React.ReactNode;
  HandlePasswordChangeForm: () => void;
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  HandlePasswordChangeForm,
}) => {
  const axiosPrivate = useAxiosPrivate();

  const [alert, setAlert] = useState(false);
  const [alertInfo, setAlertInfo] = useState({
    message: '',
    type: '',
  });

  const initialFormData = Object.freeze({
    old_password: '',
    new_password1: '',
    new_password2: '',
  });

  const [formData, updateFormData] = useState(initialFormData);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    axiosPrivate
      .post(
        PASSWORD_CHANGE_URL,
        {
          old_password: formData.old_password,
          new_password1: formData.new_password1,
          new_password2: formData.new_password2,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        setAlert(true);
        if (response.status === 200) {
          setAlertInfo({
            message: response.data.detail,
            type: 'success',
          });
        }

        updateFormData({
          ...formData,
          old_password: '',
          new_password1: '',
          new_password2: '',
        });
      })
      .catch((err) => {
        console.log(err);
        setAlert(true);
        if (err.response.status === 400) {
          setAlertInfo({
            message:
              'Invalid details. Please enter the correct password and ensure the new passwords match',
            type: 'error',
          });
        } else {
          setAlertInfo({
            message:
              'There is a problem with our server. Please try again later',
            type: 'error',
          });
        }
        updateFormData({
          ...formData,
          old_password: '',
          new_password1: '',
          new_password2: '',
        });
      });
  };
  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          width: '100%',
        }}
      >
        {alert ? (
          <AlertMessage type={alertInfo.type} message={alertInfo.message} />
        ) : (
          <></>
        )}
        <TextField
          id="old_password"
          name="old_password"
          label="Current Password"
          type="password"
          fullWidth={true}
          margin="normal"
          onChange={handleChange}
          value={formData.old_password}
        />
        <TextField
          id="new_password1"
          name="new_password1"
          label="New Password"
          type="password"
          fullWidth={true}
          margin="normal"
          onChange={handleChange}
          value={formData.new_password1}
        />
        <TextField
          id="new_password2"
          name="new_password2"
          label="New Password (again)"
          type="password"
          fullWidth={true}
          margin="normal"
          onChange={handleChange}
          value={formData.new_password2}
        />
        <Button
          type="submit"
          variant="contained"
          sx={{
            marginTop: '1rem',
          }}
        >
          Change Password
        </Button>
      </Box>
    </>
  );
};

export default ChangePasswordForm;
