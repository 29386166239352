import { DateCell } from '@shared/components/data-table/cells/date-cell';
import { HeaderCell } from '@shared/components/data-table/cells/header-cell';
import { DataTable } from '@shared/components/data-table/data-table';
import { DataTableToolbar } from '@shared/components/data-table/data-table-toolbar';
import { useDataTable } from '@shared/components/data-table/hooks/use-data-table';
import { ColumnDef } from '@tanstack/react-table';

interface TransportTableProps {
  transport: any;
}

function getColumns(): ColumnDef<any>[] {
  return [
    {
      accessorKey: 'uuid',
    },
    {
      accessorKey: 'name',
      header: ({ column }) => (
        <HeaderCell
          column={column}
          name="Transport step"
          className="text-gray-00"
        />
      ),
      cell: ({ getValue }) => {
        const name = getValue<string>();
        return <p className="min-w-[150px] max-w-[240px] truncate">{name}</p>;
      },
      enableHiding: false,
    },
    {
      accessorKey: 'createdDate',
      header: ({ column }) => (
        <HeaderCell column={column} name="Creation date" textWhite />
      ),
      cell: ({ getValue }) => {
        const date = getValue<string>();
        return (date && <DateCell date={date} />) ?? null;
      },
      enableSorting: true,
    },
  ];
}

export default function TransportTable({ transport }: TransportTableProps) {
  const filterFields = [
    {
      label: 'Transport step',
      value: 'name',
      placeholder: 'Search transport...',
    },
  ];
  const { table } = useDataTable({
    columns: getColumns(),
    data: transport,
    columnVisibilityState: {
      uuid: false,
    },
  });

  return (
    <DataTable
      table={table}
      enablePagination
      className="bg-gray-00"
      headerConfig={{
        className: 'bg-primary',
      }}
    >
      <DataTableToolbar table={table} filterFields={filterFields} />
    </DataTable>
  );
}
