import { PageHeader } from '@app/components/layout/page/components/page-header';
import CreateProcessingForm from '@app/components/product-form/create-ppt-forms/create-processing-form';
import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import Page from '../../components/layout/page/page';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

function CreateProcessingPage() {
  const [displaySuccess, setDisplaySuccess] = React.useState<boolean>(false);

  const axiosPrivate = useAxiosPrivate();

  return (
    <Page name="Create Processing">
      <PageHeader
        title="Create Processing"
        name="Create Processing"
        className="mb-10"
      />
      <Grid container spacing={4} sx={{ width: '100%' }}>
        <Grid item xs={12} style={{ paddingTop: '0.5rem' }}>
          {displaySuccess ? (
            <>
              <Typography
                variant="h5"
                style={{
                  marginTop: '2rem',
                  color: 'green',
                  fontWeight: 'bolder',
                }}
              >
                Processing created successfully!
              </Typography>
              <Button
                type="submit"
                variant="contained"
                sx={{ textTransform: 'none', marginTop: '2rem' }}
                onClick={() => setDisplaySuccess(false)}
              >
                Create another processing
              </Button>
            </>
          ) : (
            <CreateProcessingForm
              onProcessingCreated={() => setDisplaySuccess(true)}
            />
          )}
        </Grid>
      </Grid>
    </Page>
  );
}

export default CreateProcessingPage;
