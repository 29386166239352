import { PageHeader } from '@app/components/layout/page/components/page-header';
import Page from '@app/components/layout/page/page';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import DateRangeIcon from '@mui/icons-material/DateRange';
import FilterDramaIcon from '@mui/icons-material/FilterDrama';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import { useGetSalesReport } from '@shared/api';
import { capitalizeFirstLetter, convertDate } from '@shared/lib/utils';
import { useParams } from 'react-router-dom';
import { roundNumberToNDecimalPlaces } from '../../utils/helpers';
import TotalEmissionsByStageBarChart from './components/TotalEmissionsByStageBarChart';
import UnitsEmissionsCategoryPieChart from './components/UnitsByEmissionsCategoryPieChart';
import ReportProductsTable from './components/report-products-table';

const SalesReportPage: React.FC = () => {
  const param = useParams();
  const reportId = param.report_id;

  const { data: reportData, status: reportDataStatus } = useGetSalesReport(
    reportId!
  );

  return (
    <Page
      name="Report Details"
      title={reportData?.title}
      status={reportDataStatus}
    >
      {reportData && (
        <div>
          <PageHeader
            name={reportData.title}
            nameTag={capitalizeFirstLetter(reportData.category)}
            description={reportData.description!}
            breadcrumbs={[
              {
                label: 'Reports',
                url: '/reports',
              },
              {
                label: reportData.title,
                url: '',
              },
            ]}
          />
          {/* Main */}
          <div className="space-y-12">
            <div className="space-y-4">
              <p className="text-2xl font-bold">Overview</p>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
                {/* ONE BOX */}
                <div className="col-span-1 lg:col-span-1">
                  <div className="flex h-full flex-col rounded-lg bg-gray-00 p-4 sm:flex-row sm:pl-4">
                    {/* Icon Box */}
                    <div className="mr-4 hidden size-11 flex-none items-center justify-center rounded-full bg-gray-100 sm:flex">
                      <DateRangeIcon />
                    </div>

                    {/* Text Box */}
                    <div className="flex flex-col space-y-1">
                      <span className="text-sm font-normal">Time Period</span>
                      <p className="text-2xl font-semibold">
                        {convertDate(reportData.dateRangeStart!)} -{' '}
                        {convertDate(reportData.dateRangeEnd!)}
                      </p>
                    </div>
                  </div>
                </div>
                {/* TWO BOX */}

                <div className="col-span-1 lg:col-span-1">
                  <div className="flex h-full flex-col rounded-lg bg-gray-00 p-4 sm:flex-row sm:pl-4">
                    {/* Icon Box */}
                    <div className="mr-4 hidden size-11 flex-none items-center justify-center rounded-full bg-gray-100 sm:flex">
                      <ListAltOutlinedIcon />
                    </div>

                    {/* Text Box */}
                    <div className="flex flex-col space-y-1">
                      <p className="text-sm font-normal">Total Products Sold</p>
                      <p className="text-2xl font-semibold">
                        {reportData.totalProducts! > 100000
                          ? roundNumberToNDecimalPlaces(
                              reportData.totalProducts! / 1000,
                              1,
                              true
                            )
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'K'
                          : roundNumberToNDecimalPlaces(
                              reportData.totalProducts!,
                              1,
                              true
                            )
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </p>
                    </div>
                  </div>
                </div>
                {/* THREE BOX */}

                <div className="col-span-1 lg:col-span-1">
                  <div className="flex h-full flex-col rounded-lg bg-gray-00 p-4 sm:flex-row sm:pl-4">
                    {/* Icon Box */}
                    <div className="mr-4 hidden size-11 flex-none items-center justify-center rounded-full bg-gray-100 sm:flex">
                      <CloudQueueIcon />
                    </div>

                    {/* Text Content */}
                    <div className="flex flex-col space-y-1">
                      <p className="text-sm font-normal">Total emissions</p>
                      <div className="flex items-baseline">
                        <p className="text-2xl font-semibold">
                          {reportData.totalEmissions! > 100000
                            ? roundNumberToNDecimalPlaces(
                                reportData.totalEmissions! / 1000,
                                1
                              )
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            : roundNumberToNDecimalPlaces(
                                reportData.totalEmissions!,
                                1
                              )
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </p>
                        <p className="ml-1 text-xs">
                          {reportData.totalEmissions! > 100000 ? (
                            <p>
                              TCO<sub>2</sub>e
                            </p>
                          ) : (
                            <p>
                              kgCO<sub>2</sub>e
                            </p>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* FOUR BOX */}
                <div className="col-span-1 lg:col-span-1">
                  <div className="flex h-full flex-col rounded-lg bg-gray-00 p-4 sm:flex-row sm:pl-4">
                    {/* Icon Box */}
                    <div className="mr-4 hidden size-11 flex-none items-center justify-center rounded-full bg-gray-100 sm:flex">
                      <FilterDramaIcon />
                    </div>

                    {/* Text Content */}
                    <div className="flex flex-col space-y-1">
                      <p className="text-sm font-normal">
                        Average Carbon Intensity
                      </p>
                      <div className="flex items-baseline">
                        <p className="text-2xl font-semibold">
                          {roundNumberToNDecimalPlaces(
                            reportData.averageEmissionsPerKg!,
                            2,
                            true
                          )
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </p>
                        <p className="ml-1 text-xs">
                          kgCO<sub>2</sub>e/kg<sub>food</sub>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="space-y-4">
              <div className="mt-2 space-y-3 md:flex md:flex-wrap md:items-center md:justify-center md:space-y-0">
                {/* Breakdown by product rating */}
                <div className="mt-8 w-full px-2 md:mt-2 md:w-1/2">
                  <div className="mb-5 flex items-center">
                    <p className="text-2xl font-bold">
                      Breakdown By Product Rating
                    </p>
                  </div>
                  <div className="flex h-[350px] w-full flex-col items-center rounded-lg bg-gray-00">
                    <div className="size-full">
                      {/* Assuming UnitsEmissionsCategoryPieChart is a component you'd include here */}
                      <UnitsEmissionsCategoryPieChart
                        totalEmissionsByCategory={
                          reportData.totalEmissionsByCategory
                        }
                      />
                    </div>
                  </div>
                </div>

                {/* Total Emissions Per Lifecycle Stage */}
                <div className="mt-8 w-full px-2 md:mt-2 md:w-1/2">
                  <div className="mb-5 flex items-center">
                    <p className="text-2xl font-bold">
                      Total Emissions Per Lifecycle Stage
                    </p>
                  </div>
                  <div className="flex h-[350px] w-full flex-col items-center rounded-lg bg-gray-00">
                    <div className="size-full">
                      {/* Assuming TotalEmissionsByStageBarChart is a component you'd include here */}
                      <TotalEmissionsByStageBarChart
                        totalEmissionsByStage={reportData.totalEmissionsByStage}
                        totalEmissions={reportData.totalEmissions!}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <ReportProductsTable products={reportData.products} />
            </div>
          </div>
        </div>
      )}
    </Page>
  );
};

export default SalesReportPage;
