import Logo, { LogoProps } from '@shared/components/logo/logo';

interface NavbarProps {
  logo?: LogoProps;
}

export default function Navbar({
  logo = {
    variant: 'name',
    color: 'light',
  },
}: NavbarProps) {
  return (
    <div className="flex h-nav max-h-min items-center justify-between p-4">
      <Logo variant={logo.variant} color={logo.color} className="w-56" />
    </div>
  );
}
