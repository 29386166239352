import { Box } from '@mui/material';
import { ResponsivePie } from '@nivo/pie';
import {
  getColorByLifeCycleStage,
  roundNumberToNDecimalPlaces,
} from '../../../utils/helpers';

import { TotalEmissionsPerItemType } from '../packaging-list-page';

interface PackagingPieChartProps {
  totalEmissionsPerItem: TotalEmissionsPerItemType[];
}

const PackagingPieChart: React.FC<PackagingPieChartProps> = ({
  totalEmissionsPerItem,
}: PackagingPieChartProps) => {
  const preProcessData = (
    data: { label: string; totalEmissionsPerItem: number; id: string }[]
  ): { label: string; value: number; color: string }[] => {
    const result = [];
    for (let i = data.length - 1; i >= 0; i--) {
      result.push({
        id: data[i]['id'],
        label:
          data[i]['label'].charAt(0).toUpperCase() + data[i]['label'].slice(1),
        value: roundNumberToNDecimalPlaces(data[i]['totalEmissionsPerItem'], 2),
        color: getColorByLifeCycleStage(data[i]['id']),
      });
    }
    return result;
  };

  return (
    <Box
      sx={{
        fontFamily: 'Inter, Roboto',
        textAlign: 'center',
        height: '100%',
        minWidth: '100px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <ResponsivePie
        data={preProcessData(totalEmissionsPerItem)}
        margin={{ top: 40, right: 150, bottom: 46, left: 150 }}
        activeOuterRadiusOffset={8}
        colors={{ scheme: 'oranges' }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#161a1e"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: 'color',
          modifiers: [['darker', 2]],
        }}
        enableArcLabels={false}
        enableArcLinkLabels={true}
        arcLinkLabel={({ value, label }) => {
          return label.toString().length > 15
            ? `${label.toString().substring(0, 15) + '...'}`
            : `${label}`;
        }}
        defs={[]}
        tooltip={({ datum: { id, value, color, label } }) => (
          <div
            style={{
              padding: 12,
              color: '#000',
              background: '#fff',
              boxShadow: '5px 3px 5px #00000030',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width: '15px',
                height: '15px',
                backgroundColor: color,
                marginRight: '10px',
              }}
            ></div>
            <span>
              {label} :{' '}
              <b>{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</b>{' '}
              kgCO<sub>2</sub>e
            </span>
          </div>
        )}
      />
    </Box>
  );
};

export default PackagingPieChart;
