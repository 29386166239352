import { useAnalytics } from '@app/services/analytics/AnalyticsContext';
import { Banner } from './banner';

const visibleFrom = new Date('2024-03-05T00:00:00');
const visibleUntil = new Date('2024-04-02T00:00:00');
const name = 'methodology-update';
const bannerUrl =
  'https://myemissions.notion.site/Our-Carbon-Thresholds-are-Changing-What-You-Need-to-Know-3ba4136a0efd4d6f87b192a5ba2f2c80';

export function BannerMethodologyUpdate() {
  const { track } = useAnalytics();
  const trackClick = () => track('Clicked Banner', { name });

  return (
    <Banner
      name={name}
      visibleFrom={visibleFrom}
      visibleUntil={visibleUntil}
      dismissable
    >
      <a
        className="flex flex-col items-center justify-center px-2 md:max-w-[80%]"
        href={bannerUrl}
        target="_blank"
        rel="noreferrer"
        onClick={trackClick}
      >
        <h4 className="mb-1 text-sm font-bold text-secondary-foreground">
          📣 Important news!
        </h4>
        <p className="text-center text-sm">
          We&apos;re updating our methodology to better align food choices with
          planetary health.
          <br className="hidden lg:block" /> We have set new A-E carbon rating
          thresholds. Click here to learn more.
        </p>
      </a>
    </Banner>
  );
}
