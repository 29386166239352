import { useCreateCollection, useGetCollections } from '@shared/api';
import { MultiSelect } from '@shared/components/form/multi-select';
import { FormControl, FormField, FormItem } from '@shared/components/ui/form';
import { useToast } from '@shared/components/ui/use-toast';
import { SelectItem } from '@shared/lib/types';
import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

interface CollectionPickerProps {
  className?: ClassValue;
  name: string;
}

export default function CollectionPicker({
  className,
  name,
}: CollectionPickerProps) {
  const { control, setValue } = useFormContext();
  const { data: collectionsData } = useGetCollections();
  const { mutateAsync: createCollection } = useCreateCollection();
  const { toast } = useToast();

  const collections = useMemo(() => {
    if (!collectionsData) return [];
    return collectionsData
      .map((coll) => ({
        label: coll.name,
        value: coll.uuid,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [collectionsData]);

  const handleCreate = async (collectionName: string): Promise<SelectItem> => {
    try {
      const createCollectionResponse = await createCollection({
        data: {
          name: collectionName,
          products: [],
        },
      });
      const newCollection: SelectItem = {
        label: collectionName,
        value: createCollectionResponse.uuid,
      };
      return newCollection;
    } catch (error) {
      toast({
        variant: 'destructive',
        title: 'Oops! Something went wrong',
        description: `An error occurred while trying to create collection "${collectionName}". Please try again or contact us if the problem persists.`,
      });
      throw error;
    }
  };

  const handleSelect = (selectedCollections: SelectItem[]) => {
    setValue(
      name,
      selectedCollections.map((item) => item.value)
    );
  };

  return (
    <FormField
      control={control}
      name={name}
      render={() => (
        <FormItem className={cn('', className)}>
          <FormControl>
            <MultiSelect
              placeholder="Select or create collections"
              items={collections}
              onCreate={handleCreate}
              onSelectionChange={handleSelect}
            />
          </FormControl>
        </FormItem>
      )}
    />
  );
}
