import Page from '@app/components/layout/page/page';

import IngredientsTable from '@app/components/tables/ingredients-table/ingredients-table';
import { useGetIngredients } from '@shared/api';
import Text from '@shared/components/content/text';

export default function IngredientsPage() {
  const { data, status } = useGetIngredients();

  return (
    <Page name="Ingredients" status={status}>
      {!data || data.length <= 0 ? (
        <Text>
          You have no ingredients yet. Please create one to get started.
        </Text>
      ) : (
        <IngredientsTable ingredients={data!} />
      )}
    </Page>
  );
}
