import ProductTable from '@app/components/tables/product-table/product-table';
import { zodResolver } from '@hookform/resolvers/zod';
import { CollectionCreateUpdate, CollectionDetail } from '@shared/api/types';
import InputFormField from '@shared/components/form/input-form-field';
import TextareaFormField from '@shared/components/form/textarea-form-field';
import { Button } from '@shared/components/ui/button';
import { Form, FormMessage } from '@shared/components/ui/form';
import { useToast } from '@shared/components/ui/use-toast';
import sentry from '@shared/services/sentry';
import { RowSelectionState } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { collectionSchema } from '../schema';

interface CollectionFormProps {
  type: 'create' | 'edit';
  collectionData?: CollectionDetail;
  handleSubmit: (
    data: z.infer<typeof collectionSchema>
  ) => Promise<CollectionCreateUpdate | undefined>;
}

export default function CollectionForm({
  collectionData,
  handleSubmit,
}: CollectionFormProps) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { toast } = useToast();

  const form = useForm<z.infer<typeof collectionSchema>>({
    resolver: zodResolver(collectionSchema),
    defaultValues: {
      name: collectionData?.name || '',
      description: collectionData?.description || '',
      products: collectionData?.products || [],
    },
  });
  const { trigger, getValues, setValue, getFieldState } = form;

  const handleRowSelection = (selectedRows: RowSelectionState) => {
    const selectedRowUuids = Object.keys(selectedRows);
    setValue('products', selectedRowUuids);
  };

  const preSelectedRow = useMemo(() => {
    // any used here because the type of collectionData?.products is CollectionDetailProductsItem[] which is {[key: string]: unknown}[]
    return collectionData?.products.reduce((acc, product: any) => {
      acc[product] = true;
      return acc;
    }, {});
  }, [collectionData?.products]) as Record<string, boolean>;

  const handleUpdateCollection = async () => {
    setIsLoading(true);
    try {
      const validFields = await trigger();
      if (!validFields) return;
      await handleSubmit(getValues());
      toast({
        description: 'Collection updated successfully',
        variant: 'success',
      });
      navigate('/collections');
    } catch (error) {
      sentry.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form {...form}>
      <form className="mb-4 space-y-6">
        <InputFormField
          name="name"
          includeErrorMessage
          label="Collection name"
        />
        <TextareaFormField
          name="description"
          includeErrorMessage
          label="Collection description"
          optional
          className="resize-none"
        />
        <Button
          type="button"
          disabled={isLoading}
          className="float-right mb-10"
          loading={isLoading}
          onClick={handleUpdateCollection}
        >
          Confirm changes
        </Button>
        {getFieldState('products').error && (
          <FormMessage className="my-0">
            {getFieldState('products').error?.message}
          </FormMessage>
        )}
      </form>
      <ProductTable
        includeToolbarActions={false}
        enableRowSelection
        handleRowSelection={handleRowSelection}
        enableRowClick={false}
        preSelectedRows={preSelectedRow}
      />
    </Form>
  );
}
