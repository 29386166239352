import ProductPPTEmissionsDonutChart from '@app/components/charts/ProductPPTEmissionsDonutChart';
import ProductLifeCycleInfo from '@app/components/RecipleLifeCycleInfo';
import StatsCard from '@app/components/stats/StatsCard';
import { RECIPE_LIFE_CYCLE_INFO } from '@app/utils/constants';
import { roundNumberToNDecimalPlaces } from '@app/utils/helpers';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { ProductResult } from '@shared/api/types';
import { useOutletContext } from 'react-router-dom';
import ProductTransportTypes from '../../tabs/components/ProductTransportTypes';
import { getSeparateTotalEmissions } from './product-detail-processing';

export default function ProductDetailTransport() {
  const product: ProductResult = useOutletContext();
  const { status, transport } = product;

  const totalTransportEmissions = getSeparateTotalEmissions(
    product,
    'Transport'
  );

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          filter: status === 'expired' ? 'grayscale(100%)' : '',
        }}
      >
        <ProductLifeCycleInfo
          title="Transport"
          text={RECIPE_LIFE_CYCLE_INFO.Transport.text}
          image={RECIPE_LIFE_CYCLE_INFO.Transport.image}
        />
      </Grid>
      <Typography
        variant="h5"
        sx={{
          color: 'text.primary',
          marginTop: '1.5rem',
          marginBottom: '1.5rem',
          fontWeight: 600,
        }}
      >
        Overview
      </Typography>
      <Grid
        container
        spacing={2}
        //alignItems="flex-start"
        sx={{
          filter: status === 'expired' ? 'grayscale(100%)' : '',
        }}
      >
        <Grid item xs={12} sm={12} md={6}>
          <Box
            sx={{
              backgroundColor: 'white',
              borderRadius: '10px',
              width: '100%',
              paddingX: '1.4rem',
              paddingY: '1rem',
              minHeight: '109px',
              overflow: 'visible',
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={0.5}
            >
              <Typography
                variant="body2"
                sx={{
                  color: 'text.primary',
                  fontWeight: 'medium',
                  marginBottom: '0',
                  minHeight: '24px',
                }}
              >
                Total Emissions from Transport (gCO2<sub>e</sub>)
              </Typography>
              {/* <InfoPopOver subjectMatter="" /> */}
            </Box>
            <div
              style={{
                width: '100%',
                height: '305px',
              }}
            >
              <ProductPPTEmissionsDonutChart
                type="Transport"
                PPTTotalEmissions={totalTransportEmissions}
                productEmissionsCategoryBreakdown={[
                  {
                    label: 'Ingredients',
                    emissions: product.ingredientTransportEmissions,
                  },
                  {
                    label: 'Product',
                    emissions: transport.transportEmissions,
                  },
                ]}
              />
            </div>
          </Box>
        </Grid>
        <Grid item container spacing={2} xs={12} sm={12} md={6}>
          <Grid item xs={12} sm={12} md={12}>
            <Box>
              <StatsCard
                title="Emissions From Product Transport"
                value={
                  transport.transportEmissions !== null
                    ? roundNumberToNDecimalPlaces(
                        transport!.transportEmissions * 1000,
                        1
                      )
                    : 'N/A'
                }
                unit="gCO2e"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={12}>
            <Box>
              <StatsCard
                title="Percentage of Product's Total Transport Emissions"
                value={
                  transport.shareOfTotalEmissions !== null
                    ? roundNumberToNDecimalPlaces(
                        transport!.shareOfTotalEmissions * 100,
                        1,
                        transport!.shareOfTotalEmissions * 100 === 100
                          ? false
                          : true
                      )
                    : 'N/A'
                }
                tooltip
                unit="%"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={12}>
            <Box>
              <StatsCard
                title="Percentage of Product's Transported"
                value={
                  transport.proportionOfProductsTransported !== null
                    ? roundNumberToNDecimalPlaces(
                        transport.proportionOfProductsTransported * 100,
                        1,
                        transport.proportionOfProductsTransported * 100 === 100
                          ? false
                          : true //don't add trailing 0 if it's 100%
                      )
                    : 'N/A'
                }
                tooltip
                unit="%"
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider
            sx={{
              marginY: '1.5rem',
            }}
          />
          <Typography
            variant="h5"
            sx={{
              color: 'text.primary',
              marginTop: '1.5rem',
              marginBottom: '0.75rem',
              fontWeight: 600,
            }}
          >
            Product&apos;s Transport Types
          </Typography>

          {transport.transportTypes.map((type) => (
            <div key={type.description}>
              <ProductTransportTypes
                name={type.name}
                description={type.description}
                proportionOfProduct={type.proportionOfProduct}
                totalEmissionsPerItem={type.totalEmissionsPerItem}
                journeys={type.journeys}
                notesAndAssumptions={type.notesAndAssumptions}
              />
            </div>
          ))}
        </Grid>
      </Grid>

      {/* <NotesBox notes={transport.transportNotes} /> */}
    </Grid>
  );
}
