import { ResponsiveBar } from '@nivo/bar';
import React from 'react';
import {
  computeMaxValue,
  getColorByLifeCycleStage,
  roundNumberToNDecimalPlaces,
} from '../../utils/helpers';

import {
  Agriculture,
  Factory,
  Inventory2,
  LocalShipping,
} from '@mui/icons-material';
interface DashboardFPPTBarChartProps {
  chartLayout: 'horizontal' | 'vertical' | undefined;
  averageLifeCycleStageEmissionsPerKg: {
    lifeCycleStage: string;
    emissions: number;
  }[];
}

const DashboardFPPTBarChart = ({
  chartLayout,
  averageLifeCycleStageEmissionsPerKg,
}: DashboardFPPTBarChartProps) => {
  const maxChartValue = computeMaxValue(
    averageLifeCycleStageEmissionsPerKg,
    'emissions'
  );
  const computeMargin = () => {
    if (chartLayout === 'horizontal') {
      return { top: -5, right: 50, bottom: 50, left: 115 };
    } else {
      return { top: 25, right: 50, bottom: 45, left: 65 };
    }
  };

  const computeBarIconXPosition = (
    barX: number,
    barWidth: number,
    barSize: number,
    chartLayout: 'horizontal' | 'vertical',
    offset: number = 0
  ): number => {
    const xPos =
      chartLayout === 'horizontal'
        ? barX - 110
        : barX + barWidth / 2 - barSize / 2 - offset;
    return xPos;
  };

  const computeBarIconYPosition = (
    barY: number,
    chartLayout: 'horizontal' | 'vertical',
    offset: number = 0
  ): number => {
    const yPos = chartLayout === 'horizontal' ? barY + 5 : barY - 30 - offset;
    return yPos;
  };

  const preProcessData = (
    averageLifeCycleStageEmissionsPerKg: {
      lifeCycleStage: string;
      emissions: number;
    }[]
  ): { lifeCycleStage: string; emissions: number; color?: string }[] => {
    const result = [];
    for (let i = 0; i < averageLifeCycleStageEmissionsPerKg.length; i++) {
      result.push({
        lifeCycleStage:
          averageLifeCycleStageEmissionsPerKg[i]['lifeCycleStage'],
        emissions:
          chartLayout === 'horizontal'
            ? roundNumberToNDecimalPlaces(
                averageLifeCycleStageEmissionsPerKg[i]['emissions'] * 1000,
                1
              )
            : roundNumberToNDecimalPlaces(
                averageLifeCycleStageEmissionsPerKg[i]['emissions'],
                2
              ),
        color:
          chartLayout === 'horizontal'
            ? getColorByLifeCycleStage(
                averageLifeCycleStageEmissionsPerKg[i]['lifeCycleStage']
              )
            : '#08314c',
      });
    }
    return chartLayout === 'horizontal' ? result.reverse() : result;
  };

  const CustomBarComponent = ({ bars }: any) => {
    const size = 24;
    const images = bars.map((bar: any) => {
      return (
        <React.Fragment key={bar.key}>
          {bar.key.includes('Packaging') ? (
            <svg
              width="25"
              height="25"
              x={computeBarIconXPosition(bar.x, bar.width, size, chartLayout!)}
              y={computeBarIconYPosition(bar.y, chartLayout!)}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Inventory2 style={{ color: '#08314c' }} fontSize="large" />
            </svg>
          ) : bar.key.includes('Transport') ? (
            <svg
              width="30"
              height="30"
              x={computeBarIconXPosition(bar.x, bar.width, size, chartLayout!)}
              y={computeBarIconYPosition(bar.y, chartLayout!)}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <LocalShipping style={{ color: '#08314c' }} fontSize="large" />
            </svg>
          ) : bar.key.includes('Farming') ? (
            <svg
              width="30"
              height="26"
              x={computeBarIconXPosition(
                bar.x,
                bar.width,
                size,
                chartLayout!,
                2
              )}
              y={computeBarIconYPosition(bar.y, chartLayout!)}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Agriculture style={{ color: '#08314c' }} fontSize="large" />
            </svg>
          ) : (
            <svg
              width="28"
              height="28"
              x={computeBarIconXPosition(
                bar.x,
                bar.width,
                size,
                chartLayout!,
                2
              )}
              y={computeBarIconYPosition(bar.y, chartLayout!, 5)}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Factory style={{ color: '#08314c' }} fontSize="large" />
            </svg>
          )}
        </React.Fragment>
      );
    });
    return <g>{images}</g>;
  };

  return (
    <ResponsiveBar
      data={preProcessData(averageLifeCycleStageEmissionsPerKg)}
      keys={['emissions']}
      indexBy="lifeCycleStage"
      margin={computeMargin()}
      padding={0.4}
      valueScale={{ type: 'linear' }}
      colors={{ datum: 'data.color' }}
      layout={chartLayout}
      animate={true}
      maxValue={
        chartLayout === 'horizontal'
          ? 'auto'
          : maxChartValue + maxChartValue / 8
      }
      enableLabel={false}
      axisRight={null}
      enableGridY={chartLayout === 'horizontal' ? false : true}
      layers={[
        'grid',
        'axes',
        'bars',
        'markers',
        'legends',
        'annotations',
        CustomBarComponent,
      ]}
      tooltip={({ id, value, color, data }) => (
        <div
          style={{
            padding: 12,
            color: '#000',
            background: '#fff',
            boxShadow: '5px 3px 5px #00000030',
          }}
        >
          {chartLayout === 'horizontal' ? (
            <span>
              Total {data.lifeCycleStage} Emissions: <b>{value}</b> gCO
              <sub>2</sub>e
            </span>
          ) : (
            <span>
              Total {data.lifeCycleStage} Emissions: <b>{value}</b> kgCO
              <sub>2</sub>e/kg
            </span>
          )}
        </div>
      )}
      axisBottom={
        chartLayout === 'horizontal'
          ? {
              tickSize: 3,
              tickValues: 6,
              tickPadding: 0,
              tickRotation: 0,
              legendPosition: 'middle',
              legend: `Emissions (gCO2e)`,
              legendOffset: 30,
            }
          : {
              legendPosition: 'middle',
              legendOffset: 20,
            }
      }
      axisLeft={
        chartLayout !== 'horizontal'
          ? {
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Emissions per kg (kgCO2e/kg)',

              legendPosition: 'middle',
              legendOffset: -40,
            }
          : {}
      }
    />
  );
};

export default DashboardFPPTBarChart;
