import Page from '@app/components/layout/page/page';

import { PageHeader } from '@app/components/layout/page/components/page-header';
import { useCreateCollection } from '@shared/api';
import sentry from '@shared/services/sentry';
import { z } from 'zod';
import CollectionForm from './components/collection-form';

const createCollectionSchema = z.object({
  name: z.string().min(1, {
    message: 'Please enter a collection name',
  }),
  description: z.string().optional(),
  products: z.array(z.string()).refine((val) => val.length > 0, {
    message: 'Please select at least one product',
  }),
});

export default function CreateCollectionPage() {
  const { mutateAsync: createCollection } = useCreateCollection();

  const handleSubmit = async (data: z.infer<typeof createCollectionSchema>) => {
    try {
      const res = await createCollection({
        data: {
          name: data.name,
          description: data.description,
          products: data.products,
        },
      });
      return res;
    } catch (error) {
      sentry.log(error);
    }
  };

  return (
    <Page title="Create Collection" name="Create Collection">
      <PageHeader
        name="Create Collection"
        description="Create a 'Collection' to group your products, e.g. by brand, season, menu or site. Simply name your collection, add a description, and tick the products you would like to add to your Collection in the left hand column of the table below. Easy!"
      />
      <CollectionForm type="create" handleSubmit={handleSubmit} />
    </Page>
  );
}
