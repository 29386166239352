import { useRefreshToken } from '@app/auth/api';
import useAuth from '@app/auth/use-auth';
import useLogout from '@app/auth/use-logout';
import { AXIOS_INSTANCE } from '@shared/api/client';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useEffect, useMemo } from 'react';
import ApiValidationError from './errors';

const FAILED_AUTH_STATUSES = [401, 403];
const INVALID_TOKEN_CODE = 'token_not_valid';

const baseConfig: AxiosRequestConfig = {
  baseURL: import.meta.env.VITE_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
  responseType: 'json',
};

const getValidationDataType = (value: unknown) => {
  if (Array.isArray(value)) {
    return 'array';
  }
};

const handleError = (response: AxiosResponse) => {
  if (response.status === 400) {
    const message: string = response.data[0];
    return new ApiValidationError(message, response.data);
  }
};

export const useAppAxiosConfig = () => {
  const logout = useLogout();
  const { user, setToken, token } = useAuth();
  const { mutateAsync: refreshAccessToken } = useRefreshToken();

  useEffect(() => {
    const responseIntercept = AXIOS_INSTANCE.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error.config;
        const response: AxiosResponse = error.response;

        if (FAILED_AUTH_STATUSES.includes(response.status)) {
          const shouldTryRefresh =
            response.data?.code === INVALID_TOKEN_CODE && !prevRequest._retry;

          if (shouldTryRefresh) {
            prevRequest._retry = true;
            const token = await refreshAccessToken();
            if (token) {
              prevRequest.headers['Authorization'] = `Bearer ${token}`;
              setToken(token);
              return AXIOS_INSTANCE(prevRequest);
            }
          }

          await logout();
        }

        const handledError = handleError(response);

        return Promise.reject(handledError || error);
      }
    );
    return () => {
      AXIOS_INSTANCE.interceptors.response.eject(responseIntercept);
    };
  }, [user, logout, refreshAccessToken, setToken]);

  const config = useMemo(
    () => ({
      ...baseConfig,
      headers: {
        Authorization: token ? `Bearer ${token}` : null,
      },
    }),
    [token]
  );

  return config;
};
