import Alert from '@shared/components/alert';
import { Icons } from '@shared/components/content/icons';
import { ClassValue } from 'clsx';

interface UnassessableProductCardProps {
  className?: ClassValue;
}

export default function UnassessableProductCard({
  className,
}: UnassessableProductCardProps) {
  return (
    <Alert
      variant="info"
      title="Product cannot be instantly assessed"
      className={className}
      icon={<Icons.clock className="size-4" />}
    >
      This product contains items that need to be processed by a member of our
      team. Once submitted, our assessments team aim to review your uploaded
      files within a week and follow up with any questions as needed, before
      completing your assessment shortly after.
    </Alert>
  );
}
