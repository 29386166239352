import HubspotMeetingEmbed from '@app/components/hubspot-meeting-embed';
import { SimpleDialog } from '@app/hooks/use-simple-dialog';
import {
  CloudOutlined,
  FilterTiltShiftOutlined,
  TrendingUpOutlined,
} from '@mui/icons-material';
import { Button } from '@shared/components/ui/button';

export default function ReportsEmptyListView() {
  return (
    <div>
      <section>
        <div className="mx-auto  space-y-8 py-8 sm:pb-16 sm:pt-6 ">
          <SimpleDialog
            trigger={<Button>Book a call to get started!</Button>}
            header="Book a call to get started with reporting!"
            contentClassName="sm:max-w-4xl"
            titleClassName="px-6 pt-6 text-3xl font-bold"
            description={
              <HubspotMeetingEmbed className="mt-2 h-[664px] w-full px-6" />
            }
          />
          <div className="space-y-8 md:grid md:grid-cols-2 md:gap-6 md:space-y-0 lg:grid-cols-3">
            <div className="rounded-lg border bg-gray-00 p-6 ">
              <div className="mb-4 flex size-10 items-center justify-center rounded-full">
                <CloudOutlined />
              </div>
              <h3 className="mb-2 text-xl font-bold">Emissions Tracking</h3>
              <p className="text-gray-500 dark:text-gray-400">
                Generate reports on emissions over specific periods, integrating
                sales, consumption, and/or purchasing data.
              </p>
            </div>
            <div className="rounded-lg border bg-gray-00 p-6 ">
              <div className="mb-4 flex size-10 items-center justify-center rounded-full">
                <TrendingUpOutlined />
              </div>
              <h3 className=" mb-2 text-xl font-bold">
                Product Impact Analysis
              </h3>
              <p className="text-gray-500 dark:text-gray-400">
                Pinpoint major emission contributors, establish baselines, and
                compare across timeframes for deeper insights.
              </p>
            </div>
            <div className="rounded-lg border bg-gray-00 p-6">
              <div className="mb-4 flex size-10 items-center justify-center rounded-full">
                <FilterTiltShiftOutlined />
              </div>
              <h3 className=" mb-2 text-xl font-bold">
                Hotspots Identification
              </h3>
              <p className="text-gray-500 dark:text-gray-400">
                Identify areas of high emissions intensity to focus on reducing
                product-level emissions, aligning with net zero and reduction
                goals.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
