import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    palette: {
      primary: {
        main: string;
      };
      secondary: {
        main: string;
      };
      common: {
        white: string;
        black: string;
      };
      background: {
        paper: string;
        default: string;
      };
      text: {
        primary: string;
        secondary: string;
        disabled: string;
      };
      action: {
        active: string;
        hover: string;
      };
    };
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    laptop: true;
    desktop: true;
  }
}
declare module '@mui/material/TableCell' {
  interface TableCellPropsVariantOverrides {
    head: true;
  }
}

const Colors = {
  primaryBrand: '#08314c',
  secondaryBrand: '#5e9e44',
  darkBrand: '#031b2d',
  lightBrand: '#e4e8ec',
  text: '#161a1e',
  backgroundMain: '#F8F8F8',
};

const theme = createTheme({
  typography: {
    fontFamily: ['Inter', 'Roboto', 'system-ui', 'sans-serif'].join(','),
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
    },
  },

  palette: {
    primary: {
      main: Colors.primaryBrand,
    },
    secondary: {
      main: Colors.secondaryBrand,
    },
    background: {
      default: Colors.backgroundMain,
    },
    text: {
      primary: Colors.text,
      secondary: Colors.darkBrand,
    },
    action: {
      hover: Colors.lightBrand,
      selected: Colors.lightBrand,
      selectedOpacity: 0.1,
    },
  },
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: Colors.primaryBrand,
          padding: '0px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '10px',
        },
      },
    },

    MuiTableCell: {
      variants: [
        {
          props: { variant: 'head' },
          style: {
            color: '#fff',
            // fontWeight: "semibold",
          },
        },
      ],
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: '0.875rem',
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },
  },
});

export default theme;
