import { zodResolver } from '@hookform/resolvers/zod';
import { ProductWithRelations } from '@shared/api/types';
import {
  ingredientsSchema,
  netWeightSchema,
  processingListSchema,
  productPackagingListSchema,
  servingsSchema,
  transportListSchema,
} from '@shared/components/product-form/schema';
import { Form } from '@shared/components/ui/form';
import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import RecipeFormSection from './recipe-form-section';
import PPTFormSection from './ppt-form-section';

export const productSummaryFormSchema = z.object({
  servings: servingsSchema,
  netWeight: netWeightSchema,
  ingredients: ingredientsSchema,
  packaging: productPackagingListSchema,
  processing: processingListSchema,
  transport: transportListSchema,
});

interface ProductSummaryFormProps {
  product: ProductWithRelations;
  defaultValues: z.infer<typeof productSummaryFormSchema>;
  className?: ClassValue;
}

export default function ProductSummaryForm({
  product,
  defaultValues,
  className,
}: ProductSummaryFormProps) {
  const form = useForm<z.infer<typeof productSummaryFormSchema>>({
    resolver: zodResolver(productSummaryFormSchema),
    defaultValues: defaultValues,
  });

  return (
    <Form {...form}>
      <form className={cn('mb-4 space-y-8', className)}>
        <RecipeFormSection product={product!} />
        <PPTFormSection
          productUuid={product.uuid}
          type="processing"
          data={product.productProcessingTypes}
        />
        <PPTFormSection
          productUuid={product.uuid}
          type="packaging"
          data={product.productPackagingTypes}
        />
        <PPTFormSection
          productUuid={product.uuid}
          type="transport"
          data={product.productTransportTypes}
        />
      </form>
    </Form>
  );
}
