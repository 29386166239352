import {
  Button,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { CreateTarget } from '../../api/api';
import InfoPopOver from '../../components/InfoPopOver';
import { roundNumberToNDecimalPlaces } from '../../utils/helpers';

interface TargetMetric {
  name: string;
  targetDirection: string;
  unit: string;
  currentValues: {
    accountWide: number;
    collections: any[];
  };
}

interface Props {
  onSuccessfulSubmit: () => void;
  targetsMetrics: TargetMetric[];
}

function CreateTargetForm({ onSuccessfulSubmit, targetsMetrics }: Props) {
  const [selectedMetric, setSelectedMetric] = useState<TargetMetric | ''>('');
  const [targetValue, setTargetValue] = useState<number>(NaN); // "NaN" is MUI's default for no value in this input component
  const [error, setError] = useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedMetric(
      inferMetricByMetricName(event.target.value as string) as TargetMetric
    );
  };

  const inferMetricByMetricName = (metricName: string) => {
    let metric = null;
    for (let i = 0; i < targetsMetrics.length; i++) {
      if (targetsMetrics[i].name === metricName) {
        metric = targetsMetrics[i];
        break;
      }
    }
    return metric;
  };

  const onTargetCreate = async () => {
    if (selectedMetric && selectedMetric.name && targetValue) {
      await CreateTarget(selectedMetric.name, targetValue, null)
        .then((res) => onSuccessfulSubmit())
        .catch((err) => {
          setError(true);
        });
    }
  };

  return (
    <div style={{ maxWidth: '860px' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        I want my
        <FormControl
          style={{ marginLeft: '1rem', marginRight: '1rem', minWidth: '150px' }}
        >
          <InputLabel
            id="demo-simple-select-label"
            style={{ color: '#727272' }}
          >
            Metric
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedMetric ? selectedMetric.name : ''}
            label="Select metric"
            //placeholder="Select metric"
            onChange={handleChange}
          >
            {targetsMetrics &&
              targetsMetrics.map((metric: any) => {
                return (
                  <MenuItem key={metric.name} value={metric.name}>
                    {metric.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        to be...{' '}
        {selectedMetric && (
          <>
            {' '}
            {selectedMetric.targetDirection}
            <FormControl
              variant="standard"
              sx={{
                minWidth: '68px',
                marginLeft: '1rem',
                marginBottom: '-28px',
              }}
            >
              <Input
                id="target-value-input-field"
                type="number"
                endAdornment={
                  <InputAdornment position="end">
                    {selectedMetric.unit}
                  </InputAdornment>
                }
                aria-describedby="standard-target-value-input-field"
                inputProps={{
                  'aria-label': 'target-value',
                }}
                placeholder="Enter value"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setTargetValue(parseFloat(e.target.value))
                }
              />
              <FormHelperText
                id="standard-target-value-input-field"
                style={{
                  color: '#727272',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                Current:{' '}
                {roundNumberToNDecimalPlaces(
                  selectedMetric.currentValues.accountWide,
                  2
                )}
                {selectedMetric.unit}
                <div style={{ marginLeft: '6px' }}>
                  <InfoPopOver
                    subjectMatter="Targets - Current Value"
                    width="14px"
                  />
                </div>
              </FormHelperText>
            </FormControl>
          </>
        )}
        {/* wrap the button with handleSubmit function and pass the onSubmit handler */}
      </div>
      <Button
        type="submit"
        variant="contained"
        sx={{
          textTransform: 'none',
          borderRadius: '10px',
          float: 'right',
          marginTop: '1rem',
        }}
        onClick={onTargetCreate}
        disabled={selectedMetric === '' || isNaN(targetValue)}
      >
        Create Target
      </Button>
      {
        // display error message if something goes wrong
        error && (
          <p style={{ color: 'red' }}>
            Something went wrong. Please try again later.
          </p>
        )
      }
    </div>
  );
}

export default CreateTargetForm;
