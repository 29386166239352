import React, { useEffect } from 'react';

import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';

import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';

import useAuth from '@app/auth/use-auth';
import { PageHeader } from '@app/components/layout/page/components/page-header';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import { useGetPackaging } from '@shared/api';
import Text from '@shared/components/content/text';
import Loader from '@shared/components/loader';
import Page from '../../components/layout/page/page';
import { roundNumberToNDecimalPlaces } from '../../utils/helpers';
import PackagingPieChart from './components/PackagingPieChart';
import PackagingTable from './components/packaging-table';

export interface PackagingTypesType {
  uuid: string;
  description: string;
  noOfTimesUsed: number;
  emissionsPerProductPackaged: number;
}

export interface TotalEmissionsPerItemType {
  id: string;
  label: string;
  totalEmissionsPerItem: number;
}

const PackagingListPage = () => {
  const [select, setSelect] = React.useState('Category');

  const { user } = useAuth();
  const { data: packagingListData, isLoading } = useGetPackaging();

  const handleChange = (event: SelectChangeEvent) => {
    setSelect(event.target.value);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  if (isLoading) return <Loader />;

  return (
    <Page name="Packaging">
      <PageHeader name="Packaging" />
      {packagingListData && Object.keys(packagingListData).length === 0 ? (
        <p>You have no packaging insights yet.</p>
      ) : null}

      {packagingListData && Object.keys(packagingListData).length > 0 ? (
        <Box>
          <Grid container spacing={2}>
            <Grid item container spacing={2} xs={12} md={6}>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ color: 'text.primary' }}>
                  Overview
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box
                  sx={{
                    padding: '1rem',
                    paddingLeft: { xs: '2rem', sm: '1rem' },
                    backgroundColor: 'white',
                    display: 'flex',
                    borderRadius: '10px',
                    height: '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: { xs: 'none', sm: 'flex' },
                      borderRadius: '50%',
                      width: '45px',
                      height: '45px',
                      padding: '1rem',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mr: '1rem',
                      backgroundColor: 'background.default',
                    }}
                  >
                    <CloudQueueIcon />
                  </Box>
                  <Box>
                    <Typography variant="caption">
                      Average Emissions Per Product
                    </Typography>
                    <Box display="flex" alignItems="baseline">
                      <Typography variant="h5">
                        <b>
                          {roundNumberToNDecimalPlaces(
                            packagingListData.averageEmissionsPerRecipe * 1000,
                            1
                          )
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </b>
                      </Typography>
                      <Typography variant="body2" ml={1}>
                        gCO<sub>2</sub>e
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box
                  sx={{
                    padding: '1rem',
                    paddingLeft: { xs: '2rem', sm: '1rem' },
                    backgroundColor: 'white',
                    display: 'flex',
                    borderRadius: '10px',
                    height: '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: { xs: 'none', sm: 'flex' },
                      borderRadius: '50%',
                      width: '45px',
                      height: '45px',
                      padding: '1rem',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mr: '1rem',
                      backgroundColor: 'background.default',
                    }}
                  >
                    <AutorenewOutlinedIcon />
                  </Box>
                  <Box>
                    <Typography variant="caption">
                      Percentage Recycled
                    </Typography>
                    <Box display="flex" alignItems="baseline">
                      <Typography variant="h5">
                        <b>
                          {roundNumberToNDecimalPlaces(
                            packagingListData.averagePercentageRecycled * 100,
                            1
                          )}
                        </b>
                      </Typography>
                      <Typography variant="body2" ml={1}>
                        %
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} mt={2}>
                <Typography variant="h6" sx={{ color: 'text.primary' }}>
                  Highest Emission Materials
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <Box
                    sx={{
                      padding: '1rem',
                      paddingLeft: { xs: '2rem', sm: '1rem' },
                      backgroundColor: 'white',
                      display: 'flex',
                      borderRadius: '10px 10px 0 0',
                      height: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        display: { xs: 'none', sm: 'flex' },
                        borderRadius: '50%',
                        width: '45px',
                        height: '45px',
                        padding: '1rem',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mr: '1rem',
                        backgroundColor: 'background.default',
                      }}
                    >
                      <ListAltOutlinedIcon />
                    </Box>
                    <Box>
                      <Typography variant="caption">By Intensity</Typography>
                      {!packagingListData.highestEmissionsMaterials
                        .byIntensity ? (
                        <></>
                      ) : (
                        <>
                          <Box display="flex" alignItems="baseline">
                            <Typography variant="h6">
                              {
                                packagingListData.highestEmissionsMaterials
                                  .byIntensity.material
                              }
                            </Typography>
                            <Typography variant="body2" ml={1}>
                              -{' '}
                              {
                                packagingListData.highestEmissionsMaterials
                                  .byIntensity.materialSubCategory
                              }{' '}
                              (
                              {
                                packagingListData.highestEmissionsMaterials
                                  .byIntensity.materialSource
                              }
                              )
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="baseline">
                            <Typography variant="h5">
                              <b>
                                {roundNumberToNDecimalPlaces(
                                  packagingListData.highestEmissionsMaterials
                                    .byIntensity.emissionsPerKg,
                                  1
                                )
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              </b>
                            </Typography>
                            <Typography variant="body2" ml={1}>
                              kgCO<sub>2</sub>e / kg
                            </Typography>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box
                    sx={{
                      padding: '1rem',
                      paddingLeft: { xs: '2rem', sm: '1rem' },
                      backgroundColor: 'white',
                      display: 'flex',
                      borderRadius: '0 0 10px 10px',
                      height: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        display: { xs: 'none', sm: 'flex' },
                        borderRadius: '50%',
                        width: '45px',
                        height: '45px',
                        padding: '1rem',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mr: '1rem',
                        backgroundColor: 'background.default',
                      }}
                    >
                      <ListAltOutlinedIcon />
                    </Box>
                    <Box>
                      <Typography variant="caption">By Total</Typography>
                      {!packagingListData.highestEmissionsMaterials.byTotal ? (
                        <></>
                      ) : (
                        <>
                          <Box display="flex" alignItems="baseline">
                            <Typography variant="h6">
                              {
                                packagingListData.highestEmissionsMaterials
                                  .byTotal.material
                              }
                            </Typography>
                            <Typography variant="body2" ml={1}>
                              -{' '}
                              {
                                packagingListData.highestEmissionsMaterials
                                  .byTotal.materialSubCategory
                              }{' '}
                              (
                              {
                                packagingListData.highestEmissionsMaterials
                                  .byTotal.materialSource
                              }
                              )
                            </Typography>
                          </Box>

                          <Box display="flex" alignItems="baseline">
                            <Typography variant="h5">
                              <b>
                                {roundNumberToNDecimalPlaces(
                                  packagingListData.highestEmissionsMaterials
                                    .byTotal.totalEmissions * 1000,
                                  1
                                )
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              </b>
                            </Typography>
                            <Typography variant="body2" ml={1}>
                              gCO<sub>2</sub>e
                            </Typography>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid item container spacing={2} xs={12} md={6}>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h6" sx={{ color: 'text.primary' }}>
                  Total Emissions Per Product
                </Typography>
                <FormControl
                  variant="standard"
                  sx={{
                    m: 1,
                    minWidth: 120,
                    border: 'none',
                    underline: 'none',
                  }}
                >
                  <Select
                    value={select}
                    onChange={handleChange}
                    displayEmpty
                    disableUnderline
                    inputProps={{ 'aria-label': 'Without label' }}
                    sx={{
                      border: 'none',
                      underline: 'none',
                      height: '15px',
                      focus: 'none',
                    }}
                  >
                    {packagingListData.totalEmissionsPerItem.byCategory.length >
                    0 ? (
                      <MenuItem value="Category">By Category</MenuItem>
                    ) : null}
                    {packagingListData.totalEmissionsPerItem.byMaterial.length >
                    0 ? (
                      <MenuItem value="Material">By Material</MenuItem>
                    ) : null}
                    {packagingListData.totalEmissionsPerItem.byCollection
                      .length > 0 ? (
                      <MenuItem value="Collection">By Collection</MenuItem>
                    ) : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{
                    width: '100%',
                    height: '408px',
                    backgroundColor: 'white',
                    borderRadius: '10px',
                  }}
                >
                  {packagingListData.totalEmissionsPerItem.byCategory &&
                    select === 'Category' && (
                      <PackagingPieChart
                        totalEmissionsPerItem={
                          packagingListData.totalEmissionsPerItem.byCategory
                        }
                      />
                    )}

                  {packagingListData.totalEmissionsPerItem.byMaterial &&
                    select === 'Material' && (
                      <PackagingPieChart
                        totalEmissionsPerItem={
                          packagingListData.totalEmissionsPerItem.byMaterial
                        }
                      />
                    )}

                  {packagingListData.totalEmissionsPerItem.byCollection &&
                    select === 'Collection' && (
                      <PackagingPieChart
                        totalEmissionsPerItem={
                          packagingListData.totalEmissionsPerItem.byCollection
                        }
                      />
                    )}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <div
            className="mt-6"
            style={{
              marginBottom: '3rem',
              backgroundColor: '#F8F8F8',
              marginTop: '1.5rem',
            }}
          >
            <Text className="mb-2 text-xl font-semibold">Packaging types</Text>
            <PackagingTable
              packagingTypes={packagingListData.packagingTypes}
              includeCreateButton={user.productCategory === 'product'}
            />
          </div>
        </Box>
      ) : null}
    </Page>
  );
};

export default PackagingListPage;
