import { Grid, Typography, Box, Button } from "@mui/material";
import React from "react";

interface ProductLifeCycleInfoProps {
  title: string;
  text: string;
  image: string;
}

const ProductLifeCycleInfo: React.FC<ProductLifeCycleInfoProps> = ({
  title,
  text,
  image,
}) => {
  return (
    <>
      <Grid container alignItems="center" columnSpacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" mb={2} sx={{ color: "text.primary" }}>
            {title}
          </Typography>
          <Typography
            variant="body2"
            mb={{ xs: 1, sm: 4 }}
            sx={{ color: "text.primary" }}
          >
            {text}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              height: { xs: "250px", sm: "250px" },
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={
                new URL(`../assets/images/${image}.png`, import.meta.url).href
              }
              style={{ width: "100%" }}
              alt="product"
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ProductLifeCycleInfo;
