import { useResetPasswordConfirm } from '@app/auth/api';
import { zodResolver } from '@hookform/resolvers/zod';
import TextLink from '@shared/components/content/text-link';
import InputFormField from '@shared/components/form/input-form-field';
import Logo from '@shared/components/logo/logo';
import { Button } from '@shared/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@shared/components/ui/card';
import { Form } from '@shared/components/ui/form';
import { useToast } from '@shared/components/ui/use-toast';
import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { z } from 'zod';

const passwordResetFormSchema = z
  .object({
    newPassword1: z.string().min(8, {
      message: 'Password must be at least 8 characters',
    }),
    newPassword2: z.string(),
  })
  .refine((data) => data.newPassword1 === data.newPassword2, {
    message: 'Passwords must match',
    path: ['newPassword2'],
  });

interface PasswordResetFormProps {
  className?: ClassValue;
}

export default function PasswordResetForm({
  className,
}: PasswordResetFormProps) {
  const params = useParams();
  const uuid = params.uid;
  const token = params.token;

  const {
    mutateAsync: passwordResetConfirm,
    isLoading: isSubmitting,
    isSuccess: passwordChanged,
  } = useResetPasswordConfirm();
  const { toast } = useToast();
  const form = useForm<z.infer<typeof passwordResetFormSchema>>({
    resolver: zodResolver(passwordResetFormSchema),
  });

  const onSubmit = async (data: z.infer<typeof passwordResetFormSchema>) => {
    try {
      await passwordResetConfirm({
        uid: uuid!,
        token: token!,
        newPassword1: data.newPassword1,
        newPassword2: data.newPassword2,
      });
      toast({
        title: 'Password changed successfully',
        variant: 'success',
      });
    } catch (error: any) {
      (error.response.status === 400 &&
        toast({
          title:
            'Please ensure your new passwords match. If this error persists please restart the process',
          variant: 'destructive',
        })) ||
        toast({
          title: 'An error occurred while changing your password',
          variant: 'destructive',
        });
    }
  };

  return (
    <Form {...form}>
      <Card
        className={cn(
          'min-w-[300px] max-w-md sm:px-6 py-6 p-4 shadow-md w-full',
          className
        )}
      >
        <CardHeader className="flex items-center">
          <Logo className="size-14" />
          <CardTitle className="text-2xl">Set new password</CardTitle>
          <CardDescription>
            {passwordChanged && (
              <p className="my-2">
                You have successfully changed your password. Please{' '}
                <TextLink to="/login" className="text-primary">
                  Login
                </TextLink>{' '}
                in with your new credentials!
              </p>
            )}
          </CardDescription>
        </CardHeader>
        <CardContent>
          {!passwordChanged ? (
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="grid gap-4">
                <InputFormField
                  name="newPassword1"
                  type="password"
                  label="Password"
                  placeholder="New password"
                  disabled={isSubmitting}
                  includeErrorMessage
                />
                <InputFormField
                  name="newPassword2"
                  type="password"
                  label="Password (again)"
                  placeholder="New password again"
                  disabled={isSubmitting}
                  includeErrorMessage
                />
                <Button type="submit" className="w-full" loading={isSubmitting}>
                  Set password
                </Button>
              </div>
            </form>
          ) : null}

          <TextLink to="/login" className="ml-auto mt-4 inline-block text-sm ">
            Return to login
          </TextLink>
        </CardContent>
      </Card>
    </Form>
  );
}
