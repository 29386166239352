import useAuth from '@app/auth/use-auth';
import Page from '@app/components/layout/page/page';
import { useSendEmailVerification } from '@shared/api';
import Text from '@shared/components/content/text';
import { Button } from '@shared/components/ui/button';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@shared/components/ui/card';
import { useToast } from '@shared/components/ui/use-toast';
import { cn } from '@shared/lib/utils';
import sentry from '@shared/services/sentry';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function CheckEmailPage() {
  const { mutateAsync: sendEmailVerification, isLoading } =
    useSendEmailVerification();
  const { toast } = useToast();
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleSendEmail = useCallback(async () => {
    try {
      await sendEmailVerification();
      toast({
        title: 'Verification email sent',
        variant: 'success',
      });
    } catch (error) {
      sentry.log(error);
      toast({
        title: 'Failed sending verification email, please try again',
        variant: 'destructive',
      });
    }
  }, [sendEmailVerification, toast]);

  useEffect(() => {
    if (user!.emailVerified) return navigate('/');
  }, [navigate, user]);

  return (
    <Page name="Check Your Email">
      <div className="flex size-full items-center justify-center p-2 md:p-0">
        <Card
          className={cn(
            'min-w-[300px] max-w-md sm:px-6 py-6 p-4 shadow-md w-full mx-auto'
          )}
        >
          <CardHeader className="flex items-center space-y-3">
            <CardTitle>Check your email</CardTitle>
            <Text className="text-center">
              We&apos;ve sent you an email to {user!.userEmail}, please follow
              the link in the email to verify for your email.
            </Text>
          </CardHeader>
          <CardContent className="mt-4 text-center">
            <div className="space-y-2">
              <Text>Not received an email?</Text>
              <Button onClick={handleSendEmail} disabled={isLoading}>
                Resend Verification Email
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </Page>
  );
}
