import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import { Button, Chip, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MUIDataTable, {
  MUIDataTableMeta,
  MUIDataTableOptions,
} from 'mui-datatables';
import React, { useState } from 'react';
import { NUMBER_TO_MONTH_MAPPING } from '../../../utils/constants';

import { Link } from 'react-router-dom';
import { roundNumberToNDecimalPlaces } from '../../../utils/helpers';

import AddIcon from '@mui/icons-material/Add';
import { TargetList } from '@shared/api/types';

interface TargetFilterableTableProps {
  targets: TargetList[]; //TODO add type
  onTargetDelete: (targetUuid: string) => void;
}

export type GeneralFilterType =
  | 'dropdown'
  | 'checkbox'
  | 'multiselect'
  | 'textField'; // needed for specifying filterType at column level (see https://github.com/gregnb/mui-datatables/issues/652)
export type DisplayFilterType = 'excluded' | true | false | undefined;

function getUserFriendlyDate(dateAsStr: string): string {
  // dateAsStr comes in as YYYY-MM-DDTHH:mm:ss.sssZ (e.g. 2022-09-20T16:06:48.558053Z)
  const year = dateAsStr.slice(0, 4);
  const month = NUMBER_TO_MONTH_MAPPING[dateAsStr.slice(5, 7)];
  const day = dateAsStr.slice(8, 10);
  return day + ' ' + month + ' ' + year;
}

export const TargetsTable: React.FC<TargetFilterableTableProps> = ({
  targets,
  onTargetDelete,
}) => {
  const [numActiveFilters, setNumActiveFilters] = useState(0);

  const computeUserFriendlyUnit = (unit: string) => {
    // if unit contains CO2e, render the 2 as a subscript but keep the rest of the unit the same (examples include units such as "kg CO2e/food" or "kg CO2e/kg" amongst others)
    if (unit.includes('CO2e')) {
      return (
        <>
          {unit.slice(0, unit.indexOf('CO2e'))}
          CO<sub>2</sub>e{unit.slice(unit.indexOf('CO2e') + 4)}
        </>
      );
    } else {
      return unit;
    }
  };

  const columns = [
    // INVISIBLE COLUMNS USED FOR NAVIGATION
    {
      name: 'uuid',
      label: 'uuid',
      options: {
        filter: false,
        display: 'excluded' as DisplayFilterType,
      },
    },
    {
      name: 'unit',
      label: 'unit',
      options: {
        filter: false,
        display: 'excluded' as DisplayFilterType,
      },
    },

    // VISIBLE COLUMNS
    {
      name: 'metricName',
      label: 'Metric',
      options: {
        filter: false,
        sort: true,
        customHeadLabelRender: () => {
          return (
            <Typography variant="body2">
              <b>Metric</b>
            </Typography>
          );
        },
      },
    },
    {
      name: 'targetValue',
      label: 'Target value',
      options: {
        filter: false,
        sort: true,

        customHeadLabelRender: () => {
          return (
            <Typography variant="body2">
              <b>Target value</b>
            </Typography>
          );
        },
        customBodyRender: (
          targetValue: number,
          tableMeta: MUIDataTableMeta
        ) => {
          const unit = tableMeta.rowData[1];
          return (
            <>
              <Typography variant="body2">
                {roundNumberToNDecimalPlaces(targetValue, 2, true)}{' '}
                <span style={{ color: '#727272' }}>
                  {computeUserFriendlyUnit(unit)}
                </span>
              </Typography>
            </>
          );
        },
        // setCellProps: () => ({ style: { textAlign: "center" }}),
      },
    },

    {
      name: 'currentValue',
      label: 'Current Value',
      options: {
        filter: false,
        sort: true,
        customHeadLabelRender: () => {
          return (
            <Typography variant="body2">
              <b>Current Value</b>
            </Typography>
          );
        },
        customBodyRender: (
          currentValue: number,
          tableMeta: MUIDataTableMeta
        ) => {
          const unit = tableMeta.rowData[1];
          return (
            <>
              <Typography variant="body2">
                {roundNumberToNDecimalPlaces(currentValue, 2, true)}{' '}
                <span style={{ color: '#727272' }}>
                  {computeUserFriendlyUnit(unit)}
                </span>
              </Typography>
            </>
          );
        },

        // setCellProps: () => ({ style: { textAlign: "center" }}),
      },
    },
    {
      name: 'isAchieved',
      label: 'Status',
      options: {
        filter: false,
        sort: true,
        customHeadLabelRender: () => {
          return (
            <Typography variant="body2">
              <b>Status</b>
            </Typography>
          );
        },
        customBodyRender: (met: string) => {
          return (
            <>
              <Chip
                icon={
                  met ? (
                    <DoneIcon sx={{ color: '#4caf50 !important' }} />
                  ) : (
                    <CloseIcon sx={{ color: '#f44336 !important' }} />
                  )
                }
                label={met ? 'Met' : 'Not met'}
                color={met ? 'success' : 'error'}
                variant="outlined"
                size="small"
                style={{
                  backgroundColor: 'transparent',
                  color: met ? '#4caf50' : '#f44336',
                  fontWeight: 'bold',
                  minWidth: '72px',
                }}
              />
            </>
          );
        },
      },
    },
    {
      name: 'createdDate',
      label: 'Creation Date',
      options: {
        filter: false,
        sort: true,
        customHeadLabelRender: () => {
          return (
            <Typography variant="body2">
              <b>Creation Date</b>
            </Typography>
          );
        },
        customBodyRender: (createdDate: string) => {
          return (
            <>
              <Typography variant="body2">
                {getUserFriendlyDate(createdDate)}
              </Typography>
            </>
          );
        },
      },
    },
    {
      name: '',
      label: '',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
          return (
            <button
              style={{
                zIndex: '100 !important',
                position: 'relative',
                minWidth: '10px',
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                //display: "none",
              }}
              onClick={() => {
                onTargetDelete(tableMeta.rowData[0]);
                //await deleteTarget(tableMeta.rowData[0]);
                //queryClient.invalidateQueries(["targets"]);

                // clear the cache for the collection that was deleted
                //queryClient.removeQueries(["targets", tableMeta.rowData[0]]);
              }}
            >
              <DeleteIcon sx={{ fontSize: '20px' }} />
            </button>
          );
        },
      },
    },
  ];

  function getMuiTheme() {
    // needed for some reason to make column headers visible
    return createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              // textAlign: 'center',
              backgroundColor: '#08314c',
              color: '#FFFFFF',
              '&:nth-of-type(1)': {
                // add border radius to top left of table
                borderTopLeftRadius: numActiveFilters === 0 ? '10px' : '0px',
              },
              '&:last-child': {
                // add border radius to top right of table
                borderTopRightRadius: numActiveFilters === 0 ? '10px' : '0px',
              },
            },
            sortAction: {
              '& path': {
                color: '#FFFFFF', // or whatever you need
              },
            },
            sortActive: {
              color: '#FFFFFF', // whatever you need
            },
          },
        },
        MUIDataTableToolbar: {
          styleOverrides: {
            icon: {
              color: '#FFFFFF',
            },
            root: {
              // color: "#f8f8f8", // temporary hacky workaround: makes title (which we don't want to see) same color as background and thus not visible
              backgroundColor: '#f8f8f8',
              paddingLeft: '0px',
              // width: "100%",
            },
          },
        },
        MUIDataTableSearch: {
          styleOverrides: {
            main: {
              marginLeft: '-1.5rem',
            },
          },
        },
        MUIDataTableFilterList: {
          styleOverrides: {
            root: {
              color: '#FFFFFF',
              backgroundColor: '#08314c',
              margin: '0px',
              paddingLeft: '8px',
              borderTopRightRadius: '10px',
              borderTopLeftRadius: '10px',
              //marginBottom: '5px',
              //marginTop: '-12px'
            },
          },
        },
        MuiSelect: {
          styleOverrides: {
            select: {
              color: '#1a253a',
            },
          },
        },
        MuiChip: {
          styleOverrides: {
            root: {
              color: '#08314c',
              fontWeight: '600',
              backgroundColor: 'rgb(180,199,229,1)',
            },
          },
        },
        MuiTablePagination: {
          styleOverrides: {
            select: {
              color: '#1a253a', // styles number at the bottom of table, for pagination
            },
          },
        },
        MuiSvgIcon: {
          styleOverrides: {
            root: {
              color: '#1a253a !important',
            },
          },
        },
        MuiTableRow: {
          styleOverrides: {
            root: {
              borderRadius: '10px',
            },
          },
        },
        /* MuiTablePagination: {
                            styleOverrides: {
                                select: {
                                    color: 'black'
                                }
                            }
                            
                        } */
      },
    });
  }

  const options: MUIDataTableOptions = {
    filterType: 'dropdown',
    selectableRows: 'none', // if not set, all rows (including header) have a checkbox next to them
    print: false,
    responsive: 'standard',
    sortOrder: {
      name: 'createdDate',
      direction: 'desc',
    },
    rowsPerPageOptions: [5, 10, 25, targets.length],
    download: false,
    filter: false,

    textLabels: {
      body: {
        noMatch: 'No matching targets',
      },
      pagination: {
        rowsPerPage: 'Targets per page:',
      },
    },
    onFilterChange: (columnChanged, filterList) => {
      let numActiveFilters = 0;
      for (let i = 0; i < filterList.length; i++) {
        numActiveFilters += filterList[i].length;
      }
      setNumActiveFilters(numActiveFilters);
    },
    customToolbar: () => {
      return (
        <Link
          to="/targets/create"
          style={{ backgroundColor: '#F8F8F8', marginLeft: '12px' }}
        >
          <Button
            variant="contained"
            style={{ backgroundColor: '#08314c' }}
            sx={{
              textTransform: 'none',
              color: 'white',
              marginRight: '-24px',
            }}
          >
            <AddIcon sx={{ mr: 1, color: 'white !important' }} />
            <Typography variant="body2" sx={{ mr: 1 }}>
              Create{' '}
            </Typography>
          </Button>
        </Link>
      );
    },
  };

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        title={''}
        data={targets}
        columns={columns}
        options={options}
      />
    </ThemeProvider>
  );
};

export default TargetsTable;
