import { Delete, OutdoorGrill, Storefront } from '@mui/icons-material';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { FaLink } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import useAuth from '../auth/use-auth';

import {
  getUserFriendlyDate,
  roundNumberToNDecimalPlaces,
} from '../utils/helpers';
import ProductFPPTBarChart from './charts/ProductFPPTBarChart';
import StatsCard from './stats/StatsCard';

interface Props {
  productResultsData?: any;
  productName: string;
}

export default function ProductPdfExport({
  productResultsData,
  productName,
}: Props) {
  const { user } = useAuth();
  const {
    assessmentDate,
    servings,
    expiryDate,
    systemBoundary,
    totalEmissions,
    totalEmissionsPerServing,
    totalEmissionsPerKg,
    emissionsCategory,
    totalIngredientWeight,
    netWeight,
    status,
    notesAndAssumptions,
  } = productResultsData;
  const organizationName = user.organizationName;

  const FPPTBarChartData = [
    {
      lifeCycleStage: 'Farming',
      ingredientsEmissions:
        productResultsData.lifeCycleStageEmissions[0].emissions,
      productEmissions: 0,
      totalEmissions: productResultsData.lifeCycleStageEmissions[0].emissions,
    },
    {
      lifeCycleStage: 'Processing',
      ingredientsEmissions: productResultsData.ingredientProcessingEmissions,
      productEmissions: productResultsData.processing.processingEmissions,
      totalEmissions: productResultsData.lifeCycleStageEmissions[1].emissions,
    },
    {
      lifeCycleStage: 'Packaging',
      ingredientsEmissions: productResultsData.ingredientPackagingEmissions,
      productEmissions: productResultsData.packaging.packagingEmissions,
      totalEmissions: productResultsData.lifeCycleStageEmissions[2].emissions,
    },
    {
      lifeCycleStage: 'Transport',
      ingredientsEmissions: productResultsData.ingredientTransportEmissions,
      productEmissions: productResultsData.transport.transportEmissions,
      totalEmissions: productResultsData.lifeCycleStageEmissions[3].emissions,
    },
  ];

  return (
    <div className="flex h-[1750px] min-h-[1750px] w-[1252px] max-w-[1252px] flex-col overflow-scroll p-20">
      <div className="flex flex-col px-24 pt-8">
        <div className="mb-24 flex items-center justify-between">
          <img
            src={
              new URL(
                `@shared/components/logo/assets/logo-name.png`,
                import.meta.url
              ).href
            }
            style={{ width: 270, height: 48 }}
            alt="cloud-rating"
          />
          <h3 className="text-[18px] font-semibold text-[#264675]">
            Product Carbon Summary
          </h3>
        </div>

        <h1 className="mb-[36px] text-4xl font-extrabold">{productName}</h1>
        <div className="flex flex-row items-center space-x-6">
          <img
            src={
              new URL(
                `../assets/labels/carbonrating-cloud-myemissions-${emissionsCategory}.png`,
                import.meta.url
              ).href
            }
            alt="carbon-label"
            style={{
              height: 260,
            }}
          />
          <div className="mt-[16px] flex flex-col space-y-[12px]">
            <p>
              <span className="font-bold">Report date</span>:{' '}
              {getUserFriendlyDate(new Date().toISOString())}
            </p>
            <p>
              <span className="font-bold">Assessment date</span>:{' '}
              {assessmentDate ? getUserFriendlyDate(assessmentDate) : 'Pending'}
            </p>
            <p>
              <span className="font-bold">Expiry date</span>:{' '}
              {getUserFriendlyDate(expiryDate)}
            </p>
            <Divider />
            <p>
              <span className="font-bold">System boundary</span>:{' '}
              {systemBoundary.slice(0, 1).toUpperCase() +
                systemBoundary.slice(1)}
            </p>

            <p>
              <span className="font-bold">Product weight</span>:{' '}
              {netWeight && totalIngredientWeight ? (
                <>
                  {roundNumberToNDecimalPlaces(totalIngredientWeight, 2)}g (net:{' '}
                  {roundNumberToNDecimalPlaces(netWeight * 1000, 2)}g)
                </>
              ) : totalIngredientWeight ? (
                <>{roundNumberToNDecimalPlaces(totalIngredientWeight, 2)}g</>
              ) : (
                <></>
              )}
            </p>

            <p>
              <span className="font-bold">Number of servings</span>:{' '}
              {servings && roundNumberToNDecimalPlaces(servings, 1)}
            </p>
          </div>
        </div>

        <div className="my-8 max-w-[1180px] text-[18px]">
          <p>
            My Emissions has assessed the carbon footprint of {organizationName}
            &apos;s {productName}, in line with the{' '}
            <span className="font-semibold">
              GHG Protocol, Product Standard
            </span>
            . A combination of supplier primary data and third-party research
            data from the My Emissions database was used in the assessment.
          </p>
          <p>
            The system boundary was {systemBoundary}, which includes the
            emissions from farming, manufacturing, packaging and transport.
            Emissions from retail, consumer storage and transport, cooking, and
            end of life are not included.
          </p>
          <p className="mt-2">
            The carbon assessment results are presented in the figure below:
          </p>
        </div>

        <Grid item xs={12} md={8} className="mt-36">
          <Grid
            container
            rowSpacing={1}
            columnSpacing={2}
            //justifyContent="center"
          >
            <Grid
              container
              columnSpacing={2}
              rowSpacing={1}
              item
              xs={12}
              sx={{ paddingTop: '0px', maxWidth: '1252px' }}
            >
              <Grid item style={{ width: '292px' }}>
                <Box>
                  <StatsCard
                    title="Total Emissions"
                    value={
                      totalEmissions !== null
                        ? roundNumberToNDecimalPlaces(totalEmissions * 1000, 1)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        : 'N/A'
                    }
                    unit="gCO2e"
                  />
                </Box>
              </Grid>
              <Grid item style={{ width: '292px' }}>
                <Box>
                  <StatsCard
                    title="Emissions Per Serving"
                    value={(totalEmissionsPerServing !== null
                      ? roundNumberToNDecimalPlaces(
                          totalEmissionsPerServing * 1000,
                          1
                        )
                      : 'N/A'
                    )
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    unit="gCO2e"
                  />
                </Box>
              </Grid>
              <Grid item style={{ width: '292px' }}>
                <Box mb="1rem">
                  <StatsCard
                    title="Carbon Intensity"
                    value={
                      totalEmissionsPerKg !== null
                        ? roundNumberToNDecimalPlaces(totalEmissionsPerKg, 2)
                        : 'N/A'
                    }
                    unit="kgCO2e/kgfood"
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} className="mt-16">
              <Box className="w-[99%] rounded-[10px] bg-gray-00 px-6 py-4">
                <Box className="mb-2 flex items-center justify-between">
                  <Typography
                    variant="body2"
                    sx={{ color: 'text.primary', fontWeight: 'medium' }}
                  >
                    Breakdown of Your Product Emissions by Life-Cycle Stage
                  </Typography>
                </Box>
                <Box className="mb-[10px] h-[300px]">
                  {status !== 'in progress' ? (
                    <ProductFPPTBarChart data={FPPTBarChartData} />
                  ) : (
                    <>
                      <Box className="flex size-full items-center justify-center bg-[#F5F5F5]">
                        <Typography
                          variant="body2"
                          sx={{ color: 'text.primary', fontWeight: 'medium' }}
                        >
                          Results are not available yet
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>
                <Stack direction="row" spacing={4}>
                  <small className="mt-[12px] text-[#A1A1A1]">
                    Not included:
                  </small>
                  <div>
                    <Stack alignItems="center" justifyContent="center">
                      <Storefront className="text-[#A1A1A1]" />
                      <small className="text-[#A1A1A1]">Store</small>
                    </Stack>
                  </div>
                  <div>
                    <Stack alignItems="center" justifyContent="center">
                      <OutdoorGrill className="text-[#A1A1A1]" />
                      <small className="text-[#A1A1A1]">Cooking</small>
                    </Stack>
                  </div>
                  <div>
                    <Stack alignItems="center" justifyContent="center">
                      <Delete className="text-[#A1A1A1]" />
                      <small className="text-[#A1A1A1]">Disposal</small>
                    </Stack>
                  </div>
                </Stack>
              </Box>
            </Grid>
          </Grid>
          {notesAndAssumptions.length > 0 && (
            <>
              <Grid item xs={12} className="mt-4">
                <Typography variant="h6" sx={{ color: 'text.primary' }}>
                  Notes and Assumptions
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box className="w-full rounded-[10px] bg-gray-00 py-2 pr-6">
                  <Typography variant="body2" sx={{ color: 'text.primary' }}>
                    <ul>
                      {notesAndAssumptions.map((note: any, index: any) => {
                        return (
                          <li key={index}>
                            <Typography variant="body2">{note}</Typography>
                          </li>
                        );
                      })}
                    </ul>
                  </Typography>
                </Box>
              </Grid>
            </>
          )}
        </Grid>

        <div className="mt-[60px] flex w-full flex-row justify-between pb-[34px]">
          <div className="font-bold text-[#274676]">
            <img
              src={
                new URL(
                  `@shared/components/logo/assets/logo-name.png`,
                  import.meta.url
                ).href
              }
              style={{ width: 270, height: 48 }}
              alt="cloud-rating"
            />
            <div className="mt-2">
              <div className="flex flex-row items-center space-x-2">
                <MdEmail style={{ marginBottom: '-12px' }} />{' '}
                <span>hello@myemissions.co</span>
              </div>
              <div className="flex flex-row items-center space-x-2">
                <FaLink style={{ marginBottom: '-12px' }} />
                <span>myemissions.co</span>
              </div>
            </div>
          </div>

          <div className="font-bold text-[#274676]">
            <p>1 Bayhead Cottage</p>
            <p>Hyde Park Corner</p>
            <p>Cinderford, England,</p>
            <p>GL14 2UD</p>
          </div>
        </div>
      </div>
    </div>
  );
}
