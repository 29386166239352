import * as React from 'react';

import { ProductComparisonCommand } from '@app/components/comparisons/components/product-comparison-command';
import { SimpleDialog } from '@app/hooks/use-simple-dialog';
import { useDeleteProduct } from '@shared/api';
import { ProductList, StatusEnum } from '@shared/api/types';
import { Button } from '@shared/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '@shared/components/ui/dropdown-menu';
import { useToast } from '@shared/components/ui/use-toast';
import { Loader2, MoreHorizontal } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface ProductTableRowActionsProps {
  product: ProductList;
}

export function ProductTableRowActions({
  product,
}: ProductTableRowActionsProps): JSX.Element {
  const [open, setOpen] = React.useState(false);
  const { toast } = useToast();

  const navigate = useNavigate();

  const product_a_uuid: string = product.uuid;
  const product_a_status: string = product.status || '';

  const { mutateAsync: deleteProduct, isLoading: isDeleting } =
    useDeleteProduct();

  const editPage = `/products/${product_a_uuid}/edit`;
  const detailsPage = `/products/${product_a_uuid}`;

  const onProductDelete = async () => {
    try {
      await deleteProduct({ productUuid: product_a_uuid });
      toast({
        description: 'Product deleted successfully.',
      });
    } catch (error) {
      console.error(error);
      toast({
        description: 'Failed to delete product.',
        variant: 'destructive',
      });
    }
  };

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="sm">
          <span className="sr-only">Open menu</span>
          <MoreHorizontal className="size-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="z-50 w-[200px]">
        <DropdownMenuLabel>Actions</DropdownMenuLabel>
        <DropdownMenuGroup
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <DropdownMenuItem
            onClick={() => {
              product_a_status === StatusEnum.draft
                ? navigate(editPage)
                : navigate(detailsPage);
            }}
          >
            Open
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => window.open(detailsPage, '_blank')}>
            Open in new tab
          </DropdownMenuItem>
          {product_a_status !== StatusEnum.draft && (
            <DropdownMenuSub>
              <DropdownMenuSeparator />
              <DropdownMenuSubTrigger>Compare</DropdownMenuSubTrigger>
              <DropdownMenuSubContent className="p-0">
                <ProductComparisonCommand
                  product_a={product_a_uuid}
                  setOpen={setOpen}
                />
              </DropdownMenuSubContent>
            </DropdownMenuSub>
          )}
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={() => navigate(editPage)}>
            Edit
          </DropdownMenuItem>
          <SimpleDialog
            trigger={
              <DropdownMenuLabel className="cursor-default rounded-sm font-normal text-destructive hover:bg-accent">
                Delete
              </DropdownMenuLabel>
            }
            header="Delete product"
            description={
              <p>
                Are you sure you want to delete this product?
                <p className="mt-2 font-bold">This action cannot be undone.</p>
              </p>
            }
            footer={
              <Button
                type="submit"
                variant="destructive"
                disabled={isDeleting}
                onClick={async () => await onProductDelete()}
              >
                Delete
                {isDeleting && (
                  <Loader2 size={14} className="ml-2 animate-spin" />
                )}
              </Button>
            }
          />
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
