// React
import * as React from "react";

// Materual UI Components
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";

// Helpers
import { roundNumberToNDecimalPlaces } from "../../../../utils/helpers";

import { ProductJourneyTypes } from "../../../../types/products";

interface ProductTransportTableProps {
  journeys: ProductJourneyTypes[];
}
const ProductTransportTable: React.FC<ProductTransportTableProps> = ({
  journeys,
}) => {
  return (
    <>
      {journeys.length === 0 ? (
        <></>
      ) : (
        <TableContainer component={Paper}>
          {/*don't render table if there's no journeys*/}
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Journey</TableCell>
                <TableCell align="left">Modes</TableCell>
                <TableCell align="right">Total Distance&nbsp;(km)</TableCell>
                <TableCell align="right">
                  Total Emissions&nbsp;(gCO<sub>2</sub>e)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {journeys.map((journey, i) => (
                <TableRow
                  key={journey.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {journey.name}
                  </TableCell>
                  <TableCell align="left" size="small">
                    {journey.modes?.map((mode, i) => (
                      <Typography key={i} variant="body2">
                        {mode}
                        {i !== journey.modes.length - 1 ? ", " : ""}
                      </Typography>
                    ))}
                  </TableCell>
                  <TableCell align="right" size="small">
                    {journey.averageDistance
                      ? roundNumberToNDecimalPlaces(journey.averageDistance, 1)
                      : roundNumberToNDecimalPlaces(journey.totalDistance, 1)}
                  </TableCell>

                  <TableCell align="right" size="small">
                    {roundNumberToNDecimalPlaces(
                      journey.totalEmissions * 1000,
                      1
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default ProductTransportTable;
