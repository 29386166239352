import RatingCloud from '@shared/components/ratings/rating-cloud';
import { Card, CardContent } from '@shared/components/ui/card';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@shared/components/ui/select';
import { useState } from 'react';
import {
  emissionsFactors,
  emissionsFactorsProps,
} from '../comparison-context-data';
import { ComparisonProduct } from '../comparison-page-visualisations';

interface ComparisonVisualisationsProps {
  productA: ComparisonProduct;
  productB: ComparisonProduct;
  emissionsSavings: number;
}

export default function ComparisonVisualisations({
  productA,
  productB,
  emissionsSavings,
}: ComparisonVisualisationsProps) {
  const [selectedContext, setSelectedContext] = useState<
    emissionsFactorsProps | undefined
  >(emissionsFactors[0]);

  return (
    <div className="mt-4 space-y-2">
      <div className="flex w-full justify-end">
        <Select
          defaultValue={emissionsFactors[0].description}
          onValueChange={(value) => {
            const selectedEmissionsFactor = emissionsFactors.find(
              (factor) => factor.description === value
            );
            setSelectedContext(selectedEmissionsFactor);
          }}
        >
          <SelectTrigger className="w-[280px]">
            <SelectValue placeholder="Select comparison context" />
          </SelectTrigger>
          <SelectContent>
            {emissionsFactors.map((factor) => {
              return (
                <SelectItem key={factor.description} value={factor.description}>
                  {factor.description}
                </SelectItem>
              );
            })}
          </SelectContent>
        </Select>
      </div>
      <Card className="flex h-80 justify-center bg-primary p-4 px-8">
        <CardContent className="flex flex-col items-center justify-center space-y-4 text-gray-00">
          <div className="mb-4 grid grid-cols-3 items-center">
            <div className="flex flex-col items-center">
              <RatingCloud rating={productA.rating} className="w-28" />
              <p className="justify-center text-center">{productA.name}</p>
            </div>
            <p className="mx-auto justify-center">v</p>
            <div className="flex flex-col items-center">
              <RatingCloud rating={productB.rating} className="w-28" />
              <p className="justify-center text-center">{productB.name}</p>
            </div>
          </div>
          <p className="text-xl">
            Choosing {productA.name} instead of {productB.name}{' '}
            {emissionsSavings > 0 ? 'emits' : 'saves'}{' '}
            <b>
              {Math.abs(emissionsSavings).toFixed(2)} kgCO
              <sub>2</sub>e
            </b>
          </p>
          {selectedContext && (
            <p className="text-lg">
              Which is the same as traveling{' '}
              <b>
                {' '}
                {(Math.abs(emissionsSavings) / selectedContext!.factor).toFixed(
                  2
                )}
                km
              </b>{' '}
              {selectedContext?.contextText}
            </p>
          )}
        </CardContent>
      </Card>
    </div>
  );
}
