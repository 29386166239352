import useAuth from '@app/auth/use-auth';
import useUpsellDialog from '@app/hooks/use-upsell-dialog';
import { useNavigate } from 'react-router-dom';

const useHandleCreateNewProductAction = () => {
  const navigate = useNavigate();
  const { showDialog, UpsellDialogComponent } = useUpsellDialog();
  const { user } = useAuth();

  const handleCreateNewProductAction = () => {
    if (
      user.organizationPlan === 'free' &&
      user.organizationProductLimit <= user.numberOfProducts
    ) {
      showDialog();
    } else {
      navigate('/products/create');
    }
  };

  return {
    handleCreateNewProductAction,
    UpsellDialogComponent,
  };
};

export default useHandleCreateNewProductAction;
