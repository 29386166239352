import Page from '@app/components/layout/page/page';
import { useNavigate } from 'react-router-dom';
import OnboardingGuideCards from './components/onboarding-guide-card';
import OnboardingGuideTemplate from './components/onboarding-guide-template';

import CollectionScreenshot from './assets/collection-screenshot.png';
import PackagingScreenshot from './assets/packaging-screenshot.png';
import ProductFormScreenshot from './assets/product-input-screenshot.png';

export default function ProductsGuide() {
  const navigate = useNavigate();

  return (
    <Page name="Products guide">
      <OnboardingGuideTemplate
        preTitle={true}
        title="Add your products"
        description="Begin by measuring the emissions of your products. Simply input your recipe, and receive comprehensive insights into its carbon impact."
        primaryActionText="Next"
        handlePrimaryAction={() => {
          return navigate('/guide/assessments');
        }}
        secondaryActionText="Back"
        handleSecondaryAction={() => {
          return navigate('/guide/welcome');
        }}
      >
        <div className="grid grid-cols-1 gap-8 text-center lg:grid-cols-3 lg:gap-4">
          <OnboardingGuideCards
            step="Add your recipe"
            image={ProductFormScreenshot}
            description="Use our AI recipe generator if you’re not sure of the exact recipe."
          />
          <OnboardingGuideCards
            step="Add packaging"
            image={PackagingScreenshot}
            description="Input your packaging information or select our standard packaging options"
          />
          <OnboardingGuideCards
            step="Organise into collections"
            image={CollectionScreenshot}
            description="Group your products to easily track the impact of individual menus or product lines."
          />
        </div>
      </OnboardingGuideTemplate>
    </Page>
  );
}
