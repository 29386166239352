export interface emissionsFactorsProps {
  type: string;
  description: string;
  factor: number;
  contextText: string;
  source: string;
}

export const emissionsFactors: emissionsFactorsProps[] = [
  {
    type: 'Road',
    description: 'Driving in an average car (size and fuel) in the UK.',
    factor: 0.2103,
    contextText: 'in an average car (size and fuel) in the UK.',
    source: 'Data source: DEFRA GHG Conversion factors 2023.',
  },
  {
    type: 'Road',
    description:
      'Driving in an average car (size and fuel) in the US / Canada.',
    factor: 0.2839,
    contextText: 'in an average car (size and fuel) in the US / Canada.',
    source: 'Assumes a car with average fuel that drives 20 miles-per-gallon.',
  },
  {
    type: 'Road',
    description: 'Driving on an average motorbike (size and fuel) in the UK.',
    factor: 0.1432,
    contextText: 'on an average motorbike (size and fuel) in the UK.',
    source: 'Data source: DEFRA GHG Conversion factors 2023.',
  },
  {
    type: 'Train',
    description: 'Travelling on national rail in the UK.',
    factor: 0.0444,
    contextText: 'on national rail in the UK.',
    source: 'Data source: DEFRA GHG Conversion factors 2023.',
  },
  {
    type: 'Train',
    description: 'Travelling on light rail or by tram in the UK.',
    factor: 0.0361,
    contextText: 'on light rail or by tram in the UK.',
    source: 'Data source: DEFRA GHG Conversion factors 2023.',
  },
  {
    type: 'Train',
    description: 'Travelling on the London Underground (UK).',
    factor: 0.0351,
    contextText: 'on the London Underground (UK).',
    source: 'Data source: DEFRA GHG Conversion factors 2023.',
  },
  {
    type: 'Train',
    description: 'Travelling on national rail in Europe.',
    factor: 0.0056,
    contextText: 'on national rail in Europe.',
    source: 'Data source: DEFRA GHG Conversion factors 2023.',
  },

  {
    type: 'Flight',
    description: 'An average passenger flying  on a UK domestic flight.',
    factor: 0.3061,
    contextText: 'as average passenger flying on a UK domestic flight.',
    source:
      'Data source: DEFRA GHG Conversion factors 2023. The emission factors includes getting the fuel to the vehicles ("well-to-tank") and the indirect emissions from greenhouse gas emissions (RF). The distance can be used &apos;as the crow flies &apos;, as the factor already includes a distance uplift of 8% to compensate for planes not flying using the most direct route.',
  },
  {
    type: 'Flight',
    description:
      'An average passenger flying on a short-haul flight to/from the UK.',
    factor: 0.2088,
    contextText:
      'as average passenger flying on a short-haul flight to/from the UK.',
    source:
      'Data source: DEFRA GHG Conversion factors 2023. The emission factors includes getting the fuel to the vehicles ("well-to-tank") and the indirect emissions from greenhouse gas emissions (RF). The distance can be used &apos;as the crow flies &apos;, as the factor already includes a distance uplift of 8% to compensate for planes not flying using the most direct route.',
  },
  {
    type: 'Flight',
    description:
      'An average passenger flying on a long-haul flight to/from the UK.',
    factor: 0.2934,
    contextText:
      'as average passenger flying on a long-haul flight to/from the UK.',
    source:
      'Data source: DEFRA GHG Conversion factors 2023. The emission factors includes getting the fuel to the vehicles ("well-to-tank") and the indirect emissions from greenhouse gas emissions (RF). The distance can be used &apos;as the crow flies &apos;, as the factor already includes a distance uplift of 8% to compensate for planes not flying using the most direct route.',
  },
  {
    type: 'Flight',
    description:
      'An average passenger flying on an international flight (not to/from UK).',
    factor: 0.1974,
    contextText:
      'as average passenger flying on an international flight (not to/from UK).',
    source:
      'Data source: DEFRA GHG Conversion factors 2023. The emission factors includes getting the fuel to the vehicles ("well-to-tank") and the indirect emissions from greenhouse gas emissions (RF). The distance can be used &apos;as the crow flies &apos;, as the factor already includes a distance uplift of 8% to compensate for planes not flying using the most direct route.',
  },
];
