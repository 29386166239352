import useLogout from '@app/auth/use-logout';
import { PageHeader } from '@app/components/layout/page/components/page-header';
import LockIcon from '@mui/icons-material/Lock';
import PaymentsIcon from '@mui/icons-material/Payments';
import { Avatar, Box, Divider, Grid, Typography } from '@mui/material';
import { Icons } from '@shared/components/content/icons';
import { Button } from '@shared/components/ui/button';
import { capitalizeFirstLetter } from '@shared/lib/utils';
import React, { useState } from 'react';
import ChangePasswordForm from '../../auth/forms/ChangePasswordForm';
import useAuth from '../../auth/use-auth';
import Page from '../../components/layout/page/page';

const SettingsPage: React.FC = () => {
  const { user } = useAuth();
  const [passwordChangeOpen, setPasswordChangeOpen] = useState(false);
  const logout = useLogout();

  const HandlePasswordChangeForm = () => {
    setPasswordChangeOpen(!passwordChangeOpen);
  };

  return (
    <Page name="Settings">
      <PageHeader title="Settings" name="Settings" />
      {user && (
        <div>
          <Grid container spacing={2} sx={{ maxWidth: 1296, width: '100%' }}>
            <Grid item xs={12} lg={6}>
              <Box
                sx={{
                  padding: 3,
                  backgroundColor: 'background.paper',
                  borderRadius: '10px',
                }}
              >
                <Box display="flex" alignItems="center" mb={3}>
                  <Avatar />
                  <Typography
                    ml={2}
                    sx={{ fontWeight: 'bold', flexGrow: 1, textAlign: 'left' }}
                  >
                    Profile
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" my={2}>
                  <Box sx={{ textAlign: 'left', flexGrow: 1 }}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      Name
                    </Typography>
                    <Typography variant="body2">{user.userName}</Typography>
                  </Box>
                </Box>
                <Divider />
                <Box display="flex" alignItems="center" my={2}>
                  <Box sx={{ textAlign: 'left', flexGrow: 1 }}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      Email
                    </Typography>
                    <Typography variant="body2">{user.userEmail}</Typography>
                  </Box>
                </Box>
                <Divider />
                <Box display="flex" my={2}>
                  <Box sx={{ textAlign: 'left', flexGrow: 1 }}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      Organisation Name
                    </Typography>
                    <Typography variant="body2">
                      {user.organizationName}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box
                sx={{
                  padding: 3,
                  backgroundColor: 'background.paper',
                  height: '100%',
                  borderRadius: '10px',
                }}
              >
                <Box display="flex" alignItems="center" mb={3}>
                  <Avatar>
                    <LockIcon />
                  </Avatar>
                  <Typography
                    ml={2}
                    sx={{ fontWeight: 'bold', flexGrow: 1, textAlign: 'left' }}
                  >
                    Security
                  </Typography>
                </Box>
                <Box display="flex" my={2}>
                  <Box sx={{ textAlign: 'left', flexGrow: 1 }}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      Password
                    </Typography>
                    <Typography variant="body2">••••••••</Typography>
                  </Box>

                  <Box>
                    <Button
                      variant="outline"
                      color="info"
                      onClick={() => setPasswordChangeOpen(!passwordChangeOpen)}
                    >
                      Change
                    </Button>
                  </Box>
                </Box>
                {passwordChangeOpen ? (
                  <ChangePasswordForm
                    HandlePasswordChangeForm={HandlePasswordChangeForm}
                  />
                ) : null}
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box
                sx={{
                  padding: 3,
                  backgroundColor: 'background.paper',
                  borderRadius: '10px',
                }}
              >
                <Box display="flex" alignItems="center" mb={3}>
                  <Avatar>
                    <PaymentsIcon />
                  </Avatar>
                  <Typography
                    ml={2}
                    sx={{ fontWeight: 'bold', flexGrow: 1, textAlign: 'left' }}
                  >
                    Current Plan
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" my={2}>
                  <Box sx={{ textAlign: 'left', flexGrow: 1 }}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      Plan Name
                    </Typography>
                    <Typography variant="body2">
                      {capitalizeFirstLetter(user.organizationPlan)}
                    </Typography>
                  </Box>
                </Box>
                <Divider />
                <Box display="flex" alignItems="center" my={2}>
                  <Box sx={{ textAlign: 'left', flexGrow: 1 }}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      Product Limit
                    </Typography>
                    <Typography variant="body2">
                      {user.organizationProductLimit ?? 'Unlimited'}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Button
            variant={'secondary'}
            className="mt-6 hover:bg-primary-700 hover:text-primary-50"
            icon={<Icons.logOut />}
            onClick={logout}
          >
            Log out
          </Button>
        </div>
      )}
    </Page>
  );
};

export default SettingsPage;
