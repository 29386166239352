import Page from '@app/components/layout/page/page';
import Text from '@shared/components/content/text';
import CheckboxMultipleFormField from '@shared/components/form/checkbox-multiple-form-field';
import { Button } from '@shared/components/ui/button';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@shared/components/ui/card';
import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { organizationAimsOptions } from './company-form-options';

interface CompanyAimsPageProps {
  className?: ClassValue;
}

export default function CompanyAimsPage({ className }: CompanyAimsPageProps) {
  const { trigger, getValues, handleSubmit } = useFormContext();
  const navigate = useNavigate();
  const context: any[] = useOutletContext();

  const [onSubmit, completingSignup] = context;

  useEffect(() => {
    if (!getValues('countryUuid')) {
      return navigate('/sign-up/about-company');
    }
  });

  return (
    <Page name="Company aims">
      <div className="flex size-full items-center justify-center p-2 md:p-0">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card
            className={cn(
              'min-w-[300px] max-w-md sm:px-6 py-6 p-4 shadow-md w-full mx-auto',
              className
            )}
          >
            <CardHeader className="flex items-center space-y-3">
              <CardTitle>Your aims</CardTitle>
              <Text className="text-center">
                Finally, please tell us what you&apos;re looking to achieve with
                My Emissions. Select all that apply.
              </Text>
            </CardHeader>
            <CardContent className="mt-4">
              <div className="grid gap-4">
                <CheckboxMultipleFormField
                  name="organizationAims"
                  options={organizationAimsOptions}
                />

                <div className="mt-4 flex justify-between">
                  <Button
                    type="button"
                    variant="secondary"
                    className="mr-auto"
                    disabled={completingSignup}
                    onClick={() => navigate('/sign-up/about-company')}
                  >
                    Back
                  </Button>
                  <Button
                    type="submit"
                    className="ml-auto"
                    onClick={() => trigger()}
                    loading={completingSignup}
                    disabled={completingSignup}
                  >
                    Complete signup
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        </form>
      </div>
    </Page>
  );
}
