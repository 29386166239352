import Page from '@app/components/layout/page/page';
import { useGetCountries } from '@shared/api';
import Text from '@shared/components/content/text';
import SelectFormField from '@shared/components/form/select-form-field';
import Loader from '@shared/components/loader';
import { Button } from '@shared/components/ui/button';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@shared/components/ui/card';
import { cn } from '@shared/lib/utils';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  organizationCategoryOptions,
  organizationNumProductsRangeOptions,
} from './company-form-options';

export default function AboutCompanyPage() {
  const { trigger } = useFormContext();
  const navigate = useNavigate();
  const { data: countries, isLoading: loadingCountries } = useGetCountries();

  const formattedCountries = useMemo(() => {
    return countries?.map((country) => ({
      value: country.uuid,
      label: country.name,
    })) as { value: string; label: string }[];
  }, [countries]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isValid = await trigger([
      'countryUuid',
      'organizationCategory',
      'organizationNumProductsRange',
    ]);

    if (isValid) navigate('/sign-up/company-aims');
  };

  if (loadingCountries) return <Loader size="md" />;

  return (
    <Page name="About company">
      <div className="flex size-full items-center justify-center p-2 md:p-0">
        <form onSubmit={onSubmit}>
          <Card
            className={cn(
              'min-w-[300px] max-w-md sm:px-6 py-6 p-4 shadow-md w-full mx-auto'
            )}
          >
            <CardHeader className="flex items-center space-y-3">
              <CardTitle>About your company</CardTitle>
              <Text className="text-center">
                Tell us about your company, so we can optimise the experience
                for your business
              </Text>
            </CardHeader>
            <CardContent className="mt-4">
              <div className="grid gap-4">
                <SelectFormField
                  name="organizationCategory"
                  label="Type of company"
                  options={organizationCategoryOptions}
                />
                <SelectFormField
                  name="countryUuid"
                  label="Which country do you primarily operate in?"
                  options={formattedCountries}
                />
                <SelectFormField
                  name="organizationNumProductsRange"
                  label="How many products do you have in total?"
                  options={organizationNumProductsRangeOptions}
                />
                <Button type="submit" className="ml-auto">
                  Next
                </Button>
              </div>
            </CardContent>
          </Card>
        </form>
      </div>
    </Page>
  );
}
