import { EmissionsCategoryEnum, StatusEnum } from '@shared/api/types';
import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';
import ratingA from './clouds/cloud-a.svg';
import ratingB from './clouds/cloud-b.svg';
import ratingC from './clouds/cloud-c.svg';
import ratingD from './clouds/cloud-d.svg';
import ratingE from './clouds/cloud-e.svg';
import inProgress from './clouds/cloud-only-progress.svg';

export interface RatingCloudProps {
  rating?: EmissionsCategoryEnum | null;
  status?: StatusEnum;
  className?: ClassValue;
}

const RatingCloud = ({ rating, status, className }: RatingCloudProps) => {
  let imageSrc;

  switch (rating) {
    case null:
      imageSrc = inProgress;
      break;
    case 'A':
      imageSrc = ratingA;
      break;
    case 'B':
      imageSrc = ratingB;
      break;
    case 'C':
      imageSrc = ratingC;
      break;
    case 'D':
      imageSrc = ratingD;
      break;
    case 'E':
      imageSrc = ratingE;
      break;
    default:
      imageSrc = inProgress; // default image or leave it blank
      break;
  }

  return (
    <img
      src={imageSrc}
      className={cn(
        'w-10',
        {
          'w-12': rating,
          'grayscale filter': status === 'expired',
        },
        className
      )}
      alt={`Rating: ${rating}`}
    />
  );
};

export default RatingCloud;
