import { Box, Typography } from "@mui/material";
import ProductPackagingTable from "./ProductPackagingTable";

// Temporary import of mock data to be able to see UI without making too many changes (TO BE DELETE)
import NotesBox from "../../../../components/NotesBox";
import { roundNumberToNDecimalPlaces } from "../../../../utils/helpers";

interface ProductPackagingTypesProps {
  description: string;
  proportionOfProduct?: number;
  totalEmissionsPerItem?: number;
  items: {
    description: string;
    category: string;
    weight: number;
    proportionFromRecycledSources: number;
    emissionsPerKg: number;
    numberOfItemsContained: number;
    totalEmissionsPerItem: number;
  }[];
  notesAndAssumptions: string[];
}

const ProductPackagingTypes: React.FC<ProductPackagingTypesProps> = ({
  description,
  proportionOfProduct,
  totalEmissionsPerItem,
  items,
  notesAndAssumptions,
}) => {
  return (
    <Box mt={2} mb={6}>
      <Box
        display="flex"
        sx={{
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "start", md: "center" },
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "text.primary",
            marginBottom: "0.5rem",
            fontSize: "1.125rem",
          }}
        >
          {description}{" "}
          {proportionOfProduct ? (
            <>
              (
              <strong>
                {roundNumberToNDecimalPlaces(
                  proportionOfProduct * 100,
                  1,
                  proportionOfProduct * 100 === 100 ? false : true
                )}
                % {/* don't add trailing 0 if it's 100% */}
              </strong>{" "}
              of products)
            </>
          ) : (
            <></>
          )}
        </Typography>
        {totalEmissionsPerItem && (
          <Typography
            variant="body1"
            sx={{ color: "text.primary", marginBottom: "0.5rem" }}
          >
            Total Emissions per Product:{" "}
            <strong>
              {roundNumberToNDecimalPlaces(totalEmissionsPerItem! * 1000, 1)}{" "}
              gCO
              <sub>2</sub>e
            </strong>
          </Typography>
        )}
      </Box>
      <ProductPackagingTable items={items} />
      {notesAndAssumptions.length !== 0 ? (
        <NotesBox notes={notesAndAssumptions} />
      ) : null}
    </Box>
  );
};

export default ProductPackagingTypes;
