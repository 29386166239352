import { PackagingType } from '@shared/api/types';
import { DateCell } from '@shared/components/data-table/cells/date-cell';
import { HeaderCell } from '@shared/components/data-table/cells/header-cell';
import { NumberCell } from '@shared/components/data-table/cells/number-cell';
import { DataTable } from '@shared/components/data-table/data-table';
import { DataTableToolbar } from '@shared/components/data-table/data-table-toolbar';
import { useDataTable } from '@shared/components/data-table/hooks/use-data-table';
import { DataTableFilterField } from '@shared/components/data-table/types';
import { Button } from '@shared/components/ui/button';
import { ColumnDef } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';

interface PackagingTableProps {
  packagingTypes: PackagingType[];
  includeCreateButton?: boolean;
}

function getColumns(): ColumnDef<PackagingType>[] {
  return [
    {
      accessorKey: 'uuid',
    },
    {
      accessorKey: 'name',
      header: ({ column }) => (
        <HeaderCell
          column={column}
          name="Packaging type"
          className="text-gray-00"
        />
      ),
      cell: ({ getValue }) => {
        const name = getValue<string>();
        return <p className="min-w-[150px] max-w-[240px] truncate">{name}</p>;
      },
      enableHiding: false,
    },
    {
      accessorKey: 'createdDate',
      header: ({ column }) => (
        <HeaderCell column={column} name="Creation date" textWhite />
      ),
      cell: ({ getValue }) => {
        const date = getValue<string>();
        return (date && <DateCell date={date} />) ?? null;
      },
      enableSorting: true,
    },
    {
      accessorKey: 'numberOfTimesUsed',
      header: ({ column }) => (
        <HeaderCell column={column} name="No. of times used" textWhite />
      ),
      cell: ({ getValue }) => {
        const numberOfTimesUsed = getValue<number>();
        return <NumberCell number={numberOfTimesUsed} />;
      },
    },
    {
      accessorKey: 'emissionsPerProductPackaged',
      header: ({ column }) => (
        <HeaderCell
          column={column}
          name="Emissions per product packaged"
          textWhite
          unit={
            <span>
              (kgCO<sub>2</sub>e/kg<sub>food</sub>)
            </span>
          }
        />
      ),
      cell: ({ getValue }) => {
        const emissionsPerProductPackaged = getValue<number | null>();

        if (!emissionsPerProductPackaged) return null;

        return (
          <NumberCell number={emissionsPerProductPackaged! * 1000} rounded />
        );
      },
    },
  ] as ColumnDef<PackagingType>[];
}

export default function PackagingTable({
  packagingTypes,
  includeCreateButton = true,
}: PackagingTableProps) {
  const navigate = useNavigate();

  const filterFields: DataTableFilterField<PackagingType>[] = [
    {
      label: 'Packaging type',
      value: 'name',
      placeholder: 'Search packaging...',
    },
  ];
  const { table } = useDataTable({
    columns: getColumns(),
    data: packagingTypes,
    columnVisibilityState: {
      uuid: false,
    },
  });

  return (
    <DataTable
      table={table}
      enablePagination
      className="bg-gray-00"
      headerConfig={{
        className: 'bg-primary',
      }}
      onRowClick={(row) => navigate(`/packaging/${row?.original.uuid}`)}
    >
      <DataTableToolbar table={table} filterFields={filterFields}>
        {includeCreateButton && (
          <Button onClick={() => navigate('/packaging/create')}>Create</Button>
        )}
      </DataTableToolbar>
    </DataTable>
  );
}
