import { PageHeader } from '@app/components/layout/page/components/page-header';
import { useGetProcessing } from '@shared/api';
import Loader from '@shared/components/loader';
import { Button } from '@shared/components/ui/button';
import { useNavigate } from 'react-router-dom';
import Page from '../../components/layout/page/page';
import ProcessingTable from './components/processing-table';

export interface Processing {
  createdDate: string;
  modifiedDate: string;
  description: string;
  notesAndAssumptions: string[];
  uuid: string;
}

const ProcessingPage: React.FC = () => {
  const { data: processingData, isLoading } = useGetProcessing();

  const navigate = useNavigate();

  return (
    <Page name="Processing">
      <PageHeader
        name="Processing"
        toolbar={
          <Button onClick={() => navigate('/processing/create')}>Create</Button>
        }
      />
      <div style={{ marginBottom: '3rem' }}>
        {isLoading && <Loader />}
        {processingData && processingData.length < 1 && (
          <p>You have no processing types yet. Please create some.</p>
        )}

        {processingData && processingData.length > 0 && (
          <ProcessingTable processing={processingData} />
        )}
      </div>
    </Page>
  );
};

export default ProcessingPage;
