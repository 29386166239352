import { boolean, object, string, z } from 'zod';

export const userSignupSchema = object({
  firstName: string({
    required_error: 'First name is required',
  })
    .min(1, {
      message: 'First name is required',
    })
    .trim(),
  lastName: string({
    required_error: 'Last name is required',
  })
    .min(1, {
      message: 'Last name is required',
    })
    .trim(),
  organizationName: string({
    required_error: 'Company name is required',
  })
    .trim()
    .min(1, {
      message: 'Company name is required',
    }),
  email: z
    .string({
      required_error: 'Email is required',
    })
    .email({
      message: 'Invalid email address',
    })
    .trim(),
  password: z
    .string()
    .min(8, {
      message: 'Password must be at least 8 characters',
    })
    .superRefine((value, ctx) => {
      value.includes(' ') &&
        ctx.addIssue({
          message: 'Password must not contain spaces',
          code: z.ZodIssueCode.custom,
        });

      !(value.match(/[a-z]/) && value.match(/[A-Z]/) && value.match(/[0-9]/)) &&
        ctx.addIssue({
          message:
            'Password must contain at least one uppercase letter, one lowercase letter, and one number',
          code: z.ZodIssueCode.custom,
        });
    }),

  termsAndConditions: boolean({
    required_error: 'You must agree to the terms and conditions',
  }).refine((value) => value === true, {
    message: 'You must agree to the terms and conditions',
  }),
  consentsToMarketing: boolean().default(false),
  phoneNumber: string()
    .min(1, {
      message: 'Phone number is required',
    })
    .superRefine((value, ctx) => {
      if (!value.match(/^\+?[\d\s\-()]{7,15}$/)) {
        ctx.addIssue({
          message: 'Please enter a valid phone number',
          code: z.ZodIssueCode.custom,
        });
      }
    }),
});

export const aboutCompanyFormSchema = object({
  countryUuid: string().min(1, {
    message: 'Country is required',
  }),
  organizationCategory: string().min(1, {
    message: 'Type of company is required',
  }),
  organizationNumProductsRange: string().min(1, {
    message: 'Number of products is required',
  }),
  organizationAims: string().array().min(1, {
    message: 'At least one aim is required',
  }),
});

export type AboutCompanyFormInput = z.infer<typeof aboutCompanyFormSchema>;
export type UserSignupFormInput = z.infer<typeof userSignupSchema>;
