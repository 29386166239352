import ProductFPPTBarChart from '@app/components/charts/ProductFPPTBarChart';
import { useAssessProduct } from '@shared/api';
import Text from '@shared/components/content/text';
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@shared/components/ui/sheet';
import sentry from '@shared/services/sentry';
import { useMemo } from 'react';
import { Icons } from './content/icons';
import RatingsThresholdSlider from './ratings-thresholds-slider';
import RatingCloud from './ratings/rating-cloud';
import { Alert } from './ui/alert';
import { Button } from './ui/button';
import { Separator } from './ui/separator';
import { Skeleton } from './ui/skeleton';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './ui/tooltip';

interface AssessmentPreviewSheetProps {
  productUuid: string;
  disabled?: boolean;
}

export default function AssessmentPreviewSheet({
  productUuid,
  disabled,
}: AssessmentPreviewSheetProps) {
  const {
    mutateAsync: assessProduct,
    data,
    isLoading,
    isError,
  } = useAssessProduct();

  const formatFPPTData = useMemo(() => {
    if (!data) return [];

    return [
      {
        lifeCycleStage: 'Farming',
        ingredientsEmissions: data.ingredientFarmingEmissions,
        productEmissions:
          data.totalFarmingEmissions - data.ingredientFarmingEmissions,
        totalEmissions: data.totalFarmingEmissions,
      },
      {
        lifeCycleStage: 'Processing',
        ingredientsEmissions: data.ingredientProcessingEmissions,
        productEmissions:
          data.totalProcessingEmissions - data.ingredientProcessingEmissions,
        totalEmissions: data.totalProcessingEmissions,
      },
      {
        lifeCycleStage: 'Packaging',
        ingredientsEmissions: data.ingredientPackagingEmissions,
        productEmissions:
          data.totalPackagingEmissions - data.ingredientPackagingEmissions,
        totalEmissions: data.totalPackagingEmissions,
      },
      {
        lifeCycleStage: 'Transport',
        ingredientsEmissions: data.ingredientTransportEmissions,
        productEmissions:
          data.totalTransportEmissions - data.ingredientTransportEmissions,
        totalEmissions: data.totalTransportEmissions,
      },
    ];
  }, [data]);

  const handlePreviewAssessment = async () => {
    try {
      await assessProduct({
        productUuid: productUuid,
        params: {
          preview: true,
        },
      });
    } catch (error) {
      sentry.log(error);
    }
  };

  if (disabled) {
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <Button variant="outline" className="mr-2" disabled={disabled}>
              Preview assessment
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <Text variant="body" className="text-sm">
              Instant preview assessment is not available for this product
            </Text>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }

  return (
    <Sheet
      onOpenChange={(open) => {
        if (open) {
          handlePreviewAssessment();
        }
      }}
    >
      <SheetTrigger asChild disabled={disabled}>
        <Button variant="outline" className="mr-2">
          Preview assessment
        </Button>
      </SheetTrigger>
      <SheetContent className="space-y-2 overflow-scroll">
        <div className="flex h-full flex-col space-y-4 p-2 ">
          <SheetHeader className="space-y-2">
            <SheetTitle className="mt-2 flex flex-row items-center">
              Preview assessment
            </SheetTitle>
          </SheetHeader>

          {data && !isLoading && (
            <>
              <div className="flex w-full flex-col items-center p-2">
                <RatingCloud
                  rating={data.emissionsCategory}
                  className="mb-6 mt-2 h-[80px] w-[135px]"
                />
                <div className="mb-10 w-full px-2">
                  <RatingsThresholdSlider
                    value={parseFloat(data.totalEmissionsPerKg.toFixed(2))}
                    methodologyVersion={2}
                    showValue={true}
                  />
                </div>
                <div className="flex w-full flex-row justify-between">
                  <div className="flex flex-col items-center space-y-1">
                    <Text>Total Emissions</Text>
                    <div className="text-center">
                      <Text variant="heroValue">
                        {data.totalEmissions.toFixed(2)}
                      </Text>
                      <Text variant="unit">
                        kgCO<sub>2</sub>e
                      </Text>
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center space-y-1">
                    <Text>Carbon Intensity</Text>
                    <div className="text-center">
                      <Text variant="heroValue">
                        {data.totalEmissionsPerKg.toFixed(2)}
                      </Text>
                      <Text variant="unit">
                        kgCO<sub>2</sub>e / kg<sub>food</sub>
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
              <Separator />
              <div className="flex min-h-[300px] w-full flex-col items-center justify-center">
                <Text className="mb-2">Emissions by life-cycle stage</Text>
                <div className="h-72 w-full">
                  <ProductFPPTBarChart
                    data={formatFPPTData}
                    marginRight={20}
                    marginLeft={70}
                    includeCustomBarComponent={false}
                  />
                  <Alert className="mt-2 px-3 py-2">
                    <Text
                      variant="subtle"
                      className="flex items-center text-xs"
                    >
                      <Icons.info className="mr-2 size-4 shrink-0" />
                      Ingredient emissions can include packaging, processing and
                      transport, as well as farming.
                    </Text>
                  </Alert>
                </div>
              </div>
            </>
          )}
          {isLoading && (
            <>
              <Skeleton className="h-32 w-full" />
              <Skeleton className="h-32 w-full" />
              <Separator />
              <Skeleton className="h-64 w-full" />
              <Skeleton className="h-24 w-full" />
            </>
          )}
          {isError && (
            <Text className=" text-error-500">
              An error occurred while previewing this assessment. Please refresh
              and try again or contact us if the issue persists.
            </Text>
          )}
        </div>
      </SheetContent>
    </Sheet>
  );
}
