import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { Link } from 'react-router-dom';
import { getCountriesData, getFoodsData } from '../../api/api';
import Page from '../../components/layout/page/page';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import CreateIngredientForm from './CreateIngredientForm';

export interface Country {
  name: string;
  iso_code: string;
}

function CreateIngredientPage() {
  const axiosPrivate = useAxiosPrivate();

  const [displaySuccess, setDisplaySuccess] = React.useState<boolean>(false);

  const { data: countries } = useQuery({
    queryKey: ['countries'],
    queryFn: getCountriesData,
    staleTime: 1000 * 60 * 60 * 24,
  });

  const { data: foods } = useQuery({
    queryKey: ['foods'],
    queryFn: getFoodsData,
    staleTime: 1000 * 60 * 60 * 24,
  });

  return (
    <Page name="Create Ingredient">
      <Grid container spacing={4} sx={{ width: '100%' }}>
        <Grid item xs={12}>
          <Grid container sx={{ width: '100%' }}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '1rem' }}>
              <Link
                color="inherit"
                to="/ingredients"
                style={{
                  color: '#333',
                  textDecoration: 'none',
                }}
              >
                <Typography variant="body2">Ingredients</Typography>
              </Link>
              <Typography variant="body2">Create Ingredient</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{
                marginTop: '2rem',
                color: 'text.secondary',
                fontWeight: 'bolder',
              }}
            >
              Create Ingredient
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {displaySuccess && (
            <>
              <Typography
                variant="h5"
                style={{
                  marginTop: '2rem',
                  color: 'green',
                  fontWeight: 'bolder',
                }}
              >
                Ingredient created successfully!
              </Typography>
              <Button
                type="submit"
                variant="contained"
                sx={{ textTransform: 'none', marginTop: '2rem' }}
                onClick={() => setDisplaySuccess(false)}
              >
                Create another ingredient
              </Button>
            </>
          )}
          {!displaySuccess && (!countries || !foods) && (
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                height: '100vh',
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {!displaySuccess && countries && foods && (
            <CreateIngredientForm
              countries={countries}
              foods={foods}
              onSuccessfulSubmit={() => setDisplaySuccess(true)}
            />
          )}
        </Grid>
      </Grid>
    </Page>
  );
}

export default CreateIngredientPage;
