import Page from '@app/components/layout/page/page';

import IngredientsWithInsightsTable from '@app/components/tables/ingredients-table/insights-table';
import { useGetIngredientsWithInsights } from '@shared/api';
import Text from '@shared/components/content/text';

import { Icons } from '@shared/components/content/icons';
import Loader from '@shared/components/loader';
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from '@shared/components/ui/alert';

export default function IngredientInsightsPage() {
  const { data, isLoading, isError } = useGetIngredientsWithInsights();

  if (isLoading) return <Loader />;

  if (isError)
    return (
      <Text>
        Something went wrong while loading this page. Please refresh to try
        again, or contact us if the error persists.
      </Text>
    );

  return (
    <Page name="Ingredient Insights">
      <Alert className="my-4 border-teal-400 bg-teal-50 text-teal-700">
        <Icons.info className="size-4" color="#285f59" />
        <AlertTitle className="flex  font-semibold">About insights</AlertTitle>
        <AlertDescription>
          Insights are a new way for you to learn about the emissions of your
          ingredients. The table below shows a list of ingredients with
          insights: simply click on the &quot;Insights&quot; button next to an
          ingredient&apos;s name for a deep dive into the ingredient&apos;s
          emissions.
        </AlertDescription>
      </Alert>
      <IngredientsWithInsightsTable ingredientsWithInsights={data!} />
    </Page>
  );
}
