import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { ResponsivePie } from '@nivo/pie';
import { EmissionsCategoryEnum } from '@shared/api/types';
import RatingCloud from '@shared/components/ratings/rating-cloud';
import { getColorByEmissionsCategory } from '../../utils/helpers';

interface EmissionsCategoryPieChartProps {
  averageEmissionsCategory: string;
  productEmissionsCategoryBreakdown: {
    emissionsCategory: string;
    numProducts: number;
  }[];
}

const margin = { top: 46, right: 8, bottom: 46, left: 8 };

const styles = {
  root: {
    fontFamily: 'Inter, Roboto',
    textAlign: 'center',
    height: '100%',
    minWidth: '100px',
    width: '100%',

    position: 'relative',
  },
  overlay: {
    position: 'absolute',
    top: margin.top,
    right: margin.right,
    bottom: margin.bottom,
    left: margin.left,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 96,
    color: '#000',
    textAlign: 'center',
    pointerEvents: 'none',
  },
  totalLabel: {
    fontSize: 24,
  },
};

const EmissionsCategoryPieChart = ({
  averageEmissionsCategory,
  productEmissionsCategoryBreakdown,
}: EmissionsCategoryPieChartProps) => {
  const preProcessData = (
    data: { emissionsCategory: string; numProducts: number }[]
  ): { emissionsCategory: string; value: number; color: string }[] => {
    const result = [];
    for (let i = 0; i < data.length; i++) {
      result.push({
        id: data[i]['emissionsCategory'],
        emissionsCategory: data[i]['emissionsCategory'],
        value: data[i]['numProducts'],
        color: getColorByEmissionsCategory(data[i]['emissionsCategory']),
      });
    }
    return result;
  };

  return (
    <Box sx={styles.root}>
      <ResponsivePie
        data={preProcessData(productEmissionsCategoryBreakdown)}
        margin={margin}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#161a1e"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLinkLabelsDiagonalLength={12}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: 'color',
          modifiers: [['darker', 2]],
        }}
        innerRadius={0.8}
        padAngle={2}
        cornerRadius={70}
        colors={{ datum: 'data.color' }}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.2]],
        }}
        enableArcLabels={false} // Remove Labels
        enableArcLinkLabels={true} // Remove lines
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends']}
      />
      <Box sx={styles.overlay}>
        <Box
          sx={{
            backgroundColor: '#F5F5F5',
            height: { xs: '150px', sm: '185px' },
            width: { xs: '150px', sm: '185px' },
            borderRadius: '100%',
          }}
        ></Box>
      </Box>
      <Box sx={styles.overlay}>
        <RatingCloud
          rating={averageEmissionsCategory as EmissionsCategoryEnum}
        />
        <Typography variant="caption">Avg Rating</Typography>
      </Box>
    </Box>
  );
};

export default EmissionsCategoryPieChart;
