import { useResetPassword } from '@app/auth/api';
import { zodResolver } from '@hookform/resolvers/zod';
import { CheckCircle } from '@mui/icons-material';
import TextLink from '@shared/components/content/text-link';
import InputFormField from '@shared/components/form/input-form-field';
import Logo from '@shared/components/logo/logo';
import { Button } from '@shared/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@shared/components/ui/card';
import { Form } from '@shared/components/ui/form';
import { useToast } from '@shared/components/ui/use-toast';
import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';
import { FC } from 'react';
import { useForm } from 'react-hook-form';

import { z } from 'zod';

const forgotPasswordFormSchema = z.object({
  email: z.string().email().trim(),
});

interface ForgotPasswordFormProps {
  className?: ClassValue;
}

export const ForgotPasswordForm: FC = ({
  className,
}: ForgotPasswordFormProps) => {
  const { toast } = useToast();

  const {
    mutateAsync: resetPassword,
    isLoading: isSubmitting,
    isSuccess: emailSent,
  } = useResetPassword();

  const form = useForm<z.infer<typeof forgotPasswordFormSchema>>({
    resolver: zodResolver(forgotPasswordFormSchema),
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async (data: z.infer<typeof forgotPasswordFormSchema>) => {
    try {
      await resetPassword(data);
      toast({
        title: 'Password reset link sent',
        variant: 'success',
      });
    } catch (error) {
      toast({
        title: 'An error occurred while resetting the password',
        variant: 'destructive',
      });
    }
  };
  return (
    <Form {...form}>
      <Card
        className={cn(
          'min-w-[300px] max-w-md sm:px-6 py-6 p-4 shadow-md w-full',
          className
        )}
      >
        <CardHeader className="flex items-center">
          <Logo className="size-14" />
          <CardTitle className="text-2xl">Reset your password</CardTitle>
          <CardDescription className="pt-4 text-card-foreground">
            {!emailSent ? (
              <>
                Enter your email address below. We&apos;ll email you a link to a
                page where you can easily create a new password
              </>
            ) : (
              <>
                We sent you a recovery link, please be sure to check your spam
                folder too.
              </>
            )}
          </CardDescription>
        </CardHeader>
        <CardContent>
          {!emailSent ? (
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="mb-2 grid gap-2">
                <InputFormField
                  name="email"
                  label="Email"
                  placeholder="m@example.com"
                  disabled={isSubmitting}
                  includeErrorMessage
                />

                <Button
                  type="submit"
                  className="w-full"
                  loading={isSubmitting}
                  icon={
                    emailSent && (
                      <CheckCircle className="bg-secondary text-secondary" />
                    )
                  }
                >
                  Reset password
                </Button>
              </div>
            </form>
          ) : null}
          <TextLink to="/login" className="mt-6 text-sm text-primary">
            Return to login
          </TextLink>
        </CardContent>
      </Card>
    </Form>
  );
};

export default ForgotPasswordForm;
