import { SelectItem } from '@shared/lib/types';
import Fuse from 'fuse.js';
import React from 'react';

interface UseSearchProps<T> {
  items: T[];
  searchQuery: string;
  searchKeys: (keyof T)[];
}

export const useSearch = ({
  items,
  searchQuery,
  searchKeys,
}: UseSearchProps<SelectItem>): SelectItem[] => {
  const fuse = React.useMemo(() => {
    return new Fuse(items, {
      keys: searchKeys,
      threshold: 0.1,
    });
  }, [items, searchKeys]);

  if (!searchQuery) {
    return items;
  }
  return fuse.search(searchQuery).map((result) => result.item);
};
