import { AccountResponse, PasswordResetConfirm } from '@shared/api/types';
import { useMutation, useQuery } from '@tanstack/react-query';
import { authClient } from './client';

type RefreshResponse = {
  access: string;
};

export const useLogin = () =>
  useMutation({
    mutationFn: login,
  });

export const useLogout = () =>
  useMutation({
    mutationFn: logout,
  });

export const useAccount = (token: string) => {
  return useQuery({
    queryKey: ['account'],
    queryFn: () => getAccount(token),
    enabled: !!token,
    refetchOnWindowFocus: false,
    retry: 1,
  });
};

export const useRefreshToken = () => {
  return useMutation({
    mutationFn: refreshAccessToken,
  });
};

export const useResetPassword = () => {
  return useMutation({
    mutationFn: resetPassword,
  });
};

export const useResetPasswordConfirm = () => {
  return useMutation({
    mutationFn: resetPasswordConfirm,
  });
};

const login = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const { data } = await authClient.post('/app/login/', {
    email,
    password,
  });
  return data;
};

const logout = () => authClient.post('/app/logout/');

const getAccount = async (token: string) => {
  const { data } = await authClient.get<AccountResponse>('/app/account/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

const refreshAccessToken = async () => {
  try {
    const {
      data: { access: token },
    } = await authClient.post<RefreshResponse>(`/app/token/refresh2/`);

    return token;
  } catch (error) {
    return null;
  }
};

const resetPassword = async ({ email }: { email: string }) => {
  const { data } = await authClient.post('/app/password/reset/', { email });
  return data;
};

const resetPasswordConfirm = async ({
  uid,
  token,
  newPassword1,
  newPassword2,
}: PasswordResetConfirm) => {
  const { data } = await authClient.post(
    `/app/password/reset/confirm/${uid}/${token}/`,
    {
      new_password1: newPassword1,
      new_password2: newPassword2,
      uid,
      token,
    }
  );
  return data;
};
