/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * My Emissions API
 * We calculate food carbon footprints
 * OpenAPI spec version: 1.0.0 (v1)
 */

/**
 * * `draft` - Draft
* `expired` - Expired
* `not suitable for assessment` - Not suitable for assessment
* `in progress` - In progress
* `complete` - Complete
* `editing` - Editing
 */
export type StatusEnum = typeof StatusEnum[keyof typeof StatusEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StatusEnum = {
  draft: 'draft',
  expired: 'expired',
  not_suitable_for_assessment: 'not suitable for assessment',
  in_progress: 'in progress',
  complete: 'complete',
  editing: 'editing',
} as const;
