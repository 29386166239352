import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import InfoPopOver from "../InfoPopOver";

interface Props {
  title: string;
  value: number | string | null;
  unit?: string;
  tooltip?: boolean;
}

const getUnit = (unit: string | undefined) => {
  if (unit === "gCO2e") {
    return (
      <Typography
        variant="caption"
        sx={{
          ml: "0.3rem",
          fontWeight: "bolder",
        }}
      >
        gCO<sub>2</sub>e
      </Typography>
    );
  } else if (unit === "kgCO2e/kgfood") {
    return (
      <Typography
        variant="caption"
        sx={{
          ml: "0.3rem",
          fontWeight: "bolder",
        }}
      >
        kgCO<sub>2</sub>e/kg<sub>food</sub>
      </Typography>
    );
  } else {
    return (
      <Typography
        variant="caption"
        sx={{
          ml: "0.3rem",
          fontWeight: "bolder",
        }}
      >
        {unit}
      </Typography>
    );
  }
};

// the difference in months between the current date and the date the product expires
const getExpiryDate = (title: string, date: any) => {
  if (title.includes("Expiry Date")) {
    const currentDate = new Date();
    const expiryDate = new Date(date);
    var months = (expiryDate.getFullYear() - currentDate.getFullYear()) * 12;
    months -= currentDate.getMonth();
    months += expiryDate.getMonth();
    if (months < 4) {
      return (
        <Typography
          variant="caption"
          sx={{ color: "error.light", fontWeight: "bolder" }}
        >
          {months} months left
        </Typography>
      );
    } else {
      return null;
    }
  }
};

const formatDate = (date: any) => {
  if (date !== null) {
    const dateArr = date.split(" ");
    const day = dateArr[0];
    const month = dateArr[1];
    const year = dateArr[2];
    return `${month} ${day}, ${year}`;
  } else {
    return "N/A";
  }
};

const StatsCard: React.FC<Props> = ({ title, value, unit, tooltip }) => {
  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: "10px",
        width: "100%",
        paddingX: "1.4rem",
        paddingY: "1rem",
        minHeight: "109px",
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={0.5}
      >
        <Typography
          variant="body2"
          sx={{
            color: "text.primary",
            fontWeight: "medium",
            marginBottom: "0",
            minHeight: "24px",
          }}
        >
          {title}
        </Typography>
        {tooltip ? <InfoPopOver subjectMatter={title} /> : null}
      </Box>
      <Box display="flex" alignItems="baseline" mt="0.5rem">
        <Typography
          variant={typeof value === "number" || unit ? "h5" : "h6"}
          sx={{
            color: "text.secondary",
            fontWeight: "bold",
            fontsize: "1rem",
          }}
        >
          {title.includes("Date")
            ? formatDate(value)
            : value!.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </Typography>

        {getUnit(unit)}
      </Box>
      {(typeof value === "string" && title.includes("Date")) ||
      title.includes("System")
        ? getExpiryDate(title, value)
        : null}
    </Box>
  );
};

export default StatsCard;
