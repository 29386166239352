import useAuth from '@app/auth/use-auth';
import { ProductCategoryEnum, StatusEnum } from '@shared/api/types';

export function useStatusFilterOptions(): {
  label: string;
  value: string;
}[] {
  const { user } = useAuth();

  return [
    { label: 'Draft', value: StatusEnum.draft },
    { label: 'Complete', value: StatusEnum.complete },
    ...(user.productCategory === ProductCategoryEnum.product
      ? [
          { label: 'Submitted', value: StatusEnum.in_progress },
          { label: 'Editing', value: StatusEnum.editing },
        ]
      : []),
    { label: 'Expired', value: StatusEnum.expired },
  ];
}
