import useAuth from '@app/auth/use-auth';
import Page from '@app/components/layout/page/page';

import { PageHeader } from '@app/components/layout/page/components/page-header';
import { useGetReports } from '@shared/api';
import Text from '@shared/components/content/text';
import Loader from '@shared/components/loader';
import React from 'react';
import ReportsEmptyListView from './components/reports-empty-list-view';
import ReportsTable from './components/reports-table';

const ReportsPage: React.FC = () => {
  const {
    data: reportsData,
    isLoading: isQueryLoading,
    error,
  } = useGetReports();
  const { user } = useAuth();

  if (isQueryLoading) {
    return <Loader />;
  }

  if (error)
    return (
      <Text>
        Something went wrong while loading this page. Please refresh to try
        again, or contact us if the error persists.
      </Text>
    );

  return (
    <Page name="Reports">
      <PageHeader
        name="Reports"
        description="Unlock insights, reduce emissions: simplified reporting to drive sustainable decisions and help you navigate net zero."
      />
      {user.hasReporting && reportsData && reportsData.length > 0 ? (
        <div className="space-y-10 pt-6">
          <ReportsTable reports={reportsData} />
        </div>
      ) : (
        <ReportsEmptyListView />
      )}
    </Page>
  );
};

export default ReportsPage;
