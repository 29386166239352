import { useCreateTransport, useTransportFileCreate } from '@shared/api';
import { JourneyGroupCreate } from '@shared/api/types';
import { Icons } from '@shared/components/content/icons';
import Text from '@shared/components/content/text';
import { uploadFile } from '@shared/lib/utils';
import sentry from '@shared/services/sentry';
import { useState } from 'react';
import { z } from 'zod';
import CreatePptForm, { createPptSchema } from './create-ppt-form';

const descriptionPlaceholder =
  'e.g. Sandwich transport (manufacturer to German retailer)';

const namePlaceholder = 'Enter a name for your transport step';

interface CreateTransportFormProps {
  onTransportCreated?: (data: JourneyGroupCreate) => void;
}

export default function CreateTransportForm({
  onTransportCreated,
}: CreateTransportFormProps) {
  const { mutateAsync: createTransport } = useCreateTransport();
  const { mutateAsync: confirmTransportFileUpload } = useTransportFileCreate();
  const [isLoading, setIsLoading] = useState(false);

  const handleTransportCreate = async (
    values: z.infer<typeof createPptSchema>
  ) => {
    try {
      setIsLoading(true);
      const createItemResponse = await createTransport({
        data: {
          name: values.name,
          description: values.description,
          fileName: values.file.name,
        },
      });
      const { uuid: pptItemUuid, fileUuid } = createItemResponse;

      const uploadResponse = await uploadFile({
        data: createItemResponse,
        file: values.file,
      });

      const uploadSuccessful = uploadResponse!.status === 204;

      await confirmTransportFileUpload({
        transportTypeUuid: pptItemUuid,
        transportTypeFileUuid: fileUuid,
        data: { uploadSuccessful: uploadSuccessful },
      });
      onTransportCreated?.(createItemResponse);
    } catch (error) {
      sentry.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="max-w-3xl">
      <CreatePptForm
        namePlaceholder={namePlaceholder}
        descriptionPlaceholder={descriptionPlaceholder}
        onFormSubmit={(values) => handleTransportCreate(values)}
        isLoading={isLoading}
        infoSection={<TransportInfoSection />}
      />
    </div>
  );
}

const TransportInfoSection = () => {
  return (
    <Text>
      To add a transport step, you will need to fill out the following template
      and upload it through this form:{' '}
      <Icons.paperClip className=" inline size-3.5" />{' '}
      <a
        href="
              https://myemissions-platform-production.s3.eu-west-2.amazonaws.com/template-files/transport_template.xlsx"
        rel="noopener noreferrer"
        className="underline underline-offset-2"
      >
        Transport template{' '}
      </a>
    </Text>
  );
};
