import { Box, Grid, Typography } from '@mui/material';
import InfoPopOver from '../../../components/InfoPopOver';
import EmissionsCategoryPieChart from '../../../components/charts/EmissionsCategoryPieChart';

interface ProductRatingsInterface {
  productEmissionsCategoryBreakdown: {
    emissionsCategory: string;
    numProducts: number;
  }[];
  averageEmissionsCategory: string;
}

const ProductRatings = ({
  productEmissionsCategoryBreakdown,
  averageEmissionsCategory,
}: ProductRatingsInterface) => {
  return (
    <>
      <Grid item xs={12} sm={12} md={6} mt={2}>
        <Box display="flex" alignItems="center" mb="1.3rem">
          <Typography
            variant="h6"
            sx={{ color: 'text.primary', marginRight: '0.5rem' }}
          >
            Breakdown of Product Ratings
          </Typography>
          <InfoPopOver subjectMatter="Breakdown of Product Ratings" />
        </Box>
        <div
          style={{
            height: '350px',

            width: '100%',
            backgroundColor: '#FFFFFF',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              height: '100%',
              width: { xs: '260px', sm: '100%' },
            }}
          >
            <EmissionsCategoryPieChart
              productEmissionsCategoryBreakdown={
                productEmissionsCategoryBreakdown
              }
              averageEmissionsCategory={averageEmissionsCategory}
            />
          </Box>
        </div>
      </Grid>
    </>
  );
};

export default ProductRatings;
