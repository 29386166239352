import {
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { getTargetsMetrics } from '../../api/api';
import Page from '../../components/layout/page/page';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import CreateTargetForm from './CreateTargetForm';

function CreatePackagingPage() {
  const [displaySuccess, setDisplaySuccess] = React.useState<boolean>(false);

  const { data: targetsMetrics } = useQuery({
    queryKey: ['targetsMetrics'],
    queryFn: getTargetsMetrics,
    //staleTime: 1000 * 60 * 60 * 24,
  });

  const axiosPrivate = useAxiosPrivate();

  const handleSuccessfulSubmit = () => {
    setDisplaySuccess(true);
  };

  return (
    <Page name="Create Target">
      <Grid container spacing={4} sx={{ width: '100%' }}>
        <Grid item xs={12}>
          <Grid container sx={{ width: '100%' }}>
            <Grid item xs={12}>
              <Breadcrumbs
                aria-label="breadcrumb"
                style={{ marginTop: '2rem' }}
              >
                <Link underline="hover" color="inherit" href="/targets">
                  Targets
                </Link>
                <Typography style={{ color: 'black', fontWeight: 600 }}>
                  Create Target
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                style={{
                  marginTop: '2rem',
                  color: 'text.secondary',
                  fontWeight: 'bolder',
                }}
              >
                Create Target
              </Typography>
              <p>
                Start by selecting a metric. You&apos;ll then be prompted to
                pick a target related to that metric
              </p>
              <Divider />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {displaySuccess ? (
            <>
              <Typography
                variant="h5"
                style={{
                  marginTop: '2rem',
                  color: 'green',
                  fontWeight: 'bolder',
                }}
              >
                Target created successfully!
              </Typography>
              <Button
                type="submit"
                variant="contained"
                sx={{ textTransform: 'none', marginTop: '2rem' }}
                onClick={() => setDisplaySuccess(false)}
              >
                Create another target
              </Button>
            </>
          ) : (
            <div>
              <CreateTargetForm
                onSuccessfulSubmit={handleSuccessfulSubmit}
                targetsMetrics={targetsMetrics}
              />
            </div>
          )}
        </Grid>
      </Grid>
    </Page>
  );
}

export default CreatePackagingPage;
