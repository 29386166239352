import { ColumnDef } from '@tanstack/react-table';

import { DataTable } from '@shared/components/data-table/data-table';

import { HeaderCell } from '@shared/components/data-table/cells/header-cell';

import { roundNumberToNDecimalPlaces } from '@app/utils/helpers';
import {
  EmissionsCategoryEnum,
  ReportResultOrganizationFood,
} from '@shared/api/types';
import Heading from '@shared/components/content/heading';
import { DataTableToolbar } from '@shared/components/data-table/data-table-toolbar';
import { useDataTable } from '@shared/components/data-table/hooks/use-data-table';
import { useGetStaticFilterOptions } from '@shared/components/data-table/hooks/use-get-static-filter-options';
import { DataTableFilterField } from '@shared/components/data-table/types';
import RatingCloud from '@shared/components/ratings/rating-cloud';

const formatNumericalValue = (value: number) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const columns: ColumnDef<ReportResultOrganizationFood>[] = [
  {
    id: 'name',
    accessorKey: 'name',
    header: ({ column }) => (
      <HeaderCell column={column} name="Name" textWhite />
    ),
    cell: ({ row }) => {
      return <p className="max-w-xs truncate">{row.getValue('name')}</p>;
    },
    enableHiding: false,
    enableSorting: false,
  },
  {
    id: 'totalEmissionsPerKg',
    accessorKey: 'totalEmissionsPerKg',
    header: ({ column }) => (
      <HeaderCell
        column={column}
        name={
          <span>
            Emissions Per Kg (kgCO<sub>2</sub>e/kg)
          </span>
        }
        textWhite
      />
    ),
    cell: ({ row }) => {
      return formatNumericalValue(
        roundNumberToNDecimalPlaces(
          row.getValue('totalEmissionsPerKg'),
          2,
          false
        )
      );
    },
    enableHiding: false,
    enableSorting: true,
  },
  {
    id: 'totalKg',
    accessorKey: 'totalKg',
    header: ({ column }) => (
      <HeaderCell column={column} name="Total Kg Purchased" textWhite />
    ),
    cell: ({ row }) => {
      return formatNumericalValue(
        roundNumberToNDecimalPlaces(row.getValue('totalKg'), 1)
      );
    },
    enableHiding: false,
    enableSorting: true,
  },
  {
    id: 'totalEmissions',
    accessorKey: 'totalEmissions',
    header: ({ column }) => (
      <HeaderCell
        column={column}
        name={
          <span>
            Total Emissions (kgCO<sub>2</sub>e)
          </span>
        }
        textWhite
      />
    ),
    cell: ({ row }) => {
      return formatNumericalValue(
        roundNumberToNDecimalPlaces(row.getValue('totalEmissions'), 1)
      );
    },
    enableHiding: false,
    enableSorting: true,
  },
  {
    id: 'emissionsCategory',
    accessorKey: 'emissionsCategory',
    header: ({ column }) => (
      <HeaderCell column={column} name="Rating" textWhite />
    ),
    cell: ({ row }) => {
      return <RatingCloud rating={row.getValue('emissionsCategory')} />;
    },
    enableHiding: false,
    enableSorting: false,
    filterFn: 'arrIncludesSome',
  },
];

interface ReportIngredientsTableProps {
  ingredients: ReportResultOrganizationFood[];
}

export default function ReportIngredientsTable({
  ingredients,
}: ReportIngredientsTableProps) {
  const filterFields: DataTableFilterField<ReportResultOrganizationFood>[] = [
    {
      label: 'Ingredients',
      value: 'name',
      placeholder: 'Search ingredients..',
    },
    {
      label: 'Rating',
      value: 'emissionsCategory',
      options: useGetStaticFilterOptions(EmissionsCategoryEnum),
    },
  ];

  const { table } = useDataTable({
    data: ingredients,
    columns,
    filterFields,
    defaultSorting: [
      {
        id: 'deadline',
        desc: false,
      },
    ],
  });

  return (
    <div className="space-y-4">
      <Heading variant="subtitle">Ingredients</Heading>
      <DataTable
        table={table}
        enablePagination
        className="bg-gray-00"
        headerConfig={{
          className: 'bg-primary',
        }}
      >
        <DataTableToolbar table={table} filterFields={filterFields} />
      </DataTable>
    </div>
  );
}
