import { useAnalytics } from '@app/services/analytics/AnalyticsContext';
import { EmissionsCategoryEnum } from '@shared/api/types';
import { Icons } from '@shared/components/content/icons';
import RatingCloud from '@shared/components/ratings/rating-cloud';
import { Badge } from '@shared/components/ui/badge';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@shared/components/ui/sheet';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

interface IngredientEmissionsInsightProps {
  emissionsCategory: EmissionsCategoryEnum;
  markdown: string;
  longDesc: string;
}
export default function IngredientEmissionsInsight({
  emissionsCategory,
  markdown: hotspotsMarkdown,
  longDesc,
}: IngredientEmissionsInsightProps) {
  const { track } = useAnalytics();

  return (
    <Sheet>
      <SheetTrigger
        onClick={() => {
          track('Viewed Ingredient Insight', { longDesc });
        }}
      >
        <Badge className="ml-3 rounded-md border-primary-300 bg-primary-50 hover:bg-primary-100">
          <span className=" text-xs text-primary-800">Insights</span>
          <Icons.cloud className="ml-1 size-4 text-primary-800" />
        </Badge>
      </SheetTrigger>
      <SheetContent className="space-y-2">
        <SheetHeader className="space-y-2">
          <SheetTitle className="mt-2 flex flex-row items-center">
            <RatingCloud rating={emissionsCategory} className="mr-2 w-[52px]" />
            {longDesc}
          </SheetTitle>
          <SheetDescription className="font-semibold text-muted-foreground">
            Emissions breakdown
          </SheetDescription>
        </SheetHeader>
        <div>
          <div className=" flex min-h-full flex-1 flex-col">
            <p className="mt-4 text-primary">
              <ReactMarkdown
                className="prose break-words text-sm text-primary "
                rehypePlugins={[rehypeRaw]} // does not escape HTML - Thus, something like H<sub>2</sub>O will be rendered correctly (with the subscript). See appendix A here: https://github.com/remarkjs/react-markdown
              >
                {hotspotsMarkdown}
              </ReactMarkdown>
            </p>
            <small className="pt-2 text-muted-foreground">
              Note that the information above reflects world average data for
              this food. It does not directly correspond to your specific
              suppliers or operations.
            </small>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
}
