import { PageHeader } from '@app/components/layout/page/components/page-header';
import CreatePackagingForm from '@app/components/product-form/create-ppt-forms/create-packaging-form';
import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import Page from '../../components/layout/page/page';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

function CreatePackagingPage() {
  const [displaySuccess, setDisplaySuccess] = React.useState<boolean>(false);

  const axiosPrivate = useAxiosPrivate();

  return (
    <Page name="Create Packaging">
      <PageHeader
        title="Create Packaging"
        name="Create Packaging"
        className="mb-10"
      />
      <Grid container spacing={4} sx={{ width: '100%' }}>
        <Grid item xs={12} style={{ paddingTop: '0.5rem' }}>
          {displaySuccess ? (
            <>
              <Typography
                variant="h5"
                style={{
                  marginTop: '2rem',
                  color: 'green',
                  fontWeight: 'bolder',
                }}
              >
                Packaging created successfully!
              </Typography>
              <Button
                type="submit"
                variant="contained"
                sx={{ textTransform: 'none', marginTop: '2rem' }}
                onClick={() => setDisplaySuccess(false)}
              >
                Create another packaging
              </Button>
            </>
          ) : (
            <>
              <CreatePackagingForm
                onPackagingCreated={() => setDisplaySuccess(true)}
              />
            </>
          )}
        </Grid>
      </Grid>
    </Page>
  );
}

export default CreatePackagingPage;
