import { Box, Typography } from '@mui/material';
import 'react-circular-progressbar/dist/styles.css';
import { RiDraftLine } from 'react-icons/ri';

import { AutorenewOutlined } from '@mui/icons-material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface ProductsStatusBreakdownProps {
  calculating: number;
  draft: number;
  complete: number;
  editing?: number;
}

const ProductsStatusBreakdown: React.FC<ProductsStatusBreakdownProps> = ({
  calculating,
  draft,
  complete,
  editing,
}: ProductsStatusBreakdownProps) => {
  return (
    <div className="hidden size-full flex-wrap items-center justify-between rounded-md bg-gray-00 p-6 sm:flex">
      <div className="flex min-w-32 space-x-4">
        <RiDraftLine style={{ fontSize: '22px' }} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            ml: '1rem',
          }}
        >
          <Typography variant="body2">Drafts</Typography>
          <Typography variant="h6">{draft}</Typography>
        </Box>
      </div>
      <div className="flex min-w-32  space-x-4">
        <AutorenewOutlined />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            ml: '1rem',
          }}
        >
          <Typography variant="body2">Submitted</Typography>
          <Typography variant="h6">{calculating}</Typography>
        </Box>
      </div>
      <div className="flex min-w-32  space-x-4">
        <ErrorOutlineIcon />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            ml: '1rem',
          }}
        >
          <Typography variant="body2">Editing</Typography>
          <Typography variant="h6">{editing}</Typography>
        </Box>
      </div>
      <div className="flex min-w-32 space-x-4">
        <CheckCircleOutlineOutlinedIcon />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            ml: '1rem',
          }}
        >
          <Typography variant="body2">Complete</Typography>
          <Typography variant="h6">{complete}</Typography>
        </Box>
      </div>
    </div>
  );
};

export default ProductsStatusBreakdown;
