import Navbar from '@app/components/layout/nav/navbar';
import Page from '@app/components/layout/page/page';
import { Outlet } from 'react-router-dom';

export default function OnboardingGuideLayout() {
  return (
    <Page name="Onboarding guide" page="layout">
      <div className="flex h-full min-h-screen flex-col bg-gradient-to-r from-teal-300  to-teal-100">
        <Navbar
          logo={{
            variant: 'name',
            color: 'dark',
          }}
        />
        <div className="grid flex-1 grid-cols-1 overflow-y-auto p-2">
          <Outlet />
        </div>
      </div>
    </Page>
  );
}
