import { ColumnDef } from '@tanstack/react-table';

import { DataTable } from '@shared/components/data-table/data-table';

import { HeaderCell } from '@shared/components/data-table/cells/header-cell';

import { roundNumberToNDecimalPlaces } from '@app/utils/helpers';
import { EmissionsCategoryEnum, ReportResultProduct } from '@shared/api/types';
import Heading from '@shared/components/content/heading';
import { DataTableToolbar } from '@shared/components/data-table/data-table-toolbar';
import { useDataTable } from '@shared/components/data-table/hooks/use-data-table';
import { useGetStaticFilterOptions } from '@shared/components/data-table/hooks/use-get-static-filter-options';
import { DataTableFilterField } from '@shared/components/data-table/types';
import RatingCloud from '@shared/components/ratings/rating-cloud';
import { useNavigate } from 'react-router-dom';

const formatNumericalValue = (value: number) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const columns: ColumnDef<ReportResultProduct>[] = [
  {
    id: 'name',
    accessorKey: 'name',
    header: ({ column }) => (
      <HeaderCell column={column} name="Name" textWhite />
    ),
    cell: ({ row }) => {
      return <p className="max-w-xs truncate">{row.getValue('name')}</p>;
    },
    enableHiding: false,
    enableSorting: false,
  },
  {
    id: 'totalEmissionsPerProduct',
    accessorKey: 'totalEmissionsPerProduct',
    header: ({ column }) => (
      <HeaderCell
        column={column}
        name={
          <span>
            Emissions Per Product (kgCO<sub>2</sub>e)
          </span>
        }
        textWhite
      />
    ),
    cell: ({ row }) => {
      const productStatus = row.original.status;

      if (productStatus === 'complete') {
        try {
          return formatNumericalValue(
            roundNumberToNDecimalPlaces(
              row.getValue('totalEmissionsPerProduct'),
              2,
              false
            )
          );
        } catch {
          return '';
        }
      }
      return '';
    },
    enableHiding: false,
    enableSorting: true,
  },
  {
    id: 'totalProducts',
    accessorKey: 'totalProducts',
    header: ({ column }) => (
      <HeaderCell column={column} name="Total Products Sold" textWhite />
    ),
    cell: ({ row }) => {
      const productStatus = row.original.status;
      if (productStatus === 'complete') {
        try {
          return formatNumericalValue(
            roundNumberToNDecimalPlaces(row.getValue('totalProducts'), 1)
          );
        } catch {
          return '';
        }
      }
      return '';
    },
    enableHiding: false,
    enableSorting: true,
  },
  {
    id: 'totalEmissions',
    accessorKey: 'totalEmissions',
    header: ({ column }) => (
      <HeaderCell
        column={column}
        name={
          <span>
            Total Emissions (kgCO<sub>2</sub>e)
          </span>
        }
        textWhite
      />
    ),
    cell: ({ row }) => {
      const productStatus = row.original.status;
      if (productStatus === 'complete') {
        try {
          return formatNumericalValue(
            roundNumberToNDecimalPlaces(row.getValue('totalEmissions'), 1)
          );
        } catch {
          return '';
        }
      }
      return '';
    },
    enableHiding: false,
    enableSorting: true,
  },
  {
    id: 'emissionsCategory',
    accessorKey: 'emissionsCategory',
    header: ({ column }) => (
      <HeaderCell column={column} name="Rating" textWhite />
    ),
    cell: ({ row }) => {
      const productStatus = row.original.status;
      if (productStatus === 'complete') {
        return (
          <RatingCloud
            rating={row.getValue('emissionsCategory')}
            status={productStatus}
          />
        );
      }
    },
    enableHiding: false,
    enableSorting: false,
    filterFn: 'arrIncludesSome',
  },
];

interface ProductsTableProps {
  products: ReportResultProduct[];
}

export default function ReportProductsTable({ products }: ProductsTableProps) {
  const navigate = useNavigate();

  const filterFields: DataTableFilterField<ReportResultProduct>[] = [
    {
      label: 'Products',
      value: 'name',
      placeholder: 'Search products...',
    },
    {
      label: 'Rating',
      value: 'emissionsCategory',
      options: useGetStaticFilterOptions(EmissionsCategoryEnum),
    },
  ];

  const { table } = useDataTable({
    data: products,
    columns,
    filterFields,
    defaultSorting: [
      {
        id: 'deadline',
        desc: false,
      },
    ],
  });

  return (
    <div className="space-y-4">
      <Heading variant="subtitle">Products</Heading>
      <DataTable
        table={table}
        enablePagination
        headerConfig={{
          className: 'bg-primary',
        }}
        className="bg-gray-00"
        onRowClick={(row) => {
          const uuid = row!.original.uuid;
          navigate(`/products/${uuid}`);
        }}
      >
        <DataTableToolbar table={table} filterFields={filterFields} />
      </DataTable>
    </div>
  );
}
