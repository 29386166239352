import * as React from 'react';

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@shared/components/ui/popover';

import { ProductList } from '@shared/api/types';
import { Button } from '@shared/components/ui/button';

import { ErrorBoundary } from '@sentry/react';
import { ProductComparisonCommand } from './product-comparison-command';

interface CompareDialogProps {
  product_a: ProductList['uuid'];
  disabled?: boolean;
}

export function CompareDialog({ product_a, disabled }: CompareDialogProps) {
  const [open, setOpen] = React.useState(false);

  return (
    <div className="flex items-center space-x-4">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="ghost"
            className="w-full justify-start"
            disabled={disabled}
          >
            Compare
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-0" side="right" align="start">
          <ErrorBoundary
            fallback={
              <div className="p-4 text-sm">
                Error fetching comparison products
              </div>
            }
          >
            <ProductComparisonCommand product_a={product_a} setOpen={setOpen} />
          </ErrorBoundary>
        </PopoverContent>
      </Popover>
    </div>
  );
}
