import useSimpleDialog from '@app/hooks/use-simple-dialog';
import { AvailableProcessing, CustomProcessing } from '@shared/api/types';
import {
  CategorizedPicker,
  Category,
} from '@shared/components/form/categorized-picker';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@shared/components/ui/form';
import { cn } from '@shared/lib/utils';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import CreateProcessingForm from './create-ppt-forms/create-processing-form';

interface ProductProcessingPickerProps {
  name: string;
  label?: string;
  availableProcessing: AvailableProcessing;
  index?: number;
  className?: string;
  includeErrorMessage?: boolean;
}

export default function ProductProcessingPicker({
  name,
  availableProcessing,
  index,
  className,
  includeErrorMessage,
  label,
}: ProductProcessingPickerProps) {
  const { control, setValue, getValues } = useFormContext();
  const { showSimpleDialog, closeSimpleDialog, SimpleDialogComponent } =
    useSimpleDialog();

  type AvailableProcessingType = CustomProcessing;

  const categories: Category<AvailableProcessingType>[] = useMemo(() => {
    const customProcessingCategory: Category<CustomProcessing> = {
      name: 'My processing',
      items: availableProcessing.custom,
      searchableKeys: ['name'],
      valueKey: 'uuid',
      labelKey: 'name',
      onSelect: (selectedItem: CustomProcessing) => {
        setValue(`processing.${index}.name`, selectedItem.name);
        setValue(`processing.${index}.processingType`, selectedItem.uuid);
      },
    };
    return [customProcessingCategory] as Category<AvailableProcessingType>[];
  }, [availableProcessing.custom, index, setValue]);

  return (
    <FormField
      control={control}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <>
            <FormItem className={cn('grid flex-1 items-center', className)}>
              {label && <FormLabel>{label}</FormLabel>}
              <FormControl>
                <CategorizedPicker
                  categories={categories}
                  value={field.value}
                  key={categories[0].items.length}
                  error={fieldState.error?.message}
                  onCreate={showSimpleDialog}
                />
              </FormControl>
              {includeErrorMessage &&
                index === getValues('processing').length - 1 && <FormMessage />}
            </FormItem>
            {SimpleDialogComponent({
              header: 'Create Processing Step',
              description: (
                <CreateProcessingForm
                  onProcessingCreated={(data) => {
                    setValue(`processing.${index}.name`, data.name);
                    setValue(`processing.${index}.processingType`, data.uuid);
                    closeSimpleDialog();
                  }}
                />
              ),
            })}
          </>
        );
      }}
    />
  );
}
