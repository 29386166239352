import { AxiosConfigProvider } from '@shared/api/AxiosConfigProvider';
import { ReactNode } from 'react';
import { useAppAxiosConfig } from './config';

function ApiProvider({ children }: { children: ReactNode }) {
  const axiosConfig = useAppAxiosConfig();
  return (
    <AxiosConfigProvider axiosConfig={axiosConfig}>
      {children}
    </AxiosConfigProvider>
  );
}

export default ApiProvider;
