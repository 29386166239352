import useAuth from '@app/auth/use-auth';
import { AnalyticsBrowser, EventProperties } from '@segment/analytics-next';
import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { hotjar } from 'react-hotjar';
import { AnalyticsContext } from './AnalyticsContext';
import { analyticsConfig } from './config';

const analytics = new AnalyticsBrowser();

export const AnalyticsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuth();
  const [enabled, setEnabled] = useState<boolean>(false);

  useEffect(() => {
    if (analyticsConfig.loadAnalytics) {
      analytics.load({
        writeKey: analyticsConfig.segmentWriteKey,
      });
      hotjar.initialize({ id: analyticsConfig.hotjarId, sv: 6 });
      ReactGA.initialize(analyticsConfig.gaTrackingId);
      setEnabled(true);
    }
  }, []);

  useEffect(() => {
    if (!user || !user.uuid || !enabled) return;

    if (user.isStaff) {
      setEnabled(false);
    } else {
      analytics.identify(user.uuid);
    }
  }, [enabled, user]);

  const resetAnalytics = useCallback(() => {
    if (analyticsConfig.loadAnalytics) {
      setEnabled(true);
      analytics.reset();
    }
  }, []);

  const track = useCallback(
    (event: string, properties?: EventProperties) => {
      if (!enabled) return;
      analytics.track(event, properties);
    },
    [enabled]
  );

  const trackPage = useCallback(
    (page?: string) => {
      if (!enabled) return;
      analytics?.page(page);
    },
    [enabled]
  );

  const value = {
    resetAnalytics,
    track,
    trackPage,
  };

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};
