export interface NavItem {
  title: string;
  icon?: React.ReactNode;
  badge?: React.ReactNode;
  newTab?: boolean;
  disabled?: boolean;
  to: string;
}

export interface NavCategory {
  title: string;
  icon?: React.ReactNode;
  items: NavItem[];
}

export enum SidebarItemPosition {
  Top = 'top',
  Bottom = 'bottom',
}

export interface SidebarItem {
  item: NavItem | NavCategory;
  position: SidebarItemPosition;
}
