import { PageHeader } from '@app/components/layout/page/components/page-header';
import { Box, Grid, Typography } from '@mui/material';
import { useGetPackagingDetail } from '@shared/api';
import { useParams } from 'react-router-dom';
import Page from '../../components/layout/page/page';
import StatsCard from '../../components/stats/StatsCard';
import ProductsFilterableTable from '../../components/tables/ProductsFilterableTable';
import { roundNumberToNDecimalPlaces } from '../../utils/helpers';
import ProductPackagingTypes from '../product/tabs/components/ProductPackagingTypes';
import ProductTransportTable from '../product/tabs/components/ProductTransportTable';

const PackagingDetailPage = () => {
  const params = useParams();
  const packagingUuid = params.packaging_id;

  const { data: packagingDetailData, status } = useGetPackagingDetail(
    packagingUuid!
  );

  const breadcrumb = [
    {
      label: 'Packaging',
      url: '/packaging',
    },
    {
      label: packagingDetailData?.name,
      url: ``,
    },
  ];

  return (
    <Page
      name="Packaging Details"
      title={packagingDetailData?.name}
      status={status}
    >
      <PageHeader name={packagingDetailData?.name} breadcrumbs={breadcrumb} />
      {packagingDetailData ? (
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={4}>
              <Box>
                <StatsCard
                  title="Total Emissions Per Product"
                  value={roundNumberToNDecimalPlaces(
                    packagingDetailData.totalEmissionsPerProduct * 1000,
                    1
                  )
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  unit="gCO2e"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={4}>
              <Box>
                <StatsCard
                  title="Average Percentage of Product Emissions"
                  value={
                    packagingDetailData.avgPercOfProductEmissions
                      ? roundNumberToNDecimalPlaces(
                          packagingDetailData.avgPercOfProductEmissions,
                          1
                        )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      : 'N/A'
                  }
                  unit="%"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={4}>
              <Box>
                <StatsCard
                  title="Percentage Recycled"
                  value={
                    packagingDetailData.proportionFromRecycledSources ||
                    packagingDetailData.proportionFromRecycledSources === 0
                      ? roundNumberToNDecimalPlaces(
                          packagingDetailData.proportionFromRecycledSources *
                            100,
                          1
                        )
                      : 'N/A'
                  }
                  unit="%"
                />
              </Box>
            </Grid>
          </Grid>
          {packagingDetailData.items.length > 0 ? (
            <>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  sx={{
                    color: 'text.primary',
                    marginTop: '1.5rem',
                    fontWeight: 600,
                  }}
                >
                  Emissions Results
                </Typography>
              </Grid>
              <ProductPackagingTypes
                description={''}
                items={packagingDetailData.items}
                notesAndAssumptions={[]}
              />
            </>
          ) : null}

          {packagingDetailData.transportTypes.length > 0 ? (
            <Box mb={2}>
              <Typography variant="h6" sx={{ color: 'text.primary', mt: 2 }}>
                Transport Types
              </Typography>
              {packagingDetailData.transportTypes.map((transportType: any) => {
                return (
                  <>
                    <Box mb={1} mt={2}>
                      {transportType.description}
                    </Box>
                    <Box>
                      <ProductTransportTable
                        journeys={transportType.journeys}
                      />
                    </Box>
                  </>
                );
              })}
            </Box>
          ) : null}
          <Box mt={3}>
            <ProductsFilterableTable
              title="Products Using This Packaging"
              products={packagingDetailData.productsUsingPackagingType}
            />
          </Box>
        </div>
      ) : null}
    </Page>
  );
};

export default PackagingDetailPage;
