import { useEffect, useRef, useState } from 'react';

export const useSidebarWidth = () => {
  const [sidebarWidth, setSidebarWidth] = useState(0);
  const sidebarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const sidebar = sidebarRef.current;
    if (!sidebar) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === sidebar) {
          setSidebarWidth(entry.contentRect.width);
        }
      }
    });

    resizeObserver.observe(sidebar);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return { sidebarWidth, sidebarRef };
};
