import { Typography } from '@mui/material';
import Text from '@shared/components/content/text';

import useAuth from '@app/auth/use-auth';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import { roundNumberToNDecimalPlaces } from '../../utils/helpers';

interface AvgProductEmissionsPerKgStatProps {
  avgProductEmissionsPerKg: number;
}

const AvgProductEmissionsPerKgStat = ({
  avgProductEmissionsPerKg,
}: AvgProductEmissionsPerKgStatProps) => {
  const { user } = useAuth();

  if (user.productCategory === 'product') {
    return (
      <div className="bg-gray-50 p-4 md:w-[300px]">
        <div className="pl-2">
          <div className="flex size-11 items-center justify-center rounded-full bg-gray-25">
            <CloudQueueIcon />
          </div>
          <Typography variant="body2" mt={2}>
            Average carbon intensity
          </Typography>
          <div className="flex items-baseline">
            <Typography
              variant={avgProductEmissionsPerKg > 9999 ? 'h5' : 'h4'}
              mt={2}
            >
              <b>
                {roundNumberToNDecimalPlaces(
                  avgProductEmissionsPerKg,
                  2
                ).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </b>
            </Typography>
            <Typography variant="caption" ml={1}>
              kgCO<sub>2</sub>e/kg<sub>food</sub>
            </Typography>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-50 p-4">
      <div className="flex p-4">
        <div className=" mr-8 flex size-11 items-center justify-center rounded-full bg-gray-00 p-4">
          <CloudQueueIcon />
        </div>
        <div>
          <Text variant="body" className="text-nowrap">
            Average carbon intensity
          </Text>
          <div className="flex items-baseline">
            <Typography variant="h4">
              <b>
                {roundNumberToNDecimalPlaces(
                  avgProductEmissionsPerKg,
                  2
                ).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </b>
            </Typography>
            <Typography variant="caption" ml={1}>
              kgCO<sub>2</sub>e/kg<sub>food</sub>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvgProductEmissionsPerKgStat;
