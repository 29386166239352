// React
import * as React from "react";

// Materual UI Components
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

// Helpers
import { roundNumberToNDecimalPlaces } from "../../../../utils/helpers";

interface ProductPackagingTableProps {
  items: {
    description: string;
    weight: number;
    category: string;
    proportionFromRecycledSources: number;
    emissionsPerKg: number;
    numberOfItemsContained: number;
    totalEmissionsPerItem: number;
  }[];
}

const ProductPackagingTable: React.FC<ProductPackagingTableProps> = ({
  items,
}) => {
  return (
    <>
      {items.length === 0 ? (
        <></>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 850 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Description</TableCell>
                <TableCell align="left">Type</TableCell>
                <TableCell align="right">Weight (g)</TableCell>
                <TableCell align="right">Percentage recycled</TableCell>
                <TableCell align="right">No. of products contained</TableCell>
                <TableCell align="right">
                  Carbon Intensity (kgCO<sub>2</sub>e/kg)
                </TableCell>
                <TableCell align="right">
                  Emissions per product (gCO<sub>2</sub>e)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items
                .sort(
                  (i1, i2) =>
                    i2.totalEmissionsPerItem - i1.totalEmissionsPerItem
                )
                .map((item) => (
                  <TableRow
                    key={item.description}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {item.description}
                    </TableCell>
                    <TableCell align="left" size="small">
                      {item.category.charAt(0).toUpperCase() +
                        item.category.slice(1)}
                    </TableCell>
                    <TableCell align="right" size="small">
                      {roundNumberToNDecimalPlaces(item.weight * 1000, 1)}
                    </TableCell>
                    <TableCell align="right" size="small">
                      {roundNumberToNDecimalPlaces(
                        item.proportionFromRecycledSources * 100,
                        1,
                        item.proportionFromRecycledSources * 100 === 100
                          ? false
                          : true
                      )}{" "}
                      {/* // don't add trailing 0 if it's 100% */}
                    </TableCell>
                    <TableCell align="right" size="small">
                      {item.numberOfItemsContained}
                    </TableCell>
                    <TableCell align="right" size="small">
                      {roundNumberToNDecimalPlaces(item.emissionsPerKg, 2)}
                    </TableCell>
                    <TableCell align="right" size="small">
                      {roundNumberToNDecimalPlaces(
                        item.totalEmissionsPerItem * 1000,
                        1
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default ProductPackagingTable;
