import Page from '@app/components/layout/page/page';

import { PageHeader } from '@app/components/layout/page/components/page-header';
import { ErrorBoundary } from '@sentry/react';
import { useEditCollection, useGetCollection } from '@shared/api';
import sentry from '@shared/services/sentry';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import CollectionForm from './components/collection-form';
import { collectionSchema } from './schema';

export default function EditCollectionPage() {
  const { collection_id } = useParams();
  const { data: collectionData, status: collectionStatus } = useGetCollection(
    collection_id!
  );

  const { mutateAsync: editCollection } = useEditCollection();

  const handleSubmit = async (data: z.infer<typeof collectionSchema>) => {
    try {
      const res = await editCollection({
        collectionUuid: collection_id!,
        data: {
          name: data.name,
          description: data.description,
          products: data.products,
        },
      });
      return res;
    } catch (error) {
      sentry.log(error);
    }
  };

  const breadcrumb = [
    {
      label: 'Collections',
      url: '/collections',
    },
    {
      label: `${collectionData?.name}`,
      url: `/collections/${collection_id}`,
    },
    {
      label: 'Edit',
      url: ``,
    },
  ];

  return (
    <Page
      title="Edit Collection"
      name="Edit Collection"
      status={collectionStatus}
    >
      <PageHeader name="Edit Collection" breadcrumbs={breadcrumb} />
      <ErrorBoundary
        fallback={
          <h1>
            Error getting your collection, please try again or contact us if the
            problem persists.
          </h1>
        }
      >
        {collectionData && (
          <CollectionForm
            type="edit"
            collectionData={collectionData}
            handleSubmit={handleSubmit}
          />
        )}
      </ErrorBoundary>
    </Page>
  );
}
