import { PackagingPicker } from '@app/components/product-form/packaging-picker';
import ProductPackagingPicker from '@app/components/product-form/product-packaging-picker';
import { useGetAvailablePackaging } from '@shared/api';
import { AvailablePackaging } from '@shared/api/types';
import { Icons } from '@shared/components/content/icons';
import InputFormField from '@shared/components/form/input-form-field';
import { RepeatableRowComponentProps } from '@shared/components/form/types';
import { Button } from '@shared/components/ui/button';
import { FormLabel } from '@shared/components/ui/form';
import { Skeleton } from '@shared/components/ui/skeleton';
import { cn } from '@shared/lib/utils';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import PackagingSizePicker from './packaging-size-picker';

interface RecipePackagingRowProps extends RepeatableRowComponentProps {}

export interface PackagingOption {
  uuid: string;
  name: string;
  availableSizes: {
    size: string;
    packagingUuid: string;
    totalEmissions: number;
  }[];
}

const formatData = (
  data: AvailablePackaging | undefined
): PackagingOption[] => {
  if (!data || !data.default) return [];

  const groupedData = data.default.reduce<Record<string, PackagingOption>>(
    (acc, item) => {
      if (!acc[item.name]) {
        acc[item.name] = {
          uuid: item.uuid,
          name: item.name,
          availableSizes: [],
        };
      }
      if (item.size) {
        acc[item.name].availableSizes.push({
          size: item.size,
          packagingUuid: item.uuid,
          totalEmissions: item.totalEmissions,
        });
      }
      return acc;
    },
    {}
  );

  return Object.values(groupedData).map((item) => ({
    ...item,
    availableSizes: item.availableSizes.sort(
      (a, b) => a.totalEmissions - b.totalEmissions
    ),
  }));
};

type RecipePackagingRowData = {
  packagingType: string;
  name: string;
  size: string;
};

export default function RecipePackagingRow({
  index,
  remove,
  setDisableAdd,
  variant = 'dish',
}: RecipePackagingRowProps) {
  const {
    data: availablePackaging,
    isLoading: packagingOptionsLoading,
    isError: packagingOptionsError,
  } = useGetAvailablePackaging({
    include: variant === 'product' ? 'custom' : undefined,
  });
  const { getValues, watch, setValue, clearErrors, getFieldState } =
    useFormContext();

  const packagingRows: RecipePackagingRowData[] = getValues('packaging');
  const selectedPackagingRows = packagingRows.map(({ name }, i) => ({
    name,
    index: i,
  }));

  const packagingOptions: PackagingOption[] = useMemo(
    () =>
      formatData(availablePackaging).filter((p) => {
        const selectedRow = selectedPackagingRows.find(
          ({ name }) => name === p.name
        );

        return !selectedRow || selectedRow.index === index;
      }),
    [availablePackaging, index, selectedPackagingRows]
  );

  const availablePackagingOptions: AvailablePackaging = useMemo(() => {
    const availableDefaults: AvailablePackaging['default'] | undefined =
      availablePackaging?.default.filter((p) => {
        const selectedRow = selectedPackagingRows.find(
          ({ name }) => name === p.name
        );
        return !selectedRow || selectedRow.index === index;
      });
    const availableCustoms: AvailablePackaging['custom'] =
      availablePackaging?.custom?.filter((p) => {
        const selectedRow = selectedPackagingRows.find(
          ({ name }) => name === p.name
        );
        return !selectedRow || selectedRow.index === index;
      });

    return {
      default: availableDefaults,
      custom: availableCustoms,
    } as AvailablePackaging;
  }, [availablePackaging, index, selectedPackagingRows]);

  const watchPackagingSize = watch(`packaging.${index}.size`);
  const watchPackagingName = watch(`packaging.${index}.name`);

  useEffect(() => {
    packagingOptionsError ||
    packagingOptionsLoading ||
    packagingOptions.length === 1
      ? setDisableAdd(true)
      : setDisableAdd(false);
  }, [
    packagingOptionsError,
    packagingOptionsLoading,
    packagingOptions,
    setDisableAdd,
  ]);

  useEffect(() => {
    const packagingOption = packagingOptions.find(
      (option) => option.name === watchPackagingName
    );

    if (packagingOption && packagingOption.availableSizes.length > 0) {
      const packagingUuid = packagingOption.availableSizes.find(
        (size) => size.size === watchPackagingSize
      )?.packagingUuid;

      setValue(`packaging.${index}.packagingType`, '');

      if (watchPackagingSize) {
        return setValue(`packaging.${index}.packagingType`, packagingUuid);
      }
    }

    if (packagingOption?.availableSizes.length === 0) {
      if (getFieldState(`packaging.${index}.size`)?.error) {
        clearErrors(`packaging.${index}.size`);
      }

      if (getValues(`packaging.${index}.size`) !== '') {
        setValue(`packaging.${index}.size`, '');
      }
      return setValue(
        `packaging.${index}.packagingType`,
        packagingOption?.uuid
      );
    }
  }, [
    clearErrors,
    getFieldState,
    getValues,
    index,
    packagingOptions,
    setValue,
    watchPackagingName,
    watchPackagingSize,
  ]);

  if (packagingOptionsLoading) {
    return (
      <div className="grid grid-cols-12 items-end gap-2">
        <Skeleton className="col-span-7 h-10 rounded-lg" />
        <Skeleton className="col-span-4 h-10 rounded-lg" />
        <Skeleton className="col-span-1 h-10 rounded-lg" />
      </div>
    );
  }

  if (packagingOptionsError || !packagingOptions) {
    return (
      <div className="mx-auto">
        There was an error loading your packaging options
      </div>
    );
  }

  const availableSizes: string[] =
    packagingOptions
      .find((option) => option.name === watchPackagingName)
      ?.availableSizes.map((size) => size.size) || [];

  return (
    <div className="grid grid-cols-12 items-start gap-2">
      {variant === 'dish' && (
        <PackagingPicker
          name={`packaging.${index}.name`}
          options={packagingOptions}
          showLabel={index === 0}
          className="col-span-7"
        />
      )}
      {variant === 'product' && (
        <ProductPackagingPicker
          name={`packaging.${index}.packagingType`}
          availablePackaging={availablePackagingOptions}
          index={index}
          label={index === 0 ? 'Item' : ''}
          className="col-span-5"
        />
      )}

      <PackagingSizePicker
        sizes={availableSizes}
        name={`packaging.${index}.size`}
        disabled={!watchPackagingName || availableSizes.length === 0}
        showLabel={index === 0}
        className="col-span-4"
      />
      {variant === 'product' && (
        <InputFormField
          name={`packaging.${index}.proportionOfProduct`}
          label={index === 0 ? 'Percentage' : ''}
          disabled={!watchPackagingName}
          type="number"
          min={1}
          className="col-span-2"
          inputClassName="text-right"
        />
      )}
      <div className="col-span-1 grid items-end">
        {index === 0 && (
          <FormLabel className="mb-0 opacity-0">Remove</FormLabel>
        )}
        <Button
          type="button"
          variant="outline"
          size="sm"
          className={cn('size-10', {
            'mt-2': index === 0,
          })}
          onClick={() => {
            setDisableAdd(false);
            remove(index);
          }}
        >
          <Icons.x className="size-4" />
        </Button>
      </div>
    </div>
  );
}
