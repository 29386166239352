import Alert from '@shared/components/alert';
import { ClassValue } from 'clsx';

interface EditingProductCardProps {
  className?: ClassValue;
}

export default function EditingProductCard({
  className,
}: EditingProductCardProps) {
  return (
    <Alert variant="info" title="Editing product" className={className}>
      Changes have been made to this product since the last assessment. You will
      need to complete a new assessment before the changes are visible in your
      dashboard.
    </Alert>
  );
}
